import axios from "axios";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { BoxComponent } from "../../onboarding/box.component";
import { SevenStep } from "./onboarding/steps/seven.step";
import { Environment } from "@environment";
import { EightStep } from "./onboarding/steps/eight.step";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { MainRoutes } from "../../main.routes";


export function NewStudioScreen(properties: {
    navigation: any
}) {
    const navigation = useNavigation<NavigationProp<MainRoutes>>()

    const [step, setStep] = useState(7);
    const [members, setMembers] = useState<number>(1)
    const [studioUsername, setStudioUsername] = useState('')
    const [studioName, setStudioName] = useState('')
    const [addressLatitude, setAddressLatitude] = useState<number>()
    const [addressLongitude, setAddressLongitude] = useState<number>()
    const [addressStreet, setAddressStreet] = useState<string>()
    const [addressCity, setAddressCity] = useState<string>()
    const [addressCountry, setAddressCountry] = useState<string>()
    const [addressState, setAddressState] = useState<string>()
    const [addressZip, setAddressZip] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useRecoilState(userState)
    const [error, setError] = useState<string>()
    const [cache, setCache] = useState<string>()
    const [timezone, setTimezone] = useState<string>('')
    useEffect(() => {
        // get timezone moment
        const time = moment.tz.guess()
        setTimezone(time)
        if (user) {
        }
    }, [])
    return <BoxComponent
        key={`${step}-${error}-${cache}`}
        loading={loading}
        goBack={() => {
            if (step === 7) {
                navigation.navigate('user')
            }
            else setStep(step - 1)
        }}>

        {step === 7 && <SevenStep setStep={setStep}
            error={error}
            validatePartialShop={() => {
                setError(undefined)

                setLoading(true)
                axios.post(`${Environment.api}/group/partial`, {
                    name: studioName,
                    username: studioUsername,
                }, {
                    headers: {
                        Authorization: `Bearer ${user?.accessToken}`
                    }

                }).then((response) => {
                    setLoading(false)
                    setError(undefined)
                    setStep(8)

                }).catch((error) => {
                    setLoading(false)
                    const message = error.response.data.message
                    setError(message)
                    setCache(new Date().getTime().toString())
                })

            }}

            setStudioName={setStudioName} setMembers={setMembers} members={members} setStudioUsername={setStudioUsername} studioName={studioName} studioUsername={studioUsername} />}
        {step === 8 && <EightStep setStep={setStep}
            setAddressLatitude={setAddressLatitude}
            error={error}
            setAddressLongitude={setAddressLongitude}
            setAddressStreet={setAddressStreet}
            setAddressCity={setAddressCity}
            setAddressCountry={setAddressCountry}
            setAddressState={setAddressState}
            setAddressZip={setAddressZip}
            addressLatitude={addressLatitude}
            addressLongitude={addressLongitude}
            addressStreet={addressStreet}
            addressCity={addressCity}
            addressCountry={addressCountry}
            addressState={addressState}
            addressZip={addressZip}
            timezone={timezone}
            setTimezone={setTimezone}
            handlerSubmit={() => {
                setError(undefined)
                setLoading(true)
                axios.post(`${Environment.api}/group`, {
                    name: studioName,
                    username: studioUsername,
                    members: members,
                    addressCity: addressCity,
                    addressCountry: addressCountry,
                    addressLatitude: addressLatitude,
                    addressLongitude: addressLongitude,
                    addressState: addressState,
                    addressStreet: addressStreet,
                    addressZip: addressZip,

                    timezone: timezone
                }, {
                    headers: {
                        Authorization: `Bearer ${user?.accessToken}`
                    }
                }).then((response) => {

                    setTimeout(() => {

                        const group = response.data?.item


                        properties.navigation.reset({
                            index: 0,
                            routes: [{
                                name: 'studio', params: {
                                    screen: 'calendar',
                                    groupId: group.id
                                }
                            }],
                        });


                    }, 1000);

                }).catch((error) => {
                    setLoading(false)
                    const message = error.response.data.message
                    setError(message)
                    setCache(new Date().getTime().toString())
                })
            }
            }
        />}
    </BoxComponent>
}