import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListScheduleScreen } from "./list/list.schedule.screen";
import { EditScheduleScreen } from "./edit/edit.schedule.screen";
import { DeleteScheduleScreen } from "./delete/delete.schedule.screen";
import { NewScheduleScreen } from "./new/new.schedule.screen";
import { ScheduleRoutes } from "./schedule.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";

const stack = createNativeStackNavigator<ScheduleRoutes>();

export function ScheduleNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'schedules'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListScheduleScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewScheduleScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="meet"
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditScheduleScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteScheduleScreen}
        />
    </stack.Navigator>
}
