// Modules
import { StyleSheet } from 'react-native'

/**
 * Input styles
 * @constant {StyleSheet} InputStyles
 */
export const InputStyles = StyleSheet.create({
  component: {
  },
  header: {
    marginBottom: 15,
  },
  field: {
    borderRadius: 0,

    fontSize: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldInput: {
    flex: 1,
    height: 45,
    borderRadius: 5,
    fontSize: 16,
    paddingLeft: 15,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  fieldError: {
    borderColor: '#dc1e3c',
  },
  fieldValid: {},
  title: {
    fontSize: 18,

    fontWeight: 'bold',
  },
  description: {
    fontSize: 14,
    opacity: 0.5,
  },
  error: {
    backgroundColor: '#dc1e3c',
    borderBottomEndRadius: 10,
    borderBottomStartRadius: 10,
    fontSize: 12,
    marginTop: -15,
    zIndex: -1,
  },
  errorText: {
    color: '#fff',
    padding: 10,
    paddingTop: 23,
    paddingLeft: 15,
    fontWeight: 'bold',
  },
  positionRelative: { position: 'relative' },
})
