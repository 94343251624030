import { IconComponent } from "@components/icon/icon.component";
import { IconType } from "@components/icon/icon.types";
import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, Animated, Easing, View } from "react-native";

const defaultColors = ["#4dabf7", "#3bc9db", "#38d9a9", "#69db7c"];

export function LoadingDots({
    dots = 3,
    colors = defaultColors,
    size = 12,
    bounceHeight = 10,
    borderRadius = 10,
    components = null,
}: {
    dots: number,
    colors: string[],
    size: number,
    bounceHeight: number,
    borderRadius: number,

    components: any
}) {
    const [animations, setAnimations] = useState<Animated.Value[]>([]);
    const [reverse, setReverse] = useState(false);

    const opacity = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        const dotAnimations = [];
        let animationsAmount =
            !!components && Array.isArray(components) ? components.length : dots;
        for (let i = 0; i < animationsAmount; i++) {
            const r = new Animated.Value(0)
            dotAnimations.push(r);
        }
        setAnimations(dotAnimations);
    }, []);

    useEffect(() => {
        if (animations.length === 0) return;
        loadingAnimation(animations, reverse);
        appearAnimation();
    }, [animations]);

    function appearAnimation() {
        Animated.timing(opacity, {
            toValue: 1,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start();
    }

    function floatAnimation(node, reverseY, delay) {
        const floatSequence = Animated.sequence([
            // scale 2 to 1
            Animated.timing(node, {
                toValue: 1.1,
                duration: 200,
                easing: Easing.elastic(1.5),
                delay: delay,
                useNativeDriver: true,
            }),
            // scale 1 to 1.5
            Animated.timing(node, {
                toValue: 0,
                duration: 0,
                easing: Easing.elastic(1.5),
                useNativeDriver: true,
            }),
            // scale 1.5 to 1
            Animated.timing(node, {
                toValue: 1.1,
                duration: 400,
                easing: Easing.elastic(1.5),
                useNativeDriver: true,
            }),
        ]);
        return floatSequence;
    }

    function loadingAnimation(nodes, reverseY) {
        Animated.parallel(
            nodes.map((node, index) => floatAnimation(node, reverseY, index * 250))
        ).start(() => {
            setReverse(!reverse);
        });
    }

    useEffect(() => {
        if (animations.length === 0) return;
        loadingAnimation(animations, reverse);
    }, [reverse, animations]);

    const icons: IconType[] = [
        'tint', 'paint-brush', 'capsules'
    ]

    return <View style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,0.9)',
        zIndex: 50,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',

        bottom: 0,
    }}>
        <Animated.View style={[styles.loading, { opacity }]}>
            {animations.map((animation, index) =>
                components ? (
                    <Animated.View
                        key={`loading-anim-${index}`}
                        style={[{ transform: [{ scale: animation }] }]}
                    >
                        {components[index]}
                    </Animated.View>
                ) : (
                    <Animated.View
                        key={`loading-anim-${index}`}
                        style={[
                            {
                                width: size,
                                height: size,
                                borderRadius: borderRadius || size / 2,
                            },
                            { backgroundColor: '#000' },
                            { transform: [{ scale: animation }] },
                        ]}
                    >


                    </Animated.View>
                )
            )}
        </Animated.View>
    </View>


}

const styles = StyleSheet.create({
    loading: {
        display: "flex",
        flexDirection: "row",
        height: 50,
        width: 50,
        alignItems: "center",
        justifyContent: "space-between",
    },
});

