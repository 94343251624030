import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { TemplateRoutes } from "../template.routes";
import { useTranslation } from "react-i18next";

export function EditTemplateScreen() {
    const navigation = useNavigation<NavigationProp<TemplateRoutes>>()
    const route = useRoute<RouteProp<TemplateRoutes, 'edit'>>()
    const { t } = useTranslation()
    return <PutModule<{
        name: string,
        content: string,
        showMaterials: boolean,
        showImages: boolean,
        showSignatures: boolean,
        materialsText: string,
        imagesText: string,
        signaturesText: string,
        showLogotype: boolean,
        showSeal: boolean,
        showBarcode: boolean,
        showFooter: boolean,
        showHeader: boolean,
        customCSS: boolean,
        customCSSText: string,
    }>
        prefix="updateTemplate"
        path="template"
        id={route.params.templateId}
        onSuccess={() => {
            navigation.goBack()
        }}
        width={800}
        dontRenderInLoading
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            },
            {
                onNewLabel: t('newTemplate.content'),

                onNew: (callback) => {
                    navigation.navigate('variables', {
                        groupId: route.params.groupId,
                    })
                },
                name: 'content',
                type: 'editor'
            },
            {
                name: 'showMaterials',
                type: 'checkbox'
            },
            {
                name: 'showImages',
                type: 'checkbox'
            },
            {
                name: 'showSignatures',
                type: 'checkbox'
            },
            {
                name: 'materialsText',
                type: 'editor'
            },
            {
                name: 'imagesText',
                type: 'editor'
            },
            {
                name: 'signaturesText',
                type: 'editor'
            },
        ]}
    />
}
