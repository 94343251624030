// Module
import { StyleSheet } from 'react-native'

/**
 * Knob Styles
 * @constant {StyleSheet} KnobStyles
 */
export const KnobStyles = StyleSheet.create({
  component: {
    backgroundColor: '#000',
    height: 60,
    width: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 30,
    right: 30,
    zIndex: 1,
  },
  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
    left: undefined,
    right: undefined,
    bottom: undefined,
    top: undefined,
  },
  icon: {
    fontSize: 30,
    color: '#FFF',
  },
  bubble: {
    position: 'absolute',
    right: -10,
    top: -10,
    backgroundColor: '#fff',
    width: 30,
    height: 30,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
})

/**
 * Place Component Styles
 * @constant {StyleSheet} PlaceComponentStyles
 */
export const PlaceComponentStyles = StyleSheet.create({
  bottomRight: {
    bottom: 30,
    right: 30,
  },
  bottomLeft: {
    bottom: 30,
    left: 30,
  },
  topRight: {
    top: 30,
    right: 30,
  },
  topLeft: {
    top: 30,
    left: 30,
  },
})
