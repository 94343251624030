// Modules
import { View } from 'react-native'

// Styles
import { LineStyles } from './line.styles'
import { useWeb } from '../../hook/web.hook'

/**
 * Line Component
 * @description This component render a line
 * @function LineComponent
 * @return {JSX.Element}
 */
export function LineComponent(): JSX.Element {
  const isWeb = useWeb()

  return <View style={[LineStyles.component, {
    opacity: isWeb ? 0.6 : 1,
  }]} />
}
