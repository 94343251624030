import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListMeetScreen } from "./list/list.meet.screen";
import { EditMeetScreen } from "./edit/edit.meet.screen";
import { DeleteMeetScreen } from "./delete/delete.meet.screen";
import { MeetRoutes } from "./meet.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { NavigationProp, RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewCustomerScreen } from "../customer/new/new.customer.screen";
import { NewRoomScreen } from "../room/new/new.room.screen";
import { NewMemberScreen } from "../member/new/new.member.screen";
import { NewUserScreen } from "../member/newUser/newUser.member.screen";
import { EditCustomerScreen } from "../customer/edit/edit.customer.screen";
import { NewConsentScreen } from "../consent/new/new.consent.screen";
import { NewReceiptScreen } from "../receipt/new/new.receipt.screen";

const stack = createNativeStackNavigator<MeetRoutes>();

export function MeetNavigator(properties: {
    navigation: NavigationProp<MeetRoutes, 'list'>
    route: {
        params: MeetRoutes['list']
    }
}) {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const groupId = properties.route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >

        <stack.Screen
            name="list"
            component={ListMeetScreen}
            initialParams={{ groupId }}
            options={{
                freezeOnBlur: true,
            }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            name="newCustomer"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewCustomerScreen}
        />
        <stack.Screen
            name="newMeet"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            name="newRoom"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewRoomScreen}
        />
        <stack.Screen
            name="newMember"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewMemberScreen}
        />
        <stack.Screen
            name="editCustomer"
            initialParams={{ groupId, }}
            options={modalOptions}
            component={EditCustomerScreen}
        />
        <stack.Screen
            name="newConsent"
            initialParams={{ groupId, level: 2 }}
            options={modalOptions}
            component={NewConsentScreen}
        />
        <stack.Screen
            name="newReceipt"
            initialParams={{ groupId, level: 2 }}
            options={modalOptions}
            component={NewReceiptScreen}
        />
        <stack.Screen
            name="newUser"
            initialParams={{ groupId, level: 2 }}
            options={modalOptions}
            component={NewUserScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteMeetScreen}
        />

    </stack.Navigator>
}
