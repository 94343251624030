import React, { useEffect, useState } from 'react';
import { Text, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { ServiceInputProperties } from './service.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import axios from 'axios';
import { Environment } from '@environment';
import { useIsFocused } from '@react-navigation/native';
import LoaderComponent from '@components/loader/loader.component';
import { IconComponent } from '@components/icon/icon.component';
import { PriceInputForm } from '../price/price.input';
import { DatetimeInputForm } from '../datetime/datetime.input';
import { ChooseInputForm } from '../choose/choose.input';
import { NumberInputForm } from '../number/number.input';
import { FieldsetFormModule } from '@modules/form/form.module';
import { priceUtility } from '../../../../utilities/price/priceUtility';
import { EmptyComponent } from '@components/empty/empty.component';
import { useCurrencyHook } from '../../../../hooks/currency.hook';
type ServiceType = {
    "id": string
    "createdAt": string
    "name": string
    "color": string
    "exactPrice": number
    "minutePrice": number
    "minimumPrice": number
    "description": string
    "depositPercentage": number
    "notification": boolean
    "notificationMessage": string
    "bookingOnline": boolean
    "type": "EXACT" | "MINUTE"
    "ServiceMembers": {
        "id": string,
        "memberId": string,
        "exactPrice": number,
        "minutePrice": number
        type: 'EXACT' | 'MINUTE'
        "minimumPrice": number,
        "customPrice": boolean,
        "depositPercentage": number,
    }[]
}
export function ServiceInputForm<T>(properties: ServiceInputProperties<T>) {
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState)
    const [search, setSearch] = useState('')
    const [rows, setRows] = useState<ServiceType[]>([])

    // start:
    const [member, setMember] = useState<{
        id: string
        name: string
        commission: number
    }>()
    const startedAt = properties.startedAt
    const finishedAt = properties.finishedAt
    const serviceId = properties.serviceId
    const commission = properties.commission
    const memberId = properties.memberId
    const deposit = properties.deposit
    const budget = properties.budget

    const [service, setService] = useState<ServiceType>()
    const currency = useCurrencyHook(properties.groupId)

    const isFocus = useIsFocused()
    const [noResults, setNoResults] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [labelSelected, setLabelSelected] = useState<string>()
    const [showFinder, setShowFinder] = useState(false)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const getRows = async (search?: string) => {
        if (!user) return
        setLoading(true)
        let queryString = `groupId=${properties.groupId}${search ? `&search=${search}` : ''}`
        if (properties.data && properties.params) {
            properties.params.forEach(param => {
                if (properties?.data?.[param]) queryString += `&${param}=${properties.data[param]}`
            })
        }
        setRows([])

        axios.get(`${Environment.api}/${properties.path}?limit=10&page=${page}&${queryString}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const items = response.data.items
            const total = response.data.total
            setTotal(total)
            setRows(items)
            setLoading(false)
            if (items.length === 0) setNoResults(true)
            else setNoResults(false)
        })
    }

    useEffect(() => {
        showFinder && getRows(search)
    }, [isFocus, showFinder, properties.data, page])

    useEffect(() => {
        setPage(0)
        const timeout = setTimeout(() => {
            getRows(search)
        }
            , 500)
        return () => {
            clearTimeout(timeout)
        }
    }
        , [search])




    const getLabel = (id: string) => {
        if (!user) return
        setLoadingLabel(true)
        axios.get(`${Environment.api}/${properties.path}?groupId=${properties.groupId}&${properties.path}Id=${id}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const item = response.data?.items?.[0]
            if (item) {
                if (properties.onSelectBackground) {
                    properties.onSelectBackground(item)
                }
                setService(item)
            }
            else undefined
        }).finally(() => {
            setLoadingLabel(false)
        })
    }

    useEffect(() => {
        if (properties.serviceId) {
            getLabel(properties.serviceId)
        }
    }, [properties.serviceId])



    // total minutes:
    const totalMinutes = () => {

        if (!startedAt || !finishedAt) return 0
        const dateStart = new Date(startedAt)
        const dateEnd = new Date(finishedAt)
        return (dateEnd.getTime() - dateStart.getTime()) / 1000 / 60
    }

    const minutes = totalMinutes()

    const totalCommisionPrice = () => {

        const total = (deposit || 0) + (budget || 0)
        return total * (commission || 0) / 100
    }

    return <View style={{
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 20,
    }}>

        <DatetimeInputForm

            nameFinish={'finishedAt'} nameStart={'startedAt'} type={'datetime'}
            valueStart={startedAt} valueFinish={finishedAt}
            onChange={(start, end) => {

                properties.onChangeStartedAt?.(start)
                properties.onChangeFinishedAt?.(end)
            }} />
        <View style={{
            height: 20,
        }} />

        <FieldsetFormModule

            name='memberId' noDescription prefix={`${properties.prefix}`}>
            <ChooseInputForm


                renderLabel={(item) => { return `${item?.User?.name} ${item?.User?.surname}` }}
                name='memberId'
                type='choose'
                value={memberId}
                path='member'
                groupId={properties.groupId}
                onSelectBackground={(item) => {
                    setMember(item)
                    properties.onChangeMember?.(item.id)
                    properties.commission === undefined && properties.onChangeCommission?.(item.commission)
                }}
                onSelect={(value) => {
                    setMember(value)
                    properties.onChangeMember?.(value.id)

                    properties.onChangeCommission?.(value.commission)
                }}
            />

        </FieldsetFormModule>
        {
            member && <>
                <FieldsetFormModule name='service' noDescription prefix={`${properties.prefix}`}>
                    <View style={{
                        borderWidth: 1,
                        borderColor: '#ddd',
                        padding: 0,
                        borderRadius: 5,
                    }}>
                        {
                            loadingLabel && <LoaderComponent />
                        }
                        {
                            service ? <TouchableOpacity onPress={() => {
                                setShowFinder(!showFinder)
                            }} style={{
                                padding: 10,

                            }}>
                                <TextComponent>{service.name}</TextComponent>
                                <IconComponent name="angle-down" style={{
                                    box: {
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                    },
                                    icon: {

                                        fontSize: 20,
                                    }
                                }} />
                            </TouchableOpacity> : <TouchableOpacity onPress={() => {
                                setShowFinder(!showFinder)
                            }} style={{
                                padding: 10,

                            }}>
                                <TextComponent>{
                                    t('choose.select')
                                }</TextComponent>
                                <IconComponent name="angle-down" style={{
                                    box: {
                                        position: 'absolute',
                                        right: 20,
                                        top: 0,
                                        bottom: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    },
                                    icon: {

                                        fontSize: 16,
                                    }
                                }} />
                            </TouchableOpacity>
                        }

                        {showFinder && <>

                            <View >
                                <IconComponent name='search' style={{
                                    box: {
                                        position: 'absolute',
                                        top: 4,
                                        left: 7,
                                        height: 30,
                                        width: 30,
                                        borderRadius: 35,
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                    },
                                    icon: {
                                        fontSize: 20,
                                        color: '#333',

                                    }
                                }}
                                />

                                <TextInputNative
                                    style={{
                                        borderTopColor: '#ddd',
                                        borderTopWidth: 1,
                                        borderWidth: 0,
                                        borderColor: '#ddd',
                                        padding: 10,
                                        paddingLeft: 50,
                                        borderLeftWidth: 0,
                                        borderRightWidth: 0,
                                        borderRadius: 0,
                                    }}
                                    placeholder={t(`search`)}
                                    value={search}
                                    onSubmitEditing={() => {
                                        getRows(search)
                                    }}
                                    onChangeText={(value) => {
                                        setSearch(value);
                                    }}
                                />
                            </View>
                            {
                                noResults && <EmptyComponent />
                            }
                            {
                                loading && <LoaderComponent />
                            }
                            {
                                rows.map((row, index) => {

                                    // this memeber have a custom price?
                                    const memberService = row.ServiceMembers.find(xxx => xxx.memberId === member.id)
                                    let newExactPrice: number | undefined = row.exactPrice
                                    let newMinutePrice: number | undefined = row.minutePrice
                                    let newMinimumPrice: number | undefined = row.minimumPrice
                                    let newDepositPercentage: number | undefined = row.depositPercentage
                                    let newType: 'EXACT' | 'MINUTE' | undefined = row.type
                                    if (memberService) {
                                        newExactPrice = memberService.exactPrice
                                        newMinutePrice = memberService.minutePrice
                                        newMinimumPrice = memberService.minimumPrice
                                        newDepositPercentage = memberService.depositPercentage
                                        newType = memberService.type

                                    }

                                    // calculate real price
                                    const priceTotal = newType === 'EXACT' ? newExactPrice : newMinutePrice * minutes / 60
                                    const serviceTotal = row.type === 'EXACT' ? row.exactPrice : row.minutePrice * minutes / 60

                                    // calculate minimum price
                                    const finishPrice = priceTotal < newMinimumPrice ? newMinimumPrice : priceTotal
                                    const serviceTotalFinish = serviceTotal < row.minimumPrice ? row.minimumPrice : serviceTotal

                                    // calculate commision
                                    const depositTotal = newDepositPercentage ? newDepositPercentage / 100 * finishPrice : 0

                                    const isSelected = service?.id === row.id
                                    return <TouchableOpacity key={index} onPress={() => {
                                        setService(row)
                                        setShowFinder(false)
                                        properties.onChangeDeposit?.(depositTotal)
                                        properties.onChangeBudget?.(finishPrice - depositTotal)
                                        properties.onChangeService?.(row.id)
                                    }} style={{
                                        padding: 10,
                                        paddingVertical: 10,
                                        marginHorizontal: 5,
                                        borderRadius: 3,
                                        marginBottom: 2,
                                        flexDirection: 'row',
                                        backgroundColor: '#444',
                                        borderColor: isSelected ? '#65c366' : '#eee',

                                    }}>
                                        <View style={{
                                            marginRight: 10,
                                            backgroundColor: isSelected ? '#65c366' : 'rgba(0,0,0,0.2)',
                                            height: 31,
                                            width: 31,
                                            borderRadius: 53,
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            {
                                                isSelected && <IconComponent name="check" style={{
                                                    box: {
                                                        width: 15,
                                                        height: 15,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: 45,
                                                    },
                                                    icon: {
                                                        fontSize: 14,
                                                        color: '#fff',
                                                    }
                                                }} />
                                            }
                                        </View>
                                        <View style={{
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                        }}>

                                            <TextComponent styles={{
                                                text: {
                                                    fontWeight: '600',
                                                    fontSize: 14,
                                                    color: isSelected ? '#65c366' : '#fff'
                                                }
                                            }}>{row.name}</TextComponent>
                                            {
                                                memberService && <TextComponent styles={{
                                                    layout: {
                                                        position: 'absolute',
                                                        bottom: -7,
                                                        left: 0,
                                                        height: 15,
                                                        marginLeft: 'auto',
                                                        backgroundColor: '#333',
                                                        borderRadius: 35,
                                                        paddingHorizontal: 5,
                                                    },
                                                    text: {
                                                        height: 15,
                                                        lineHeight: 15,
                                                        color: '#fff',
                                                        fontSize: 8,
                                                        fontWeight: 'bold'
                                                    }
                                                }}>
                                                    {
                                                        member.name
                                                    }
                                                </TextComponent>
                                            }
                                        </View>
                                        <TextComponent styles={{
                                            layout: {
                                                height: 16,
                                                position: 'absolute',
                                                top: '50%',
                                                marginTop: -8,
                                                right: 195,
                                                zIndex: 1100,
                                                paddingHorizontal: 5,
                                                backgroundColor: '#6575c3',
                                                borderRadius: 445,
                                            },
                                            text: {
                                                lineHeight: 16,
                                                height: 16,
                                                fontSize: 10,
                                                color: '#fff',
                                                fontWeight: 'bold'
                                            }
                                        }}>{
                                                t(`service.${row.type}`)
                                            }</TextComponent>
                                        <View style={{
                                            gap: 8,
                                            width: 180,
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            borderTopEndRadius: 5,
                                            borderBottomEndRadius: 5,
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            borderLeftColor: 'rgba(0,0,0,0.1)',
                                            borderLeftWidth: 1,
                                            marginLeft: 'auto',
                                            backgroundColor: '#222',
                                            paddingLeft: 5,
                                            flexDirection: 'row',
                                        }}>

                                            <View style={{
                                                paddingHorizontal: 8,
                                                flex: 1,
                                                borderRightColor: 'rgba(255,255,255,0.1)',
                                                borderRightWidth: 1,
                                                flexDirection: 'column',
                                                gap: 0,
                                                alignContent: 'center',
                                            }}>

                                                <TextComponent styles={{
                                                    layout: {
                                                        height: 15,

                                                    },
                                                    text: {
                                                        height: 15,
                                                        lineHeight: 15,
                                                        fontSize: 11,
                                                        fontWeight: 'bold',
                                                        color: '#65c366'
                                                    }
                                                }}>
                                                    {
                                                        priceUtility({
                                                            price: depositTotal
                                                        })
                                                    }

                                                </TextComponent>
                                                <TextComponent styles={{
                                                    layout: {


                                                    },
                                                    text: {
                                                        fontSize: 7, color: '#fff', opacity: 0.7,

                                                        fontWeight: '400'
                                                    }
                                                }}>
                                                    {t('service.deposit')}
                                                </TextComponent>
                                            </View>
                                            <View style={{
                                                paddingHorizontal: 8,
                                                flex: 1,
                                                flexDirection: 'column',
                                                gap: 0,
                                                alignContent: 'center',
                                            }}>

                                                <TextComponent styles={{
                                                    layout: {
                                                        height: 15,

                                                    },
                                                    text: {
                                                        height: 15,
                                                        lineHeight: 15,
                                                        fontSize: 11, color: '#65c366',
                                                        fontWeight: 'bold'
                                                    }
                                                }}>
                                                    {
                                                        priceUtility({
                                                            price: finishPrice - depositTotal
                                                        })
                                                    }

                                                </TextComponent>
                                                <TextComponent styles={{
                                                    layout: {

                                                    },
                                                    text: {
                                                        fontSize: 7, color: '#fff', opacity: 0.7,

                                                        fontWeight: '400'
                                                    }
                                                }}>
                                                    {t('service.budget')}
                                                </TextComponent>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                })
                            }
                            {
                                rows.length > 0 && <View style={{
                                    height: 3,
                                }}></View>
                            }
                        </>}


                    </View>
                </FieldsetFormModule>


                <View style={{
                    flexDirection: 'row',
                    gap: 20,
                }}>
                    <View style={{
                        flex: 1
                    }}>
                        <FieldsetFormModule name='deposit' noDescription prefix={`${properties.prefix}`}>

                            <PriceInputForm
                                type='price'
                                value={deposit}
                                name={'deposit'}
                                onChange={(value) => {
                                    properties.onChangeDeposit?.(value)
                                }}
                                groupId={properties.groupId}
                            />
                        </FieldsetFormModule>
                    </View>
                    <View style={{
                        flex: 1
                    }}>
                        <FieldsetFormModule name='budget' noDescription prefix={`${properties.prefix}`}>

                            <PriceInputForm
                                type='price'
                                name={'budget'}
                                value={budget}
                                onChange={(value) => {
                                    properties.onChangeBudget?.(value)
                                }}
                                groupId={properties.groupId}
                            />
                        </FieldsetFormModule>
                    </View>
                </View>
                <FieldsetFormModule name='commission' prefix={`${properties.prefix}`}>

                    <NumberInputForm
                        type='number'
                        currency="%"
                        onChange={(value) => {
                            properties.onChangeCommission?.(value)
                        }}
                        value={commission}
                        name={'commission'}
                    />
                </FieldsetFormModule>
            </>
        }
        <View>

        </View>
        <RenderTotal
            minutes={minutes}
            budget={budget || 0}
            total={(deposit || 0) + (budget || 0)}
            commission={commission || 0}
            deposit={deposit || 0}
            currency={`${currency.currency || ''}`} />
    </View >
}

const RenderTotal = (properties: {
    total: number
    minutes: number
    budget: number
    commission: number
    deposit: number
    currency?: string
}) => {
    const { t } = useTranslation()
    const niceTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60)
        const minutesLeft = minutes % 60
        return `${hours}h ${minutesLeft}m`
    }
    const commisionTotal = (properties.deposit + properties.budget) * properties.commission / 100
    return <View style={{
        marginTop: 'auto'
    }}>
        <View style={{
            backgroundColor: '#eee',
            borderColor: 'rgba(0,0,0,0.1)',
            borderWidth: 1,
            borderRadius: 5,
            overflow: 'hidden',
        }}>
            <View style={{

                flexDirection: 'row',
                borderBottomColor: 'rgba(0,0,0,0.1)',
                borderBottomWidth: 1,
                paddingVertical: 4,
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,

                }}>
                    <IconComponent name="clock" style={{
                        box: {
                            width: 20,
                            height: 20,
                            justifyContent: 'center',
                            alignItems: 'center',

                            borderRadius: 45,
                        },
                        icon: {
                            fontSize: 19,
                            color: '#333', opacity: 0.7,
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,

                            fontWeight: '500',
                            paddingLeft: 7,

                        }
                    }}>
                        {t('service.totalTime')}
                    </TextComponent>
                </View>
                <View style={{
                    marginLeft: 'auto',
                    paddingRight: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 14,
                            fontWeight: 'bold', textAlign: 'right'
                        }
                    }}>{niceTime(properties.minutes)}
                    </TextComponent>
                </View>

            </View>




            <View style={{
                paddingVertical: 4,
                flexDirection: 'row',
                borderBottomColor: 'rgba(0,0,0,0.1)',
                borderBottomWidth: 1,
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,

                }}>
                    <IconComponent name="hand-holding-usd" style={{
                        box: {
                            width: 20,
                            height: 20,
                            justifyContent: 'center',
                            alignItems: 'center',

                            borderRadius: 45,
                        },
                        icon: {
                            fontSize: 19,
                            color: '#333', opacity: 0.7,
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,
                            fontWeight: '500',
                            paddingLeft: 7,

                        }
                    }}>
                        {t('service.totalDeposit')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190,
                    paddingRight: 10,

                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 14,
                            fontWeight: 'bold', textAlign: 'right'
                        }
                    }}>{priceUtility({
                        price: properties.deposit || 0
                    })
                        }
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 14,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>{properties.currency}</TextComponent></TextComponent>
                </View>

            </View>
            <View style={{
                paddingVertical: 4,
                flexDirection: 'row',
                borderBottomColor: 'rgba(0,0,0,0.1)',
                borderBottomWidth: 1,
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,

                }}>
                    <IconComponent name="coins" style={{
                        box: {
                            width: 20,
                            height: 20,
                            justifyContent: 'center',
                            alignItems: 'center',

                            borderRadius: 45,
                        },
                        icon: {
                            fontSize: 19,
                            color: '#333', opacity: 0.7,
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,

                            fontWeight: '500',
                            paddingLeft: 7,

                        }
                    }}>
                        {t('service.totalBudget')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190,
                    paddingRight: 10,

                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 14,
                            fontWeight: 'bold', textAlign: 'right'
                        }
                    }}>{priceUtility({
                        price: properties.budget || 0
                    })
                        }
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 14,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>{properties.currency}</TextComponent></TextComponent>
                </View>

            </View>
            <View style={{
                paddingVertical: 4,
                flexDirection: 'row',
                borderBottomColor: 'rgba(0,0,0,0.1)',
                borderBottomWidth: 1,
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 5,

                }}>
                    <IconComponent name="funnel-dollar" style={{
                        box: {
                            width: 20,
                            height: 20,
                            justifyContent: 'center',
                            alignItems: 'center',

                            borderRadius: 45,
                        },
                        icon: {
                            fontSize: 19,
                            color: '#333', opacity: 0.7,
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,

                            fontWeight: '500',
                            paddingLeft: 7,

                        }
                    }}>
                        {t('service.totalCommission')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190,
                    paddingRight: 10,

                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 14,
                            fontWeight: 'bold', textAlign: 'right'
                        }
                    }}>{priceUtility({
                        price: commisionTotal || 0
                    })
                        }
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 14,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>{properties.currency}</TextComponent></TextComponent>
                </View>

            </View>
            <View style={{
                backgroundColor: '#fff',
                flexDirection: 'row',
                paddingVertical: 10,
            }}>
                <View style={{

                    flex: 1,

                    paddingLeft: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 17,
                            textAlign: 'left',
                            fontWeight: '600',
                            textTransform: 'uppercase'
                        }
                    }}>
                        {t('products.totalPrice')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190,
                    paddingRight: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 25,
                            fontWeight: 'bold',
                            textAlign: 'right'
                        }
                    }}>{properties.total && priceUtility({
                        price: properties.total

                    }) || '0,00'}
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 25,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>
                            {properties.currency}
                        </TextComponent>
                    </TextComponent>
                </View>

            </View>
        </View>
    </View>

}