import { Image, Text, TouchableOpacity, View } from "react-native";
import { CardSubscriptionType, SubscriptionType } from "../subscription.types";
import { IconComponent } from "@components/icon/icon.component";
import { useTranslation } from "react-i18next";
const visa = require('./visa.png')
const mastercard = require('./mastercard.png')
const card = require('./card.png')
export function CardNoPayPaymentComponent(properties: {

    subscription: SubscriptionType,
    getUrlStripe: () => void
}) {
    const { t } = useTranslation()
    return <View style={{
        backgroundColor: '#111',
        flexDirection: 'row',
        gap: 40
    }}>
        <TouchableOpacity style={{
            // credit card dimensions
            width: 300,
            borderColor: 'red',
            backgroundColor: '#222',
            height: 200,
            padding: 20,
            borderWidth: 0,
            borderRadius: 13,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        }}
            onPress={properties.getUrlStripe}
        >
            <Image source={card} style={{
                opacity: 0.4,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            />
            <View style={{
                backgroundColor: 'rgba(255, 25, 89,0.3)',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                borderRadius: 13,
            }}></View>
            <View style={{

                zIndex: 111111,
                borderRadius: 43,
                borderWidth: 2,
                padding: 7,
                paddingHorizontal: 15,
                borderColor: 'rgb(255, 25, 89)',
            }}>
                <Text style={{
                    color: 'white',
                    textTransform: 'uppercase',

                    fontSize: 14,
                    fontWeight: '500',

                }}>
                    {t('subscription.addNewCard')}
                </Text>
            </View>


        </TouchableOpacity>
    </View>
}