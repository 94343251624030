import { NavigationContainer, NavigationProp } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { userState } from "@states/user.state";
import { useRecoilState } from "recoil";
import { useWeb } from "../hook/web.hook";
import { MainLinking } from "./main.linking";
import { MainRoutes } from "./main.routes";
import { StudioNavigator, } from "./studio/studio.screens";
import { UserNavigator } from "./user/user.navigator";
import { WelcomeNavigator } from "./welcome/welcome.navigator";
import { NewStudioScreen } from "./studio/new/new.screen";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProfessionaScreen, ProfessionalNavigator } from "./professional/professional.screen";
import { Platform } from "react-native";
import { RecoveryScene } from "./recovery/recovery.scene";
import { RecoveryPasswordScene } from "./recoveryPassword/recoveryPassword.scene";
import Toast from "react-native-toast-message";

export type RootStackParamList = MainRoutes;
export type MainStackNavigation = NavigationProp<MainRoutes>;
const Drawer = createNativeStackNavigator<RootStackParamList>();

export function MainScreens() {
    const [user,] = useRecoilState(userState)
    const { t, i18n, } = useTranslation()

    useEffect(() => {

        if (user?.language) {
            i18n.changeLanguage(user.language)
        }
        if (Platform.OS === 'web') {
            // StorageEvent sessionDestroy true
            window.addEventListener('storage', (event) => {
                if (event.key === 'sessionDestroy') {
                    // value true
                    if (event.newValue === 'true') {
                        window.location.reload()
                    }
                    // delete key
                    window.localStorage.removeItem('sessionDestroy')
                }
            })
            // detect history change:

        }
    }, [])


    return (
        <NavigationContainer linking={MainLinking}

            documentTitle={{
                enabled: Platform.OS === 'web',
                formatter: (options, route) => {
                    console.log({ options, route })
                    const path = route?.path;
                    if (path) {
                        // split in /
                        const paths = path.split('/')
                        // remove first
                        paths.shift()
                        // remove second
                        paths.shift()
                        paths.shift()
                        // now join two columns:
                        if (paths.length === 0) {
                            return `INKONSKY`
                        }
                        if (paths.length === 1) {
                            return `${t(`title.${paths[0]}`)} - INKONSKY`
                        }
                        return `${t(`title.${paths[0]}.${paths[1]}`)} - INKONSKY`

                    }
                    return `${t(`title.${route?.name}`)} - INKONSKY`
                },

            }}
        >
            <Drawer.Navigator
                screenOptions={{
                    headerShown: false,

                }} >


                <Drawer.Screen name="user" component={UserNavigator} />
                <Drawer.Screen name="studio" component={StudioNavigator} />

                <Drawer.Screen name="new" component={NewStudioScreen} />
                <Drawer.Screen name="professional" component={ProfessionalNavigator} />

                <Drawer.Screen name="welcome" component={WelcomeNavigator} />
                <Drawer.Screen name="recovery" component={RecoveryScene} />
                <Drawer.Screen name="recoveryPassword" component={RecoveryPasswordScene} />

            </Drawer.Navigator >
        </NavigationContainer >
    );
}


