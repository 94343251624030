import { IconComponent } from "@components/icon/icon.component";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useWeb } from "../../hook/web.hook";
import { useWidth } from "../../hook/isWidth";
import { ReactNode } from "react";

export function ModalComponent(properties: {
  onClose?: () => void;
  title: string;
  noHeader?: boolean;
  scrollable?: boolean;
  description: string;
  children: any;
  headerBottomComponent?: () => ReactNode;
  overlay?: boolean;
  loading?: boolean;
  maxWidth?: number;
  position?: "left";
  level?: number;
  contentFlex?: number;
}) {
  const width = useWidth();
  const isWeb = useWeb();
  if (isWeb === undefined) return null;

  const renderContent = () => {
    return (
      <>
        {properties.onClose && (
          <IconComponent
            name="times"
            style={{
              box: {
                position: "absolute",
                top: 15,
                right: 15,
                backgroundColor: "#eee",
                borderRadius: 20,
                height: 40,
                width: 40,
                justifyContent: "center",
                alignItems: "center",
                zIndex: 100,
                opacity: 1,
              },
              icon: {
                color: "black",
                fontSize: 16,
              },
            }}
            onPress={() => {
              properties.onClose?.();
            }}
          />
        )}
        {properties.noHeader ? null : (
          <>
            <View
              style={{
                padding: 40,
                paddingTop: width.laptop ? 40 : 30,
                paddingLeft: width.laptop ? 40 : 30,
                paddingBottom: 0,
                opacity:
                  typeof properties.loading === "boolean" && properties.loading
                    ? 0
                    : 1,
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: "600",
                }}
              >
                {properties.title}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  opacity: 0.5,
                }}
              >
                {properties.description}
              </Text>
            </View>
            {properties.headerBottomComponent &&
              properties.headerBottomComponent()}
          </>
        )}
        <View
          style={{
            marginTop: properties.noHeader ? 0 : 30,
            flex: 1,
            overflow: "hidden",
          }}
        >
          {properties.children}
        </View>
      </>
    );
  };
  return (
    <View
      style={{
        flex: 1,
        backgroundColor:
          properties.overlay === false ? "transparent" : "rgba(0,0,0,0.2)",
      }}
    >
      {properties.onClose && isWeb && properties.overlay !== false && (
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.2)",
            opacity: 0.5,
          }}
          onPress={() => {
            properties.onClose?.();
          }}
        />
      )}

      <View
        style={{
          flex: 1,
          flexDirection: "column",

          marginBottom: !width.laptop ? 0 : 40,
          marginTop: !width.laptop
            ? 0
            : properties.level
            ? properties.level * 20 + 40
            : 40,
          marginRight: !width.laptop
            ? 0
            : properties.level
            ? properties.level * 20 + 40
            : 40,
          width: "100%",
          overflow: "hidden",
          maxWidth: !isWeb ? undefined : properties.maxWidth || 600,
          marginLeft: properties.position !== "left" ? "auto" : 60,
        }}
      >
        <View
          style={{
            maxHeight: "100%",
            flex: properties.contentFlex
              ? properties.contentFlex
              : !width.laptop
              ? 1
              : undefined,
            shadowColor: !isWeb ? undefined : "#000",
            shadowOffset: !isWeb
              ? undefined
              : {
                  width: 0,
                  height: 2,
                },
            borderRadius: !width.laptop ? 0 : 10,
            overflow: "hidden",
            shadowOpacity: !isWeb ? undefined : 0.25,
            shadowRadius: !isWeb ? undefined : 73.84,
            backgroundColor: "#fff",
          }}
        >
          {properties.scrollable ? (
            <ScrollView
              scrollEnabled={properties.scrollable || false}
              style={{
                flex: 1,
              }}
            >
              {renderContent()}
            </ScrollView>
          ) : (
            renderContent()
          )}
        </View>
      </View>
    </View>
  );
}
