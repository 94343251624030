import { IconComponent } from "@components/icon/icon.component";
import { MapComponent } from "@components/map/map.component";
import { TimezoneModule } from "@modules/selectors/timezone/timezone.module";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import { BoxTitle, ErrorRender } from "../../../../onboarding/box.component";

export function EightStep(properties: {
    setStep: (step: number) => void
    setAddressLatitude: (latitude: number) => void
    setAddressLongitude: (longitude: number) => void
    setAddressStreet: (street: string) => void
    setAddressCity: (city: string) => void
    setAddressCountry: (country: string) => void
    setAddressState: (state: string) => void
    setAddressZip: (zip: string) => void
    addressLatitude?: number
    addressLongitude?: number
    addressStreet?: string
    addressCity?: string
    addressCountry?: string
    addressState?: string
    addressZip?: string
    handlerSubmit: () => void
    setTimezone: (timezone: string) => void
    timezone?: string
    error?: string
}) {

    const { t, i18n } = useTranslation()

    return <View style={{
        maxWidth: 700,
        width: '100%',
        padding: 20,
        overflow: 'visible',
        marginHorizontal: 'auto',
    }}>
        <BoxTitle title={t('onboarding.eight.title')}

            subtitle={t('onboarding.eight.subtitle')} />
        {
            properties.error && <ErrorRender message={properties.error} />
        }
        <Text style={{
            color: '#000',
            fontWeight: 'bold',
            marginTop: 20,
            fontSize: 20,
            paddingVertical: 10,
        }}>
            {t('onboarding.eight.timezone')}
        </Text>
        <View style={{
            flexDirection: 'column',
            backgroundColor: '#fff',
            borderRadius: 10,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 6,
            },
            overflow: 'hidden',
            shadowOpacity: 0.1,
            shadowRadius: 26.00,
        }}>
            <TimezoneModule timezone={properties.timezone} onSelect={(item) => {
                properties.setTimezone(item.id)
            }} />
        </View>

        <Text style={{
            color: '#000',
            fontWeight: 'bold',
            marginTop: 20,
            fontSize: 20,
            paddingVertical: 10,
        }}>
            {t('onboarding.eight.address')}
        </Text>
        <View style={{

            flexDirection: 'column',
            backgroundColor: '#fff',
            borderRadius: 10,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 6,
            },
            overflow: 'hidden',
            shadowOpacity: 0.1,
            shadowRadius: 26.00,
        }}>
            <MapComponent addressCity={properties.addressCity}
                addressCountry={properties.addressCountry}
                addressLatitude={properties.addressLatitude}
                addressLongitude={properties.addressLongitude}
                addressState={properties.addressState}
                addressStreet={properties.addressStreet}
                addressZip={properties.addressZip}

                onChange={(address) => {
                    properties.setAddressLatitude(address.addressLatitude)
                    properties.setAddressLongitude(address.addressLongitude)
                    properties.setAddressStreet(address.addressStreet)
                    properties.setAddressCity(address.addressCity)
                    properties.setAddressCountry(address.addressCountry)
                    properties.setAddressState(address.addressState)
                    properties.setAddressZip(address.addressZip)


                }} />
        </View>

        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 30
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: '#333',
                    padding: 20,
                    paddingHorizontal: 40,
                    borderRadius: 10,
                    alignSelf: 'center',
                    margin: 10,
                    marginTop: 0,
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
                onPress={() => {
                    properties.handlerSubmit()
                }}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                    {t('onboarding.eight.submit')}

                </Text>
                <IconComponent name="arrow-right" style={{
                    icon: {
                        color: '#fff',
                        fontSize: 30,
                        textAlign: 'center',
                    }
                }} />
            </TouchableOpacity>
        </View>
    </View>

}