import { ScrollView, Text } from "react-native"
import { ModalComponent } from "../../@components/modal/modal.component"
import { userState } from "@states/user.state"
import { useRecoilState } from "recoil"
import { useState } from "react"
import axios from "axios"
import { Environment } from "@environment"
import { SubmitComponent } from "@components/submit/submit.component"
import { SwitchComponent } from "@components/switch/switch.component"
import { View } from "react-native"
import { useTranslation } from "react-i18next"
import LoaderComponent from "@components/loader/loader.component"
import Toast from "react-native-toast-message"
import { counterState } from "@states/counter.state"
import { CheckboxInputForm } from "@modules/form/inputs/checkbox/checkbox.input"

export function DeleteComponent(properties: {
    prefix: string,
    path: string,
    id: string,
    groupId?: string
    goBack: () => void
    onDeleted: () => void
}) {
    const { t } = useTranslation()
    const [user, setUser] = useRecoilState(userState)
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [confirm, setConfirm] = useState<boolean>(false)
    const [counters, setCounters] = useRecoilState(counterState)
    const handlerDelete = () => {
        if (!user) return;
        if (!properties.id) return;
        setLoading(true)
        axios.delete(`${Environment.api}/${properties.path}?${properties.path}Id=${properties.id}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            Toast.show({
                type: 'error',
                text1: t(`${properties.prefix}.deleteSuccessTitle`),

            })
            setLoading(false)
            setCounters(new Date().getTime())
            // reset navigation and go to rooms
            properties.onDeleted()
        }).catch((error) => {
            setLoading(false)
            const message = error.response.data.message
            setError(message)
        })
    }
    return <ModalComponent
        title={t(`${properties.prefix}.title`)}
        maxWidth={400}
        description={t(`${properties.prefix}.description`)}
        onClose={properties.goBack}
    >
        {loading ? <LoaderComponent /> : null}
        <ScrollView style={{
            borderTopColor: 'rgba(0,0,0,0.1)',
            borderTopWidth: 1,
            padding: 20,
            paddingTop: 20
        }}>
            {
                error ?
                    <View
                        style={{ marginBottom: 20, marginTop: 6, backgroundColor: 'rgba(255,0,0,0.1)', padding: 10, paddingHorizontal: 13, borderRadius: 5 }}>
                        <Text style={{ color: 'red', fontWeight: 'bold' }}>{t(`${error}`)}</Text>
                    </View>
                    : null
            }
            <View style={{
                height: 0
            }}></View>
            <Text style={{
                paddingHorizontal: 0,
                paddingBottom: 20,
            }}>
                {t(`${properties.prefix}.legal`)}
            </Text>
            <CheckboxInputForm
                prefix={properties.prefix}
                name={'confirm'}
                type="checkbox"
                value={confirm}
                onChange={setConfirm}
            />

            {confirm && <SubmitComponent
                title={t(`${properties.prefix}.submit`)}
                onPress={handlerDelete}
            />}
        </ScrollView>
    </ModalComponent>
}