import { TableComponent } from "@components/table/table.component";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useCurrencyHook } from "../../../../hooks/currency.hook";
import { MeetRoutes } from "../meet.routes";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";

export function ListMeetScreen(properties: {
    navigation: NavigationProp<MeetRoutes, 'list'>
    route: {
        params: MeetRoutes['list']
    }
}) {
    const { currency } = useCurrencyHook(properties.route?.params?.groupId)
    const permissions = usePermissionsHook(properties.route?.params?.groupId)
    console.log('permissions', permissions)
    return <TableComponent<{
        id: string
        name: string
        createdAt: string
        budget: number
        customerId: string
        deposit: number
        Customer: {
            name: string
            surname: string
        },
        Member: {
            name: string
            color: string
        },
        startedAt: string
        finishedAt: string
    }>
        groupId={properties.route?.params?.groupId}

        groupBy="startedAt"
        prefix="listMeet"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        properties.navigation.navigate('new', {
                            groupId: properties.route?.params?.groupId

                        })
                    }
                }
            ]
        }}
        paginator={{
            limit: 40
        }}
        search={true}
        pagination={false}
        membering={permissions?.permissions?.meeterAll ? true : false}
        path={`meet?groupId=${properties.route?.params.groupId}&old=true${properties.route?.params.customerId ? `&customerId=${properties.route?.params.customerId}` : ''}${properties.route?.params.memberId ? `&memberId=${properties.route?.params.memberId}` : ''}`}
        filters={properties.route?.params.customerId ? [
            {
                name: 'customerId',
                value: properties.route?.params.customerId,
                onPress: () => {
                    properties.navigation.navigate('list', {
                        groupId: properties.route?.params.groupId
                    })
                }
            }
        ] : properties.route?.params.memberId ? [
            {
                name: 'memberId',
                value: properties.route?.params.memberId,
                onPress: () => {
                    properties.navigation.navigate('list', {
                        groupId: properties.route?.params.groupId
                    })
                }
            }
        ] : undefined}
        columns={[

            {
                always: true,
                menu: {
                    label: 'update',
                    icon: 'pen',
                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    properties.navigation.navigate('edit', {
                        groupId: properties.route?.params.groupId,
                        meetId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'startedAt',
                getDateStart: (item) => { return item.startedAt },
                getDateEnd: (item) => { return item.finishedAt },
                type: 'range',
                width: 130,
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                // subtitle: {
                //     phone: true,
                //     render: (item) => {
                //         // render HH:MM - HH:MM
                //         const startedAt = new Date(item.startedAt)
                //         const finishedAt = new Date(item.finishedAt)
                //         // force minutes with 2 digits
                //         const startedAtMinutes = startedAt.getMinutes() < 10 ? `0${startedAt.getMinutes()}` : startedAt.getMinutes()
                //         const finishedAtMinutes = finishedAt.getMinutes() < 10 ? `0${finishedAt.getMinutes()}` : finishedAt.getMinutes()
                //         return `${startedAt.getHours()}:${startedAtMinutes} - ${finishedAt.getHours()}:${finishedAtMinutes}`

                //     },
                // },
                width: 200,
                type: 'text',
            },


            {
                menu: {
                    label: 'customer',
                    icon: 'user-tag',
                    onPress: (data) => {

                        properties.navigation.navigate('editCustomer', {
                            customerId: data.customerId,
                            groupId: properties.route?.params.groupId
                        })
                    }
                },
                phone: false,
                title: 'Customer',
                renderLabel: (item) => { return `${item.Customer.name} ${item.Customer.surname}` },
                width: 140,
                fontWeight: '500',
                type: 'text',
            },
            {
                phone: false,

                title: 'member',
                renderLabel: (item) => { return `${item?.Member?.name}` },
                renderColor: (item) => { return item?.Member?.color },
                width: 200,
                type: 'member',
            },



            {

                phone: false,
                title: 'budget',
                renderPrice: (item) => { return item.budget },
                renderCurrency: () => { return `${currency}` },
                width: 120,
                type: 'price',
            },
            {
                phone: false,
                title: 'deposit',
                renderPrice: (item) => { return item.deposit },
                renderCurrency: () => { return `${currency}` },
                width: 120,
                type: 'price',
            },

            {
                menu: {
                    label: 'delete',
                    icon: 'trash-alt',
                },
                phone: false,
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    properties.navigation.navigate('delete', {
                        groupId: properties.route?.params.groupId,
                        meetId: item.id
                    })
                }
            },

        ]}

    />

}
