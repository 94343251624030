import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { RoomRoutes } from "../room.routes";

export function ListRoomScreen() {
    const navigation = useNavigation<NavigationProp<RoomRoutes, 'list'>>()
    const route = useRoute<RouteProp<RoomRoutes, 'list'>>()
    return <TableComponent<{
        id: string
        name: string
        createdAt: string
    }>
        groupId={route.params.groupId}

        prefix="listRoom"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`room?groupId=${route.params.groupId}`}
        columns={[
            {
                always: true,
                menu: {
                    icon: 'pen',
                    label: 'edit',

                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        roomId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                width: 200,
                type: 'text',
            },
            {
                title: 'createdAt',
                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200,
            },
            {
                label: 'createMeet',
                width: 150,
                type: 'button',
                variant: 'primary',
                icon: 'plus',
                onPress: (item) => {
                    navigation.navigate('meet', {
                        groupId: route.params.groupId,
                        roomId: item.id
                    })
                },
                menu: {
                    icon: 'calendar-plus',
                    label: 'createMeet',

                },

            },
            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',

                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        roomId: item.id
                    })
                }
            },
        ]}

    />

}
