import { Linking, ScrollView, Text, View } from "react-native";
import { AccordionComponent } from "../../../components/accordion/accordion.component";
import { SectionComponent } from "../../../components/section/section.component";
import { LineComponent } from "../../../components/line/line.component";
import { InputComponent } from "../../../components/input/input.component";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { MapComponent } from "@components/map/map.component";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import axios from "axios";
import { KnobComponent } from "@components/knob/knob.component";
import { ErrorComponent } from "@components/error/error.component";
import { Environment } from "../../../../environment";
import { LanguageModule } from "@modules/selectors/language/language.module";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "@components/switch/switch.component";
import { SpaceComponent } from "@components/space/space.component";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LoaderComponent from "@components/loader/loader.component";
import { ButtonComponent } from "@components/button/button.component";
import { TextComponent } from "@components/text/text.component";
import { FilmComponent } from "@components/film/film.component";
import { SubmitComponent } from "@components/submit/submit.component";
import { ContainerFormModule } from "@modules/form/form.module";
const videoBlue = require('../../../../assets/blue.mp4')

export function DeleteAccountScreen() {
    const [user, setUser] = useRecoilState(userState)
    const [confirm, setConfirm] = useState<boolean>(false)
    const { t } = useTranslation()
    return <FilmComponent video={videoBlue} noClose prefix="delete-account">
        <ContainerFormModule prefix="delete-account" onSubmit={confirm ? () => {
            if (!user) return;
            axios.delete(`${Environment.api}/me?userId=${user.id}&confirm=${confirm}`, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {
                setUser(null)
                Linking.openURL('/')
            })

        } : undefined}>
            <View style={{
                padding: 20
            }}>
                <TextComponent
                    styles={{
                        text: {
                            fontSize: 13,
                            lineHeight: 18
                        }
                    }}
                    content={t('delete-account.information')} />

            </View>
            <SwitchComponent label={t('delete-account.agree')} value={confirm} onChange={setConfirm} />


        </ContainerFormModule>


    </FilmComponent >
}