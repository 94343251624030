import { IconComponent } from "@components/icon/icon.component";
import { TextComponent } from "@components/text/text.component";
import { View } from "react-native";
import { ToastConfig } from "react-native-toast-message";

export const toastConfig: ToastConfig
    = {
    /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
    success: (props) => (
        <View style={{
            height: 60,
            width: 270,
            top: 0,

            left: 80,
            alignContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 20,
            position: 'absolute',
            backgroundColor: 'rgb(22, 199, 89)',
        }}>
            <IconComponent name="check" style={{
                box: {

                    justifyContent: 'center',
                    alignItems: 'center',
                },
                icon: {
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 'bold',
                }
            }} />
            <TextComponent styles={{
                text: {
                    color: '#fff',
                    padding: 10,
                    fontSize: 12,
                    fontWeight: 'bold',
                }
            }}>
                {props.text1}
            </TextComponent>
        </View>
    ),
    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    error: (props) => (
        <View style={{
            height: 60,
            width: 270,
            top: 0,
            left: 80,
            alignContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 20,
            position: 'absolute',
            backgroundColor: 'rgb(255, 40, 40)',
        }}>
            <IconComponent name="times" style={{
                box: {

                    justifyContent: 'center',
                    alignItems: 'center',
                },
                icon: {
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 'bold',
                }
            }} />
            <TextComponent styles={{
                text: {
                    color: '#fff',
                    padding: 10, fontSize: 12,
                    fontWeight: 'bold',
                }
            }}>
                {props.text1}
            </TextComponent>
        </View>
    ),

};