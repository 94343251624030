import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ConsentRoutes } from "../consent.routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useDrawerStatus } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import { menuState } from "@states/menu.state";
import { IconComponent } from "@components/icon/icon.component";
import { Document, Page, pdfjs } from "react-pdf";
import { userState } from "@states/user.state";
import { TextComponent } from "@components/text/text.component";
import { SignatureComponent } from "@components/signature/signature.component";
import { Environment } from "@environment";
import axios from "axios";
import LoaderComponent from "@components/loader/loader.component";
import { useWidth } from "../../../../hook/isWidth";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export function SignatureConsentScreen() {
  const navigation = useNavigation<NavigationProp<ConsentRoutes>>();
  const route = useRoute<RouteProp<ConsentRoutes, "signature">>();
  const [showSignatures, setShowSignatures] = useState<boolean>(false);
  const [menu, setMenu] = useRecoilState(menuState);
  const [user, setUser] = useRecoilState(userState);
  const widthDevice = useWidth();
  const [pages, setPages] = useState<number>(1);
  const file = useMemo(
    () => `${Environment.api}/consent/pdf?consentId=${route.params.consentId}`,
    [route.params.consentId]
  );
  const [pdfurl, setPdfUrl] = useState<string>();
  const [customerSignature, setCustomerSignature] = useState<string>();
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [signatureWidth, setSignatureWidth] = useState<number>(0);
  const [loadingFinish, setLoadingFinish] = useState<boolean>(false);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
  useEffect(() => {
    // get consent /consent?consentId=1
    if (!user) return;
    axios
      .get(
        `${Environment.api}/consent?consentId=${route.params.consentId}&groupId=${route.params.groupId}`,
        {
          headers: {
            authorization: `Bearer ${user?.accessToken}`,
          },
        }
      )
      .then((data) => {
        const item = data.data.items[0];
        if (item.signatureCustomer) {
          setIsSigned(true);
        }
        setCustomerSignature(item.signatureCustomer);
      });
    fetch(file, {
      headers: {
        authorization: `Bearer ${user?.accessToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPdfUrl(data.url);
      });
  }, []);

  if (!pdfurl)
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#333",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        onLayout={(event) => {
          const width = event.nativeEvent.layout.width;
          setWidth(width - 60);
        }}
      >
        <LoaderComponent />
      </View>
    );

  if (loadingFinish) {
    return <LoaderComponent />;
  }

  return (
    <View
      style={{
        backgroundColor: "#333",
        flex: 1,
      }}
    >


      <ScrollView
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          flex: 1,
          alignContent: "center",
          paddingTop: 40,
          alignItems: "center",
          paddingBottom: 40,
        }}
        onLayout={(event) => {
          const width = event.nativeEvent.layout.width;
          setWidth(width - 30);
        }}
      >
        {
          loadingDocument && <View style={{
            zIndex: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'red'
          }}><LoaderComponent /></View>
        }
        <IconComponent
          onPress={() => {
            navigation.navigate("list", {
              groupId: route.params.groupId,
            });
            setShowSignatures(false);
            setMenu({
              show: true,
            });
          }}
          style={{
            box: {
              position: "absolute",
              top: 20,
              zIndex: 100,
              right: 20,
              height: 50,
              width: 50,
              borderRadius: 30,
              backgroundColor: "rgba(255,255,255,0.1)",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            },
            icon: {
              color: "white",
              fontSize: 20,
            },
          }}
          name="times"
        />
        <IconComponent
          onPress={() => {
            // download pdf
            const link = document.createElement("a");
            link.href = pdfurl;
            const name = `${route.params.consentId}.pdf`;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
          style={{
            box: {
              position: "absolute",
              top: 20,
              zIndex: 100,
              right: 80,
              height: 50,
              width: 50,
              borderRadius: 30,
              backgroundColor: "rgba(255,255,255,0.1)",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            },
            icon: {
              color: "white",
              fontSize: 20,
            },
          }}
          name="download"
        />
        <View
          style={{
            padding: 30,
            flexDirection: widthDevice.computer ? "row" : "column",
            paddingBottom: widthDevice.laptop ? 0 : 50,
            marginTop: 50,
          }}
        >

          <View>
            {pdfurl && width && (
              <Document
                onLoadError={console.error}
                file={pdfurl}
                onLoadStart={() => {
                  setLoadingDocument(true)
                }}
                onLoadSuccess={({ numPages }) => {
                  setLoadingDocument(false)
                  setPages(numPages);
                }}
              >
                <PageRender pages={pages} width={width} />
              </Document>
            )}
          </View>
          {!isSigned && (
            <View
              onLayout={(event) => {
                const width = event.nativeEvent.layout.width;
                setSignatureWidth(width);
              }}
              style={{
                marginLeft: width > 900 ? 30 : 0,
                borderLeftColor: "rgba(255,255,255,0.2)",
                borderLeftWidth: widthDevice.laptop ? 1 : 0,
                borderStyle: "dashed",
                flex: 1,
                paddingLeft: 10,
                maxWidth: 370,
                width: "100%",
              }}
            >
              <TextComponent
                styles={{
                  text: {
                    fontSize: 30,
                    paddingLeft: 20,
                    fontWeight: "bold",
                    color: "white",
                  },
                }}
              >
                Firma
              </TextComponent>
              <TextComponent
                styles={{
                  text: {
                    paddingLeft: 20,
                    fontSize: 16,
                    lineHeight: 16,
                    marginTop: 15,
                    opacity: 0.6,
                    color: "white",
                  },
                }}
              >
                Lea detenidamente el contenido de este documento y si está de
                acuerdo firme en el recuadro
              </TextComponent>
              <View>
                <SignatureComponent
                  defaultValue={customerSignature}
                  onChange={(signature) => {
                    setCustomerSignature(signature);
                  }}
                />
                {customerSignature && (
                  <IconComponent
                    name="times"
                    onPress={() => {
                      setCustomerSignature("");
                    }}
                    style={{
                      box: {
                        height: 50,
                        width: 50,
                        borderRadius: 25,
                        backgroundColor: "red",
                        position: "absolute",
                        top: -10,
                        right: -10,
                        zIndex: 100,
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      icon: {
                        color: "white",
                      },
                    }}
                  />
                )}
              </View>
              <TouchableOpacity
                style={{
                  marginHorizontal: 20,
                  backgroundColor: "#65c366",
                  padding: 13,
                  borderRadius: 5,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={() => {
                  if (!user) return;
                  setLoadingFinish(true);
                  axios
                    .put(
                      `${Environment.api}/consent/signature?consentId=${route.params.consentId}&groupId=${route.params.groupId}`,
                      {
                        signatureCustomer: customerSignature,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                      }
                    )
                    .then((response) => {
                      navigation.navigate("list", {
                        groupId: route.params.groupId,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      setLoadingFinish(false);
                    });
                }}
              >
                <TextComponent
                  styles={{
                    text: {
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                    },
                  }}
                >
                  ACEPTAR CONSENTIMIENTO
                </TextComponent>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

function PageRender(properties: { pages: number; width?: number }) {
  const [page, setPage] = useState<number>(1);
  const width = useWidth();
  console.log({ xxxx: properties.width });
  return (
    <>
      {properties.width && (
        <>
          <Page
            // a4
            width={
              properties.width > 700
                ? 700
                : properties.width < 600
                  ? properties.width
                  : 600
            }
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={page}
            canvasBackground="#fff"
          />
          <View
            style={{
              flexDirection: "row",
              gap: 20,
              marginTop: 20,
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconComponent
              onPress={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
              name="angle-left"
              style={{
                box: {
                  backgroundColor: "rgba(0,0,0,0.1)",
                  borderRadius: 30,
                  height: 60,
                  width: 60,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                },
                icon: {
                  fontSize: 30,
                  color: "white",
                },
              }}
            />
            <TextComponent
              styles={{
                text: {
                  color: "white",
                  fontSize: 20,
                },
              }}
            >
              {page} / {properties.pages}
            </TextComponent>
            <IconComponent
              onPress={() => {
                if (page < properties.pages) {
                  setPage(page + 1);
                }
              }}
              name="angle-right"
              style={{
                box: {
                  backgroundColor: "rgba(0,0,0,0.1)",
                  borderRadius: 30,
                  height: 60,
                  width: 60,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                },
                icon: {
                  fontSize: 30,
                  color: "white",
                },
              }}
            />
          </View>
        </>
      )}
    </>
  );
}
