import { Environment } from "@environment";
import {
  ContainerFormModule,
  ErrorModule,
  FieldsetFormModule,
} from "@modules/form/form.module";
import { AccordionInputForm } from "@modules/form/inputs/accordion/accordion.input";
import { ChooseInputForm } from "@modules/form/inputs/choose/choose.input";
import { EditorComponent } from "@modules/form/inputs/editor/textarea.component";
import { ImagesInputForm } from "@modules/form/inputs/images/images.input";
import { MaterialsInputForm } from "@modules/form/inputs/materials/materials.input";
import { SignatureInputForm } from "@modules/form/inputs/signature/signature.input";
import { TextInputForm } from "@modules/form/inputs/text/text.input";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useRecoilState } from "recoil";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { ConsentRoutes } from "../consent.routes";
import LoaderComponent from "@components/loader/loader.component";

// component with function that will be call from parent with ref

interface newConsent {
  customerId: string;
  memberId: string;
  meetId: string;
  products: {
    units: number;
    productId: string;
    price: number;
    tax: number;
    name: string;
  }[];
  mode: "deposit" | "budget";
  method: string;
}

export function NewConsentForm() {
  const navigation = useNavigation<NavigationProp<ConsentRoutes>>();
  const route = useRoute<RouteProp<ConsentRoutes, "new">>();
  const [user] = useRecoilState(userState);
  const [materials, setMaterials] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const { t } = useTranslation();
  // values
  const [error, setError] = useState<string>();
  const [customerId, setCustomerId] = useState<string | undefined>(
    route.params.customerId
  );
  const [memberId, setMemberId] = useState<string>();
  const [meetId, setMeetId] = useState<string>();
  const [templateId, setTemplateId] = useState<string>();
  const [images, setImages] = useState<string[]>([]);
  const [content, setContent] = useState<string>();
  const [consent, setConsent] = useState<any>();
  const [signatureMember, setSignatureMember] = useState<string>();
  const [cacheSignature, setCacheSignature] = useState<string>();
  const [tutorName, setTutorName] = useState<string>();
  const [tutorSurname, setTutorSurname] = useState<string>();
  const [tutorDocument, setTutorDocument] = useState<string>();
  const [reload, setReload] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (route.params.memberId) {
      setMemberId(route.params.memberId);
    }
    if (route.params.customerId) {
      setCustomerId(route.params.customerId);
    }

    if (route.params.templateId) {
      axios
        .get(
          `${Environment.api}/template?templateId=${route.params.templateId}&groupId=${route.params.groupId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        )
        .then((response) => {
          const item = response.data.items[0];
          setTemplateId(item.id);
          setContent(item.content);
          setConsent({
            templateId: item.id,
            ...item,
          });
          setReload(new Date().getTime());
        })
        .catch((error) => {
          setError(error.response.data.message);
        });
    }
  }, []);
  return (
    <ModalComponent
      title={t("newConsent.title")}
      description={t("newConsent.description")}
      onClose={() => {
        navigation.goBack();
      }}
    >
      <ContainerFormModule
        key={reload}
        error={
          error
            ? {
              inputName: "newConsent",
              message: error,
            }
            : undefined
        }
        prefix="newConsent"
        onSubmit={() => {
          if (!user) return;
          setLoading(true);
          axios
            .post(
              `${Environment.api}/consent`,
              {
                customerId,
                memberId,
                groupId: route.params.groupId,
                ...consent,
                content,
                images: images,
                tutorDocument,
                tutorName,
                tutorSurname,
                signatureMember,
                materials,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.accessToken}`,
                },
              }
            )
            .then((response) => {
              const id: string = response.data.items?.[0]?.id;

              navigation.goBack();
              setTimeout(() => {
                navigation.navigate("consents", {
                  groupId: route.params.groupId,
                });
                setTimeout(() => {
                  navigation.navigate("signature", {
                    groupId: route.params.groupId,
                    consentId: id,
                  });
                }, 100);
              }, 100);
            })
            .catch((error) => {
              setError(error.response.data.message);
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {error && <ErrorModule message={error} />}
        {loading && <LoaderComponent />}
        {!route.params.customerId && (
          <FieldsetFormModule
            prefix="newConsent"
            name="customerId"
            error={
              error
                ? {
                  inputName: "customerId",
                  message: error,
                }
                : undefined
            }
          >
            <ChooseInputForm<newConsent>
              value={customerId}
              name="customerId"
              type="choose"
              renderLabel={(item) => {
                return `${item.name} ${item.surname}`;
              }}
              onNew={() => {
                navigation.navigate("newCustomer", {
                  groupId: route.params.groupId,
                });
              }}
              path="customer"
              groupId={route.params.groupId}
              onSelect={(item) => {
                setCustomerId(item.id);
              }}
            />
          </FieldsetFormModule>
        )}
        <AccordionInputForm
          type="accordion"
          name="tutor"
          prefix="newConsentTutor"
          inputs={[]}
        >
          <FieldsetFormModule prefix="newConsentTutor" name="name">
            <TextInputForm
              type="text"
              name="name"
              prefix="newConsentTutor"
              value={tutorName}
              onChange={(value) => {
                setTutorName(value);
              }}
            />
          </FieldsetFormModule>
          <FieldsetFormModule prefix="newConsentTutor" name="surname">
            <TextInputForm
              type="text"
              name="surname"
              prefix="newConsentTutor"
              value={tutorSurname}
              onChange={(value) => {
                setTutorSurname(value);
              }}
            />
          </FieldsetFormModule>

          <FieldsetFormModule prefix="newConsentTutor" name="document">
            <TextInputForm
              type="text"
              name="document"
              prefix="newConsentTutor"
              value={tutorDocument}
              onChange={(value) => {
                setTutorDocument(value);
              }}
            />
          </FieldsetFormModule>
        </AccordionInputForm>
        <View style={{ height: 20 }} />
        <FieldsetFormModule
          prefix="newConsent"
          name="memberId"
          error={
            error
              ? {
                inputName: "memberId",
                message: error,
              }
              : undefined
          }
        >
          <ChooseInputForm<newConsent>
            value={memberId}
            name="memberId"
            type="choose"
            renderLabel={(item) => {
              return item.name;
            }}
            path="member"
            groupId={route.params.groupId}
            onSelectBackground={(item) => {
              setMemberId(item.id);
              if (item.User.signature) {
                setSignatureMember(item.User.signature);
              } else {
                setSignatureMember("");
              }
              setCacheSignature(new Date().getTime().toString());
            }}
            onSelect={(item) => {
              setMemberId(item.id);
              if (item.User.signature) {
                setSignatureMember(item.User.signature);
              } else {
                setSignatureMember("");
              }
              setCacheSignature(new Date().getTime().toString());
            }}
          />
        </FieldsetFormModule>
        <FieldsetFormModule
          prefix="newConsent"
          name="templateId"
          error={
            error
              ? {
                inputName: "templateId",
                message: error,
              }
              : undefined
          }
        >
          <ChooseInputForm<newConsent>
            value={templateId}
            name="templateId"
            type="choose"
            path="template"
            groupId={route.params.groupId}
            onSelect={(item) => {
              setTemplateId(item.id);
              setContent(item.content);
              setConsent(item);
            }}
          />
        </FieldsetFormModule>

        <FieldsetFormModule
          prefix="newConsent"
          name="content"
          key={templateId}
          error={
            error
              ? {
                inputName: "content",
                message: error,
              }
              : undefined
          }
        >
          <EditorComponent<newConsent>
            value={content}
            name="content"
            type="editor"
            onChange={(value) => {
              setContent(value);
            }}
          />
        </FieldsetFormModule>
        <FieldsetFormModule
          prefix="newConsent"
          name="signatureMember"
          error={
            error
              ? {
                inputName: "signatureMember",
                message: error,
              }
              : undefined
          }
        >
          <SignatureInputForm<newConsent>
            value={signatureMember}
            key={cacheSignature}
            eraser={true}
            name="signatureMember"
            type="signature"
            onChange={(value) => {
              setSignatureMember(value);
            }}
          />
        </FieldsetFormModule>
        <FieldsetFormModule prefix="newConsent" name="images">
          <ImagesInputForm<newConsent>
            images={images}
            name="images"
            type="images"
            onChange={(value) => {
              setImages(value);
            }}
          />
        </FieldsetFormModule>
        <FieldsetFormModule prefix="newConsent" name="materials">
          <MaterialsInputForm<newConsent>
            value={materials}
            name="materials"
            type="materials"
            onChange={(value) => {
              setMaterials(value);
            }}
            groupId={route.params.groupId}
          />
        </FieldsetFormModule>
      </ContainerFormModule>
    </ModalComponent>
  );
}
