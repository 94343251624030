import { Picker } from "@react-native-picker/picker"
import { useState } from "react"
import { Image, TouchableOpacity, View } from "react-native"
import * as ImagePicker from 'expo-image-picker';
import { TextComponent } from "@components/text/text.component";
import { IconComponent } from "@components/icon/icon.component";
import { InputStyles } from "@components/input/input.styles";
import { useTranslation } from "react-i18next";
export function PickerImage(properties: {
    imageURL?: string,
    onChange: (value: any) => void
}) {
    const { t } = useTranslation()
    const maxWidth = 1000
    const maxHeight = 1000
    const [imageSource, setImageSource] = useState<string | undefined>()
    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 4],
            quality: 1,
        });


        if (!result.canceled) {
            setImageSource(result.assets[0].uri);
            properties.onChange(result.assets[0].uri)
        }
    };

    return <View>
        {
            imageSource && <Image source={{ uri: imageSource }} style={{
                width: '100%',
                aspectRatio: 1
            }} />
        }
        {
            imageSource && <TouchableOpacity style={{
                position: 'absolute',
                right: 20,
                top: 20,
                height: 50,
                width: 50,
                backgroundColor: '#fff',
                borderRadius: 100,
                justifyContent: 'center',
                alignItems: 'center',
            }} onPress={() => {
                setImageSource(undefined)
                properties.onChange(undefined)
            }}
            >
                <IconComponent name='times' style={{
                    icon: {
                        fontSize: 22
                    }
                }} />
            </TouchableOpacity>
        }
        {!imageSource && <TouchableOpacity onPress={pickImage} style={{

        }}>
            <View style={[InputStyles.fieldInput, {
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                gap: 10,
                padding: 10,
                paddingVertical: 6,
            }]}>
                <IconComponent name='image' style={{
                    icon: {
                        opacity: 0.3,
                        fontSize: 20
                    }
                }} />
                <TextComponent content={t('SELECT_IMAGE')} styles={{
                    text: {
                        borderRadius: 0,
                        fontSize: 14,
                        fontWeight: '500',
                        color: '#111'
                    }
                }
                } />
            </View>
        </TouchableOpacity>}

    </View>
}