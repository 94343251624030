import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { ReceiptRoutes } from "../receipt.routes";

export function DeleteReceiptScreen() {
    const navigation = useNavigation<NavigationProp<ReceiptRoutes, 'delete'>>()
    const route = useRoute<RouteProp<ReceiptRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteReceipt'}
        path={'receipt'}
        id={route.params.receiptId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

