import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import GooglePlacesAutocomplete from './components/searchWeb'
import { GooglePlaceData, GooglePlaceDetail } from './components/search.types';
import { LineComponent } from '../line/line.component';
import { InputComponent } from '../input/input.component';
import GoogleMapReact from 'google-map-react';
import { geocodeByPlaceId } from './components/searchWeb'
import { useTranslation } from 'react-i18next';
import { MapStyles } from './map.styles';

export function MapComponent(properties: {
    addressLatitude?: number,
    addressLongitude?: number,
    addressStreet?: string,
    addressCity?: string,
    addressState?: string,
    addressCountry?: string,
    addressZip?: string,
    onChange: (address: {
        addressLatitude: number,
        addressLongitude: number,
        addressStreet: string,
        addressCity: string,
        addressState: string,
        addressCountry: string,
        addressZip: string,
    }) => void

}) {
    const { t } = useTranslation()
    const mapRef = React.useRef(null);
    const googleKey = 'AIzaSyBqy88ky_7Mr9gYt7UockAKGX1YNTJ_eIs'
    const [region, setRegion] = React.useState({
        latitude: properties.addressLatitude || 37.78825,
        longitude: properties.addressLongitude || -122.4324,

    })
    const [value, setValue] = useState(null);

    const [delta, setDelta] = React.useState({
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    })
    const [street, setStreet] = React.useState('')
    const [city, setCity] = React.useState('')
    const [state, setState] = React.useState('')
    const [country, setCountry] = React.useState('')
    const [zip, setZip] = React.useState('')
    const [zoom, setZoom] = React.useState<number>(0)
    useEffect(() => {
        if (properties.addressLatitude && properties.addressLongitude) {
            setRegion({
                latitude: properties.addressLatitude,
                longitude: properties.addressLongitude,

            })
            if (properties.addressLatitude && properties.addressLongitude) {

            }
        }
        if (properties.addressStreet) {
            setStreet(properties.addressStreet)
        }
        if (properties.addressCity) {
            setCity(properties.addressCity)
        }
        if (properties.addressState) {
            setState(properties.addressState)
        }
        if (properties.addressCountry) {
            setCountry(properties.addressCountry)
        }
        if (properties.addressZip) {
            setZip(properties.addressZip)
        }
    }, [])

    useEffect(() => {
        properties.onChange({
            addressLatitude: region.latitude,
            addressLongitude: region.longitude,
            addressStreet: street,
            addressCity: city,
            addressState: state,
            addressCountry: country,
            addressZip: zip,
        })
    }, [region, street, city, state, country, zip])


    return (
        <View style={styles.container}>

            <View style={{
                position: 'absolute',
                top: 25,
                left: 25,
                right: 25,
                zIndex: 1,
                // shadow
                shadowColor: '#000',
                shadowOffset: {
                    width: 0,
                    height: 3,
                },
                shadowOpacity: 0.45,
                shadowRadius: 53.84,

            }}>
                <Text>{value}</Text>
                <GooglePlacesAutocomplete
                    apiKey={googleKey}
                    apiOptions={
                        {
                            libraries: ['geometry', 'places', 'visualization']
                        }
                    }
                    selectProps={{
                        value,
                        onChange: (ss, rrr) => {
                            geocodeByPlaceId(ss?.value.place_id)
                                .then(results => {
                                    const detail = results?.[0]
                                    if (detail) {
                                        if (detail) {
                                            // 'details' is provided when fetchDetails = true
                                            const latitude = detail?.geometry?.location?.lat()
                                            const longitude = detail?.geometry?.location?.lng()
                                            // @ts-ignore
                                            if (latitude && longitude) {
                                                setRegion({
                                                    latitude,
                                                    longitude,

                                                })
                                            }
                                            // get postal_code
                                            const postalCode = detail?.address_components?.find?.((component) => {
                                                return component.types.includes('postal_code')
                                            })
                                            postalCode && setZip(postalCode?.long_name)
                                            // get street and number
                                            const street = detail?.address_components.find?.((component) => {
                                                return component.types.includes('route')
                                            }
                                            )
                                            const number = detail?.address_components.find?.((component) => {
                                                return component.types.includes('street_number')
                                            }
                                            )
                                            street && setStreet(`${street?.long_name}${number?.long_name ? ` ${number?.long_name}` : ''}`)

                                            // get city
                                            const city = detail?.address_components.find?.((component) => {
                                                return component.types.includes('locality')
                                            }
                                            )
                                            city && setCity(city?.long_name)

                                            // get state
                                            const state = detail?.address_components.find?.((component) => {
                                                return component.types.includes('administrative_area_level_1')
                                            }
                                            )
                                            state && setState(state?.long_name)

                                            // get country
                                            const country = detail?.address_components.find?.((component) => {
                                                return component.types.includes('country')
                                            }
                                            )

                                            country && setCountry(country?.long_name)
                                        }
                                    }


                                })
                                .catch(error => console.error(error));


                        },
                    }}

                />
            </View>
            <View style={styles.map}>
                <GoogleMapReact
                    style={styles.map}
                    ref={mapRef}

                    options={{
                        styles: MapStyles,
                        fullscreenControl: false,
                    }}
                    center={{
                        lat: region.latitude,
                        lng: region.longitude,
                    }}
                    onChange={(location) => {
                        const lat = location?.center?.lat
                        const lng = location?.center?.lng
                        setRegion({
                            latitude: lat,
                            longitude: lng,

                        })
                    }}

                    bootstrapURLKeys={{ key: googleKey }}
                    defaultCenter={{
                        lat: region.latitude,
                        lng: region.longitude,
                    }}
                    defaultZoom={11}

                >

                </GoogleMapReact>
                <Image
                    resizeMode='contain'
                    source={require('./marker.png')}
                    style={{
                        left: '50%',
                        pointerEvents: 'none',
                        top: '50%',
                        marginTop: -30,
                        marginLeft: -15,
                        position: 'absolute',
                        height: 30,
                        width: 30,
                        zIndex: 12222,
                    }}
                />
            </View>
            <View style={{
                backgroundColor: '#fff',
                padding: 20
            }}>
                <InputComponent title={t('ADDRESS_STREET')}
                    value={street} onChange={setStreet} />
                <InputComponent title={t('ADDRESS_CITY')}
                    value={city} onChange={setCity} />
                <InputComponent title={t('ADDRESS_STATE')}
                    value={state} onChange={setState} />
                <InputComponent title={t('ADDRESS_COUNTRY')}
                    value={country} onChange={setCountry} />
                <InputComponent
                    title={t('ADDRESS_ZIP')}
                    value={zip} onChange={setZip}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    map: {
        width: '100%',
        height: 300,
    },
});