import { EmptyComponent } from "@components/empty/empty.component";
import { IconComponent } from "@components/icon/icon.component";
import { SpaceComponent } from "@components/space/space.component";
import { TextComponent } from "@components/text/text.component";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { Environment } from "../../../environment";

export function InvitationsScreen(properties: {
    setShowInvitations: () => void
    reloadGroups: () => void
}) {
    const navigation = useNavigation()
    const [user, setUser] = useRecoilState(userState)
    const isFocused = useIsFocused()
    const { t } = useTranslation()
    const [invitations, setInvitations] = useState<{
        id: string
        createdAt: string
        Group: {
            name: string
            username: string
        }
    }[]>([])
    useEffect(() => {
        navigation.setOptions({
            headerShown: false,
            headerTitle: undefined,

        })


    }, [])

    const getInvitations = () => {
        if (!user) return;
        axios.get(`${Environment.api}/invitation`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const invitations = response.data.items
            setInvitations(invitations)
        }).catch((error) => {
            console.log({ error })
        })
    }


    useEffect(() => {
        if (!user) return;
        getInvitations()
    }, [user, isFocused])


    return <ScrollView style={{
        backgroundColor: '#535861',
        flex: 1,
    }} contentContainerStyle={{
        padding: 30,
    }}>
        <IconComponent name="times" style={{
            icon: {
                fontSize: 17,
                color: '#fff'
            },
            box: {
                zIndex: 1000,
                alignSelf: 'flex-end',
                height: 40,
                width: 40,
                borderRadius: 25,
                backgroundColor: 'rgba(0,0,0,0.2)',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 30,
                right: 30,
            }
        }}

            onPress={() => {
                properties.setShowInvitations()

            }}
        />
        <TextComponent styles={{
            text: {
                fontSize: 24,
                fontWeight: 'bold',
                color: '#fff'
            }
        }}>{t('invitations.title')}</TextComponent>
        <TextComponent styles={{
            text: {
                fontSize: 13,
                fontWeight: '200',
                maxWidth: 240,
                lineHeight: 17,
                opacity: 0.7,
                color: '#fff'
            }
        }}>{t('invitations.subtitle')}</TextComponent>
        <SpaceComponent />
        {
            invitations.map((invitation) => {
                return <View
                    style={{
                        marginBottom: 10,
                        borderRadius: 5,
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        padding: 20,
                    }}
                    key={invitation.id}

                >
                    <TextComponent styles={{
                        text: {
                            color: '#fff',
                            fontSize: 18,
                            lineHeight: 20,
                            fontWeight: 'bold',
                        }
                    }}>{invitation.Group.name}</TextComponent>
                    <TextComponent styles={{
                        layout: {
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        },
                        text: {
                            color: '#fff',
                            fontSize: 12,
                            opacity: 0.5,

                            lineHeight: 20,
                            fontWeight: '500',
                        }
                    }}><Moment fromNow>{invitation.createdAt}</Moment></TextComponent>
                    <TextComponent styles={{
                        text: {
                            color: '#fff',
                            opacity: 0.5,
                            fontSize: 15,
                            fontWeight: '200',
                            lineHeight: 17,
                        }
                    }}>@{invitation.Group.username}</TextComponent>
                    <TextComponent styles={{
                        text: {
                            color: '#fff',
                            opacity: 0.9,
                            fontSize: 12,
                            marginTop: 10,
                            lineHeight: 16,
                            fontWeight: '500',
                        }
                    }}>{t('invitations.description')}</TextComponent>
                    <SpaceComponent />
                    <View style={{
                        gap: 10,

                        flexDirection: 'row',
                    }}>
                        <TouchableOpacity onPress={() => {
                            if (!user) return;
                            // delete
                            axios.delete(`${Environment.api}/invitation?invitationId=${invitation.id}`, {
                                headers: {
                                    Authorization: `Bearer ${user.accessToken}`
                                }
                            }).then((response) => {
                                getInvitations()
                                properties.reloadGroups()
                            })

                        }} style={{
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            borderRadius: 25,
                            padding: 10,
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'center',
                            gap: 10,

                        }}>
                            <IconComponent name="times" style={{
                                icon: {
                                    fontSize: 16,
                                    color: '#eb3434',
                                }
                            }} />
                            <TextComponent styles={{
                                text: {
                                    color: '#eb3434',
                                    fontSize: 14,
                                }
                            }}>{t('invitations.no')}</TextComponent>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            if (!user) return;
                            axios.put(`${Environment.api}/invitation?invitationId=${invitation.id}`, {
                                status: true,
                            }, {
                                headers: {
                                    Authorization: `Bearer ${user.accessToken}`
                                }
                            }).then((response) => {

                                getInvitations()
                                properties.reloadGroups()
                            })

                        }} style={{
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            borderRadius: 25,
                            padding: 10,
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            gap: 10,
                        }}>
                            <IconComponent name="check" style={{
                                icon: {
                                    fontSize: 16,
                                    color: '#34eb4f',
                                }
                            }} />
                            <TextComponent styles={{
                                text: {
                                    color: '#34eb4f',
                                    fontSize: 14,
                                }
                            }}>{t('invitations.yes')}</TextComponent>
                        </TouchableOpacity>
                    </View>
                </View>
            })
        }

        {
            invitations.length === 0 && <EmptyComponent invert />
        }

    </ScrollView>
}