import LoaderComponent from "@components/loader/loader.component";
import { Environment } from "@environment";
import {
  DrawerActions,
  NavigationProp,
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { membersState } from "@states/members.state";
import { userState } from "@states/user.state";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Calendar,
  Event,
  ToolbarProps,
  momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useRecoilState } from "recoil";
import { CalendarRoutes } from "../calendar.routes";
import { TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { IconComponent } from "@components/icon/icon.component";
import { TextComponent } from "@components/text/text.component";
import { priceUtility } from "../../../../utilities/price/priceUtility";
import { useDrawerStatus } from "@react-navigation/drawer";
import { useWeb } from "../../../../hook/web.hook";
import { useWidth } from "../../../../hook/isWidth";
import { color } from "react-native-reanimated";
const DnDCalendar = withDragAndDrop(Calendar);
const diagonal = require('./../../../../../assets/diagonal.jpg')

interface EventType extends Event {
  data: {
    Member: {
      color: string;
      name: string;
    };
  };
}
const localizer = momentLocalizer(moment);
moment.updateLocale("en", {
  week: {
    dow: 1, // First day of week is Monday
    doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
  },
});
export function CalendarScreen() {
  const { t, i18n } = useTranslation();
  const isWeb = useWeb();
  const navigation = useNavigation<NavigationProp<CalendarRoutes, "all">>();
  const route = useRoute<RouteProp<CalendarRoutes, "all">>();
  const isFocus = useIsFocused();
  const [view, setView] = useState<"month" | "week" | "day">("week");
  const [user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [membersSelected, setMembersSelected] = useRecoilState(membersState);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [members, setMembers] = useState<
    {
      id: string;
      title: string;
      color: string;
    }[]
  >([]);

  const [events, setEvents] = useState<EventType[]>([]);
  const [availabilities, setAvailabilities] = useState<EventType[]>([]);
  const drawer = useDrawerStatus();
  useEffect(() => {
    isFocus && getMembers();
  }, [isFocus]);
  useEffect(() => {
    const startNew = new Date();
    // first day of week monday
    const day = new Date(startNew);
    // first monday of week
    const diff = day.getDate() - day.getDay() + (day.getDay() === 0 ? -8 : 1);
    const start = new Date(day.setDate(diff));
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setDate(end.getDate() + 8);
    end.setHours(23, 59, 59, 999);
    setStart(start);
    setEnd(end);
  }, []);

  useEffect(() => {
    if (isFocus && start && end) {
      getEvents(start, end);
      getAvailities(start, end);
    }
  }, [isFocus, start, end]);

  const getMembers = async () => {
    if (!user) return;
    if (!route.params) return;
    if (!route.params.groupId) return;
    const response = await fetch(
      `${Environment.api}/member?groupId=${route.params.groupId}&component=calendar`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    const data = await response.json();
    const members = data.items.map((member: any) => {
      return {
        id: member.id,
        title: `${member.User.name} ${member.User.surname}`,
        color: member.color,
      };
    });

    setMembers(members);
  };

  const getEvents = async (start: Date, end: Date) => {
    if (!user) return;
    setLoading(true);

    // date string of currentDate with 00:00:00 and first day of month

    const startedDateString = start.toISOString();
    const finishedDateString = end.toISOString();

    setLoading(true);
    setEvents([]);
    const timezone = new Date().getTimezoneOffset() / 60;

    const response = await fetch(
      `${Environment.api}/meet?groupId=${route.params.groupId}&newCalendar=true&all=true&startDate=${startedDateString}&endDate=${finishedDateString}&timezone=${timezone}`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    const data = await response.json();
    const events = data.items.map((event: any) => {
      return {
        start: new Date(event.startedAt),
        end: new Date(event.finishedAt),
        title: event.name,
        resourceId: event.memberId,
        temporal: false,
        data: event,
      };
    });
    setLoading(false);
    setEvents(events);
  };



  const getAvailities = async (start: Date, end: Date) => {
    if (!user) return;
    setLoading(true);

    // date string of currentDate with 00:00:00 and first day of month

    const startedDateString = start.toISOString();
    const finishedDateString = end.toISOString();

    setLoading(true);
    setAvailabilities([]);
    const timezone = new Date().getTimezoneOffset() / 60;

    try {
      const response = await fetch(
        `${Environment.api}/meet/availability?groupId=${route.params.groupId}&newCalendar=true&all=true&startDate=${startedDateString}&endDate=${finishedDateString}&timezone=${timezone}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      const data = await response.json();
      const events = data.items.map((event: any) => {
        return {
          start: new Date(event.startedAt),
          end: new Date(event.finishedAt),
          title: '',
          resourceId: event.memberId,
          temporal: false,
          data: {
            color: '#aaa',
            disabled: true,
            Member: {

              name: event.Member.name

            },
            Room: {
              name: event.Room.name
            },
          }
        };
      });
      setLoading(false);
      setAvailabilities(events);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const renderDay = (event: any) => {
    const { t, i18n } = useTranslation();
    const minutes = moment(event.event?.data?.finishedAt).diff(
      moment(event.event?.data?.startedAt),
      "minutes"
    );
    const hours = minutes / 60;
    const budgetPerHour = event.event?.data?.budget
      ? event.event?.data?.budget / hours
      : 0;
    return (
      <div
        style={{
          fontSize: 11,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: '2px 5px'
        }}
      >
        {
          event.event?.data?.color && <div style={{
            backgroundImage: `url(${diagonal})`,
            backgroundSize: '100px 100px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            opacity: 0.2,
            zIndex: 1111,
            bottom: 0,
          }} />
        }
        {event.event?.data?.Service?.name && (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,1)",
              padding: 3,
              position: "absolute",
              top: -1,
              display: "none",
              right: -4,
              fontWeight: "bold",
              borderRadius: 5,
              paddingRight: 5,
              fontSize: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: event.event?.data?.Service?.color,
              color: event.event?.data?.Service?.color,
              textShadow: "1px 1px 3px rgba(0,0,0,1)",
              paddingLeft: 5,
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                height: 6,
                width: 6,
                borderRadius: 3,
                backgroundColor: event.event?.data?.Service?.color,
              }}
            ></div>
            {event.event?.data?.Service?.name}
          </div>
        )}
        {event.event?.data?.Customer?.name && (
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              gap: 5,
              alignContent: "center",
              alignItems: "center",
              fontSize: 9,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                backgroundColor: "rgba(0,0,0,0.1)",
                padding: 5,
                borderRadius: 5,
                paddingRight: 7,
                paddingLeft: 7,
                whiteSpace: "nowrap",
              }}
            >
              {event.event?.data?.Customer?.name}{" "}
              {event.event?.data?.Customer?.surname}
            </div>{" "}
            <b
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {event.event?.data?.name}
            </b>
          </div>
        )}
        {event.event?.data?.Member?.name && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginTop: "auto",
              alignItems: "center",
              gap: 5,
              marginBottom: 2,
              flexWrap: "wrap",
              paddingTop: 5,
              fontSize: 9,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                backgroundColor: "rgba(0,0,0,0.1)",
                padding: 5,
                borderRadius: 5,
                paddingRight: 7,
                paddingLeft: 7,
              }}
            >
              {event.event?.data?.Member?.name}
            </div>
            <div>{t("calendar.event.in")}</div>
            <div
              style={{
                fontWeight: "bold",
                backgroundColor: "rgba(0,0,0,0.1)",
                padding: 5,
                borderRadius: 5,
                paddingRight: 7,
                paddingLeft: 7,
              }}
            >
              {event.event?.data?.Room?.name}
            </div>
            {event.event?.data?.budget ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div>{t("calendar.event.for")}</div>
                  <div
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: 5,
                      borderRadius: 5,
                      paddingRight: 7,
                      paddingLeft: 7,
                    }}
                  >
                    {priceUtility({
                      price:
                        event.event?.data?.budget + event.event?.data?.deposit,
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const filterEvents = events.filter((event) =>
    membersSelected.length > 0
      ? membersSelected.includes(event.resourceId)
      : true
  );
  const filterMembers = members.filter((member) =>
    membersSelected.length > 0 ? membersSelected.includes(member.id) : true
  );

  const filterAvailabilities = availabilities.filter((event) =>
    membersSelected.length > 0
      ? membersSelected.includes(event.resourceId)
      : true
  );

  const allEvents = (view === 'month') ? filterEvents : [...filterEvents, ...filterAvailabilities];

  const updateDragMeet = async (event: any) => {
    if (!user) return;
    if (event.event?.data.temporal) return;
    if (!event.event?.data?.id) return;
    // update meet of state

    const index = events.findIndex((e) => e.data.id === event.event?.data.id);
    const newEvents = [...events];
    newEvents[index] = {
      ...newEvents[index],
      start: event.start,
      end: event.end,
      ...(event.resourceId ? { resourceId: event.resourceId } : {}),
    };
    setEvents(newEvents);

    const response = await fetch(
      `${Environment.api}/meet/drag?meetId=${event.event?.data.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startedAt: event.start,
          finishedAt: event.end,
          memberId: event.resourceId ? event.resourceId : undefined,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        if (message) {
          start && end && getEvents(start, end);
        }
      });
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      {loading && <LoaderComponent />}
      <DnDCalendar
        localizer={localizer}
        events={allEvents}
        resizable
        messages={{
          today: t("calendar.today"),
          date: t("calendar.date"),
          time: t("calendar.time"),
          event: t("calendar.event"),
          allDay: t("calendar.allDay"),
          week: t("calendar.week"),
          work_week: t("calendar.work_week"),
          day: t("calendar.day"),
          month: t("calendar.month"),
          previous: t("calendar.previous"),
          next: t("calendar.next"),
          yesterday: t("calendar.yesterday"),
          tomorrow: t("calendar.tomorrow"),
          agenda: t("calendar.agenda"),
          showMore: (total) => `+${total} ${t("calendar.showMore")}`,
          noEventsInRange: t("calendar.noEventsInRange"),
        }}
        dayLayoutAlgorithm="no-overlap"
        defaultView="week"
        culture={i18n.language}
        selectable
        components={{
          toolbar: (props: ToolbarProps) => {
            const isWeb = useWeb();
            const width = useWidth();
            const monthNumber = moment(props.date).format("M");
            const year = moment(props.date).format("YYYY");
            const day = moment(props.date).format("DD");
            const dayWeekNumber = moment(props.date).format("d");
            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: !width.laptop ? "column" : "row",
                  gap: 10,
                  height: !width.laptop ? undefined : 60,
                  alignItems: "center",

                  paddingVertical: 15,
                  backgroundColor: "#fff",
                  paddingRight: 0,
                  paddingLeft: isWeb ? 22 : 50,
                }}
              >
                {!isWeb && (
                  <IconComponent
                    name="bars"
                    style={{
                      box: {
                        height: 50,
                        width: 50,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 25,
                        position: "absolute",
                        top: 8,
                        zIndex: 100,
                        left: 0,
                      },
                      icon: {
                        color: "black",
                        fontSize: 20,
                      },
                    }}
                    onPress={() => {
                      if (drawer === "open") {
                        navigation.dispatch(DrawerActions.closeDrawer());
                      } else {
                        navigation.dispatch(DrawerActions.openDrawer());
                      }
                    }}
                  />
                )}
                <View
                  style={{
                    gap: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {props.view === "day" && (
                    <TextComponent
                      styles={{
                        text: {
                          fontSize: 25,
                          fontWeight: "bold",
                        },
                      }}
                    >
                      {day}
                    </TextComponent>
                  )}
                  {props.view === "day" && (
                    <TextComponent
                      styles={{
                        text: {
                          fontSize: 16,
                          fontWeight: "200",
                        },
                      }}
                    >
                      {t("day." + dayWeekNumber)}
                    </TextComponent>
                  )}
                  <TextComponent
                    styles={{
                      text: {
                        fontSize: 25,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {t("month." + (monthNumber - 1))}
                  </TextComponent>
                  <TextComponent
                    styles={{
                      text: {
                        fontSize: 16,
                        fontWeight: "200",
                      },
                    }}
                  >
                    {year}
                  </TextComponent>
                </View>
                {/* modes */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    marginLeft: !width.laptop ? undefined : "auto",
                    height: !width.laptop ? undefined : 60,
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 33,
                      backgroundColor:
                        props.view === "month"
                          ? "#eee"
                          : "rgba(255,255,255,0.8)",
                      paddingHorizontal: 15,
                      borderRadius: 55,
                    }}
                    onPress={() => {
                      setView("month");
                      setLoading(true);
                      setTimeout(() => {
                        props.onNavigate("TODAY");
                      }, 200);
                    }}
                  >
                    <TextComponent
                      styles={{
                        text: {
                          textTransform: "uppercase",
                          fontWeight: "600",
                          height: 33,
                          lineHeight: 33,
                          letterSpacing: 1,
                          color: props.view === "month" ? "#65c366" : "#555",
                          fontSize: 13,
                        },
                      }}
                    >
                      {t("calendar.month")}
                    </TextComponent>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      height: 33,
                      borderRadius: 55,
                      backgroundColor:
                        props.view === "week"
                          ? "#eee"
                          : "rgba(255,255,255,0.8)",
                      paddingHorizontal: 15,
                    }}
                    onPress={() => {
                      setView("week");
                      setLoading(true);
                      setTimeout(() => {
                        props.onNavigate("TODAY");
                      }, 200);
                    }}
                  >
                    <TextComponent
                      styles={{
                        text: {
                          textTransform: "uppercase",
                          fontWeight: "600",
                          height: 33,
                          lineHeight: 33,
                          letterSpacing: 1,
                          color: props.view === "week" ? "#65c366" : "#555",
                          fontSize: 13,
                        },
                      }}
                    >
                      {t("calendar.week")}
                    </TextComponent>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      height: 33,
                      backgroundColor:
                        props.view === "day" ? "#eee" : "rgba(255,255,255,0.8)",
                      paddingHorizontal: 15,
                      borderRadius: 55,
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setView("day");
                      setLoading(true);
                      setTimeout(() => {
                        props.onNavigate("TODAY");
                      }, 200);
                    }}
                  >
                    <TextComponent
                      styles={{
                        layout: {
                          height: 33,
                          flex: 1,
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        text: {
                          textTransform: "uppercase",
                          fontWeight: "600",
                          letterSpacing: 1,
                          height: 33,
                          lineHeight: 33,
                          color: props.view === "day" ? "#65c366" : "#555",
                          fontSize: 13,
                        },
                      }}
                    >
                      {t("calendar.day")}
                    </TextComponent>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    borderRadius: 5,
                    overflow: "hidden",

                    height: !width.laptop ? undefined : 60,
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity onPress={() => props.onNavigate("PREV")}>
                    <IconComponent
                      name="arrow-left"
                      style={{
                        box: {
                          height: 60,
                          width: 60,
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        icon: {
                          color: "rgba(9, 9, 9,1)",
                          fontSize: 25,
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => props.onNavigate("TODAY")}>
                    <TextComponent
                      styles={{
                        layout: {
                          height: 33,
                          borderRadius: 100,
                          backgroundColor: "rgba(1,1,1,0.05)",
                          paddingHorizontal: 15,
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        text: {
                          color: "rgba(52, 199, 89,1)",
                          fontSize: 13,
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      {t("calendar.today")}
                    </TextComponent>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => props.onNavigate("NEXT")}>
                    <IconComponent
                      name="arrow-right"
                      style={{
                        box: {
                          height: 60,
                          width: 60,
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        icon: {
                          color: "rgba(9, 9, 9,1)",
                          fontSize: 25,
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            );
          },
          month: {
            // dateHeader: (props: any) => {
            //     return <View style={{ backgroundColor: 'red', padding: 20 }}>
            //  esto es la cabecera dentro del cubo del dia del mes
            //     </View>
            // }
          },
          week: {
            event: renderDay,
          },
          day: {
            event: renderDay,
          },
        }}
        enableAutoScroll={true}
        onEventResize={(event) => {
          if (event?.data?.disabled) return;

          updateDragMeet(event);
        }}
        onEventDrop={(event) => {
          if (event?.data?.disabled) return;

          updateDragMeet(event);
        }}
        step={15}
        timeslots={4}
        onSelectSlot={(event) => {
          // is month view?

          // format date with timezone
          const start = moment(event.start)
            .tz("UTC")
            .format("YYYY-MM-DDTHH:mm:ss");
          const end = moment(event.end).tz("UTC").format("YYYY-MM-DDTHH:mm:ss");
          // add new event
          const newEvent: Event = {
            start: event.start,
            end: event.end,
            title: "New Event",
            resourceId:
              event.resourceId && typeof event.resourceId === "string"
                ? event.resourceId
                : undefined,
            temporal: true,
          };
          setEvents([...events, newEvent]);

          navigation.navigate("new", {
            groupId: route.params.groupId,
            start: start,
            end: end,
            memberId:
              typeof event.resourceId === "string"
                ? event.resourceId
                : undefined,
          });
        }}
        // muestra todos los eventos en la version mes
        showAllEvents
        doShowMoreDrillDown
        views={["month", "week", "day"]}
        view={view}
        scrollToTime={new Date()}
        resources={view === "day" ? filterMembers : undefined}
        onNavigate={(startNew) => {
          if (view === "day") {
            const start = new Date(startNew);
            start.setHours(0, 0, 0, 0);
            const end = new Date(startNew);
            end.setHours(23, 59, 59, 999);
            setStart(start);
            setEnd(end);
          } else if (view === "week") {
            const day = new Date(startNew);
            // first monday of week
            const diff =
              day.getDate() - day.getDay() + (day.getDay() === 0 ? -8 : 1);
            const start = new Date(day.setDate(diff));
            start.setHours(0, 0, 0, 0);
            const end = new Date(start);
            end.setDate(end.getDate() + 8);
            end.setHours(23, 59, 59, 999);
            setStart(start);
            setEnd(end);
          } else if (view === "month") {
            const day = new Date(startNew);
            const start = new Date(day.setDate(1));
            start.setHours(0, 0, 0, 0);
            const end = new Date(day.setDate(1));
            end.setMonth(end.getMonth() + 1);
            end.setDate(0);
            end.setHours(23, 59, 59, 999);
            setStart(start);
            setEnd(end);
          }
        }}
        eventPropGetter={(event: Event) => {
          const color = event.temporal
            ? "#aaaaaa" : event?.data?.color ? event?.data?.color
              : members.find((member) => member.id === event.resourceId)?.color;
          return {
            style: {
              backgroundColor: color,
              borderWidth: 2,
              borderColor: event.temporal
                ? "rgba(0,0,0,0.1)" : event?.data?.color ? event?.data?.color
                  : members.find((member) => member.id === event.resourceId)
                    ?.color,
            },
          };
        }}
        onSelectEvent={(event) => {
          if (event?.data?.disabled) return;
          route.params &&
            navigation.navigate("edit", {
              groupId: route.params.groupId,
              meetId: event.data.id,
            });
        }}
        onView={(view) => {
          setView(view);
        }}
      />
    </View>
  );
}
