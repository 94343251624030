import { Image, ImageBackground, Linking, Text, View, useWindowDimensions } from "react-native";
import { BoxComponent } from "../box.component";
import { TouchableOpacity } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { IconComponent } from "@components/icon/icon.component";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageConstants } from "@modules/selectors/language/language.constants";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { MainRoutes } from "../../../screens/main.routes";

export function OneStep(properties: {
    setStep: (step: number) => void
    setLanguage: (language: string) => void
}) {
    const { width } = useWindowDimensions()
    const { t, i18n } = useTranslation()
    const navigation = useNavigation<NavigationProp<MainRoutes>>()
    const renderOption = (settings: {
        title: string,
        description: string,
        onPress: () => void
        icon?: boolean
    }) => {

        return <TouchableOpacity

            style={{
                backgroundColor: settings.icon ? 'rgb(255,255,255)' : 'rgba(0,0,0,0)',
                padding: width > 800 ? 40 : 20,
                paddingLeft: 20,
                width: '100%',
                borderRadius: 10,
                alignSelf: 'center',
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                shadowOpacity: settings.icon ? 0.2 : 0.1,
                shadowRadius: 26.00,


            }}
            onPress={() => {
                settings.onPress()
            }}>
            <Text style={{
                fontWeight: 'bold',
                fontSize: 28,
                paddingLeft: width > 700 ? 20 : 0,
                color: settings.icon ? '#000' : '#333'
            }}>{settings.title}</Text>
            <Text style={{
                opacity: 0.5,
                fontSize: 20,
                paddingLeft: width > 700 ? 20 : 0,
                paddingRight: width > 700 ? 0 : 30,

            }}>{settings.description}</Text>

            {
                <View style={{

                    height: width > 800 ? 60 : 60,
                    width: width > 800 ? 60 : 60,
                    position: 'absolute',
                    right: width > 800 ? -30 : -20,
                    top: '50%',
                    marginTop: -30,
                    backgroundColor: '#fff',
                    borderRadius: 70,
                    alignItems: 'center',
                    justifyContent: 'center',
                    shadowColor: "#000",
                    shadowOffset: {
                        width: 0,
                        height: 6,
                    },
                    shadowOpacity: 0.2,
                    shadowRadius: 26.00,

                }} >

                    <IconComponent name="arrow-right" style={{
                        icon: {
                            color: '#000',
                            fontSize: width > 800 ? 30 : 30,
                        }
                    }} />

                </View>

            }
        </TouchableOpacity >
    }
    return <>
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 0,
            marginBottom: 50,
            marginTop: 50,
            justifyContent: 'flex-end'
        }}>
            {
                LanguageConstants.map((language, index) => {

                    return <TouchableOpacity
                        style={{
                            backgroundColor: i18n.language === language.id ? '#333' : 'rgba(0,0,0,0.05)',
                            borderRadius: 40,
                            alignSelf: 'center',
                            margin: 5,
                            height: 40,
                            width: 40,
                            marginTop: 0,
                            flexDirection: 'row',
                            gap: 20,
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                        onPress={() => {
                            // set language to english
                            i18n.changeLanguage(language.id)
                            properties.setLanguage(language.id)
                        }}>
                        <Text style={{
                            fontSize: 17,
                            fontWeight: 'bold',
                            color: i18n.language === language.id ? '#fff' : '#666'
                        }}>
                            {
                                language.id.toLocaleUpperCase()
                            }
                        </Text>
                    </TouchableOpacity>
                })
            }
        </View >
        <View style={{
            paddingHorizontal: 30,
            paddingBottom: 50, maxWidth: 500
        }}>

            <Text style={{
                fontSize: width > 800 ? 36 : 36,
                marginBottom: 20,
                fontWeight: 'bold',
            }}>
                {t('onboarding.one.title')}
            </Text>

            {
                renderOption({
                    title: t('onboarding.one.yes.title'),
                    icon: true,

                    description: t('onboarding.one.yes.description'),
                    onPress: () => {
                        properties.setStep(2)
                    }
                })
            }

            {
                renderOption({
                    title: t('onboarding.one.no.title'),
                    description: t('onboarding.one.no.description'),
                    onPress: () => {
                        navigation.navigate('welcome', {
                            screen: 'login'
                        })
                    }
                })
            }
        </View>
    </>

}