import { IconComponent } from "@components/icon/icon.component";
import { useTranslation } from "react-i18next";
import { Image, ImageBackground, ScrollView, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { LoadingDots } from "./loader";

export function BoxComponent(properties: {
    children: any,
    goBack?: () => void
    loading?: boolean
}) {
    const { t } = useTranslation()
    const { width } = useWindowDimensions();
    return <View style={{ flex: 1, flexDirection: 'row', }}>
        <View style={{
            flex: 1,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 6,
            },
            shadowOpacity: 0.2,
            shadowRadius: 126.00,
            elevation: 8,
            zIndex: 100,
        }}>

            <Text style={{
                color: '#fff',
                backgroundColor: 'rgb(101, 195, 102)',
                padding: 20,
                fontWeight: 'bold',
                fontSize: width > 800 ? 20 : 13,
                zIndex: 100,
                textAlign: 'center'
            }}>
                {t('onboarding.promo')}
            </Text>

            {
                properties.loading && <LoadingDots />
            }
            <ScrollView

                style={{
                    flex: 1
                }} contentContainerStyle={{ paddingBottom: 50, }}>
                {properties.goBack && <View style={{

                }}>
                    <TouchableOpacity style={{
                        height: 110,
                        width: 110,
                        justifyContent: 'center',
                        alignItems: 'center'

                    }}
                        onPress={() => {
                            properties.goBack?.()
                        }}
                    >
                        <IconComponent style={{
                            icon: {
                                fontSize: 50
                            }
                        }} name="arrow-left" />
                    </TouchableOpacity>
                </View>}
                <View style={{
                    maxWidth: 700,
                    gap: 20,
                    marginHorizontal: 'auto',
                }}>
                    {properties.children}
                </View>
            </ScrollView>
        </View>
        {width > 1100 && <ImageBackground
            source={require('./smile.jpg')}
            style={{
                maxWidth: width < 1300 ? 450 : 10000,
                flex: 1, backgroundColor: '#333'
            }}>

            <Image source={require('../../../assets/white.png')} style={{
                height: 60,
                width: 250,
                marginTop: 30,
                marginHorizontal: 'auto',
            }}
                resizeMode="contain"
            />

        </ImageBackground>}
    </View>
}

export function BoxTitle(properties: {
    title: string,
    subtitle: string,
}) {

    const { width } = useWindowDimensions()
    return <View>
        <Text style={{
            textAlign: 'center',
            fontSize: width > 700 ? 40 : 20,
            fontWeight: 'bold',
        }}>{properties.title}</Text>
        <Text style={{
            fontSize: width > 700 ? 20 : 15,
            textAlign: 'center',
            maxWidth: '80%',
            marginHorizontal: 'auto',
            color: '#666',
        }}>
            {properties.subtitle}
        </Text>
    </View>
}
type op = number | string

export function SelectorComponent(properties: {
    translationPrefix: string,
    multiple?: boolean,
    selected: op[]
    setSelected: (selected: op[]) => void,
    options: {
        label?: string,
        value: op,
        icon: string
    }[]
}) {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    return <View style={{
        flexWrap: 'wrap',
        flexDirection: width > 600 ? 'row' : 'column',
        marginTop: 20,
        marginBottom: 20,
    }}>
        {
            properties.options.map((option, index) => {
                return <View key={index} style={{
                    width: width > 600 ? '50%' : '100%',
                    padding: 10,
                }}>
                    <TouchableOpacity
                        onPress={() => {
                            if (properties.multiple) {
                                if (properties.selected.includes(option.value)) {
                                    const newSelected = properties.selected.filter((item) => {
                                        return item !== option.value
                                    })
                                    properties.setSelected(newSelected)
                                }
                                else {
                                    properties.setSelected([...properties.selected, option.value])
                                }
                            }
                            else {
                                properties.setSelected([option.value])
                            }
                        }}
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingVertical: 20,
                            borderRadius: 10,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 6,
                            },
                            shadowOpacity: 0.1,
                            shadowRadius: 26.00,
                            backgroundColor: properties.selected.includes(option.value) ? '#fff' : '#fff'
                        }}>
                        <View style={{
                            height: 50,
                            width: 50,
                            borderRadius: 30,
                            marginLeft: 20,
                            marginRight: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: properties.selected.includes(option.value) ? 'rgb(22, 192, 152)' : '#eee'
                        }}>
                            {
                                properties.selected.includes(option.value) && <IconComponent name="check" style={{
                                    icon: {
                                        color: '#fff',
                                    }
                                }} />
                            }
                        </View>
                        <Text style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                        }}>{option.label || t(`${properties.translationPrefix}.${option.value}`)}</Text>

                    </TouchableOpacity>
                </View>
            })
        }
    </View>
}


export function ErrorRender(properties: {
    message: string
}) {
    const { t } = useTranslation()
    return <View style={{
        backgroundColor: 'rgba(255,0,0,0.05)',
        padding: 14,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        overflow: 'hidden',
        shadowOpacity: 0.1,
        shadowRadius: 26.00,
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
    }}>
        <IconComponent name="exclamation-circle" style={{
            icon: {
                color: 'rgba(255,0,0,0.5)',
                fontSize: 30,
                textAlign: 'center',
            }
        }} />
        <Text style={{
            color: 'rgba(255,0,0,0.5)',
            fontSize: 20,
        }}>{
                t(properties.message)
            }</Text>
    </View>
}
