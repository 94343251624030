import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { MeetRoutes } from "../meet.routes";
import { View } from "react-native";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";
import { useEffect, useState } from "react";

export function NewMeetScreen(properties: {
  navigation: NavigationProp<MeetRoutes>;
  route: RouteProp<MeetRoutes, "new">;
}) {
  const route = properties.route;
  const navigation = properties.navigation;

  const start = route.params.start;
  const end = route.params.end;
  const memberId = route.params.memberId;

  return (
    <PostModule<{
      name: string;
      customerId: string;
      roomId: string;
      startedAt?: string;
      finishedAt?: string;
      description: string;
      type: string;
      memberId: string;
      deposit?: number;
      budget?: number;
      commission?: number;
      size?: string;
      body?: string;
      images: string[];
      serviceId: string;
    }>
      prefix="newMeet"
      path="meet"
      level={route.params.level}
      onSuccess={(data) => {
        if (route.params.callback) {
          route.params.callback(data);
        } else navigation.goBack();
      }}
      groupId={route.params.groupId}
      onCancel={() => {
        navigation.goBack();
      }}
      width={600}
      data={{
        type: "meet",
        images: [],
        startedAt: start,
        description: "",
        commission: 0,
        finishedAt: end,
        serviceId: route.params.serviceId,
        customerId: route.params.customerId,
        roomId: route.params.roomId,
      }}
      inputs={[
        {
          name: "serviceId",
          type: "service",
          path: "service",
          groupId: route.params.groupId,
          serviceName: "serviceId",
          depositName: "deposit",
          budgetName: "budget",
          commissionName: "commission",
          startedAtName: "startedAt",
          finishedAtName: "finishedAt",
          memberName: "memberId",
        },

        {
          name: "name",
          type: "text",
        },

        {
          name: "description",
          type: "text",
          lines: 5,
        },
        {
          render: !route.params?.roomId,

          ...(!route.params.serviceId && {
            onNew: (callback) => {
              navigation.navigate("newRoom", {
                level: 1 + (route.params.level || 0),
                groupId: route.params.groupId,
                callback: callback,
              });
            },
          }),
          name: "roomId",
          type: "choose",
          path: "room",
          groupId: route.params.groupId,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newCustomer", {
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          renderLabel: (item) => {
            return `${item?.name} ${item?.surname}`;
          },
          renderDetail: (item) => {
            return `${item?.email ? item?.email : ""}`;
          },
          name: "customerId",
          type: "choose",
          path: "customer",
          groupId: route.params.groupId,
        },

        {
          name: "size",
          type: "select",
          options: ["mini", "small", "medium", "big"],
        },
        {
          name: "body",
          type: "text",
        },
        {
          name: "images",
          type: "images",
        },
      ]}
    />
  );
}
