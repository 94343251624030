import { Environment } from "@environment"
import { userState } from "@states/user.state"
import axios from "axios"
import { RefObject, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { ModalComponent } from "../../@components/modal/modal.component"
import LoaderComponent from "@components/loader/loader.component"
import { ErrorComponent } from "@components/error/error.component"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { FormComponent } from "@components/form/form.component"
import { InputComponent } from "@components/input/input.component"
import { SubmitComponent } from "@components/submit/submit.component"
import { FormModule } from "@modules/form/form.module"
import { InputFormProperties } from "@modules/form/form.properties"
import { Image, Linking, Platform, ScrollView, View } from "react-native"
import Toast from "react-native-toast-message"
import { counterState } from "@states/counter.state"
import { TextComponent } from "@components/text/text.component"
import { NavigationProp, useNavigation } from "@react-navigation/native"
import { StudioRoutes } from "../../screens/studio/studio.routes"
import { IconComponent } from "@components/icon/icon.component"
const mastercard = require('./../../../assets/mastercard.png')
const visa = require('./../../../assets/visa-blue.png')
const payPal = require('./../../../assets/paypal.png')




export function PostModule<T>(properties: {
    prefix: string
    groupId?: string
    actions?: RefObject<any>
    width?: number
    title?: string,
    description?: string
    path: string
    onSubmit?: (data: T) => void
    data?: Partial<T>
    onCancel: () => void
    onSuccess: (data: T & { id: string }, raw: any) => void
    inputs: InputFormProperties<T>[]
    level?: number
    onChange?: (data: Partial<T>) => void
}) {

    const navigation = useNavigation<NavigationProp<StudioRoutes>>()
    const [cache, setCache] = useState<string>(new Date().getTime().toString())
    const [data, setData] = useState<Partial<T>>({})
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [user, setUser] = useRecoilState(userState)
    const [counter, setCounter] = useRecoilState(counterState)
    const [showModalSubscriptions, setShowModalSubscriptions] = useState<boolean>()

    useEffect(() => {
        // merge current data

        setData((prev) => {
            return {
                ...prev,
                ...properties.data,
            }
        })

        setCache(new Date().getTime().toString())

    }, [properties.data])
    if ('actions' in properties) {

        properties.actions?.current
    }

    useEffect(() => {
        if (properties.onChange) properties.onChange(data)
    }, [data])

    const handlerSubmit = (data: Partial<T>) => {
        if (loading) return;
        if (!user) return;
        setLoading(true)

        setTimeout(() => {
            axios.post(`${Environment.api}/${properties.path}`, {
                ...data,
                groupId: properties.groupId
            }, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {
                setLoading(false)
                Toast.show({
                    type: 'success',
                    text1: t(`${properties.prefix}.postSuccessTitle`),

                })
                setCounter(new Date().getTime())
                const all = response?.data
                const data = all?.items?.[0]
                properties.onSuccess(data || {}, response.data)


            }).catch((error) => {
                setLoading(false)
                const message = error?.response?.data?.message
                if (message === 'customersLimitExceeded') setShowModalSubscriptions(true)
                setError(message)
                Toast.show({
                    type: 'error',
                    text1: t(`${error?.response?.data?.message}`),

                })
            })
        }, 300);
    }

    const convertCamelCaseString = (string: string) => {
        // from "hola me llamo"  to "holaMeLlamo"
        return string
    }
    // get first word of camel case string holaMoto = hola
    const errorField = getFristWordCamelCase(properties.inputs, error || '')
    const onChange = (newData: any) => {

        setData((prev) => {
            const clone = JSON.parse(JSON.stringify(prev))
            Object.keys(newData).forEach((key) => {
                clone[key] = newData[key]
            })
            return clone
        })

    }


    return <ModalComponent

        level={properties.level}
        title={properties.title || t(`${properties.prefix}.title`)}
        maxWidth={properties.width || 500}
        description={properties.description || t(`${properties.prefix}.description`)}
        onClose={properties.onCancel}>
        {(loading) && <LoaderComponent />}
        {showModalSubscriptions && <View style={{
            paddingHorizontal: 28,
            paddingRight: 20,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 32.84,
            elevation: 5,
            margin: 20, marginTop: 20, borderRadius: 10, backgroundColor: '#fff', padding: 20
        }}>

            <View style={{
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                gap: 15
            }}>

                <TextComponent styles={{
                    text: {
                        fontWeight: 'bold',
                        fontSize: 16
                    }
                }}>
                    {t('subscriptions.limitExceeded.title')}
                </TextComponent>
            </View>
            <TextComponent styles={{
                layout: {

                    marginBottom: 15
                },
                text: {
                    fontWeight: '400',
                    fontSize: 14,
                    lineHeight: 18
                }
            }}>
                {t('subscriptions.limitExceeded.description')}
            </TextComponent>
            <View style={{
                position: 'absolute',
                bottom: 7,
                left: 24,
                gap: 10,
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <Image source={visa}

                    resizeMode="contain"
                    style={{
                        width: 50,
                        height: 60
                    }} />
                <Image resizeMode="contain" source={mastercard} style={{
                    width: 50,
                    height: 23
                }} />

                <Image resizeMode="contain" source={payPal} style={{
                    width: 70,
                    height: 50
                }} />
            </View>
            <SubmitComponent
                icon="credit-card"
                title={t('subscriptions.limitExceeded.submit')} onPress={() => {
                    if (Platform.OS !== 'web') {
                        const path = 'https://app.inkonsky.com'
                        Linking.openURL(path)
                    }
                    else {
                        properties.groupId && navigation.navigate('subscription', {
                            groupId: properties.groupId
                        })
                    }
                }} />
        </View>}
        <FormModule

            prefix={properties.prefix}
            error={(error) ? {
                inputName: errorField,
                message: convertCamelCaseString(error)
            } : undefined}
            data={data}
            onChange={(newData) => {
                onChange(newData)
            }}
            onSubmit={() => {
                if (properties.onSubmit) properties.onSubmit(data)
                else handlerSubmit(data)

            }}
            path={properties.path}
            onCancel={properties.onCancel}
            onSuccess={properties.onSuccess}
            inputs={properties.inputs} />



    </ModalComponent>
}



export function getFristWordCamelCase<T>(inputs: InputFormProperties<T>[], text: string) {
    // check if inputs.name start with text
    let errorField: string = ''
    inputs?.forEach?.((input) => {
        if ('name' in input && typeof input.name === 'string') {
            if (text.startsWith(input.name)) {
                errorField = input.name
            }
        }
        if ('inputs' in input) {
            const rr = getFristWordCamelCase(input.inputs, text)
            if (rr) errorField = rr
        }
    })

    return errorField

}