import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { MaterialRoutes } from "../material.routes";

export function DeleteMaterialScreen() {
    const navigation = useNavigation<NavigationProp<MaterialRoutes, 'delete'>>()
    const route = useRoute<RouteProp<MaterialRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteMaterial'}
        path={'material'}
        id={route.params.materialId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

