import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { TemplateRoutes } from "../template.routes";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { LineComponent } from "@components/line/line.component";
import { Fragment } from "react";
import { TextComponent } from "@components/text/text.component";
import { ButtonComponent } from "@components/button/button.component";
import { Clipboard, ScrollView } from 'react-native'
import { useTranslation } from "react-i18next";
export function VariablesTemplateScreen() {
    const navigation = useNavigation<NavigationProp<TemplateRoutes>>()
    const route = useRoute<RouteProp<TemplateRoutes, 'new'>>()
    const { t } = useTranslation()
    const variables = [

        {
            title: t('variablesCustomer'),
            variables: [{
                name: 'customer.name',
                description: 'First Name'
            },
            {
                name: 'customer.surname',
                description: 'Last Name'
            },
            {
                name: 'customer.email',
                description: 'Email'
            },
            {
                name: 'customer.phone',
                description: 'Phone'
            },
            {
                name: 'customer.document',
                description: 'Document'
            },
            {
                name: 'customer.addressStreet',
                description: 'Street'
            },
            {
                name: 'customer.addressCity',
                description: 'City'
            },
            {
                name: 'customer.addressCountry',
                description: 'Country'
            },
            {
                name: 'customer.addressState',
                description: 'State'
            },

            {
                name: 'customer.addressZip',
                description: 'ZIP Code'
            }

            ]
        },
        {
            title: t('variablesProfessional'),
            variables: [{
                name: 'professional.name',
                description: 'First Name'
            },
            {
                name: 'professional.surname',
                description: 'Last Name'
            },

            {
                name: 'professional.document',
                description: 'Identification document'
            }
            ]
        },
        {
            title: t('variablesShop'),
            variables: [{
                name: 'shop.name',
                description: 'Name'
            },

            {
                name: 'shop.phone',
                description: 'Phone'
            },
            {
                name: 'shop.document',
                description: 'Identification document'
            },
            {
                name: 'shop.addressStreet',
                description: 'Street'
            },
            {
                name: 'shop.addressCity',
                description: 'City'
            },
            {
                name: 'shop.addressCountry',
                description: 'Country'
            },
            {
                name: 'shop.addressState',
                description: 'State'
            },

            {
                name: 'shop.addressZip',
                description: 'ZIP Code'
            }

            ]
        },

        {
            title: t('variablesDates'),
            variables: [
                {
                    name: 'date.day.name',
                    description: 'Weekday'
                },
                {
                    name: 'date.day.number',
                    description: 'Day number'
                },
                {
                    name: 'date.month.name',
                    description: 'Month name'
                },
                {
                    name: 'date.month.number',
                    description: 'Month number'
                },
                {
                    name: 'date.year.number',
                    description: 'Year'
                }
            ]
        },
    ]
    return <ModalComponent
        title={t('variablesTitle')}
        description={t('variablesDescription')}
        onClose={() => {
            navigation.goBack()
        }}
        level={1}

    >

        <ScrollView>
            <LineComponent />
            {
                variables.map((section, index) => {
                    return <Fragment key={index}>
                        <TextComponent variant="h5" styles={{
                            layout: {
                                padding: 10,
                                backgroundColor: '#ddd'
                            }
                        }}>{section.title}</TextComponent>
                        {
                            section.variables.map((variable) => {
                                return <Fragment key={variable.name}>
                                    <ButtonComponent
                                        angle={false}
                                        icon={{
                                            name: 'copy',
                                        }}
                                        onPress={() => {
                                            // copy in clipboard
                                            Clipboard.setString(`{${variable.name}}`)
                                            navigation.goBack()
                                        }}
                                        detail={{
                                            label: t('variablesCopy')
                                        }}
                                        label={variable.description} />
                                    <LineComponent />
                                </Fragment>
                            })}
                    </Fragment>
                })
            }

        </ScrollView>
    </ModalComponent >
}