import { LinkingOptions } from "@react-navigation/native";

export const MainLinking: LinkingOptions<any> = {
    prefixes: ['*'],
    config: {
        initialRouteName: 'user',
        screens: {
            new: 'new',
            welcome: {
                path: 'welcome',
                screens: {
                    login: 'login',
                    register: 'register'
                }
            },
            user: {
                path: 'user',
                screens: {
                    profile: 'profile',
                    password: 'password',
                    settings: 'settings',
                    deleteAccount: 'delete-account',
                    images: {
                        path: 'images',
                        initialRouteName: 'list',
                        screens: {
                            list: 'list',
                            new: {
                                path: 'new'
                            },
                            edit: {
                                path: 'edit/:imageId',
                                parse: {
                                    imageId: String,
                                },
                            },
                            delete: {
                                path: 'delete/:imageId',
                                parse: {
                                    imageId: String,
                                },
                            }
                        },
                    },
                }
            },

            studio: {
                path: 'studio/:groupId',
                parse: {
                    groupId: (groupId: string) => {
                        return groupId
                    }
                },
                screens: {
                    configuration: {
                        path: 'configuration',
                        initialRouteName: 'basic',
                        screens: {
                            basic: 'basic',
                            address: 'address'
                        }
                    },


                    customers: {
                        path: 'customers',
                        initialRouteName: 'list',
                        screens: {

                            list: 'list',
                            new: {
                                path: 'new'
                            },
                            edit: 'edit',

                            delete: 'delete'
                        },
                    },
                    receipts: {
                        path: 'receipts',
                        initialRouteName: 'list',

                        screens: {
                            list: {
                                path: 'list',
                            },
                            new: {
                                path: 'new'
                            },
                            edit: {
                                path: 'edit',

                            },
                            delete: {
                                path: 'delete/:receiptId',
                                parse: {
                                    receiptId: String,
                                },
                            }
                        },
                    },
                    services: {
                        path: 'services',


                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete',
                            newMemberService: 'newMemberService',
                            newMeet: 'newMeet'
                        },
                    },
                    schedules: {
                        path: 'schedules',

                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    materials: {
                        path: 'materials',

                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    dashboard: {
                        path: 'dashboard',
                    },
                    products: {
                        path: 'products',

                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    meets: {
                        path: 'meets',

                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    templates: {
                        path: 'templates',

                        initialRouteName: 'list',
                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    bookings: {
                        path: 'bookings',
                        initialRouteName: 'list',

                        screens: {
                            list: 'list',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    calendar: {
                        path: 'calendar',

                        initialRouteName: 'all',
                        screens: {
                            all: 'all',

                            new: 'new',
                            edit: 'edit',
                            delete: 'delete'
                        },
                    },
                    rooms: {

                        path: 'rooms',


                        initialRouteName: 'list',
                        screens: {
                            list: {
                                path: 'list',

                            },
                            new: {
                                path: 'new'
                            },
                            edit: {
                                path: 'edit',

                            },
                            delete: {
                                path: 'delete',

                            }
                        },
                    },
                    consents: {

                        path: 'consents',


                        initialRouteName: 'list',
                        screens: {
                            list: {
                                path: 'list',

                            },
                            signature: {
                                path: 'signature',
                                parse: {
                                    consentId: String,
                                },
                            },
                            new: {
                                path: 'new'
                            },
                            edit: {
                                path: 'edit',

                            },
                            delete: {
                                path: 'delete',

                            }
                        },
                    },
                    subscription: {
                        path: 'subscription',
                    },

                    members: {
                        path: 'members',
                        initialRouteName: 'list',
                        screens: {
                            list: 'list',
                            new: {
                                path: 'new'
                            },
                            edit: {
                                path: 'edit',

                            },
                            delete: {
                                path: 'delete',

                            }
                        },
                    }
                }

            }

        },
    },
};