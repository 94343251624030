// Modules
import { StyleSheet } from 'react-native'

/**
 * Count styles
 * @constant {StyleSheet} CountStyles
 */
export const CountStyles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    borderRadius: 100,
    paddingVertical: 5,
    paddingHorizontal: 7,
    backgroundColor: '#F3F3F3',
  },
  text: {
    fontSize: 17,
  },
})
