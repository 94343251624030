import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

// custom hook for detect platform web and width window more than 768px with useWindo....
export const useWeb = () => {
    const [isWeb, setIsWeb] = useState<boolean>()
    const width = useWindowDimensions().width;

    useEffect(() => {
        if (width > 768) {
            setIsWeb(true);
        } else {
            setIsWeb(false);
        }
    }, [width]);

    return isWeb;
}