// Modules
import { StyleSheet } from 'react-native'

/**
 * Input styles
 * @constant {StyleSheet} InputStyles
 */
export const InputStyles = StyleSheet.create({
  component: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 10000,
    right: 0,
  },
  header: {
    marginBottom: 15,
  },
  text: {
    borderRadius: 0,
    color: '#7e2424',
    backgroundColor: '#fff',
    borderColor: '#ddd',
    marginLeft: 10,
    fontSize: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

})
