// Modules
import { StyleSheet } from 'react-native'

/**
 * Icon Styles
 * @constant {StyleSheet} IconStyles
 */
export const IconStyles = StyleSheet.create({
  rawIcon: {
    fontFamily: 'line-awesome',
    fontSize: 30,
  },
})
