import { IconComponent } from "@components/icon/icon.component";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { InputComponent } from "../../../components/input/input.component";
import { BoxTitle, ErrorRender } from "../box.component";

export function FourStep(properties: {
    setStep: (step: number) => void
    setName: (name: string) => void
    setSurname: (surname: string) => void
    setEmail: (email: string) => void
    name: string
    surname: string
    email: string
    submitPartial: () => void
    error?: string

}) {
    const { t, i18n } = useTranslation()
    const { width } = useWindowDimensions()

    return <View style={{
        width: '100%',
        maxWidth: 700,
        marginHorizontal: 'auto',
        padding: 20,
        overflow: 'visible'
    }}>

        <BoxTitle title={t('onboarding.four.title')}
            subtitle={t('onboarding.four.subtitle')} />
        <View style={{
            padding: 10,
        }}>
            {
                properties.error && <ErrorRender message={properties.error} />

            }
            <View style={{
                flexDirection: width > 700 ? 'row' : 'column',
                gap: width > 700 ? 20 : 0,
            }}>

                <View style={{ flex: 1, }}>
                    <Text style={{
                        color: '#000',
                        fontWeight: 'bold',
                        marginTop: 20,
                        fontSize: 20,
                        paddingVertical: 10,
                    }}>
                        {t('onboarding.four.name')}
                    </Text>
                    <View style={{
                        flex: 1,
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 6,
                        },
                        overflow: 'hidden',
                        shadowOpacity: 0.1,
                        shadowRadius: 26.00,
                    }}>
                        <InputComponent

                            value={properties.name} onChange={properties.setName} />
                    </View>
                </View>

                <View style={{ flex: 1, }}>

                    <Text style={{
                        color: '#000',
                        fontWeight: 'bold',
                        marginTop: 20,
                        fontSize: 20,
                        paddingVertical: 10,
                    }}>
                        {t('onboarding.four.surname')}
                    </Text>
                    <View style={{
                        flex: 1,
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 6,
                        },
                        overflow: 'hidden',
                        shadowOpacity: 0.1,
                        shadowRadius: 26.00,
                    }}>
                        <InputComponent

                            value={properties.surname} onChange={properties.setSurname} />
                    </View>
                </View>
            </View>
            <Text style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: 20,
                fontSize: 20,
                paddingVertical: 10,
            }}>
                {t('onboarding.four.email')}
            </Text>
            <View style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#fff',
                borderRadius: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                overflow: 'hidden',
                shadowOpacity: 0.1,
                shadowRadius: 26.00,
            }}>
                <InputComponent

                    value={properties.email} onChange={properties.setEmail} />
            </View>
        </View>

        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 30
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: '#333',
                    padding: 20,
                    paddingHorizontal: 40,
                    borderRadius: 10,
                    alignSelf: 'center',
                    margin: 10,
                    marginTop: 0,
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
                onPress={() => {
                    properties.submitPartial()
                }}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                    {t('onboarding.four.next')}

                </Text>
                <IconComponent name="arrow-right" style={{
                    icon: {
                        color: '#fff',
                        fontSize: 30,
                        textAlign: 'center',
                    }
                }} />
            </TouchableOpacity>
        </View>
    </View>

}