import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { MemberRoutes } from "../member.routes";

export function NewUserScreen() {
    const navigation = useNavigation<NavigationProp<MemberRoutes>>()
    const route = useRoute<RouteProp<MemberRoutes, 'new'>>()
    const callback = route.params.callback
    return <PostModule<{
        email: string,
        password: string,
        name: string,
        surname: string,
        username: string,
        language: string,
        legal: boolean
    }>
        level={route.params.level}

        prefix="newMemberUser"
        path="user"
        onSuccess={(data, raw) => {
            // @ts-ignore
            callback?.(raw.user as any)
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                type: 'pair',
                inputs: [
                    {
                        name: 'name',
                        type: 'text'
                    },
                    {
                        name: 'surname',
                        type: 'text'
                    }
                ]
            },
            {
                name: 'username',
                type: 'text'
            },
            {
                name: 'email',
                type: 'text'
            },
            {
                name: 'password',
                type: 'text',
                secure: true
            },
            {
                name: 'language',
                type: 'select',
                options: [
                    "es", "en", "pt", "de", "it", "fr"
                ]
            },
            {
                name: 'legal',
                type: 'checkbox'
            }


        ]}
    />
}