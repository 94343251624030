import { SafeAreaView, ScrollView, Text, View } from "react-native";
import { SectionComponent } from "../../components/section/section.component";
import { InputComponent } from "../../components/input/input.component";
import { LineComponent } from "../../components/line/line.component";
import { KnobComponent } from "../../components/knob/knob.component";
import { SpaceComponent } from "../../components/space/space.component";
import { ButtonComponent } from "../../components/button/button.component";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import axios from "axios";
import { Environment } from "@environment";
import { ErrorComponent } from "@components/error/error.component";
import LoaderComponent from "@components/loader/loader.component";
import { useWeb } from "../../hook/web.hook";
import { LogoComponent } from "@components/logo/logo.component";
import { CenterComponent } from "@components/center/center.component";
import { useTranslation } from "react-i18next";

export function RecoveryScene(properties: { navigation: any }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [cache, setCache] = useState<string>();
  const submitDemand = () => {
    const path = "/recovery/demand";
    setLoading(true);
    axios
      .post(`${Environment.api}${path}`, {
        email_or_username: email,
      })
      .then((response) => {
        setLoading(false);
        properties.navigation.navigate("recoveryPassword", {
          email_or_username: email,
        });
      })
      .catch((error) => {
        setLoading(false);

        const message = error.response.data.message;
        setError(message);
        setCache(new Date().getTime().toString());
      });
  };
  const { t } = useTranslation();
  const isWeb = useWeb();
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <CenterComponent height={700}>
        {loading && <LoaderComponent />}
        {isWeb && <LogoComponent />}
        {isWeb && (
          <>
            <LineComponent />
            <ButtonComponent
              angle={false}
              icon={{
                name: "arrow-left",
              }}
              label={t("RECOVERY_BACK")}
              onPress={() => {
                properties.navigation.goBack();
              }}
            />
          </>
        )}
        {error && (
          <ErrorComponent message={t(error)} title="Error" key={cache} />
        )}
        <LineComponent />
        <View
          style={{
            paddingHorizontal: 20,
          }}
        >
          <InputComponent
            title={t("RECOVERY_EMAIL")}
            autoCapitalize="none"
            keyboardType="email-address"
            value={email}
            onChange={(value) => {
              setEmail(value);
            }}
          />
        </View>

        <KnobComponent icon={"arrow-right"} onPress={submitDemand} />
      </CenterComponent>
    </SafeAreaView>
  );
}
