import React, { useEffect, useState } from 'react';
import { Text, TextInputComponent, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { MaterialsInputProperties } from './materials.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import axios from 'axios';
import { Environment } from '@environment';
import { useIsFocused } from '@react-navigation/native';
import LoaderComponent from '@components/loader/loader.component';
import { IconComponent } from '@components/icon/icon.component';
import { NumberInputForm } from '../number/number.input';
import { priceUtility } from '../../../../utilities/price/priceUtility';

export function MaterialsInputForm<T>(properties: MaterialsInputProperties<T>) {
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState)
    const [search, setSearch] = useState('')
    const [rows, setRows] = useState<any[]>([])
    const isFocus = useIsFocused()
    const [loading, setLoading] = useState(false)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [labelSelected, setLabelSelected] = useState<string>()
    const [showFinder, setShowFinder] = useState(false)
    const getRows = async (search?: string) => {
        if (!user) return
        setLoading(true)
        axios.get(`${Environment.api}/material?groupId=${properties.groupId}${search ? `&search=${search}` : ''}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const items = response.data.items
            setRows(items)
            setLoading(false)
        })
    }

    useEffect(() => {
        getRows()
    }, [isFocus])

    useEffect(() => {
        const timeout = setTimeout(() => {
            getRows(search)
        }
            , 500)
        return () => {
            clearTimeout(timeout)
        }
    }
        , [search])


    const getLabel = (id: string) => {
        if (!user) return
        setLoadingLabel(true)
        axios.get(`${Environment.api}/material?groupId=${properties.groupId}&${properties.path}Id=${id}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const item = response.data?.items?.[0]
            if (item) {
                setLabelSelected(properties.renderLabel ? properties.renderLabel(item) : item.name)
            }
            else undefined
        }).finally(() => {
            setLoadingLabel(false)
        })
    }

    useEffect(() => {
        if (properties.value) {
            getLabel(properties.value)
        }
    }, [properties.value])


    const total = (properties.value?.reduce((total, item) => {
        return total + item.units * item.price
    }
        , 0) || 0) + (properties.base || 0)

    const calculateTaxTotal = () => {
        const producsToSum: {
            units: number;
            productId: string;
            price: number;
            tax: number;
            name: string;
        }[] = properties.value || []



        const tax = producsToSum.map((product) => {
            const price = product.price * product.units
            const tax = price * product.tax / 100
            return tax
        }) || []

        const totalTax = tax.reduce((total, tax) => {
            return total + tax
        }, 0)

        return priceUtility({
            price: totalTax
        })
    }

    return <View style={{
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 0,
        borderRadius: 5,
    }}>

        {
            loadingLabel && <LoaderComponent />
        }

        <View style={{
            paddingHorizontal: 10,
            borderBottomWidth: 1,
            borderColor: '#ddd',
            flexDirection: 'row',
        }}>
            <View style={{
                flex: 1,
                paddingVertical: 5,
                width: 200, borderRightColor: '#ddd',
                borderRightWidth: 1,
                paddingLeft: 5,
            }}>
                <TextComponent>{
                    t('materials.name')
                }</TextComponent>
            </View>

        </View>
        {
            properties.value && <View style={{

                borderBottomWidth: 1,
                borderColor: '#ddd',
                flexDirection: 'column',
            }}>
                {
                    properties.value.map((item, index) => {
                        return <View key={index} style={{
                            flexDirection: 'row',
                            marginBottom: 5,
                            borderBottomWidth: 1,
                            borderColor: '#ddd',
                            padding: 5,
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                paddingLeft: 10,
                                width: 205,
                                flex: 1,
                            }}>
                                <TextComponent styles={{
                                    text: {
                                        fontWeight: 'bold'
                                    }
                                }}>{item.name}</TextComponent>
                            </View>
                            <View style={{
                                width: 130,
                            }}>

                            </View>
                            <View style={{
                                width: 130,
                                paddingLeft: 10,
                                flexDirection: 'row',
                            }}>

                                <IconComponent style={{
                                    box: {
                                        height: 30,
                                        marginLeft: 'auto',
                                        width: 30,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'red',
                                        borderRadius: 45,

                                    },
                                    icon: {
                                        fontSize: 15,
                                        color: '#fff',
                                    }
                                }} name="trash-alt"
                                    onPress={() => {
                                        const newValue = properties.value?.filter((value, indexx) => {
                                            return index !== indexx
                                        })
                                        properties.onChange?.(newValue || [])
                                    }
                                    }
                                />
                            </View>

                        </View>
                    })
                }
            </View>
        }
        <TouchableOpacity onPress={() => {
            setShowFinder(true)
        }} style={{
            margin: 20,
            marginTop: 10,
            marginBottom: 10,
            padding: 9,
            marginLeft: 'auto',
            paddingLeft: 15,
            alignContent: 'center',
            alignItems: 'center',
            paddingRight: 16,
            backgroundColor: '#65c366',
            borderRadius: 35,
            flexDirection: 'row',
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
            marginHorizontal: 10,
        }}>
            <Text style={{
                color: '#FFF',
                flex: 1,
                fontWeight: 'bold',
            }}>
                {
                    t('materials.add')
                }
            </Text>
            <IconComponent style={{
                icon: {
                    opacity: 1,
                    fontSize: 15,
                    color: '#FFF'
                }
            }} name="plus" />
        </TouchableOpacity>

        {
            properties.onNew && <TouchableOpacity onPress={() => {
                properties.onNew?.((data: any) => {
                    if ('id' in data) {
                        properties.onChange?.(data.id)
                        setShowFinder(false)
                    }
                })
            }} style={{
                backgroundColor: '#ddd',
                padding: 5,
                paddingHorizontal: 10,
                borderRadius: 5,
                position: 'absolute',
                top: -37,
                right: 0,
            }}>
                <TextComponent styles={{
                    text: {
                        fontSize: 11,
                        lineHeight: 16,
                        fontWeight: 'bold',
                    }
                }}>New</TextComponent>
            </TouchableOpacity>
        }
        {showFinder && <>
            <View>
                {
                    loading && <LoaderComponent backgroundColor='#fff' />
                }
                <IconComponent name='search' style={{
                    box: {
                        position: 'absolute',
                        top: 4,
                        left: 7,
                        height: 30,
                        width: 30,
                        borderRadius: 35,
                        justifyContent: 'center',
                        alignItems: 'center',

                    },
                    icon: {
                        fontSize: 20,
                        color: '#333',

                    }
                }}
                />

                <TextInputNative
                    style={{
                        borderWidth: 0,
                        borderColor: '#ddd',
                        padding: 10,
                        paddingLeft: 50,
                        borderTopColor: '#ddd',
                        borderTopWidth: 1,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderRadius: 0,
                    }}
                    placeholder={t('search')}
                    value={search}
                    onSubmitEditing={() => {
                        getRows(search)
                    }}
                    onChangeText={(value) => {
                        setSearch(value);
                    }}
                />
            </View>

            {
                rows.map((row, index) => {
                    return <TouchableOpacity key={index} onPress={() => {

                        properties.onChange?.([
                            ...properties.value || [],
                            {
                                id: row.id,
                                name: row.name,
                                denomination: row.denomination,
                                description: row.description,
                                expiration: row.expiration,
                                health: row.health,
                                pack: row.pack,
                                reference: row.reference,
                                reused: row.reused,
                                type: row.type,
                            }
                        ])


                        setShowFinder(false)
                    }} style={{
                        padding: 10,
                        paddingVertical: 6,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        backgroundColor: '#f5f5f5',

                        marginBottom: 5,
                    }}>
                        <TextComponent styles={{
                            text: {
                                fontWeight: 'bold'
                            }
                        }}>{properties.renderLabel ? properties.renderLabel(row) : row.name}</TextComponent>
                    </TouchableOpacity>
                })
            }
        </>}

    </View >
}