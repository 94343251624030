import { NavigationProp, useNavigation } from "@react-navigation/native";

import { PostModule } from "@modules/post/post.module";
import { userState } from "@states/user.state";
import axios from "axios";
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../../environment";
import { StorageService } from "../../../../services/storage.service";
import { GalleryRoutes } from "../gallery.router";

type routes = {
    list: undefined
}




export function NewGalleryScreen(properties: {
    navigation: any
}) {
    const navigation = useNavigation<NavigationProp<GalleryRoutes>>()
    const [image, setImage] = useState<string>()
    const [title, setTitle] = useState('')
    const [user, setUser] = useRecoilState(userState)
    const [description, setDescription] = useState('')
    const [error, setError] = useState<string>()
    const [cache, setCache] = useState<string>()
    const [designId, setDesignId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const handlerSubmit = () => {
        if (!user) return;
        setLoading(true)
        axios.post(`${Environment.api}/image`, {
            title: title,
            description: description,
            path: image,
            design: designId

        }, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            setLoading(false)
            properties.navigation.goBack()
        }).catch((error) => {
            const message = error.response.data.message
            setError(message)
            setLoading(false)
            setCache(new Date().getTime().toString())
        })
    }


    const pickImage = async (uri: string) => {
        if (!user) return;

        if (uri) {
            // compress width 500
            const compressedImage = await manipulateAsync(
                uri,
                [{ resize: { width: 1500 } }],
                {
                    base64: true,
                    compress: 1, format: SaveFormat.JPEG
                }
            );
            StorageService(user?.accessToken, 'image', compressedImage).then((cleanPath: string) => {
                setImage(`${cleanPath}?cache=${new Date().getTime()}`)

            })

        }

    };

    return <PostModule<{
        title: string
        description: string
        design: string
        path: string
    }>
        prefix="newImage"
        path="image"
        onSuccess={(data) => {
            navigation.navigate('list')
        }}
        data={{
            description: '',
        }}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'title',
                type: 'text'
            },
            {
                name: 'path',
                type: 'image'
            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                name: 'design',
                type: 'select',
                options: [
                    'blackandgrey',
                    'realism',
                    'neotraditional',
                    'ornamental',
                    'lettering',
                    'anime',
                    'oldschool',
                    'geometric',
                    'dotwork',
                    'newschool',
                    'fineline',
                    'freehand',
                    'cover',
                    'line',
                    'minimalism',
                    'blackwork',
                    'watercolor',
                    'tribal',
                    'trashpolka',
                    'chicano',
                    'piercing',
                    'tattooremoval',
                    'neooriental',
                    'points',
                    'cartoon',
                    'brush',
                    'expressionism',
                    'cubism',
                    'comic',
                    'celtic',
                    'abstract',
                    'horror',
                    'graffiti',
                    'microblading',
                    'magicalgirl',
                    'pixel',
                    'biomechanical',
                    'kawaii'
                ]
            }
        ]}
    />
}