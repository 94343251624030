import { SelectComponent } from "@components/select/select.component";
import { userState } from "@states/user.state";
import axios from "axios";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export function TimezoneModule(properties: {
    timezone?: string,
    onSelect: (room: {
        name: string,
        id: string
    }) => void
}) {
    const { t } = useTranslation()
    const getRooms = async (search?: string) => {

        const timeszones = moment.tz.names()
        if (search) return timeszones.filter((language) => {
            return language.toLowerCase().includes(search.toLowerCase())
        }).map((timezone) => {
            return {
                name: timezone,
                id: timezone
            }
        })
        return timeszones.map((timezone) => {
            return {
                name: timezone,
                id: timezone
            }
        })

    }


    return <SelectComponent
        getItems={(page, search) => {
            return getRooms(search)
        }}
        modalTitle={t('MODULETIMEZONE_SELECT')}
        title={t('MODULETIMEZONE')} value={properties.timezone} onSelect={(item) => {
            properties.onSelect(item)
        }
        }
    />

}