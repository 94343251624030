import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ServiceRoutes } from "../service.routes";
import { useState } from "react";

export function EditServiceScreen() {
    const navigation = useNavigation<NavigationProp<ServiceRoutes>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'edit'>>()
    const [priceType, setType] = useState<'EXACT' | 'MINUTE'>()
    return <PutModule<{
        id: string,
        name: string,
        color: string;
        description: string;
        notification: boolean;
        notificationMessage: string;
        minimumPrice: number;
        exactPrice: number;
        depositPercentage: number;
        minutePrice: number;
        bookingOnline: boolean;
        type: 'EXACT' | 'MINUTE'
    }>
        prefix="updateService"
        path="service"
        id={route.params.serviceId}
        onSuccess={() => {
            navigation.goBack()
        }}
        onLoadData={(data) => {
            setType(data.type)
        }}

        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'type',
                type: 'select',
                options: ['EXACT', 'MINUTE'],
                onSelect: (value) => {
                    setType(value as any)
                }
            },

            ...priceType === 'EXACT' ? [{
                name: 'exactPrice',
                type: 'price',
                groupId: route.params.groupId
            }] :
                [
                    {
                        name: 'minimumPrice',
                        type: 'price',
                        groupId: route.params.groupId
                    },
                    {
                        name: 'minutePrice',
                        type: 'priceMinute',
                        groupId: route.params.groupId
                    }],
            {
                name: 'name',
                type: 'text'
            },
            {
                name: 'color',
                type: 'color'
            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                name: 'notification',
                type: 'checkbox'
            },
            {
                name: 'notificationMessage',
                type: 'text',
                lines: 5
            },

            {
                name: 'bookingOnline',
                type: 'checkbox'
            },
            {
                name: 'depositPercentage',
                type: 'number',
            }
        ]}
    />
}
