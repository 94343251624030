// Modules
import { TouchableOpacity, View } from 'react-native'
import * as React from 'react'

// Components
import { TextComponent } from '../text/text.component'
import { IconComponent } from '../icon/icon.component'

// Properties
import { type KnobProperties } from './know.properties'

// Styles
import { KnobStyles, PlaceComponentStyles } from './knob.styles'

/**
 * Knob Component
 * @description This component is used to define a float button to close any modal or one action in any component
 * @function KnobComponent
 * @param {KnobProperties} properties
 * @return {JSX.Element}
 */
export function KnobComponent(properties: KnobProperties): JSX.Element {
  const style: Array<Record<string, unknown>> = [KnobStyles.component]

  if (properties.style) {
    style.push(properties.style)
  }
  const [lastPress, setLastPress] = React.useState(0)
  const pressDebouce = () => {
    // debounce to avoid double click 1 second
    const now = Date.now()
    if (now - lastPress < 1000) {
      return
    }
    setLastPress(now)
    properties.onPress()

  }

  return (
    <TouchableOpacity
      onPress={pressDebouce}
      style={[
        style,
        properties.position === 'absolute'
          ? KnobStyles.absolute
          : properties.position === 'relative'
            ? KnobStyles.relative
            : undefined,
        properties.place && PlaceComponentStyles[properties.place],
      ]}
    >
      <IconComponent
        style={{
          icon: KnobStyles.icon,
        }}
        name={properties.icon}
      />

      {!!properties.bubble && (
        <View style={KnobStyles.bubble}>
          <TextComponent
            variant='h5'
            content={properties.bubble}
          />
        </View>
      )}
    </TouchableOpacity>
  )
}
