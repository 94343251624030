import { RouteProp, useRoute } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { StudioRoutes } from "../studio.routes";
import { AddressConfigurationScreen } from "./address/address.screen";
import { BasicConfigurationScreen } from "./basic/basic.screen";
import { ConfigurationRoutes } from "./configuration.routes";
import { DeleteConfigurationScreen } from "./delete/delete.screen";

const stack = createNativeStackNavigator<ConfigurationRoutes>();

export function ConfigurationNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'consents'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="basic"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="delete"
            component={DeleteConfigurationScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="basic"
            component={BasicConfigurationScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="address"
            initialParams={{ groupId }}
            options={modalOptions}
            component={AddressConfigurationScreen}
        />
    </stack.Navigator>
}
