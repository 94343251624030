import React, { useState, useTransition } from 'react';
import { Button, Modal, StyleSheet, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { CheckboxInputProperties } from './checkbox.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';

export function CheckboxInputForm<T>(properties: CheckboxInputProperties<T>) {
    const { t } = useTranslation()
    return <TouchableOpacity onPress={() => {
        properties.onChange?.(!properties.value);
    }} style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignItems: 'center',
        gap: 10,
    }}>
        <View style={{

            width: 40, height: 25, borderRadius: 30, margin: 5, backgroundColor: properties.value ? '#65c366' : 'rgba(0,0,0,0.2)'
        }} >
            <View style={{
                width: 15, height: 15, borderRadius: 10, backgroundColor: 'white', margin: 5,
                marginLeft: properties.value ? 20 : 5,

            }}></View>
        </View>
        {
            properties.prefix && <TextComponent styles={{
                text: {
                    fontWeight: 'bold',
                }
            }}>
                {
                    t(`${properties.prefix}.${properties.name as string}`)
                }
            </TextComponent>
        }
    </TouchableOpacity >
}