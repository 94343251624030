import { IconComponent } from "@components/icon/icon.component";
import { Environment } from "@environment";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { StudioRoutes } from "../../screens/studio/studio.routes";

export function PayNowComponent(properties: {
    groupId: string | number
}) {
    const { t } = useTranslation()
    const navigationStudio = useNavigation<NavigationProp<StudioRoutes>>()
    const [countCustomers, setCountCustomers] = useState<number>(0)
    const [user] = useRecoilState(userState)
    const [haveSubscription, setHaveSubscription] = useState<boolean>(true)
    const countCustomersAction = () => {
        if (!properties.groupId) return
        axios.get(`${Environment.api}/group?groupId=${properties.groupId}&component=PayNow`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        }).then((response) => {
            const group = response.data.items?.[0]
            if (group) {
                if (group.subscriptionId) {
                    setHaveSubscription(true)
                }
                else {
                    setHaveSubscription(false)
                }
            }
        })
    }
    useEffect(() => {
        if (properties.groupId) {
            countCustomersAction()
        }
    }, [properties.groupId])
    if (Platform.OS !== 'web') {
        return null
    }

    if (haveSubscription) return null

    return <View style={{
        backgroundColor: 'rgb(16, 25, 40)',
        padding: 14,
        paddingVertical: 6,
        gap: 10,
        paddingLeft: 10,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginBottom: 10
    }}>
        <IconComponent name="credit-card" style={{
            icon: {
                color: '#65c366',
                fontSize: 25,
            }
        }} />
        <Text style={{
            color: '#fff'
        }}>{t('payNow.title')} </Text>
        <TouchableOpacity
            style={{
                borderRadius: 100,
                padding: 10,
                paddingVertical: 6,
                backgroundColor: '#65c366',
            }}
            onPress={() => {
                navigationStudio.navigate('subscription')

            }}>
            <Text style={{
                color: '#fff',
                fontSize: 9,
                fontWeight: 'bold',
                textShadowColor: 'rgba(0, 0, 0, 0.3)',
                textShadowOffset: { width: -1, height: 1 },
                textShadowRadius: 4,
            }}>{
                    t('payNow.button')
                }</Text>
        </TouchableOpacity>

        <View style={{
            height: 30,
            backgroundColor: 'rgba(52, 199, 89,0.8)',
            borderRadius: 35,
            overflow: 'hidden'
        }}>
            <View style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: 30,
                width: 100,
                borderRadius: 35,
                backgroundColor: '#000'
            }} >
                <Text style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    height: 30,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    lineHeight: 30,
                    fontSize: 10,
                    textAlign: 'center',
                    color: '#fff',
                    fontWeight: 'bold'
                }}>
                    {countCustomers} clients of 10
                </Text>
            </View>

            <Text style={{
                position: 'absolute',
                right: 20,
                top: 0,
                height: 30,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: 30,
                textShadowColor: 'rgba(0,0,0,0.5)',
                textShadowOffset: {
                    width: 1,
                    height: 1
                },
                textShadowRadius: 3,
                color: '#fff',
                fontWeight: 'bold',
                fontSize: 12
            }}>
                TRIAL
            </Text>
        </View>
        <IconComponent name="arrow-right" style={{
            box: {
                marginLeft: -10,
            },
            icon: {
                color: '#65c366',
                fontSize: 20,
            }
        }} />
    </View>
}