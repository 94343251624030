import { IconComponent } from "@components/icon/icon.component"
import { TextComponent } from "@components/text/text.component"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

export function EmptyComponent(properties: {
    prefix?: string
    invert?: boolean
}) {
    const { t } = useTranslation()
    return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <View style={{
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <View

                style={{
                    paddingVertical: 50,
                    paddingHorizontal: 20,
                }}>
                <div className="empty-a">
                    <IconComponent name='tint' style={{

                        icon: {
                            fontSize: 33,
                            color: properties.invert ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'

                        }
                    }} />
                </div>
                <div className="empty-b">
                    <IconComponent name='ghost' style={{

                        icon: {
                            fontSize: 44,
                            color: properties.invert ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'
                        }
                    }} />
                </div>
                <div className="empty-c">
                    <IconComponent name='kiss-wink-heart' style={{

                        icon: {
                            fontSize: 25,
                            color: properties.invert ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'
                        }
                    }} />
                </div>
                <div className="empty-d">
                    <IconComponent name='anchor' style={{

                        icon: {
                            fontSize: 33,
                            color: properties.invert ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)'
                        }
                    }} />
                </div>
                <TextComponent styles={{
                    text: {
                        fontSize: 20,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: properties.invert ? '#fff' : '#000'
                    }
                }}>{t(`${properties.prefix ? `${properties.prefix}.` : ''}empty.title`)}</TextComponent>
                <TextComponent styles={{
                    text: {
                        fontSize: 15,
                        opacity: 0.5,
                        fontWeight: '400',
                        textAlign: 'center',
                        color: properties.invert ? '#fff' : '#000'
                    }
                }}>{t(`${properties.prefix ? `${properties.prefix}.` : ''}empty.subtitle`)}</TextComponent>

            </View>
        </View>
    </View>
}