import { IconComponent } from "@components/icon/icon.component";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { BoxTitle } from "../box.component";

export function ThreeStep(properties: {
    setStep: (step: number) => void
}) {
    const { t, } = useTranslation()
    const { width } = useWindowDimensions()


    const services = [{
        title: 'manager',
        icon: 'desktop'
    },
    {
        title: 'discounts',
        icon: 'percent'
    },
    {
        title: 'courses',
        icon: 'graduation-cap'
    },
    {
        title: 'social',
        icon: 'instagram'
    }
    ]
    return <>

        <BoxTitle title={t('onboarding.three.title')} subtitle="" />

        <View style={{
            flexWrap: 'wrap',
            flexDirection: width > 800 ? 'row' : 'column',
            marginTop: 20,
            marginBottom: 10,
            marginHorizontal: width > 800 ? 0 : 20,
        }}>
            {
                services.map((service, index) => {
                    return <View key={index} style={{
                        width: width > 800 ? '50%' : '100%',

                    }}>
                        <View

                            style={{
                                margin: 10,
                                flex: 1,
                                flexDirection: 'column',
                                padding: 20,
                                backgroundColor: '#fff',
                                paddingVertical: 20,
                                borderRadius: 10,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 6,
                                },
                                shadowOpacity: 0.1,
                                shadowRadius: 26.00,
                            }}>
                            <IconComponent name="check" style={{
                                box: {
                                    height: 50,
                                    width: 50,
                                    position: 'absolute',
                                    right: -15,
                                    top: -15,
                                    backgroundColor: 'rgb(22, 192, 152)',
                                    borderRadius: 30,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                icon: {
                                    fontSize: 20,
                                    color: '#fff',
                                }
                            }} />
                            <View style={{
                                flexDirection: 'row',
                                gap: 14,
                                marginBottom: 10,
                            }}>
                                <IconComponent name={service.icon as any} style={{
                                    box: {


                                        borderRadius: 30,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    icon: {
                                        color: 'rgb(22, 192, 152)',
                                    }
                                }} />
                                <Text style={{
                                    fontSize: 25,
                                    fontWeight: 'bold',
                                }}>{t(`benefits.${service.title}.title`)}</Text>
                            </View>
                            <Text

                                style={{
                                    fontSize: 18,
                                    fontWeight: '300',
                                }}>{t(`benefits.${service.title}.subtitle`)}</Text>

                        </View>
                    </View>
                })
            }
        </View>

        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: '#333',
                    padding: 20,
                    paddingHorizontal: 40,
                    borderRadius: 10,
                    alignSelf: 'center',
                    margin: 10,
                    marginTop: 0,
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
                onPress={() => {
                    properties.setStep(4)
                }}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                    {t('onboarding.three.next')}
                </Text>
                <IconComponent name="arrow-right" style={{
                    icon: {
                        color: '#fff',
                        fontSize: 30,
                        textAlign: 'center',
                    }
                }} />
            </TouchableOpacity>
        </View>
    </>

}