import { SubmitComponent } from "@components/submit/submit.component"
import { TextComponent } from "@components/text/text.component"
import { Fragment, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, Text, View } from "react-native"
import { FormProperties, InputFormProperties } from "./form.properties"
import { AccordionInputForm } from "./inputs/accordion/accordion.input"
import { BirthdayInputForm } from "./inputs/birthday/birthday.input"
import { CheckboxInputForm } from "./inputs/checkbox/checkbox.input"
import { ChooseInputForm } from "./inputs/choose/choose.input"
import { ColorInputForm } from "./inputs/color/color.input"
import { DatetimeInputForm } from "./inputs/datetime/datetime.input"
import { EditorComponent } from "./inputs/editor/textarea.component"
import { ImageInputForm } from "./inputs/image/image.input"
import { ImagesInputForm } from "./inputs/images/images.input"
import { NumberInputForm } from "./inputs/number/number.input"
import { PriceInputForm } from "./inputs/price/price.input"
import { PriceMinuteInputForm } from "./inputs/priceMinute/priceMinute.input"
import { ProductsInputForm } from "./inputs/products/products.input"
import { SelectInputForm } from "./inputs/select/select.input"
import { ServiceInputForm } from "./inputs/service/service.input"
import { SignatureInputForm } from "./inputs/signature/signature.input"
import { TextInputForm } from "./inputs/text/text.input"
import { TimeInputForm } from "./inputs/time/time.input"
import { MaterialsInputForm } from "./inputs/materials/materials.input"
import { callback } from "chart.js/dist/helpers/helpers.core"


export function ErrorModule(properties: {
    message: string
}) {
    const { t } = useTranslation()
    return <View
        style={{ marginBottom: 20, marginTop: 6, backgroundColor: 'rgba(255,0,0,0.1)', padding: 10, paddingHorizontal: 13, borderRadius: 5 }}>
        <Text style={{ color: 'red', fontWeight: 'bold' }}>{t(`${properties.message}`)}</Text>
    </View>

}

export function FormModule<T>(properties: FormProperties<T>) {
    const { t } = useTranslation()

    return <>

        <ContainerFormModule
            error={properties.error}
            extraSubmit={properties.extraSubmit}
            footerComponent={properties.footerComponent ? properties.footerComponent(properties.data) : undefined}
            prefix={properties.prefix} onSubmit={properties.onSubmit}>
            {
                properties.headerInsideComponent && properties.headerInsideComponent(properties.data)
            }
            {
                properties.error && <ErrorModule message={properties.error.message} />
            }
            {
                properties.contentComponent && properties.contentComponent()
            }
            {
                properties.inputs && <RenderInputs
                    error={properties.error}
                    data={properties.data} onChange={properties.onChange} prefix={properties.prefix} onSubmit={properties.onSubmit} inputs={properties.inputs} />
            }
        </ContainerFormModule>
    </>



}

export function ContainerFormModule(properties: {
    children: any,
    prefix: string,
    error?: {
        inputName: string
        message: string
    }
    footerComponent?: any,
    extraSubmit?: {
        submitFirst?: boolean,
        label: string,
        onPress: () => void
    }
    onSubmit?: (properties?: {
        callback?: () => void
    }) => void
}) {
    const { t } = useTranslation()
    const scrollViewRef = useRef<ScrollView>(null)
    const [currentError, setCurrentError] = useState<string | undefined>()

    useEffect(() => {
        if (properties.error) {
            if (properties.error.message !== currentError) {
                setCurrentError(properties.error?.message || '')
                scrollViewRef.current?.scrollTo({ y: 0, animated: true })
            }

        }
    }, [properties.error])
    return <>
        <ScrollView
            ref={scrollViewRef}
            style={{
                borderTopColor: 'rgba(0,0,0,0.1)',
                borderTopWidth: 1,
                padding: 20,
                paddingTop: 20,
                flex: 1,
                flexDirection: 'column',
            }}>
            {properties.children}
            {properties.footerComponent}
        </ScrollView>
        <View style={{
            padding: 20,
            paddingHorizontal: 30,
            backgroundColor: '#eee',
            borderTopColor: 'rgba(0,0,0,0.1)',
            borderTopWidth: 1,
            flexDirection: 'row',

        }}>
            {
                properties.extraSubmit && <SubmitComponent
                    backgroundColor={'#65c366'}
                    title={properties.extraSubmit.label}
                    onPress={() => {
                        if (properties.extraSubmit?.submitFirst) {
                            properties.onSubmit?.({
                                callback: properties.extraSubmit.onPress
                            })
                        } else {
                            properties.extraSubmit?.onPress()
                        }

                    }}
                />
            }

            <View style={{
                flex: 1,
            }} />

            {properties.onSubmit && <SubmitComponent
                title={t(`${properties.prefix}.submit`)}
                onPress={() => {
                    properties.onSubmit?.()
                }}
            />}
        </View>
    </>
}


export function RenderInputs<T>(settings: {
    pair?: boolean,
    prefix: string,
    onSubmit?: () => void,
    onChange: (data: Partial<T>) => void,
    data: Partial<T>,
    error?: { inputName: string, message: string },
    inputs: InputFormProperties<T>[]
}) {


    const { t } = useTranslation()
    return <>
        {
            settings.inputs.map((input, index) => {
                if ('render' in input && input.render === false) return <Fragment key={index}></Fragment>
                const name = 'nameHour' in input ? input.nameHour.toString() :
                    'nameStart' in input && typeof input.nameStart === 'string'
                        ? input.nameStart.toString()
                        : 'name' in input ? input.name.toString() : ''

                if ('dependsOn' in input) {
                    const haveAllDepends = input.dependsOn?.filter((depend) => {
                        const v = settings.data?.[depend] as string

                        return v === undefined || v === null || v === '' ? true : false

                    }) || ['nop']

                    if (haveAllDepends.length > 0) {
                        return <></>
                    }
                }

                const extraNames = [
                    'nameHour' in input ? input.nameHour as string : '',
                    'nameMinute' in input ? input.nameMinute as string : ''
                ]


                return <Fragment key={index} >
                    <FieldsetFormModule
                        marginBottom={settings.pair || index === settings.inputs.length - 1}
                        name={name}
                        pair={settings.pair || false}
                        haveOnNew={'onNew' in input}
                        backgroundColor={undefined}
                        zIndex={input.type === 'datetime' ? 1000 : 0}
                        paddingRight={input.type === 'datetime' ? true : false}
                        paddingLeft={input.type === 'checkbox' ? false : false}
                        noHeader={input.type === 'pair' || input.type === 'accordion'}
                        prefix={settings.prefix}
                        error={settings.error}
                        extraNames={extraNames}
                        noDescription={'noDescription' in input ? true : false}
                    >


                        <RenderInput
                            error={settings.error}
                            data={settings.data} input={input} onChange={settings.onChange} prefix={settings.prefix} onSubmit={settings.onSubmit} />

                    </FieldsetFormModule>

                </Fragment>
            })
        }

    </>
}

export function FieldsetFormModule(settings: {
    pair?: boolean,
    zIndex?: number,
    paddingRight?: boolean,
    paddingLeft?: boolean,
    noHeader?: boolean,
    invert?: boolean,
    prefix: string,
    haveOnNew?: boolean,
    backgroundColor?: string,
    noDescription?: boolean,
    name: string,
    error?: { inputName: string, message: string },
    marginBottom?: boolean,
    children?: any,
    extraNames?: string[]
}) {
    const haveError = () => {
        const names = [
            settings.name,
            ...settings.extraNames || []
        ]

        return settings.error && settings.error.inputName && names.includes(settings.error.inputName) && names.some((name) => {
            // start with name
            return settings.error?.message.startsWith(name)
        })
    }

    const { t } = useTranslation()
    return <View style={{
        flex: settings.pair ? 1 : undefined,
        zIndex: settings.zIndex,
        backgroundColor: settings.backgroundColor,
        padding: settings.backgroundColor ? 20 : 0,
        paddingBottom: settings.backgroundColor ? 0 : 0,
        marginLeft: settings.backgroundColor ? -20 : 0,
        marginRight: settings.backgroundColor ? -20 : 0,
        marginTop: settings.backgroundColor ? -20 : 0,
        marginBottom: settings.backgroundColor ? 20 : 0,
    }}>
        <View style={{
            paddingLeft: settings.paddingLeft ? 70 : 0,
            paddingRight: settings.haveOnNew || settings.paddingRight ? 100 : 0,
        }}>
            {
                !settings.noHeader && <>
                    <TextComponent styles={{
                        text: {
                            color: settings.invert ? '#fff' : '#333',
                            fontWeight: '600', fontSize: 14, lineHeight: 14
                        }
                    }}>{t(`${settings.prefix}.${settings.name}.title`)}</TextComponent>
                    {
                        settings.noDescription ? <View style={{
                            height: 10
                        }} /> :

                            <TextComponent styles={{
                                layout: { marginTop: 3, marginBottom: 10 },
                                text: {
                                    color: settings.invert ? 'rgba(255,255,255,0.5)' : '#666',
                                    fontWeight: '300',

                                    fontSize: 11, lineHeight: 11
                                }
                            }}>{t(`${settings.prefix}.${settings.name}.description`)}</TextComponent>}
                </>
            }
        </View>
        {settings.children}
        {
            settings.error && haveError()

            && <View
                style={{ marginBottom: 20, marginTop: 6, backgroundColor: 'rgba(255,0,0,0.1)', padding: 10, paddingHorizontal: 13, borderRadius: 5 }}>
                <Text style={{ color: 'red', fontWeight: 'bold' }}>{t(`${settings.error.message}`)}</Text>
            </View>

        }
        {
            !settings.backgroundColor && !settings.marginBottom && <View style={{ marginBottom: 20 }}></View>
        }
    </View>
}


export function RenderInput<T>(settings: {
    prefix: string,
    onSubmit?: () => void,
    error?: { inputName: string, message: string },
    onChange: (data: Partial<T>) => void,
    data: Partial<T>, input: InputFormProperties<T>
}) {
    const value = settings.input.type !== 'accordion' && 'name' in settings.input && settings.data ? settings.data?.[settings.input.name] : undefined
    const input = settings.input
    const type = settings.input.type
    if (type === 'time') {
        if (settings.input.nameHour && settings.input.nameMinute) {
            const nameMinute = settings.input.nameMinute
            const nameHour = settings.input.nameHour
            const onChangeHour = (v: number) => {
                settings.onChange({
                    [nameHour]: v
                })
            }
            const onChangeMinute = (v: number) => {
                settings.onChange({
                    [nameMinute]: v
                })
            }
            return <TimeInputForm
                {...settings.input}
                prefix={settings.prefix}
                onChangeHour={onChangeHour}
                valueHour={settings.data ? settings.data[nameHour] as number : undefined}
                onChangeMinute={onChangeMinute}
                valueMinute={settings.data ? settings.data[nameMinute] as number : undefined}
            />
        }
        else return <Text>error</Text>
    }
    else if (type === 'checkbox') {
        const checkboxName = settings.input.name
        const onChange = (v: boolean) => {
            settings.onChange({
                [checkboxName]: v
            } as T)
        }
        return <CheckboxInputForm
            {...settings.input}
            prefix={settings.prefix}
            onChange={onChange}
            value={value as boolean}
        />
    }
    else if (type === 'editor') {
        const editorName = settings.input.name
        const onChangeEditor = (v: string) => {
            settings.onChange({
                [editorName]: v
            } as T)
        }
        return <EditorComponent
            {...settings.input}
            onChange={onChangeEditor}
            value={value as string}
        />
    }
    else if (type === 'pair') {
        return <View style={{
            flexDirection: 'row',
            gap: 20, overflow: 'hidden', width: '100%',
        }}>
            {settings.input.inputs && <RenderInputs
                pair
                error={settings.error}
                data={settings.data} onChange={settings.onChange} prefix={settings.prefix} onSubmit={settings.onSubmit} inputs={settings.input.inputs} />}
        </View>
    }
    else if (type === 'signature') {
        const signatureName = settings.input.name
        const onChangeSignature = (v: string) => {
            settings.onChange({
                [signatureName]: v
            } as T)
        }
        return <SignatureInputForm

            {...settings.input}
            eraser={true}
            onChange={(v: string) => {
                onChangeSignature(v)
            }}
            value={value as string}
        />
    }
    else if (type === 'image') {
        const imageName = settings.input.name
        const onChangeImage = (v: string) => {
            settings.onChange({
                [imageName]: v
            } as T)
        }
        return <ImageInputForm
            {...settings.input}
            onChange={(v: string) => {
                onChangeImage(v)
            }}
            value={value as string}
        />
    }
    else if (type === 'price') {
        const priceName = settings.input.name
        const onChangePrice = (v: number) => {
            settings.onChange({
                [priceName]: v
            } as T)
        }
        return <PriceInputForm
            name={priceName}
            type="price"
            currency={settings.input.currency}
            groupId={settings.input.groupId}
            onSubmit={() => {
                settings.onSubmit?.()
            }}
            onChange={onChangePrice}
            value={(value || 0) as number}
        />
    }

    else if (type === 'priceMinute') {
        const priceMinuteName = settings.input.name
        const onChangePriceMinute = (v: number) => {
            settings.onChange({
                [priceMinuteName]: v
            } as T)
        }
        return <PriceMinuteInputForm
            name={priceMinuteName}
            type="priceMinute"
            onSubmit={() => {
                settings.onSubmit?.()
            }}
            prefix={settings.prefix}
            onChange={onChangePriceMinute}
            value={(value || 0) as number}
        />
    }

    else if (type === 'accordion') {
        return <AccordionInputForm
            type="accordion"
            prefix={settings.prefix}
            inputs={settings.input.inputs}
            name={settings.input.name}
        >
            {input.type === 'accordion' && input.inputs ? <RenderInputs
                data={settings.data}
                onChange={settings.onChange}
                prefix={settings.prefix}
                onSubmit={settings.onSubmit}
                inputs={input.inputs} /> : <></>}
        </AccordionInputForm>
    }

    else if (type === 'number') {
        const numberName = settings.input.name
        const numberOnChange = (v: number) => {
            settings.onChange({
                [numberName]: v
            } as T)
        }
        return <NumberInputForm
            type="number"
            name={numberName}
            onSubmit={() => {
                settings.onSubmit?.()
            }}
            onChange={numberOnChange}
            value={(value || 0) as number}
        />
    }

    else if (type === 'products') {
        const productsName = settings.input.name
        const productsOnChange = (v: string) => {
            const jsonduplicate = JSON.parse(JSON.stringify(v))
            settings.onChange({
                [productsName]: jsonduplicate
            } as T)
        }
        return <ProductsInputForm
            type="products"
            name={productsName}
            groupId={settings.input.groupId}
            onSelect={settings.input.onSelect}
            options={settings.input.options || []}
            prefix={settings.prefix}
            onChange={productsOnChange}
            value={value as string}
        />
    }

    else if (type === 'color') {
        const colorName = settings.input.name
        return <ColorInputForm
            type="color"
            name={settings.input.name}
            onChange={(v) => {
                settings.onChange({
                    [colorName]: v
                } as T)
            }}
            value={`${value}`}
        />
    }

    else if (type === 'select') {
        const selectName = settings.input.name
        const selectOnChange = (v: string | number) => {
            settings.onChange({
                [selectName]: v
            } as T)
        }
        return <SelectInputForm
            type="select"
            name={selectName}
            onSelect={settings.input.onSelect}

            options={settings.input.options || []}
            prefix={settings.prefix}
            onChange={selectOnChange}
            value={value as string | number}
        />
    }

    else if (type === 'images') {
        const imagesName = settings.input.name
        const imagesOnChange = (v: string[]) => {
            settings.onChange({
                [imagesName]: v
            } as T)
        }
        return <ImagesInputForm
            type="images"
            name={imagesName}
            prefix={settings.prefix}
            onChange={imagesOnChange}
            images={value ? value as string[] : []}
        />
    }
    else if (type === 'materials') {
        const materialsName = settings.input.name
        const materialsOnChange = (v: any[]) => {
            settings.onChange({
                [materialsName]: v
            } as T)
        }
        return <MaterialsInputForm
            type="materials"
            name={materialsName}
            prefix={settings.prefix}
            onChange={materialsOnChange}
            value={value ? value as any : []}

            groupId={settings.input.groupId}
        />
    }

    else if (type === 'choose') {
        const chooseName = settings.input.name
        function chooseOnChange(v: string) {
            console.log({
                changeVa: {
                    name: chooseName,
                    value: v
                }
            })
            settings.onChange({
                [chooseName]: v
            } as T)
        }
        const chooseOnChangeAll = (v: any) => {
            settings.onChange(v)
        }
        return <ChooseInputForm
            type="choose"
            chooseOnChangeAll={chooseOnChangeAll}
            data={settings.data}
            transformAll={settings.input.transformAll}
            params={settings.input.params}
            name={chooseName}
            onSelectBackground={settings.input.onSelectBackground}
            onSelect={settings.input.onSelect}
            onNew={settings.input.onNew}
            prefix={settings.prefix}
            onChange={chooseOnChange}
            value={value as string}
            renderDetail={settings.input.renderDetail}
            groupId={settings.input.groupId}
            path={settings.input.path}
            renderLabel={settings.input.renderLabel}
        />
    }

    else if (type === 'service') {
        const serviceName = settings.input.serviceName
        const depositName = settings.input.depositName
        const budgetName = settings.input.budgetName
        const memberName = settings.input.memberName
        const commissionName = settings.input.commissionName
        const startedAtName = settings.input.startedAtName
        const finishedAtName = settings.input.finishedAtName
        const onChangeService = (v: any) => {
            settings.onChange({
                [serviceName]: v
            } as T)
        }
        const onChangeDeposit = (v: number) => {
            settings.onChange({
                [depositName]: v
            } as T)
        }
        const onChangeBudget = (v: number) => {
            settings.onChange({
                [budgetName]: v
            } as T)
        }
        const onChangeMember = (v?: string) => {
            settings.onChange({
                [memberName]: v
            } as T)
        }
        const onChangeCommission = (v?: number) => {
            settings.onChange({
                [commissionName]: v
            } as T)
        }
        const onChangeStartedAt = (v?: string) => {

            settings.onChange({
                [startedAtName]: v
            } as T)
        }
        const onChangeFinishedAt = (v?: string) => {
            settings.onChange({
                [finishedAtName]: v
            } as T)
        }
        return <ServiceInputForm<T>
            type="service"
            data={settings.data}
            transformAll={settings.input.transformAll}
            params={settings.input.params}
            onSelectBackground={settings.input.onSelectBackground}
            onSelect={settings.input.onSelect}
            onNew={settings.input.onNew}
            prefix={settings.prefix}
            value={value as string}
            renderDetail={settings.input.renderDetail}
            groupId={settings.input.groupId}
            path={settings.input.path}
            renderLabel={settings.input.renderLabel}
            onChangeService={onChangeService}
            onChangeDeposit={onChangeDeposit}
            onChangeBudget={onChangeBudget}
            onChangeMember={onChangeMember}
            onChangeCommission={onChangeCommission}
            onChangeStartedAt={onChangeStartedAt}
            onChangeFinishedAt={onChangeFinishedAt}
            serviceName={serviceName}
            depositName={depositName}
            budgetName={budgetName}
            memberName={memberName}
            commissionName={commissionName}
            startedAtName={startedAtName}
            finishedAtName={finishedAtName}
            serviceId={settings.input.serviceName ? settings.data?.[settings.input.serviceName] as string : undefined}
            deposit={settings.input.depositName ? settings.data?.[settings.input.depositName] as number : undefined}
            budget={settings.input.budgetName ? settings.data?.[settings.input.budgetName] as number : undefined}
            memberId={settings.input.memberName ? settings.data?.[settings.input.memberName] as string : undefined}
            commission={settings.input.commissionName ? settings.data?.[settings.input.commissionName] as number : undefined}
            startedAt={settings.input.startedAtName ? settings.data?.[settings.input.startedAtName] as string : undefined}
            finishedAt={settings.input.finishedAtName ? settings.data?.[settings.input.finishedAtName] as string : undefined}



        />
    }

    else if (type === 'birthday') {
        const birthdayName = settings.input.name
        const birthdayOnChange = (start: string,) => {
            settings.onChange({
                [birthdayName]: start,
            } as T)
        }
        return <BirthdayInputForm
            name={birthdayName}
            type="birthday"
            prefix={settings.prefix}
            onChange={birthdayOnChange}
            onSubmit={settings.onSubmit}
            value={settings.data ? settings.data[birthdayName] as string : undefined}


        />
    }

    else if (type === 'datetime') {
        const datetimeNameStart = settings.input.nameStart
        const datetimeNameFinish = settings.input.nameFinish
        const datetimeOnChange = (start: string, finish: string) => {
            settings.onChange({
                [datetimeNameStart]: start,
                [datetimeNameFinish]: finish
            } as T)
        }
        return <DatetimeInputForm
            nameFinish={datetimeNameFinish}
            nameStart={datetimeNameStart}
            type="datetime"
            prefix={settings.prefix}
            onChange={datetimeOnChange}
            onSubmit={settings.onSubmit}
            valueStart={settings.data ? settings.data[datetimeNameStart] as string : undefined}
            valueFinish={settings.data ? settings.data[datetimeNameFinish] as string : undefined}
            defaultStart={settings.input.defaultStart}
            defaultFinish={settings.input.defaultFinish}


        />
    }

    else {

        const inputName = settings.input.name
        const onInputChange = (v: string) => {
            console.log({
                inputName,
                v
            })
            settings.onChange({
                [inputName]: v
            } as T)
        }
        return <TextInputForm
            type="text"
            editable={settings.input.editable}
            name={inputName}
            onNew={settings.input.onNew}
            lines={settings.input.lines}
            icon={settings.input.icon}
            prefix={settings.prefix}
            secure={settings.input.secure}
            onSubmit={() => {
                settings.onSubmit?.()
            }}
            value={settings.data?.[inputName] as string}
            onChange={onInputChange}
        />
    }

}