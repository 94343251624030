import { createDrawerNavigator } from '@react-navigation/drawer';
import { useWeb } from "../../hook/web.hook";
import { MainMenu } from "../main.menu";
import { DeleteAccountScreen } from "./delete-account/deleteAccount.screen";
import { GalleryRouter } from "./gallery/gallery.router";
import { PasswordScreen } from "./password/password.screen";
import { ProfileScreen } from "./profile/profile.screen";
import { SettingsScreen } from "./settings/settings.screen";
import { UserRoutes } from './user.routes';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import { useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { MainRoutes } from '../main.routes';

const Drawer = createDrawerNavigator<UserRoutes>();


export function UserNavigator() {
    const [user,] = useRecoilState(userState)
    const navigation = useNavigation<NavigationProp<MainRoutes>>()
    const isWeb = useWeb()
    useEffect(() => {
        if (!user) {
            navigation.navigate('welcome')
        }
    }, [user])

    if (!user) return null
    return (
        <Drawer.Navigator

            drawerContent={MainMenu}

            screenOptions={{
                drawerType: isWeb ? 'permanent' : 'slide',
                headerShown: isWeb ? false : true,
                headerTitleStyle: {
                    fontWeight: 'bold',
                    paddingLeft: 0,
                },

                headerTitleContainerStyle: {
                    marginLeft: 4
                },
                drawerStyle: {
                    width: 350,
                    borderRightWidth: 0,
                },
            }} >
            <Drawer.Screen name="profile" component={ProfileScreen} />
            <Drawer.Screen name="images" component={GalleryRouter} />
            <Drawer.Screen name="settings" component={SettingsScreen} />
            <Drawer.Screen name="password" component={PasswordScreen} />
            <Drawer.Screen name="delete-account" component={DeleteAccountScreen} />

        </Drawer.Navigator >
    );
}


