import axios from "axios";
import { Environment } from "../../environment";
import { ImageResult } from "expo-image-manipulator";
import { Platform } from "react-native";

export function StorageService(accessToken: string, type: 'selfie' | 'image' | 'groupSelfie', image: ImageResult, groupId?: string): Promise<string> {

    return new Promise((resolve, reject) => {
        const pathx = `${Environment.api}/storage?type=${type}${groupId ? `&groupId=${groupId}` : ''}`
        axios.get(pathx, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then((response) => {




























            const path = response.data.url;
            const xhr = new XMLHttpRequest();

            xhr.open('PUT', path, true);
            xhr.setRequestHeader('Content-Type', 'image/jpeg');


            xhr.onload = () => {
                if (xhr.status === 200) {
                    // path without ?
                    const cleanPath: string = path.split('?')[0];


                    resolve(cleanPath);
                }
            }
            if (Platform.OS === 'web') {

                var arr = image.uri.split(','),
                    mime = arr[0].match(/:(.*?);/)?.[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                const x = new File([u8arr], 'image.jpg', { type: mime });

                xhr.send(x);
            }
            else xhr.send(image);
        }).catch((error) => {
            console.log('error', error)
            reject(error)
        })
    })
}