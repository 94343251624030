import { useTranslation } from "react-i18next";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { InputComponent } from "../../../../../components/input/input.component";
import { IconComponent } from "@components/icon/icon.component";
import { useNavigation } from "@react-navigation/native";
import {
  BoxTitle,
  ErrorRender,
  SelectorComponent,
} from "../../../../onboarding/box.component";

export function SevenStep(properties: {
  setStep: (step: number) => void;
  studioName: string;
  error?: string;
  studioUsername: string;
  setStudioName: (username: string) => void;
  setStudioUsername: (password: string) => void;
  setMembers: (members: number) => void;
  members?: number;
  validatePartialShop: () => void;
}) {
  const { t, i18n } = useTranslation();
  const services = [1, 2, 5, 10];
  const navigation = useNavigation();
  const servicesIcons = [
    {
      value: 1,
      icon: "anchor",
    },
    {
      value: 2,
      icon: "anchor",
    },
    {
      value: 5,
      icon: "eraser",
    },
    {
      value: 10,
      icon: "anchor",
    },
  ];
  return (
    <View
      style={{
        width: "100%",
        maxWidth: 700,
        marginHorizontal: "auto",
        padding: 20,
      }}
    >
      <BoxTitle
        title={t("onboarding.seven.title")}
        subtitle={t("onboarding.seven.subtitle")}
      />

      {properties.error && <ErrorRender message={properties.error} />}
      <Text
        style={{
          color: "#000",
          fontWeight: "bold",
          marginTop: 20,
          fontSize: 20,
          paddingVertical: 10,
          paddingBottom: 0,
          marginBottom: -10,
        }}
      >
        {t("onboarding.seven.members")}
      </Text>

      <SelectorComponent
        translationPrefix="members"
        selected={properties.members ? [properties.members] : []}
        setSelected={(members: string[]) => properties.setMembers(members[0])} // TODO: Fix this
        options={servicesIcons}
        multiple={false}
      />
      <Text
        style={{
          color: "#000",
          fontWeight: "bold",
          marginTop: 0,
          fontSize: 20,
          paddingVertical: 10,
        }}
      >
        {t("onboarding.seven.studioName")}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 6,
          },
          overflow: "hidden",
          shadowOpacity: 0.1,
          shadowRadius: 26.0,
        }}
      >
        <InputComponent
          autoCapitalize="none"
          value={properties.studioName}
          onChange={properties.setStudioName}
        />
      </View>
      <Text
        style={{
          color: "#000",
          fontWeight: "bold",
          marginTop: 20,
          fontSize: 20,
          paddingVertical: 10,
        }}
      >
        {t("onboarding.seven.studioUsername")}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 6,
          },
          overflow: "hidden",
          shadowOpacity: 0.1,
          shadowRadius: 26.0,
        }}
      >
        <InputComponent
          value={properties.studioUsername}
          onChange={properties.setStudioUsername}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignContent: "flex-end",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: "#333",
            padding: 20,
            paddingHorizontal: 40,
            borderRadius: 10,
            alignSelf: "center",
            margin: 10,
            marginTop: 0,
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
          }}
          onPress={() => {
            properties.validatePartialShop();
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {t("onboarding.seven.next")}
          </Text>
          <IconComponent
            name="arrow-right"
            style={{
              icon: {
                color: "#fff",
                fontSize: 30,
                textAlign: "center",
              },
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
