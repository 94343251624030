import React, { useState } from 'react';
import { TextInput as TextInputNative, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { NumberInputProperties } from './number.properties';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';

export function NumberInputForm<T>(properties: NumberInputProperties<T>) {

    const handleChange = (text: string) => {
        // Allow only numbers 
        const numericValue = text.replace(/[^0-9]/g, "");
        // convert to numeric value
        const numericValue2 = parseFloat(numericValue);
        properties.onChange?.(numericValue2);
    };

    return <View>
        {
            properties.currency && <TextComponent
                styles={{
                    text: {
                        fontSize: 12,
                    },
                    layout: {
                        position: 'absolute',
                        top: 12,
                        left: 12,
                    }
                }}>
                {properties.currency}
            </TextComponent>
        }
        <TextInputNative
            style={{
                borderWidth: 1,
                borderColor: '#ddd',
                padding: 10,
                paddingLeft: properties.currency ? 30 : 10,
                borderRadius: 5,
            }}
            value={`${properties.value}`}
            onChangeText={(value) => {
                handleChange(value);
            }}
            onSubmitEditing={properties.onSubmit}

        />
        <IconComponent name="plus" style={{
            box: {
                position: 'absolute',
                top: 4,
                right: 4,
                width: 32,
                height: 32,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#333',
                borderRadius: 45,
            },
            icon: {
                fontSize: 20,
                color: '#fff',
            }
        }} onPress={() => {
            properties.onChange?.((properties.value || 0) + 1);
        }} />
        <IconComponent name="minus" style={{
            box: {
                position: 'absolute',
                borderRadius: 45,
                right: 40,
                top: 4,
                width: 32,
                height: 32,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ddd',
                display: properties.value === 0 ? 'none' : 'flex',
            },
            icon: {
                fontSize: 20,
                color: '#333',
            }
        }} onPress={() => {
            const newValue = (properties.value || 0) - 1;

            if (newValue < 0) return;


            properties.onChange?.((properties.value || 0) - 1);
        }} />

    </View>
}