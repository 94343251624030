// Modules
import { StyleSheet } from 'react-native'

/**
 * Input header styles
 */
export const InputHeaderStyles = StyleSheet.create({
  header: {

    justifyContent: 'center',
    pointerEvents: 'none',
  },
  headerFocused: {


  },
  headerUnfocused: {
  },
  headerFocusedText: {

    fontSize: 13,
  },
  headerUnfocusedText: {
    fontSize: 13,
  },
  title: {
    fontSize: 13,
    fontWeight: '500',
    color: '#000'
  },
  description: {
    opacity: 0.5,
  },
})
