// Modules
import { useEffect, useRef, useState } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, SafeAreaView, Text, TextInput, View, TouchableOpacity } from 'react-native'

// Components
import { CountComponent } from './components/count/count.component'
import { InputHeader } from './components/inputHeader/inputHeader.component'
import { InputErrorHighlight } from './components/inputErrorHighlight/inputErrorHighlight.component'

// Properties
import { type EditorProperties } from './input.properties'

// Styles
import { InputStyles } from './input.styles'
import { RichEditor, RichToolbar, actions } from './src'
import { LineComponent } from '@components/line/line.component'
import { TextComponent } from '@components/text/text.component'

/**
 * Input Component
 * @description Render a text field with label and error highlight if needed
 * @param {InputProperties} properties
 * @return {JSX.Element}
 */
export function EditorComponent<T>(properties: EditorProperties<T>): JSX.Element {


  // Shows input field error
  const [error, setError] = useState<string>()
  const [focus, setFocus] = useState<boolean>(false)
  const richRef = useRef<RichEditor>(null)

  const handleHead = ({ }) => <Text style={{ fontSize: 12, color: '#767c83', fontWeight: '600' }}>H1</Text>
  const handleHead2 = ({ }) => <Text style={{ fontSize: 12, color: '#767c83', fontWeight: '600' }}>H2</Text>
  const handleHead3 = ({ }) => <Text style={{ fontSize: 12, color: '#767c83', fontWeight: '600' }}>H3</Text>
  const handleP = ({ }) => <Text style={{ fontSize: 12, color: '#767c83', fontWeight: '600' }}>P</Text>

  const handleCustomAction = () => {
    const newPage = `<hr/><br/>`
    // set content
    const content = richRef.current?.insertHTML(newPage)
  }
  return (
    <View style={[InputStyles.component]}>
      {
        properties.onNew && <TouchableOpacity onPress={() => {
          properties.onNew?.((data: any) => {
            if ('id' in data) {
              properties.onChange?.(data.id)
            }

            if ('email' in data) {
              properties.onChange?.(data.email)
            }
          })
        }} style={{
          backgroundColor: '#ddd',
          padding: 5,
          paddingHorizontal: 10,
          borderRadius: 5,
          position: 'absolute',
          top: -37,
          right: 0,
        }}>
          <TextComponent styles={{
            text: {
              fontSize: 11,
              lineHeight: 16,
              fontWeight: 'bold',
            }
          }}>{
              properties.onNewLabel || 'new'}</TextComponent>
        </TouchableOpacity>
      }
      <SafeAreaView >

        {properties.editable !== false && <RichToolbar
          editor={richRef}
          actions={[
            "customAction",
            actions.heading1,
            actions.heading2,
            actions.setParagraph,
            actions.alignLeft,
            actions.alignCenter,

            actions.setBold,
            actions.setItalic,
            actions.insertBulletsList,
            actions.insertOrderedList,

          ]}
          iconMap={{
            [actions.heading1]: handleHead,
            [actions.heading2]: handleHead2,
            [actions.setParagraph]: handleP,
            customAction: () => <Text style={{ paddingHorizontal: 5, fontSize: 12, color: '#767c83', fontWeight: '600', }}>PAGE</Text>
          }}
          customAction={handleCustomAction}
        />}
        <LineComponent />
        <View renderToHardwareTextureAndroid={true} style={{
          borderWidth: 1,
          overflow: 'hidden',
          borderColor: '#ddd',
          borderRadius: 5, backgroundColor: 'white'
        }}>


          <RichEditor
            ref={richRef} onChange={(descriptionText: any) => {
              // is text?
              if (typeof descriptionText === 'string')
                properties.onChange?.(descriptionText)
            }}
            initialContentHTML={properties.value}
            style={{
              minHeight: 400,
            }}
          />


        </View>

      </SafeAreaView>

    </View>
  )
}
