import { Platform } from "react-native";
import { AtomEffect, atom, selector } from "recoil";
import { persistentAtom, storageEffect } from './persistence/react'
import { persistentAtom as persistentAtomNative, storageEffect as storageEffectNative } from './persistence/react-native'

import { recoilPersist } from 'recoil-persist'
import AsyncStorage from "@react-native-async-storage/async-storage";

export interface UserType {
    id?: string;
    name: string;
    surname: string;
    email: string;
    username: string;
    phone: string;
    accessToken: string;
    language: string;
}

const recoil = Platform.OS === 'web' ? persistentAtom : persistentAtomNative

function persistAtom<T>(key: string): AtomEffect<T> {
    return ({ setSelf, onSet, trigger }) => {
        const loadPersisted = async () => {
            const savedValue = await AsyncStorage.getItem(key);

            if (savedValue != null) {
                setSelf(JSON.parse(savedValue));
            }
        };

        // Asynchronously set the persisted data
        if (trigger === 'get') {
            loadPersisted();
        }

        // Subscribe to state changes and persist them to localForage
        onSet((newValue, _, isReset) => {
            isReset
                ? AsyncStorage.removeItem(key)
                : AsyncStorage.setItem(key, JSON.stringify(newValue));
        })
    }
}
export const userState = recoil<UserType | null>({
    key: "userType",
    default: null,
    effects_UNSTABLE: [persistAtom('userTypePersist')]

});


