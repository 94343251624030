import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { Platform, ScrollView, Text, View } from "react-native";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { StudioRoutes } from "../../studio.routes";
import { ConfigurationRoutes } from "../configuration.routes";
import { MapComponent } from "@components/map/map.component";
import React, { useEffect, useState } from "react";
import { SubmitComponent } from "@components/submit/submit.component";
import axios from "axios";
import { Environment } from "@environment";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { TimezoneModule } from "@modules/selectors/timezone/timezone.module";
import { DeleteComponent } from "@components/delete/delete.component";

export function DeleteConfigurationScreen() {
    const { t } = useTranslation()
    const [user] = useRecoilState(userState)
    const navigation = useNavigation<NavigationProp<ConfigurationRoutes, 'address'>>()
    const route = useRoute<RouteProp<ConfigurationRoutes, 'address'>>()

    const [groupId, setGroupId] = useState<string>()
    useEffect(() => {
        if (user) {
            if (route.params.groupId) {
                axios.get(`${Environment.api}/group?groupId=${route.params.groupId}&component=delete`, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                }).then((response) => {
                    const data = response.data.items?.[0]
                    setGroupId(data.id)

                })
            }
        }
    }, [user, route.params.groupId])

    return (
        <DeleteComponent
            prefix={'deleteGroup'}
            path={'group'}
            id={route.params.groupId}
            groupId={route.params.groupId}
            goBack={() => {
                navigation.goBack()
            }}
            onDeleted={() => {
                if (Platform.OS === 'web') {
                    // reload to /
                    window.location.href = '/'

                }
            }}
        />

    )
}