import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useWeb } from "../../hook/web.hook";
import { MainMenu } from "../main.menu";
import { StudioRoutes } from "./studio.routes";
import { RoomNavigator } from './room/room.navigator';
import { MainRoutes } from '../main.routes';
import { ScheduleNavigator } from './schedule/schedule.navigator';
import { MemberNavigator } from './member/member.navigator';
import { ProductNavigator } from './product/product.navigator';
import { ReceiptNavigator } from './receipt/receipt.navigator';
import { MaterialNavigator } from './material/material.navigator';
import { CustomerNavigator } from './customer/customer.navigator';
import { MeetNavigator } from './meet/meet.navigator';
import { BookingNavigator } from './booking/booking.navigator';
import { TemplateNavigator } from './template/template.navigator';
import { ConsentNavigator } from './consent/consent.navigator';
import { ServiceNavigator } from './services/service.navigator';
import { DashboardScreen } from './dashboard/dashboard.screen';
import { SubscriptionScreen } from './subscription/subscription.screen';
import { CalendarNavigator } from './calendar/calendar.navigator';
import { useTranslation } from 'react-i18next';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import { useRecoilValue } from 'recoil';
import { menuState } from '@states/menu.state';
import { ConfigurationScreen } from './configuration/basic/basic.screen';
import { useEffect } from 'react';
import { userState } from '@states/user.state';
import { ConfigurationNavigator } from './configuration/configuration.navigator';
import { View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';

const Drawer = createDrawerNavigator<StudioRoutes>();
enableExperimentalWebImplementation(true)

export function StudioNavigator() {
    const route = useRoute<RouteProp<MainRoutes, 'studio'>>()
    const groupId = route.params.groupId
    const isWeb = useWeb()
    const { t } = useTranslation()
    const menu = useRecoilValue(menuState)
    const user = useRecoilValue(userState)
    const navigation = useNavigation<NavigationProp<MainRoutes>>()
    useEffect(() => {
        if (!user) {
            navigation.navigate('welcome')
        }
    }, [user])
    if (isWeb === undefined) return null

    if (!user) return null

    return (
        <Drawer.Navigator
            drawerContent={MainMenu}

            screenOptions={{
                gestureHandlerProps: {
                    userSelect: 'auto',
                },
                drawerType: isWeb && menu?.show !== false ? 'permanent' : 'slide',
                headerShown: isWeb ? false : false,

                drawerStyle: {
                    width: isWeb ? 350 : 300,
                    borderRightWidth: 0,
                },


            }
            } >
            <Drawer.Screen
                name="calendar"
                initialParams={{ groupId }}
                component={CalendarNavigator}
            />
            <Drawer.Screen
                name="meets"

                initialParams={{ groupId }}
                component={MeetNavigator}
            />
            <Drawer.Screen
                name="customers"
                initialParams={{ groupId }}
                component={CustomerNavigator}
            />
            <Drawer.Screen
                name="receipts"
                initialParams={{ groupId }}
                component={ReceiptNavigator}
            />
            <Drawer.Screen
                name="rooms"
                initialParams={{ groupId }}
                component={RoomNavigator}
            />
            <Drawer.Screen
                name="schedules"
                initialParams={{ groupId }}
                component={ScheduleNavigator}
            />
            <Drawer.Screen
                name="dashboard"
                options={{ title: t('dashboard.title') }}
                initialParams={{ groupId }}
                component={DashboardScreen}
            />
            <Drawer.Screen
                name="members"
                initialParams={{ groupId }}
                component={MemberNavigator}
            />
            <Drawer.Screen
                name="products"
                initialParams={{ groupId }}
                component={ProductNavigator}
            />
            <Drawer.Screen
                name="materials"
                initialParams={{ groupId }}
                component={MaterialNavigator}
            />

            <Drawer.Screen
                name="bookings"
                initialParams={{ groupId }}
                component={BookingNavigator}
            />
            <Drawer.Screen
                name="consents"
                initialParams={{ groupId }}
                component={ConsentNavigator}
            />
            <Drawer.Screen
                name="templates"
                initialParams={{ groupId }}
                component={TemplateNavigator}
            />
            <Drawer.Screen
                name="services"
                initialParams={{ groupId }}
                component={ServiceNavigator}
            />
            <Drawer.Screen
                name="subscription"
                initialParams={{ groupId }}
                component={SubscriptionScreen}
            />

            <Drawer.Screen
                name="configuration"
                initialParams={{ groupId }}
                component={ConfigurationNavigator}
            />

        </Drawer.Navigator >
    );
}


