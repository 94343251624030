import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { ConsentRoutes } from "../consent.routes";

export function DeleteConsentScreen() {
    const navigation = useNavigation<NavigationProp<ConsentRoutes, 'delete'>>()
    const route = useRoute<RouteProp<ConsentRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteConsent'}
        path={'consent'}
        id={route.params.consentId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

