import { ButtonComponent } from "@components/button/button.component";
import { FilmComponent } from "@components/film/film.component";
import { PutModule } from "@modules/post/put.module";
import { StudioAvatarModule } from "@modules/selectors/studioAvatar/studioAvatar.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { reloadGroupState } from "@states/reloadGroup.state";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";
import { useRecoilState } from "recoil";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { ConfigurationRoutes } from "../configuration.routes";
const videoBlue = require('../../../../../assets/blue.mp4')

export function BasicConfigurationScreen() {
    const { t } = useTranslation()
    const navigation = useNavigation<NavigationProp<ConfigurationRoutes, 'basic'>>()
    const route = useRoute<RouteProp<ConfigurationRoutes, 'basic'>>()
    const [reloadGroup, setReloadGroup] = useRecoilState(reloadGroupState)
    const [showMap, setShowMap] = useState<boolean>(false)
    return (
        <>
            <FilmComponent prefix="configuration" noClose

                video={videoBlue}>
                <StudioAvatarModule groupId={route.params.groupId} />
                <ButtonComponent
                    icon={{
                        name: 'map'
                    }}
                    label={t('configuration.map')}
                    details={[{
                        color: 'white',
                        background: '#65c366',
                        label: t('configuration.mapDetail')
                    }]}
                    onPress={() => {
                        navigation.navigate('address', {
                            groupId: route.params.groupId
                        })
                    }}
                />
                <PutModule<{
                    name: string
                    currency: string
                    description: string
                    document: string
                    phone: string
                    tax: number
                    timezone: string

                    username: string,
                    email: string,
                    addressLatitude: number,
                    addressLongitude: number,
                    addressStreet: string,
                    addressCity: string,
                    addressCountry: string,
                    addressState: string,
                    addressZip: string,
                    messageBeforeMeet: string,
                    messageAfterMeet: string,
                    messageAcceptedBooking: string
                }>
                    noModal={true}
                    prefix="configuration"
                    path="group"
                    width={600}
                    id={route.params.groupId}

                    footerComponent={(item) => {
                        return <View>
                            {
                                Platform.OS === 'web' && <ButtonComponent
                                    icon={{
                                        name: 'trash-alt'
                                    }}
                                    label={t('configuration.delete')}

                                    onPress={() => {
                                        navigation.navigate('delete', {
                                            groupId: route.params.groupId
                                        })
                                    }}
                                />
                            }
                        </View>
                    }}
                    onSuccess={() => {
                        setReloadGroup(new Date().getTime().toString())
                    }}
                    groupId={route.params.groupId}
                    onCancel={() => {
                        navigation.goBack()
                    }}

                    inputs={[
                        {
                            name: 'name',
                            type: 'text'
                        },
                        {
                            name: 'username',
                            type: 'text'
                        },


                        {
                            name: 'document',
                            type: 'text'
                        },
                        {
                            name: 'currency',
                            type: 'text'
                        },
                        {
                            name: 'description',
                            type: 'text',
                            lines: 5
                        },
                        {
                            name: 'phone',
                            type: 'text'
                        },

                        {
                            name: 'tax',
                            type: 'price',
                            groupId: route.params.groupId,
                            currency: '%'
                        },

                        {
                            name: 'messageBeforeMeet',
                            type: 'text',
                            lines: 5
                        },
                        {
                            name: 'messageAfterMeet',
                            type: 'text',
                            lines: 5
                        },
                        {
                            name: 'messageAcceptedBooking',
                            type: 'text',
                            lines: 5
                        },
                    ]}
                />

            </FilmComponent>
            {
                showMap && <ModalComponent
                    title={t('configuration.map')}
                    description={t('configuration.mapDescription')}
                    onClose={() => {
                        setShowMap(false)
                    }}
                    maxWidth={600}
                >
                    <View style={{
                        width: 600,
                        height: 400,
                        backgroundColor: 'white'
                    }}>
                        <Text>Map</Text>
                    </View>
                </ModalComponent>
            }
        </>
    )
}