import { IconComponent } from "@components/icon/icon.component";
import { IconType } from "@components/icon/icon.types";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
export function QualitiesComponent() {
    const { t } = useTranslation()

    const properties: {
        prefix: string,
        icon: IconType
    }[] = [
            {
                prefix: 'consents',
                icon: 'signature'
            },
            {
                prefix: 'phone',
                icon: 'phone'
            },
            {
                prefix: 'calendar',
                icon: 'calendar'
            },
            {
                prefix: 'meets',
                icon: 'calendar-check'
            },
            {
                prefix: 'bookings',
                icon: 'comments-dollar'
            },
            {
                prefix: 'stores',
                icon: 'store'
            },
            {
                prefix: 'notification',
                icon: 'bell'
            },
            {
                prefix: 'mobile',
                icon: 'mobile'
            },
            {
                prefix: 'members',
                icon: 'users'
            },

            {
                prefix: 'receipts',
                icon: 'credit-card'
            },
            {
                prefix: 'products',
                icon: 'tag'
            },
            {
                prefix: 'services',
                icon: 'box'
            }
        ]
    return (


        <View style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: 30,
            gap: 14,
        }}>
            {
                properties.map((property, index) => (
                    <View key={index} style={{
                        width: 300,
                        borderColor: '#444',
                        padding: 20,
                        borderWidth: 3,
                        borderRadius: 13,
                        backgroundColor: '#111',
                    }}>
                        <IconComponent name={property.icon} style={{
                            icon: {
                                color: '#65c366',
                                fontSize: 40,
                            }
                        }} />
                        <Text style={{
                            color: 'white',
                            fontSize: 20,
                            fontWeight: '500',
                            marginTop: 20,
                            marginBottom: 10,
                        }}>
                            {t(`subscription.${property.prefix}.title`)}
                        </Text>
                        <Text style={{
                            color: 'white',
                            fontSize: 14,
                            opacity: 0.7,
                            fontWeight: '300',
                        }}>
                            {t(`subscription.${property.prefix}.subtitle`)}
                        </Text>
                    </View>
                ))
            }
        </View>
    )
}