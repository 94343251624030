import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ReceiptRoutes } from "../receipt.routes";
import { createRef, useEffect, useRef, useState } from "react";
import { ContainerFormModule, FieldsetFormModule, FormModule } from "@modules/form/form.module";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { Platform, Text, View } from "react-native";
import { ProductsInputForm } from "@modules/form/inputs/products/products.input";
import { ChooseInputForm } from "@modules/form/inputs/choose/choose.input";
import { SelectInputForm } from "@modules/form/inputs/select/select.input";
import axios from "axios";
import { Environment } from "@environment";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { Actions } from "@modules/post/put.module";
import * as FileSystem from 'expo-file-system';
import { ScrollView, Share } from "react-native";
import { useTranslation } from "react-i18next";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";
import { PriceInputForm } from "@modules/form/inputs/price/price.input";
import LoaderComponent from "@components/loader/loader.component";

// component with function that will be call from parent with ref

interface NewReceipt {
    customerId: string;
    memberId: string;
    meetId: string;
    products: {
        units: number;
        productId: string;
        price: number;
        tax: number;
        name: string;
    }[];
    mode: 'deposit' | 'budget';
    method: string;
}


export function EditReceiptScreen() {

    const navigation = useNavigation<NavigationProp<ReceiptRoutes>>()
    const route = useRoute<RouteProp<ReceiptRoutes, 'edit'>>()
    const [user,] = useRecoilState(userState)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const { permissions } = usePermissionsHook(route.params.groupId)

    // values
    const [customerId, setCustomerId] = useState<string>()
    const [memberId, setMemberId] = useState<string>()
    const [meetId, setMeetId] = useState<string>()
    const [status, setStatus] = useState<boolean>()
    const [commissionFirstTime, setCommissionFirstTime] = useState<boolean>(false)
    const [commission, setCommission] = useState<number>()
    const [products, setProducts] = useState<{
        units: number;
        productId: string;
        price: number;
        tax: number;
        name: string;
    }[]>([])
    const [mode, setMode] = useState<'deposit' | 'budget'>('deposit')
    const [method, setMethod] = useState<string>('cash')

    useEffect(() => {
        if (!user) return;
        setLoading(true)
        axios.get(`${Environment.api}/receipt?receiptId=${route.params.receiptId}&groupId=${route.params.groupId}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const item = response.data.items[0]
            setCustomerId(item.Customer.id)
            setMemberId(item.Member.id)
            setMeetId(item.meetId)
            setProducts(item.Purchases)
            setMethod(item.method)
            setStatus(item.status)
            setLoading(false)

        })
    }, [user, route])
    const downloadFile = () => {
        if (!route.params.receiptId) return;
        if (!user) return;
        setLoading(true)
        const pdf = `${Environment.api}/receipt/pdf?receiptId=${route.params.receiptId}&cache=${new Date().getTime()}`

        if (Platform.OS === 'web') {
            axios.get(pdf, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {
                setLoading(false)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                setLoading(false)
                console.error(error);
            })
            return
        }

        FileSystem.downloadAsync(
            pdf,
            FileSystem.documentDirectory + 'sample.pdf',
            {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }
        )
            .then(({ uri }) => {
                setLoading(false)
                share(uri);
            })
            .catch(error => {
                setLoading(false)
                console.error(error);
            });
    }
    const share = async (url: string) => {
        try {
            const result = await Share.share({
                url
            });

            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {

        }
    }

    return <ModalComponent title="Edit Receipt"

        description="Create a new receipt"
        headerBottomComponent={() => {
            return <View style={{
                opacity: loading ? 0 : 1
            }}>
                {
                    customerId && <Actions prefix={'updateReceipt'} data={{}} actions={[
                        {
                            icon: 'file-pdf',
                            label: 'download',
                            onPress: () => {
                                downloadFile()
                            }
                        }
                    ]} />
                }
            </View>
        }}
        onClose={() => {
            navigation.goBack()
        }}>

        {
            loading && <LoaderComponent />
        }
        {<ContainerFormModule


            prefix="updateReceipt" onSubmit={() => {
                if (!user) return;
                setLoading(true)
                axios.put(`${Environment.api}/receipt?receiptId=${route.params.receiptId}`, {
                    customerId,
                    memberId,
                    groupId: route.params.groupId,
                    meetId,
                    purchases: products,
                    mode,
                    status,
                    method
                }, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }

                }).then((response) => {
                    setLoading(false)
                    navigation.goBack()
                }).catch((error) => {
                    console.log(error)
                })
            }}>
            <FieldsetFormModule prefix="updateReceipt" name="products">
                <ProductsInputForm<NewReceipt>
                    value={products}
                    name="products"
                    type="products"
                    onChange={(value) => {
                        setProducts(value)
                    }}
                    groupId={route.params.groupId}
                />
            </FieldsetFormModule>
            <FieldsetFormModule prefix="updateReceipt" name="customerId">
                <ChooseInputForm<NewReceipt>
                    value={customerId}
                    name="customerId"
                    type="choose"
                    path="customer"
                    groupId={route.params.groupId}
                    onSelect={(item) => {
                        setCustomerId(item.id)
                    }}
                />
            </FieldsetFormModule>
            <FieldsetFormModule prefix="updateReceipt" name="memberId">
                <ChooseInputForm<NewReceipt>
                    value={memberId}
                    name="memberId"
                    type="choose"
                    renderLabel={(item) => {
                        return item.name
                    }}
                    path="member"
                    onSelectBackground={(item) => {
                        if (!commissionFirstTime) {
                            setCommissionFirstTime(true)
                        }
                        else setCommission(item.commission)
                    }}
                    groupId={route.params.groupId}
                    onSelect={(item) => {
                        setMemberId(item.id)
                    }}

                />
            </FieldsetFormModule>

            <FieldsetFormModule prefix="updateReceipt" name="method">
                <SelectInputForm<NewReceipt>
                    value={method}
                    name="method"
                    type="select"
                    onChange={(value) => {
                        setMethod(value)

                    }}
                    options={['cash', 'transfer', 'credit-card', 'paypal', 'other',]}
                />
            </FieldsetFormModule>
            {
                permissions.administrator && <FieldsetFormModule prefix="updateReceipt" name="commision">
                    <PriceInputForm<NewReceipt>
                        value={commission}
                        name="commision"
                        type="price"
                        currency="%"
                        onChange={(item) => {
                            setCommission(item)
                        }}

                    />
                </FieldsetFormModule>

            }
            <FieldsetFormModule prefix="updateReceipt" name="method">
                <SelectInputForm<NewReceipt>
                    value={status}
                    name="status"
                    type="select"
                    onChange={(value) => {
                        setStatus(value)

                    }}
                    options={[true, false]}
                />
            </FieldsetFormModule>

        </ContainerFormModule>}

    </ModalComponent>
}