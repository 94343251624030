import { FilmComponent } from "@components/film/film.component";
import { KnobComponent } from "@components/knob/knob.component";
import LoaderComponent from "@components/loader/loader.component";
import { SignatureComponent } from "@components/signature/signature.component";
import { SwitchComponent } from "@components/switch/switch.component";
import { useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { LineComponent } from "../../../components/line/line.component";
import { CheckboxInputForm } from "@modules/form/inputs/checkbox/checkbox.input";
import { ContainerFormModule, FieldsetFormModule } from "@modules/form/form.module";
import { SubmitComponent } from "@components/submit/submit.component";
const videoBlue = require('../../../../assets/blue.mp4')

export function SettingsScreen() {
    const [notificationEmail, setNotificationEmail] = useState(false)
    const [notificationSms, setNotificationSms] = useState(false)
    const [user, setUser] = useRecoilState(userState)
    const [loading, setLoading] = useState<boolean>(true)
    const { t } = useTranslation()
    const [loadingSend, setLoadingSend] = useState<boolean>(false)
    const navigation = useNavigation();
    const [signature, setSignature] = useState<string>('')
    const [loadingSignature, setLoadingSignature] = useState<boolean>(false)


    const saveSignature = () => {
        setLoadingSignature(true)
        setLoading(true)
        if (!user) return;
        axios.put(`${Environment.api}/me/signature`, {
            signature: signature
        }, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            setLoading(false)
            setLoadingSignature(false)

        })
    }

    const getSignature = () => {
        setLoadingSignature(true)
        setLoading(true)
        if (!user) return;
        axios.get(`${Environment.api}/me/signature`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            setLoadingSignature(false)
            setLoading(false)
            const signature = response.data.items?.[0]?.signature
            if (signature) {
                setSignature(signature)
            }
            setLoadingSignature(false)
        })
    }

    useEffect(() => {
        getSignature()
    }, [])

    useEffect(() => {
        if (!user) return;
        setLoading(true)
        axios.get(`${Environment.api}/notification`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const notification = response.data.items[0]
            setNotificationEmail(notification.notificationEmail)
            setNotificationSms(notification.notificationSms)
            setLoading(false)
        })
    }, [user])

    const saveNotifications = () => {
        if (!user) return;
        setLoadingSend(true)
        setLoading(true)
        axios.put(`${Environment.api}/notification`, {
            notificationEmail: notificationEmail,
            notificationSms: notificationSms
        }, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }
        ).then((response) => {
            setLoading(false)
            setLoadingSend(false)
        })
    }

    useEffect(() => {
        if (!user) return;
        if (loading) return;
        saveNotifications()
    }, [notificationEmail, notificationSms])


    return <FilmComponent video={videoBlue} prefix="settings" noClose>


        {(loading) &&
            <>

                <LoaderComponent />

            </>}
        <ContainerFormModule prefix="settings" onSubmit={saveSignature}>
            <FieldsetFormModule prefix="settings" name="email" >
                <CheckboxInputForm
                    type="checkbox"
                    name={'email'}
                    value={notificationEmail}
                    prefix="settings.email"
                    onChange={(value) => {
                        setNotificationEmail(value)

                    }}
                />

            </FieldsetFormModule>
            <FieldsetFormModule prefix="settings" name="sms" >
                <CheckboxInputForm
                    type="checkbox"
                    name={'sms'}
                    prefix="settings.sms"

                    value={notificationSms}
                    onChange={(value) => {
                        setNotificationSms(value)

                    }}
                />

            </FieldsetFormModule>



            {
                loadingSend && <>
                    <LoaderComponent />

                </>
            }

            <FieldsetFormModule prefix="settings" name="signature" >


                {!loading && signature ? <SignatureComponent
                    defaultValue={signature}
                    onChange={(signaturej) => {
                        setSignature(signaturej)
                    }} /> : <SignatureComponent
                    defaultValue={signature}
                    onChange={(signaturej) => {
                        setSignature(signaturej)
                    }} />}

                <KnobComponent
                    icon="eraser"
                    style={{
                        position: 'absolute',
                        left: 30,
                        bottom: 30,
                        backgroundColor: '#ccc',

                    }}
                    onPress={() => {
                        setSignature('')
                    }} />
            </FieldsetFormModule>


        </ContainerFormModule>
    </FilmComponent>
}