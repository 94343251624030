export const FontLoaderConstants = {
  fonts: {
    // icons font
    'line-awesome': require('../../../assets/fonts/line-awesome/line-awesome.ttf'),

    // open sans font
    'OpenSans-Bold': require('../../../assets/fonts/open-sans/OpenSans-Bold.ttf'),
    'OpenSans-BoldItalic': require('../../../assets/fonts/open-sans/OpenSans-BoldItalic.ttf'),
    'OpenSans-ExtraBold': require('../../../assets/fonts/open-sans/OpenSans-ExtraBold.ttf'),
    'OpenSans-ExtraBoldItalic': require('../../../assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf'),
    'OpenSans-Italic': require('../../../assets/fonts/open-sans/OpenSans-Italic.ttf'),
    'OpenSans-Light': require('../../../assets/fonts/open-sans/OpenSans-Light.ttf'),
    'OpenSans-LightItalic': require('../../../assets/fonts/open-sans/OpenSans-LightItalic.ttf'),
    'OpenSans-Medium': require('../../../assets/fonts/open-sans/OpenSans-Medium.ttf'),
    'OpenSans-MediumItalic': require('../../../assets/fonts/open-sans/OpenSans-MediumItalic.ttf'),
    'OpenSans-Regular': require('../../../assets/fonts/open-sans/OpenSans-Regular.ttf'),
    'OpenSans-SemiBold': require('../../../assets/fonts/open-sans/OpenSans-SemiBold.ttf'),
    'OpenSans-SemiBoldItalic': require('../../../assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf'),
  },
}
