import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import { LanguageConstants } from "./language.constants";

export function LanguageModule(properties: {
    languageId?: string,
    onSelect: (room: {
        name: string,
        id: string
    }) => void
}) {
    const { t } = useTranslation()




    return <View style={{
        width: '100%',
        maxWidth: 700,
        marginHorizontal: 'auto',
    }}>
        <View style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 5,
            padding: 5,
        }}>
            {
                LanguageConstants.map((language, index) => {
                    return <TouchableOpacity onPress={() => properties.onSelect(language)} key={index} style={{
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: 10,
                        padding: 10,
                        borderRadius: 10,
                        backgroundColor: properties.languageId === language.id ? '#000' : 'rgba(0,0,0,0.05)',
                    }}>

                        <Text style={{
                            color: properties.languageId === language.id ? '#fff' : '#000',
                            fontWeight: 'bold',

                        }}>
                            {language.name}
                        </Text>
                    </TouchableOpacity>

                })
            }
        </View>
    </View>

}