import React, { useState } from 'react';
import { TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { AccordionInputProperties } from './accordion.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@components/icon/icon.component';

export function AccordionInputForm<T>(properties: AccordionInputProperties<T>) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    return <View>
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                padding: 10,
                backgroundColor: '#fff',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#ddd',
                alignContent: 'center',
                alignItems: 'center',
                marginBottom: open ? 10 : 0
            }}
            onPress={() => {
                setOpen(!open);
            }}>
            <TextComponent styles={{
                text: {
                    fontWeight: 'bold',
                    fontSize: 16
                }
            }}>{t(`${properties.prefix}.${properties.name as string}`)}</TextComponent>
            <IconComponent name={open ? 'angle-up' : 'angle-down'} style={{
                box: {
                    marginLeft: 'auto',
                    borderRadius: 30,
                    backgroundColor: '#000',
                    height: 30,
                    width: 30,
                    justifyContent: 'center',
                    alignItems: 'center'

                },
                icon: {
                    fontSize: 15,
                    color: '#fff'
                }
            }} />
        </TouchableOpacity>
        {
            open && <View style={{
                padding: 20,
                paddingBottom: 20,
                marginTop: -15,
                borderTopEndRadius: 0,
                borderTopStartRadius: 0,
                backgroundColor: '#fff',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#ddd',
            }}>
                {properties.children}
            </View>
        }

    </View>
}