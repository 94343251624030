import { IconComponent } from "@components/icon/icon.component"
import { IconType } from "@components/icon/icon.types"
import { Text, TouchableOpacity } from "react-native"

export function SubmitComponent(properties: {
    title: string
    backgroundColor?: string
    onPress: () => void
    icon?: IconType
}) {
    return <TouchableOpacity style={{
        padding: 25,
        paddingVertical: 12,
        backgroundColor: properties.backgroundColor || 'rgb(16, 25, 40)',
        borderRadius: 555,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        flexDirection: 'row',
        gap: 10,
    }} onPress={() => {
        properties.onPress()
    }}>
        <Text style={{
            fontWeight: 'bold',
            color: '#fff',
            fontSize: 14
        }}>{properties.title}</Text>
        {
            properties.icon && <IconComponent name={properties.icon} style={{
                icon: {
                    color: 'rgba(52, 199, 89,1)',
                    fontSize: 16,
                    fontWeight: 'bold',
                }
            }} />
        }
    </TouchableOpacity>
}