import { IconComponent } from "@components/icon/icon.component";
import Moment from "react-moment";
import { Text, View } from "react-native";
import { SubscriptionType } from "../subscription.types";
import { priceUtility } from "../../../../utilities/price/priceUtility";
import { CardPaymentComponent } from "./cardPayment.component";
import { SepaPaymentComponent } from "./sepaPayment.component";
import { TrialComponent } from "./trial.component";
import { useTranslation } from "react-i18next";
import { PaypalPaymentComponent } from "./paypalPayment.component";
import { CardNoPayPaymentComponent } from "./cardNoPay.component";

export function InformationPaymentComponent(properties: {
    subscription: SubscriptionType
    getUrlStripe: () => void
}) {
    const { t, i18n } = useTranslation()
    return <View style={{
        backgroundColor: '#111',
        padding: 50,
        gap: 40
    }}>
        {properties.subscription.trial && <TrialComponent trial={properties.subscription.trial} />}

        <View style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 40,
        }}>
            {
                (properties.subscription.status === 'past_due' || properties.subscription.status === 'unpaid' || properties.subscription.status === 'canceled') ?
                    <CardNoPayPaymentComponent subscription={properties.subscription} getUrlStripe={properties.getUrlStripe} />
                    :
                    <>
                        {properties.subscription.payment?.type === 'card' && <CardPaymentComponent card={properties.subscription.payment} />}
                        {properties.subscription.payment?.type === 'sepa_debit' && <SepaPaymentComponent sepa={properties.subscription.payment} />}
                        {properties.subscription.payment?.type === 'paypal' && <PaypalPaymentComponent paypal={properties.subscription.payment} />}
                    </>
            }

            <View>
                <View style={{
                }}>

                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10,
                    }}>
                        <Text style={{
                            fontSize: 24,
                            fontWeight: '600',
                            color: 'white',
                        }}>{properties.subscription.plan.name}</Text>
                        {
                            properties.subscription.coupon && <View style={{
                                backgroundColor: 'rgba(52, 199, 89,0.5)',
                                borderColor: '#65c366',
                                borderWidth: 2,
                                flexDirection: 'row',
                                alignContent: 'center',
                                alignItems: 'center',
                                padding: 5,
                                paddingVertical: 3,
                                borderRadius: 5,
                            }}>
                                <Text style={{
                                    color: 'white',
                                    opacity: 0.4,
                                    textTransform: 'uppercase',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}>#</Text>
                                <Text style={{
                                    color: 'white',
                                    textTransform: 'uppercase',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}>{properties.subscription.coupon.name}</Text>

                            </View>
                        }
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10,
                    }}>
                        <Text
                            style={{
                                fontSize: 16,
                                opacity: 0.8,
                                fontWeight: '200',
                                color: 'white',
                            }}
                        >{t(`subscription.plan.${properties.subscription.plan.interval ? properties.subscription.plan.interval : properties.subscription.plan.name}`)}</Text>

                    </View>
                    {
                        properties.subscription.plan.name === 'ProTeam' && <View style={{
                            marginTop: 20,
                            flexDirection: 'row',
                            gap: 20,
                            maxWidth: 350,
                        }}>
                            <IconComponent name="users" style={{
                                icon: {
                                    color: 'white',
                                    fontSize: 40,
                                }
                            }} />
                            <Text style={{
                                color: 'white',
                                fontSize: 16,
                            }}>
                                {t('subscription.plan.proteam.description')}
                            </Text>
                        </View>
                    }
                    {properties.subscription.period && <Text style={{
                        fontSize: 16,
                        marginTop: 5,
                        fontWeight: '400',
                        color: 'white',
                        opacity: 0.7
                    }}>
                        {t('subscription.isDate')} <Moment
                            locale={i18n.language}
                            unix fromNow>{properties.subscription.period}</Moment>

                    </Text>}
                </View>
                {
                    properties.subscription.upcamingInvoice && <View style={{
                        marginTop: 10,
                        borderLeftWidth: 4,
                        borderLeftColor: '#fff',
                        padding: 10,
                        paddingLeft: 20,

                        backgroundColor: 'rgba(255,255,255,0.1)',
                    }}>
                        <Text style={{
                            textTransform: 'uppercase',
                            opacity: 0.7,
                            fontSize: 12,
                            marginBottom: 5,
                            color: 'white',
                        }}>{
                                t('subscription.upcamingInvoice')

                            }</Text>
                        <Text style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'white',
                        }}>{properties.subscription.upcamingInvoice.account_name}</Text>
                        <Text style={{
                            marginTop: 10,
                            fontSize: 17,
                            color: 'rgba(255, 255, 255,0.5)',
                            textTransform: 'uppercase',
                            alignItems: 'center',
                            fontWeight: '200',
                            marginBottom: 10,
                        }}>{t('subscription.upcamingInvoice.total')}
                            <Text style={{
                                fontWeight: 'bold',
                                fontSize: 17,
                                marginLeft: 5,
                                color: 'rgba(52, 199, 89,1)',
                            }}>
                                {priceUtility({
                                    price: properties.subscription.upcamingInvoice.total
                                })}
                            </Text>
                            {properties.subscription.upcamingInvoice.currency}</Text>
                        <Text style={{
                            color: 'white',
                            fontWeight: '400',
                            opacity: 0.7,
                            fontSize: 11,
                        }}>
                            {t('subscription.upcamingInvoice.created')
                            } <Text style={{
                                fontWeight: '500',
                                opacity: 1,
                                fontSize: 11,
                            }}>
                                <Moment format="DD/MM/YYYY" unix>{properties.subscription.upcamingInvoice.created}</Moment>
                            </Text> <Moment locale={
                                i18n.language

                            } fromNow unix>{properties.subscription.upcamingInvoice.created}</Moment>
                        </Text>

                    </View>
                }
            </View>
            <View style={{
                borderLeftColor: 'rgba(255,255,255,0.2)',
                borderLeftWidth: 1,
                paddingLeft: 40,
                maxWidth: 400,
            }}>

                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                }}>
                    {
                        properties.subscription.status === 'active' ? <IconComponent name="check-circle" style={{
                            icon: {
                                color: '#65c366',
                                fontSize: 40,
                            }
                        }} /> :
                            properties.subscription.status === 'trialing' ? <IconComponent name="check-circle" style={{
                                icon: {
                                    color: '#65c366',
                                    fontSize: 40,
                                }
                            }} /> :
                                <IconComponent name="times-circle" style={{
                                    icon: {
                                        color: 'rgb(255, 25, 89)',
                                        fontSize: 40,
                                    }
                                }} />
                    }


                    <Text style={{
                        fontSize: 24,
                        fontWeight: '600',
                        color: 'white',
                    }}>
                        {t(`subscription.status.${properties.subscription.status}`)}
                    </Text>

                </View>

                <Text style={{
                    fontSize: 16,
                    marginLeft: 50,
                    fontWeight: '200',
                    opacity: 0.5,
                    marginBottom: 20,
                    color: 'white',
                }}>
                    {t(`subscription.status.description`)}
                </Text>
                {
                    (properties.subscription.status === 'canceled' || properties.subscription.status === 'past_due' || properties.subscription.status === 'unpaid') ? <Text style={{
                        fontSize: 14,
                        marginBottom: 5,
                        fontWeight: '600',
                        color: 'rgb(255, 25, 89)',
                    }}>
                        {t(`subscription.status.past_due_information`)}
                    </Text> : <Text style={{
                        fontSize: 14,
                        opacity: 0.8,
                        fontWeight: '500',
                        color: 'white',
                    }}>
                        {t(`subscription.status.info`)}
                    </Text>
                }

            </View>



        </View>

    </View >
}