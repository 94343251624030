import React, { useState } from 'react';
import { Button, Modal, StyleSheet, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { ColorInputProperties } from './color.properties';
import { IconComponent } from '@components/icon/icon.component';

export function ColorInputForm<T>(properties: ColorInputProperties<T>) {
    const [showModal, setShowModal] = useState(false);
    const cakeColors = [
        "#fc2003", // Gris
        "#fc7703", // Rojo
        "#e0b909", // Verde
        "#a7e009", // Azul
        "#4ecc0e", // Amarillo
        "#0ecc57", // Naranja
        "#0eccb9", // Rosa
        "#0e86cc", // Oro
        "#0e37cc", // Naranja oscuro
        "#5d0ecc", // Rojo naranja
        "#a60ecc", // Rosa claro
        "#cc0eac", // Tomate
        "#cc0e64", // Azul acero
        "#cc0e0e", // Azul acero
        "#aaa", // Azul acero
        "#777", // Azul acero
        "#333", // Azul acero
        "#000", // Azul acero

    ];

    return <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap'

    }}>
        {
            cakeColors.map((color, index) => {
                const isSelected = properties.value === color;
                return <TouchableOpacity key={index} onPress={() => {
                    properties.onChange?.(color);
                }} style={{
                    borderRadius: 100,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: color, width: 40, height: 40, margin: 5,
                    borderWidth: isSelected ? 4 : 0,
                    borderColor: isSelected ? 'rgba(0,0,0,0.2)' : 'transparent',
                }} >
                    {
                        isSelected && <IconComponent name="check" style={{
                            icon: {
                                fontSize: 20,
                                color: 'white'
                            }
                        }} />
                    }
                </TouchableOpacity>
            })
        }
    </View>
}