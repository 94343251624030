import { Image, ImageBackground, Platform, ScrollView, Share, TouchableOpacity, View } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, FlipType, SaveFormat, ImageResult } from 'expo-image-manipulator';
import { EncodingType, readAsStringAsync } from "expo-file-system";
import { IconComponent } from "@components/icon/icon.component";
import { Fragment, useState } from "react";
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import { ErrorComponent } from "@components/error/error.component";
import Compressor from 'compressorjs';
import { ImagesInputProperties } from "./images.properties";

export function ImagesInputForm<T>(properties: ImagesInputProperties<T>) {
    const [messageDownload, setMessageDownload] = useState<string>()
    const [imageToView, setImageToView] = useState<string>()
    const images = properties?.images?.map((picture) => {
        return {
            uri: `data:image/png;base64,${picture}`
        }
    })
    const [cache, setCache] = useState<string>()
    const [visible, setIsVisible] = useState(false);

    const share = async (url: string) => {
        try {
            const result = await Share.share({
                url
            });

            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {

        }
    }
    return <>
        {
            imageToView && <View style={{
                zIndex: 999,
                backgroundColor: '#000',
                height: 600,
                overflow: 'hidden',
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ImageBackground
                    source={{
                        uri: imageToView
                    }}
                    style={{
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    resizeMode="contain"
                />
                <TouchableOpacity style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    zIndex: 999,
                    backgroundColor: '#fff',
                    borderRadius: 20,
                    padding: 10
                }} onPress={() => {
                    setImageToView(undefined)
                }}>
                    <IconComponent name="times" style={{
                        icon: {
                            fontSize: 20
                        }
                    }} />
                </TouchableOpacity>
            </View>
        }

        {messageDownload && <ErrorComponent type="success" title={'Picture'} message={messageDownload} key={cache} />}
        <ScrollView
            style={{ backgroundColor: '#fff', padding: 10 }}
            horizontal contentContainerStyle={{ backgroundColor: '#fff' }}>
            {
                // render base64 images
                properties.images?.map((picture, index) => {
                    return <View key={index} style={{
                        marginRight: 15,
                    }}>
                        <TouchableOpacity style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            backgroundColor: '#fff',
                            position: 'absolute',
                            left: 10,
                            bottom: 10,
                            zIndex: 999,
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} onPress={async () => {
                            // create link and download
                            const a = document.createElement("a"); //Create <a>
                            a.href = picture; //Image Base64 Goes here
                            a.download = "Image.png"; //File name Here
                            a.click(); //Downloaded file


                        }}  >
                            <IconComponent name="arrow-down" style={{
                                icon: {
                                    opacity: 0.5,
                                    fontSize: 20
                                }
                            }} />

                        </TouchableOpacity>
                        <TouchableOpacity style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            backgroundColor: '#fff',
                            position: 'absolute',
                            left: 60,
                            bottom: 10,
                            zIndex: 999,
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} onPress={async () => {

                            setImageToView(picture)

                        }}  >
                            <IconComponent name="eye" style={{
                                icon: {
                                    opacity: 0.5,
                                    fontSize: 20
                                }
                            }} />

                        </TouchableOpacity>
                        <TouchableOpacity style={{
                            height: 30,
                            width: 30,
                            borderRadius: 20,
                            backgroundColor: '#000',
                            position: 'absolute',
                            right: -8,
                            top: -8,
                            zIndex: 999,
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} onPress={async () => {
                            // remove
                            const images = properties.images?.filter((_, i) => i !== index)
                            images && properties.onChange?.(images)
                        }}  >
                            <IconComponent name="times" style={{
                                icon: {
                                    color: '#fff',
                                    fontSize: 16
                                }
                            }} />

                        </TouchableOpacity>
                        <TouchableOpacity style={{
                            borderRadius: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: 1,
                            borderColor: '#ddd',
                            overflow: 'hidden',
                            height: 110, width: 160, backgroundColor: '#eee',
                        }}
                            onPress={() => {
                                setImageToView(picture)
                            }}
                        >
                            <Image
                                source={{
                                    uri: `data:${picture}`
                                }}
                                style={{ borderRadius: 10, height: 110, width: 160 }} />
                        </TouchableOpacity>
                    </View>
                })
            }
            {
                properties?.images && properties?.images?.length < 5 && <TouchableOpacity style={{
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderWidth: 1,
                    borderColor: '#ddd',
                    height: 110, width: 150, backgroundColor: '#eee', marginRight: 15,
                }}
                    onPress={async () => {
                        if (Platform.OS === 'web') {
                            // get image:
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = 'image/*';
                            input.onchange = (e: any) => {
                                if (!e.target?.files?.[0]) {
                                    return
                                }
                                const file = e.target.files[0];
                                new Compressor(file, {
                                    maxWidth: 1500,
                                    mimeType: 'image/jpeg',
                                    maxHeight: 1500,
                                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                                    success: (compressedResult) => {
                                        // compressedResult has the compressed file.
                                        // Use the compressed file to upload the images to your server.        
                                        const reader = new FileReader();
                                        reader.readAsDataURL(compressedResult);
                                        reader.onloadend = () => {
                                            const base64data = reader.result;
                                            if (typeof base64data === 'string') {
                                                const images = [...properties.images, base64data]
                                                properties.onChange(images)
                                            }
                                        }
                                    },
                                });
                            };

                            input.click();

                        }


                    }}
                >
                    <IconComponent name="plus" style={{
                        icon: {
                            opacity: 0.3,
                            fontSize: 30
                        }
                    }} />
                </TouchableOpacity>
            }
        </ScrollView></>
}