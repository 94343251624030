import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ProductRoutes } from "../product.routes";

export function NewProductScreen() {
    const navigation = useNavigation<NavigationProp<ProductRoutes>>()
    const route = useRoute<RouteProp<ProductRoutes, 'new'>>()

    return <PostModule<{
        units: number;
        price: number;
        tax: number;
        name: string;
        description: string;
    }>
        prefix="newProduct"
        path="product"
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        data={{
            description: '',
            units: 1,
            price: 0,
            tax: 0,
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                name: 'units',
                type: 'number'
            },
            {
                name: 'price',
                type: 'price',
                groupId: route.params.groupId
            },
            {
                name: 'tax',
                type: 'price',
                currency: '%',
                groupId: route.params.groupId
            },
        ]}
    />
}