import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListBookingScreen } from "./list/list.booking.screen";
import { EditBookingScreen } from "./edit/edit.booking.screen";
import { DeleteBookingScreen } from "./delete/delete.booking.screen";
import { NewBookingScreen } from "./new/new.booking.screen";
import { BookingRoutes } from "./booking.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewCustomerScreen } from "../customer/new/new.customer.screen";
import { NewRoomScreen } from "../room/new/new.room.screen";
import { NewMemberScreen } from "../member/new/new.member.screen";
import { ConfirmBookingScreen } from "./confirm/confirm.booking.screen";
import { EditCustomerScreen } from "../customer/edit/edit.customer.screen";

const stack = createNativeStackNavigator<BookingRoutes>();

export function BookingNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'bookings'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"

        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListBookingScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewBookingScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditBookingScreen}
        />
        <stack.Screen
            name="editCustomer"
            initialParams={{ groupId, }}
            options={modalOptions}
            component={EditCustomerScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="confirm"
            options={modalOptions}
            component={ConfirmBookingScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteBookingScreen}
        />
        <stack.Screen
            name="newCustomer"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewCustomerScreen}
        />
        <stack.Screen
            name="newRoom"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewRoomScreen}
        />
        <stack.Screen
            name="newMember"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewMemberScreen}
        />
    </stack.Navigator>
}
