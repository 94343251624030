//Example of Infinite Loading Listview in React Native using FlatList
//https://aboutreact.com/infinite-list-view/

//import React in our code
import { IconComponent } from '@components/icon/icon.component';
import { IconType } from '@components/icon/icon.types';
import { LineComponent } from '@components/line/line.component';
import LoaderComponent from '@components/loader/loader.component';
import { useIsFocused } from '@react-navigation/native';
import { userState } from '@states/user.state';
import 'moment-timezone';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';

//import all the components we are going to use
import { HeaderComponent, actionHeaderType } from '@components/header/header.component';
import { TextComponent } from '@components/text/text.component';
import { Environment } from '@environment';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import {
    FlatList,
    SectionList,
    StyleSheet, TouchableOpacity,
    Text,
    TextInput,
    View,
    ScrollView,
    Platform
} from 'react-native';
import { useRecoilState } from 'recoil';
import { useDebounce } from 'use-debounce';
import { useWeb } from '../../hook/web.hook';
import { priceUtility } from '../../utilities/price/priceUtility';
import { t } from 'i18next';
import { EmptyComponent } from '@components/empty/empty.component';

type ColumnListType<T> = {
    title: string,
    renderLabel: (item: T) => string
    type: 'text',
    always?: boolean,
    fontSize?: number
    fontWeight?: any,
    phone?: boolean,
    width: number,
    renderDetail?: (item: T) => string,
    renderDetailComponent?: (item: T) => JSX.Element,
    subtitle?: {
        phone: boolean,
        render: (item: T) => string
    }
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    title: string,
    renderLabel: (item: T) => string
    type: 'member',
    renderColor?: (item: T) => string

    phone?: boolean,
    width: number,
    renderDetail?: (item: T) => string,
    renderDetailComponent?: (item: T) => JSX.Element,
    subtitle?: {
        phone: boolean,
        render: (item: T) => string
    }
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    title: string,
    renderLabel: (item: T) => string
    type: 'number',
    phone?: boolean,
    width: number,
    renderDetail?: (item: T) => string,
    subtitle?: {
        phone: boolean,
        render: (item: T) => string
    }
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    title: string,
    renderPrice: (item: T) => number
    renderCurrency: (item: T) => string
    type: 'price',
    width: number,
    renderDetail?: (item: T) => string
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    title: string,
    getDate?: (item: T) => string
    type: 'ago',
    width: number
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    title: string,
    getDate?: (item: T) => string
    type: 'date'
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    title: string,
    getDateStart?: (item: T) => string
    getDateEnd?: (item: T) => string
    type: 'range',
    width: number
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    width: number
    title?: string,
    renderLabel?: (item: T) => string
    label?: string
    type: 'button'
    icon?: IconType
    onPress: (item: T) => void
    variant?: 'primary' | 'secondary'
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    icon?: IconType
    type: 'icon'
    color?: string
    backgroundColor?: string
    phone?: boolean
    onPress?: (item: T) => void
    variant?: 'primary' | 'secondary',
    renderIcon?: (item: T) => IconType
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
} | {
    always?: boolean,
    phone?: boolean,
    type: 'color'
    renderColor?: (item: T) => string
    width: number
    menu?: {
        label: string
        icon: IconType
        onPress?: (item: T) => void
    },
}

export function TableComponent<T>(properties: {
    path: string
    pagination?: boolean
    paginator?: {
        limit: number
    }
    renderFooterItem?: (item: T) => JSX.Element
    menu?: {
        icon: IconType
        label: string
        onPress: (item: T) => void
    }[]
    search?: boolean
    groupBy?: keyof T
    membering?: boolean
    groupId?: string
    columns: ColumnListType<T>[]
    renderItem?: (item: T) => JSX.Element
    prefix: string
    header?: true | {
        actions: actionHeaderType[]

    },
    filters?: {
        value: string,
        name: string,
        onPress: () => void
    }[]

}) {
    const [menuRow, setMenuRow] = useState<T>()

    const { t, i18n } = useTranslation()
    const [search, setSearch] = useState<string | undefined>(undefined)
    const [user, setUser] = useRecoilState(userState)
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState<{ [key: string]: any[] }>();
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [isListEnd, setIsListEnd] = useState(false);
    const isFocused = useIsFocused()
    const height = 40
    const isWeb = useWeb()
    const viewRef = useRef<View>(null);
    const ref = useRef<FlatList<T>>(null)
    const [memberSelected, setMemberSelected] = useState<string | undefined>(undefined)
    const [members, setMembers] = useState<{ color: string, id: string, name: string }[]>([])
    const sectionRef = useRef<SectionList<T>>(null)


    useEffect(() => {
        if (!user) return;
        if (properties.membering && properties.groupId) {
            fetch(`${Environment.api}/member?groupId=${properties.groupId}&component=table`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setMembers(responseJson.items)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [properties.groupId, user, properties.membering]);



    useEffect(() => {

        isFocused && getData('clean')

    }, [properties.path, page, isFocused, memberSelected]);



    useEffect(() => {
        if (search === undefined) return;
        const bounce = setTimeout(() => {

            setPage(0)
            getData('clean')
        }, 300);
        return () => {
            clearTimeout(bounce)
        }
    }, [search, properties.filters]);
    const getData = (mode?: 'clean') => {
        if (!user) return;
        if (!loading && !isListEnd || mode === 'clean') {
            // @ts-ignore
            viewRef?.current?.setNativeProps({ style: { display: 'flex' } });

            if (dataSource) {
                const firstIndex = Object.keys(dataSource)?.[0]
                if (firstIndex) {
                    dataSource && sectionRef.current?.scrollToLocation?.({
                        animated: true,
                        sectionIndex: 0,
                        itemIndex: 0,
                    })
                }
            }

            ref?.current?.scrollToOffset?.({ animated: true, offset: 0 });

            setTimeout(() => {

                let path = `${Environment.api}/${properties.path}&page=${page}&limit=${properties.paginator?.limit}`
                if (search && properties.search) {
                    path = `${path}&search=${search}`
                }

                if (memberSelected) {
                    path = `${path}&memberId=${memberSelected}`
                }

                //Service to get the data from the server to render
                fetch(path, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                })
                    //Sending the currect offset with get request
                    .then((response) => response.json())
                    .then((responseJson) => {
                        viewRef?.current?.setNativeProps({ style: { display: 'none' } });

                        //Successful response from the API Call
                        let dataCurrent = dataSource || {}
                        if (mode === 'clean') {
                            dataCurrent = {}
                        }
                        if (responseJson.items.length > 0) {

                            //After the response increasing the offset for the next API call.
                            const item = responseJson.items

                            item.forEach((cita: any) => {
                                const dateKey = moment(cita[properties.groupBy || 'createdAt']).format('YYYY-MM-DD');
                                if (dataCurrent && !dataCurrent[dateKey]) {
                                    dataCurrent[dateKey] = [];
                                }
                                dataCurrent[dateKey].push(cita);
                            });
                            const copyDeep = JSON.parse(JSON.stringify(dataCurrent))
                            setTotal(responseJson.total)
                            setDataSource(copyDeep);

                        }
                        else {
                            setTotal(responseJson.total)
                            setDataSource({});
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
                , 0);
        }
    };
    const renderFooter = () => {

        if (!dataSource || (dataSource && Object.keys(dataSource).length === 0)) return null

        return (
            //Footer View with Loader
            <><View style={styles.footer}>{
                (properties.paginator && properties.paginator.limit) ? <View style={{
                    flex: 1,
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: 20,
                        paddingHorizontal: 0,
                        gap: 10,

                        alignItems: 'center',
                    }}>
                        <TouchableOpacity
                            style={{
                                height: 40,
                                width: 40,
                                flexDirection: 'row',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 20,
                                backgroundColor: page === 0 ? '#ccc' : '#000',
                                opacity: page === 0 ? 0.5 : 1
                            }}

                            onPress={() => {
                                if (page === 0) return
                                setPage(page - 1)
                            }}>
                            <IconComponent name='arrow-left' style={{
                                icon: {
                                    fontSize: 20,
                                    color: page === 0 ? 'rgba(0,0,0,0.3)' : '#fff'
                                }
                            }} />
                        </TouchableOpacity>
                        <Text style={{
                            height: 40,
                            width: 40, backgroundColor: '#000',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 20,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: '#fff'
                        }}>{page + 1}</Text>
                        <TouchableOpacity
                            style={{
                                height: 40,
                                width: 40,
                                flexDirection: 'row',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 20,
                                backgroundColor: total / properties.paginator.limit < (page + 1) ? '#ccc' : 'rgb(0, 0, 0)',
                                opacity: total / properties.paginator.limit < (page + 1) ? 0.5 : 1

                            }}
                            onPress={() => {
                                if (!properties.paginator?.limit) return
                                if (total / properties.paginator.limit <= (page + 1)) return
                                setPage(page + 1)
                            }}>
                            <IconComponent name='arrow-right' style={{
                                icon: {
                                    fontSize: 20,
                                    color: total / properties.paginator.limit < (page + 1) || isListEnd ? 'rgba(0,0,0,0.3)' : '#fff'
                                }
                            }} />
                        </TouchableOpacity>

                        <TextComponent
                            numberOfLines={1}
                            styles={{
                                layout: {
                                    marginLeft: 'auto',

                                },
                                text: {
                                    marginLeft: 'auto',
                                }
                            }}>
                            <TextComponent
                                numberOfLines={1}
                                styles={{
                                    text: {
                                        fontWeight: 'bold'
                                    }
                                }}>{total}</TextComponent >{' '}{t(`pagination.total`)}{' '}<TextComponent
                                    styles={{
                                        text: {
                                            fontWeight: 'bold'
                                        }
                                    }}>
                                {Math.ceil(total / properties.paginator.limit)}
                                {' '}
                            </TextComponent>
                            {' '}{t(`pagination.pages`)}
                        </TextComponent>
                    </View>

                </View> : null
            }
            </View>
            </>
        );
    };



    const renderColumn = (item: T, column: ColumnListType<T>) => {
        if ('phone' in column && column.phone === false && !isWeb) {
            return null
        }
        switch (column.type) {
            case 'text':
                return <View style={{
                    width: column.width,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 15,
                    flex: 1,
                }}>
                    <View style={{
                        flex: 1,
                        flexDirection: 'column',
                        gap: 2,
                    }}>
                        <TextComponent
                            styles={{
                                text: {
                                    fontSize: column.fontSize || 13,
                                    fontWeight: column.fontWeight || 'bold'
                                }
                            }}
                            numberOfLines={1}>{column.renderLabel(item)}</TextComponent>
                        {
                            column.subtitle && (column.subtitle.phone && !isWeb) && <Text numberOfLines={1}>{column.subtitle.render(item)}</Text>
                        }

                    </View>
                    {
                        column.renderDetail && <Text style={{
                            marginLeft: 'auto',
                            backgroundColor: '#ddd',
                            fontSize: 10,
                            padding: 5,
                            borderRadius: 3,
                            paddingHorizontal: 10,
                            marginRight: 10,
                            opacity: 0.5
                        }}>{column.renderDetail(item)}</Text>
                    }
                    {
                        column.renderDetailComponent && <View style={{
                            marginLeft: 'auto',
                            backgroundColor: '#eee',
                            borderRadius: 3,
                            marginRight: 10,
                        }}>{column.renderDetailComponent(item)}</View>
                    }
                </View>
            case 'member':
                const color = column.renderColor?.(item)
                return <View style={{
                    width: column.width,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: 7,
                    flex: 1,
                }}>
                    <View style={{
                        flexDirection: 'row',
                        gap: 5,
                        backgroundColor: '#eee',
                        paddingHorizontal: 10,
                        paddingLeft: 5,
                        borderRadius: 20,
                        paddingVertical: 4,
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                        <View style={{
                            height: 18,
                            borderWidth: 3,
                            borderColor: 'rgba(255,255,255,0.5)',
                            width: 18,
                            backgroundColor: color,
                            borderRadius: 25,
                        }} />
                        <TextComponent
                            styles={{
                                text: {
                                    fontSize: 13,
                                    fontWeight: '500'
                                }
                            }}
                            numberOfLines={1}>{column.renderLabel(item)}</TextComponent>
                        {
                            column.subtitle && (column.subtitle.phone && !isWeb) && <Text numberOfLines={1}>{column.subtitle.render(item)}</Text>
                        }

                    </View>
                    {
                        column.renderDetail && <Text numberOfLines={1} style={{
                            marginLeft: 'auto',
                            backgroundColor: '#ddd',
                            fontSize: 10,
                            padding: 5,
                            borderRadius: 3,
                            paddingHorizontal: 10,
                            marginRight: 10,
                            opacity: 0.5
                        }}>{column.renderDetail(item)}</Text>
                    }
                    {
                        column.renderDetailComponent && <View style={{
                            marginLeft: 'auto',
                            backgroundColor: '#eee',
                            borderRadius: 3,
                            marginRight: 10,
                        }}>{column.renderDetailComponent(item)}</View>
                    }
                </View>

            case 'number':
                return <View style={{
                    width: column.width,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingRight: 15,
                    flex: 1,
                }}>
                    <View style={{
                        flex: 1,
                        flexDirection: 'column',
                        gap: 2,
                        alignContent: 'flex-end'
                    }}>
                        <TextComponent
                            styles={{
                                layout: {
                                    marginLeft: 'auto'
                                },
                                text: {
                                    fontWeight: 'bold'
                                }
                            }}
                            numberOfLines={1}>{column.renderLabel(item)}</TextComponent>
                        {
                            column.subtitle && (column.subtitle.phone && !isWeb) && <Text numberOfLines={1}>{column.subtitle.render(item)}</Text>
                        }

                    </View>
                    {
                        column.renderDetail && <Text style={{
                            marginLeft: 'auto',
                            backgroundColor: '#ddd',
                            fontSize: 10,
                            padding: 5,
                            borderRadius: 3,
                            paddingHorizontal: 10,
                            marginRight: 10,
                            opacity: 0.5
                        }}>{column.renderDetail(item)}</Text>
                    }

                </View>
            case 'price':
                const price = column.renderPrice(item)
                return <View style={{
                    width: column.width,
                    height: height,
                    flex: 1,
                }}>
                    <View style={{
                        flex: 1,
                        padding: 5,
                        paddingHorizontal: 8,
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingRight: 15,

                        borderColor: price > 0 ? 'rgba(52, 199, 89,0.5)' : '#fff',
                        backgroundColor: price > 0 ? '#e3ffea' : '#fff',
                        opacity: price > 0 ? 1 : 0.5
                    }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                fontWeight: 'bold',
                                marginRight: 3,
                            }}> {priceUtility({
                                price: price
                            })}</Text>
                        <Text
                            numberOfLines={1}
                            style={{
                                opacity: 0.6
                            }}>{column.renderCurrency(item)}</Text>
                        {
                            column.renderDetail && <Text style={{
                                marginLeft: 'auto',
                                backgroundColor: '#ddd',
                                fontSize: 10,
                                padding: 5,
                                borderRadius: 3,
                                paddingHorizontal: 10,
                                marginRight: 10,
                                opacity: 0.5
                            }}>{column.renderDetail(item)}</Text>
                        }
                    </View>
                </View>

            case 'button':
                return <TouchableOpacity onPress={() => column.onPress(item)} style={{
                    width: column.width,
                    height: height,

                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10,

                    backgroundColor: 'rgba(0,0,0,0.02)',
                    gap: 10
                }}>
                    {
                        column.icon ? <IconComponent name={column.icon} style={{
                            icon: {
                                fontSize: 15,
                                color: '#000'
                            }
                        }} /> : null
                    }
                    <Text numberOfLines={1} style={{
                        color: '#000',
                        fontSize: 12,
                        fontWeight: 'bold'
                    }}>{column.label ? t(`${properties.prefix}.${column.label}`) :
                        column.renderLabel?.(item)}</Text>
                </TouchableOpacity>
            case 'ago':
                const dx = column.getDate?.(item)
                return <View style={{
                    width: column.width,
                    height: height,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 15,
                    paddingRight: 8,
                }}>
                    <Text
                        numberOfLines={1}
                        style={{
                            fontSize: 12,
                            fontWeight: '500'
                        }}>
                        <Moment format='YYYY-MM-DD' date={dx} />
                    </Text>
                    <Text style={{
                        padding: 5,
                        borderRadius: 3,
                        fontSize: 10,
                        marginLeft: 'auto',
                    }}>
                        <Moment fromNow>{dx}</Moment>
                    </Text>
                </View>
            case 'date':
                const d = column.getDate?.(item)
                return <View style={{
                    width: 250,
                    height: height,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 15,
                    paddingRight: 8,
                }}>
                    <Text
                        numberOfLines={1}
                        style={{
                            fontSize: 10,
                        }}>
                        {d ? <Moment format='YYYY-MM-DD' date={d} /> : null}
                    </Text>
                </View>
            case 'range':
                return <View style={{
                    height: height,
                    width: 120,
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgb(227, 255, 234)'
                }}>

                    <Text style={{

                        padding: 5,
                        paddingHorizontal: 7,
                        fontSize: 12,
                        fontWeight: 'bold',
                    }}>
                        <Moment format='HH:mm'>{column.getDateStart?.(item)}</Moment>
                    </Text>
                    <IconComponent name='arrow-right' style={{

                        icon: {
                            fontSize: 15,
                            color: 'rgba(0,0,0,0.5)'
                        }
                    }} />
                    <Text style={{

                        padding: 5,
                        paddingHorizontal: 7,
                        fontSize: 12,
                        fontWeight: 'bold',
                    }}>
                        <Moment format='HH:mm'>{column.getDateEnd?.(item)}</Moment>
                    </Text>
                </View>
            case 'icon':

                const icon = column.icon || column.renderIcon?.(item)

                return <TouchableOpacity
                    disabled={!column.onPress}
                    containerStyle={{
                        width: height,
                        height: height,
                        backgroundColor: '#fff',
                        overflow: 'hidden',
                    }}
                    onPress={() => column.onPress && column.onPress(item)} style={{
                        width: height,
                        height: height,
                        backgroundColor: '#fff',
                        overflow: 'hidden',
                    }}>

                    {icon && <IconComponent
                        style={{
                            box: {
                                width: height,
                                height: height,
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: column.backgroundColor || icon === 'check' ? '#daffea' : icon === ('signature') ? '#ffe9ec' : icon === ('trash-alt') ? '#fff' : icon === ('times') ? '#ffe9ec' : 'transparent',

                            }
                            ,
                            icon: {
                                fontSize: 20,
                                color: column.color || icon === 'check' ? '#13aa54' : icon === ('signature') ? '#c73447' : icon === ('trash-alt') ? '#c73447' : icon === ('times') ? '#c73447' : '#000',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }

                        }}
                        name={icon} />}
                </TouchableOpacity>
            case 'color':
                return <View style={{
                    width: 30,
                    margin: 5,
                    borderRadius: 25,
                    borderWidth: 4,
                    borderColor: 'rgba(255,255,255,0.5)',

                    height: 30,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 15,
                    backgroundColor: column.renderColor?.(item) as string
                }} />
        }
    }


    const renderRow = (item: T) => {

        if (properties.columns) {

            const haveMenu = properties.columns.filter((column) => column.menu)


            return <Fragment >
                <View style={{
                    flexDirection: 'row',
                    marginBottom: 3,
                    backgroundColor: '#fff', borderRadius: 5,
                    shadowColor: '#000',
                    shadowOffset: {
                        width: 0,
                        height: 1,
                    },
                    flex: 1,
                    height: 40,
                    paddingRight: haveMenu?.length > 0 ? 40 : 0,
                    maxHeight: 40,
                    overflow: 'hidden',
                    flexWrap: 'wrap',
                    shadowOpacity: 0.1,
                    shadowRadius: 3.84,
                }}>
                    <View style={{
                        flexWrap: 'nowrap',
                        height: 40,
                        flexDirection: 'row',

                    }}>
                        {
                            properties.columns.map((column, index) => {
                                if (!column.always) {
                                    return null
                                }
                                return <View
                                    key={index}
                                    style={{
                                        borderLeftWidth: index === 0 ? 0 : 1,
                                        borderLeftColor: '#ddd',
                                        maxWidth: column.type === 'range' ? 120 : column.type === 'icon' ? height : 'width' in column ? column.width : 50,
                                    }}>
                                    {renderColumn(item, column)}
                                </View>
                            })
                        }
                    </View>
                    {
                        properties.columns.map((column, index) => {
                            if (column.always) {
                                return null
                            }
                            if ('phone' in column && column.phone === false && !isWeb) {
                                return null
                            }
                            return <View
                                key={index}
                                style={{
                                    borderLeftWidth: index === 0 ? 0 : 1,
                                    borderLeftColor: '#ddd',
                                    width: column.type === 'icon' ? height : 'width' in column ? column.width : 50,
                                }}>
                                {renderColumn(item, column)}
                            </View>
                        })
                    }
                    {
                        haveMenu.length > 0 && <MenuTableComponent
                            row={item}
                            setMenuRow={setMenuRow}
                            columns={haveMenu} />
                    }
                </View>
                {properties.renderFooterItem && properties.renderFooterItem(item)}
            </Fragment>
        }
        return (
            // Flat List Item
            properties.renderItem ? properties.renderItem(item) : <Text>sin render</Text>
        );
    }
    const Capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const haveMenu = properties.columns.filter((column) => column.menu)

    const renderHeader = () => {

        return <>

            {
                renderSuperHeader()
            }
            {
                properties.membering && <ScrollView
                    horizontal
                    style={{
                        flexDirection: 'row',
                        gap: 10,
                    }}

                    contentContainerStyle={{
                        flexDirection: 'row',
                        gap: 5,
                        paddingBottom: 15,
                    }}>
                    {
                        members.map((member, index) => {
                            const initials = member.name.split(' ').map((n) => n.charAt(0)).join('')
                            return <TouchableOpacity
                                key={index}
                                onPress={() => {
                                    if (memberSelected === member.id) {
                                        setMemberSelected(undefined)
                                        setPage(0)
                                        return
                                    }
                                    setMemberSelected(member.id)
                                }} style={{

                                    borderRadius: 55,
                                    borderStyle: 'solid',
                                    overflow: 'hidden',
                                    padding: 0,
                                    margin: 0,
                                    opacity: memberSelected === member.id ? 1 : memberSelected ? 0.5 : 1

                                }}>
                                <View style={{
                                    height: 39,
                                    borderWidth: 4,
                                    width: 39,
                                    borderColor: memberSelected === member.id ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,0.6)',

                                    maxHeight: 39,
                                    maxWidth: 39,
                                    overflow: 'hidden', padding: 0,
                                    margin: 0,
                                    borderRadius: 25,

                                    backgroundColor: member.color,
                                }}>
                                    <View style={{
                                        height: 35,
                                        width: 35,
                                        maxHeight: 35,
                                        flex: 1,
                                        maxWidth: 35,
                                        overflow: 'hidden', padding: 0,
                                        margin: 0,
                                        borderRadius: 25,
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                    }}
                                    >
                                        <Text style={{
                                            fontSize: 12,
                                            color: '#fff',
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 1,

                                            textTransform: 'uppercase',
                                            letterSpacing: 2,
                                            fontWeight: memberSelected === member.id ? 'bold' : 'bold'
                                        }}>{initials}</Text>
                                    </View>

                                </View>
                            </TouchableOpacity>
                        })
                    }
                </ScrollView>
            }
            {properties.search && <HeaderSearch search={properties.search} searchText={search || ''} setSearch={setSearch} prefix={properties.prefix} />}
            <View style={{
                flexDirection: 'row',
                paddingVertical: 6,
                flexWrap: 'wrap',
                overflow: 'hidden',
                height: 40,
                paddingRight: haveMenu?.length > 0 ? 40 : 0,
                paddingTop: properties.search ? 6 : 0,
            }}>
                {
                    isWeb && (dataSource && Object.keys(dataSource).length > 0)
                    && properties.columns && properties.columns.map((column, index) => {
                        return <View
                            key={index}
                            style={{
                                paddingVertical: 15,
                                paddingBottom: 4,
                                width: column.type === 'icon' ? 40 : 'width' in column ? column.width : 100,
                            }}>
                            {
                                'title' in column && <Text style={{
                                    fontSize: 10,
                                    textAlign: 'center',
                                    letterSpacing: 2,
                                    textTransform: 'uppercase',
                                    opacity: 0.5
                                }}>{t(`${properties.prefix}.${column.title}`)}</Text>
                            }
                        </View>
                    })
                }
            </View>
        </>
    }
    const renderSectionHeader = ({ section }: { section: { title: string, data: any[] } }): React.ReactElement => {

        return <View style={{ height: 50, overflow: 'hidden', backgroundColor: 'rgb(246, 246, 246)', justifyContent: 'center', paddingLeft: 50, }}>
            <TextComponent
                numberOfLines={1}
                styles={{
                    text: {
                        fontWeight: '300',

                        lineHeight: 12, fontSize: 11, color: '#666'
                    }
                }}>
                {Capitalize(moment(section.title).locale(i18n?.language).format('MMMM, YYYY'))}
            </TextComponent>
            <TextComponent numberOfLines={1} styles={{
                layout: {
                    width: 30,
                    position: 'absolute',
                    left: 10, top: 10,

                    height: 30,
                    backgroundColor: '#eee',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 15,
                },
                text: { fontWeight: 'bold', lineHeight: 17, color: '#000' }
            }}>
                {moment(section.title).locale(i18n?.language).format('D')}
            </TextComponent>
            <TextComponent numberOfLines={1} styles={{ text: { fontWeight: 'bold', lineHeight: 17, color: '#000' } }}>
                {Capitalize(moment(section.title).locale(i18n?.language).format('dddd '))}
            </TextComponent>

        </View>

    };

    const renderSuperHeader = () => {
        return <>
            {
                properties.header && <HeaderComponent
                    groupId={properties.groupId}
                    title={t(`${properties.prefix}.title`)}
                    description={t(`${properties.prefix}.description`)}
                    paddingBottom={!properties.search ? 20 : undefined}
                    actions={
                        properties.header === true ? [] : properties.header.actions.map((action) => {
                            return {
                                ...action,
                                label: t(`${properties.prefix}.${action.label}`)
                            }
                        })
                    }
                />
            }
            {
                properties.filters && <View style={{
                    flexDirection: 'row',

                    paddingBottom: 10,
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                    {
                        properties.filters.map((filter, index) => {
                            return <TouchableOpacity
                                key={index}
                                onPress={filter.onPress} style={{
                                    padding: 10,
                                    backgroundColor: '#e1e1e1',
                                    borderRadius: 5,
                                    flexDirection: 'row',
                                    gap: 10,
                                    paddingLeft: 15,
                                }}>
                                <TextComponent numberOfLines={1} styles={{
                                    text: {
                                        fontWeight: 'bold'
                                    }
                                }}>{t(`${properties.prefix}.${filter.name}`)}</TextComponent>
                                <IconComponent name='times' style={{
                                    icon: {
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                    }
                                }} />
                            </TouchableOpacity>
                        })
                    }
                </View>
            }


        </>
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'rgb(246, 246, 246)' }}>

            {
                !properties.groupBy && dataSource ? <FlatList
                    ListHeaderComponent={<View>{renderHeader()}</View>}
                    ref={ref}
                    data={Object.keys(dataSource).map((date) => dataSource[date]).flat()}
                    keyExtractor={(item, index) => item.id}
                    maxToRenderPerBatch={20}
                    initialNumToRender={20}
                    persistentScrollbar={true}
                    disableScrollViewPanResponder
                    disableIntervalMomentum
                    disableVirtualization
                    renderItem={({ item }) => renderRow(item)}
                    ListFooterComponent={renderFooter}
                    onEndReachedThreshold={0.5}
                    style={{ padding: isWeb ? 20 : 0, paddingHorizontal: 30, paddingTop: 0, marginTop: 0, borderRadius: 5, }}
                    ListEmptyComponent={<EmptyComponent />}

                /> : null
            }
            {properties.groupBy && dataSource && <SectionList
                ref={sectionRef}

                sections={Object.keys(dataSource).map((date) => ({
                    title: date,
                    data: dataSource[date],
                }))}
                style={{ padding: isWeb ? 20 : 10, paddingHorizontal: isWeb ? 30 : 10, paddingTop: 0, marginTop: 0, borderRadius: 5, }}
                data={dataSource}
                keyExtractor={(item, index) => item.id}
                renderItem={({ item }) => renderRow(item)}

                ListFooterComponent={renderFooter}
                renderSectionHeader={renderSectionHeader}
                stickySectionHeadersEnabled={true}
                ListHeaderComponent={<View>{renderHeader()}</View>}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={<EmptyComponent />}
            />}
            {
                menuRow && <TouchableOpacity style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    zIndex: 10000,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} onPress={() => setMenuRow(undefined)} />}
            {
                menuRow && <View style={{
                    padding: 20,
                    position: 'absolute',
                    bottom: Platform.OS === 'web' ? 30 : 0,
                    right: Platform.OS === 'web' ? 30 : 0,
                    width: Platform.OS === 'web' ? 350 : '100%',
                    zIndex: 14000,
                    borderRadius: 15,
                    backgroundColor: '#fff',
                    shadowColor: '#000',
                    shadowOffset: {
                        width: 0,
                        height: -1,
                    },
                    shadowOpacity: 0.1,
                    shadowRadius: 3.84,
                    elevation: 5,

                }}>
                    <IconComponent name='times' style={{
                        box: {
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            zIndex: 200,
                            height: 50,
                            width: 50,
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 25,
                            backgroundColor: 'rgba(0,0,0,0.1)'
                        },
                        icon: {
                            fontSize: 20,

                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }}
                        onPress={() => setMenuRow(undefined)}
                    />
                    <TextComponent styles={{
                        layout: {
                            marginBottom: 20,

                        },
                        text: {
                            fontWeight: 'bold',
                            fontSize: 20
                        }
                    }}>{t(`${properties.prefix}.menu`)}</TextComponent>
                    {
                        properties.columns.map((column, index) => {
                            if (!column.menu) return null
                            return <TouchableOpacity
                                key={index}
                                onPress={() => {
                                    setMenuRow(undefined)
                                    if (column.menu && 'onPress' in column.menu && column.menu.onPress) {
                                        column.menu.onPress?.(menuRow)
                                    }
                                    else if ('onPress' in column && column.onPress) {

                                        column.onPress(menuRow)
                                    }
                                }} style={{
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    gap: 10
                                }}>
                                <IconComponent name={column.menu?.icon} style={{
                                    box: {
                                        height: 50,
                                        width: 50,
                                        backgroundColor: '#eee',

                                        borderRadius: 240, alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    icon: {
                                        fontSize: 25
                                    }
                                }} />
                                <TextComponent styles={{
                                    text: {
                                        fontSize: 16,
                                        fontWeight: '500'
                                    }
                                }}>{t(`${properties.prefix}.${column.menu?.label}`)}</TextComponent>
                            </TouchableOpacity>
                        })
                    }
                    {
                        properties.menu?.map((menu, index) => {
                            return <TouchableOpacity
                                key={index}
                                onPress={() => {

                                    setMenuRow(undefined)
                                    if (menu && 'onPress' in menu && menu.onPress) {
                                        menu.onPress?.(menuRow)
                                    }

                                }} style={{
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    gap: 10
                                }}>
                                <IconComponent name={menu?.icon} style={{
                                    box: {
                                        height: 50,
                                        width: 50,
                                        backgroundColor: '#eee',

                                        borderRadius: 240, alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    icon: {
                                        fontSize: 25
                                    }
                                }} />
                                <TextComponent
                                    numberOfLines={1}
                                    styles={{
                                        text: {
                                            fontSize: 16,
                                            fontWeight: '500'
                                        }
                                    }}>{t(`${properties.prefix}.${menu?.label}`)}</TextComponent>
                            </TouchableOpacity>
                        })
                    }
                </View>

            }
        </View>
    );
};

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',

    },
});




export function MenuTableComponent(properties: {
    row: any,
    setMenuRow: (row: any) => void,
    columns: ColumnListType<any>[]
}) {
    return <TouchableOpacity
        onPress={() => {
            properties.setMenuRow(properties.row)
        }}
        style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            top: 0,
            width: 30,
            backgroundColor: '#fff',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'row',

        }}>
        <IconComponent name='ellipsis-v' style={{
            icon: {
                fontSize: 24,
                color: '#555'
            }
        }} />
    </TouchableOpacity>

}



const HeaderSearch = (properties: {
    search?: boolean,
    searchText: string,
    setSearch: (text: string) => void
    prefix: string

}) => {
    const [searchT, setSearchT] = useState(properties.searchText)
    useEffect(() => {
        if (searchT === undefined) return;
        if (properties.searchText === searchT) return

        const bounce = setTimeout(() => {
            properties.setSearch(searchT)
        }, 1300);
        return () => {
            clearTimeout(bounce)
        }
    }, [searchT,]);



    const { t } = useTranslation()
    return <View style={{
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingBottom: 0,
        marginBottom: 0,
        borderRadius: 5,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3.84,
        elevation: 5,
    }}>
        {
            properties.search ? <View style={{
                flex: 1,
                overflow: 'hidden',
            }}>
                <IconComponent name='search' style={{
                    icon: {
                        position: 'absolute',
                        left: 10,
                        top: 10,
                        fontSize: 20,
                        zIndex: 1,
                        color: 'rgba(0,0,0,0.2)'
                    }
                }} />
                <TextInput
                    value={searchT}
                    placeholder={t(`${properties.prefix}.search`)}
                    style={{
                        height: 40,
                        borderColor: '#fff',
                        borderWidth: 0,
                        backgroundColor: '#fff',
                        padding: 10,
                        paddingLeft: 40,
                    }}
                    onChangeText={(text) => {
                        setSearchT(text)
                    }}
                />
                {
                    searchT ? <IconComponent name='times' style={{
                        box: {
                            position: 'absolute',
                            right: 10,
                            top: 10,
                        },
                        icon: {

                            fontSize: 20,
                            zIndex: 221,
                            color: '#c73447',
                            fontWeight: 'bold'
                        }
                    }} onPress={() => {
                        setSearchT('')
                        properties.setSearch('')
                    }} /> : null
                }
            </View> : null
        }
    </View>
}

