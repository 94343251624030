import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { MemberRoutes } from "../member.routes";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";

export function ListMemberScreen() {
    const navigation = useNavigation<NavigationProp<MemberRoutes, 'list'>>()
    const route = useRoute<RouteProp<MemberRoutes, 'list'>>()
    const { t } = useTranslation()
    const [user] = useRecoilState(userState)
    return <TableComponent<{
        id: string
        User: {
            name: string
            surname: string
            email: string
        }
        userId: string
        accounter: boolean
        color: string
        accounterAll: boolean
        administrator: boolean
        commision: number
        consenter: boolean
        consenterAll: boolean
        isPublic: boolean
        status: boolean
        createdAt: string
    }>
        groupId={route.params.groupId}

        prefix="listMember"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`member?groupId=${route.params.groupId}`}
        columns={[
            {
                always: true,
                menu: {
                    icon: 'pen',
                    label: 'edit',

                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        memberId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return `${item.User.name} ${item.User.surname}` },
                renderDetail: (item) => { return item.User.email },
                width: 300,
                type: 'text',
            },
            {
                renderColor: (item) => { return item.color },
                type: 'color',
                width: 40
            },
            {
                title: 'createdAt',
                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200
            },
            {
                title: 'invitation',
                renderLabel: (item) => {
                    return item.status ? t('listMember.invitationYes') : t('listMember.invitationNo')
                },
                type: 'text',
                width: 100
            },
            {
                title: 'administrator',
                renderLabel: (item) => {
                    return item.administrator ? t('listMember.administratorYes') : t('listMember.administratorNo')
                },
                type: 'text',
                width: 100
            },
            {
                menu: {
                    icon: 'calendar-plus',
                    label: 'createMeet',

                },
                label: 'createMeet',
                width: 150,
                type: 'button',
                variant: 'primary',
                icon: 'plus',
                onPress: (item) => {
                    navigation.navigate('meet', {
                        groupId: route.params.groupId,
                        memberId: item.id
                    })
                }

            },
            {
                icon: 'trash-alt',
                type: 'icon',
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',

                },
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        memberId: item.id,
                        isMe: user ? item.userId === user.id : false
                    })
                }
            },
        ]}

    />

}
