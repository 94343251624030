import React, { useEffect, useState } from "react";
import {
  Button,
  Text,
  TextInput as TextInputNative,
  TouchableOpacity,
  View,
} from "react-native";
import DateTimePicker from "react-native-ui-datepicker";
import dayjs from "dayjs";
import ColorPicker, {
  Panel1,
  Swatches,
  Preview,
  OpacitySlider,
  HueSlider,
} from "reanimated-color-picker";
import { DatetimeInputProperties } from "./datetime.properties";
import { TextComponent } from "@components/text/text.component";
import { ScrollView } from "react-native-gesture-handler";
import { IconComponent } from "@components/icon/icon.component";
import { useTranslation } from "react-i18next";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/tr";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);
export function DatetimeInputForm<T>(properties: DatetimeInputProperties<T>) {
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const { t, i18n } = useTranslation();

  const startDate = dayjs.utc(properties.valueStart);

  const finishDate = dayjs.utc(properties.valueFinish);

  useEffect(() => {
    if (properties.valueStart && properties.valueFinish) {
    } else {
      // add start now and end now + 1 hour with minutes 00
      const d = dayjs.utc().minute(0).second(0);
      properties?.onChange?.(
        d.format("YYYY-MM-DDTHH:mm:ss"),
        d.add(1, "hour").format("YYYY-MM-DDTHH:mm:ss")
      );
    }
  }, []);

  // get time between start and end
  const timeBetween = finishDate.diff(startDate, "minute");
  const hours = Math.floor(timeBetween / 60);
  const minutes = timeBetween % 60;
  const timeBetweenText = minutes ? `${hours}h ${minutes}m` : `${hours}h`;
  const currentTimezone = dayjs.tz.guess();

  const startToShow = dayjs.utc(properties.valueStart).tz(currentTimezone);
  const finishToShow = dayjs.utc(properties.valueFinish).tz(currentTimezone);
  return (
    <>
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          borderColor: properties.invert ? "rgba(255,255,255,0.1)" : "#ddd",
          zIndex: 1000,
        }}
      >
        {properties.valueStart ? (
          <>
            <View
              style={{
                flexDirection: "row",
                gap: 10,
                zIndex: 1000,
              }}
            >
              <TouchableOpacity
                onPress={() => setShowCalendar(!showCalendar)}
                style={{
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    gap: 10,
                    height: 40,
                    alignContent: "center",
                    alignItems: "center",
                    paddingLeft: 10,
                  }}
                >
                  <IconComponent
                    name="calendar"
                    style={{
                      icon: {
                        fontSize: 24,
                        opacity: 0.5,
                        color: properties.invert ? "white" : "black",
                      },
                    }}
                  />

                  <TextComponent
                    styles={{
                      text: {
                        color: properties.invert ? "white" : "black",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {startToShow
                      ?.locale(i18n.language)
                      .format?.("dddd, YYYY-MM-DD")}
                  </TextComponent>
                </View>
              </TouchableOpacity>
              <View
                style={{
                  width: 170,
                  minWidth: 170,
                  maxWidth: 170,
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    height: 30,
                    paddingHorizontal: 5,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowStartDate(!showStartDate);
                    setShowEndDate(false);
                  }}
                >
                  <TextComponent
                    styles={{
                      text: {
                        color: properties.invert ? "white" : "black",
                      },
                    }}
                  >
                    {startToShow?.format?.("HH:mm")}
                  </TextComponent>
                </TouchableOpacity>
                <TextComponent
                  styles={{
                    text: {
                      color: properties.invert ? "white" : "black",
                    },
                  }}
                >
                  -
                </TextComponent>
                <TouchableOpacity
                  style={{
                    height: 30,
                    paddingHorizontal: 5,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowEndDate(!showEndDate);
                    setShowStartDate(false);
                  }}
                >
                  <TextComponent
                    styles={{
                      text: {
                        color: properties.invert ? "white" : "black",
                      },
                    }}
                  >
                    {finishToShow?.format?.("HH:mm")}
                  </TextComponent>
                </TouchableOpacity>
                {startDate && properties.onChange && (
                  <TimeSelector
                    setDate={(date) => {
                      const datePlus1h = dayjs(date).add(1, "hour");

                      properties.onChange?.(
                        date,
                        datePlus1h?.format?.("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                    showDate={showStartDate}
                    setShowDate={setShowStartDate}
                    date={startToShow}
                  />
                )}
                {finishDate && properties.onChange && (
                  <TimeSelector
                    setDate={(date) => {
                      properties.onChange?.(
                        startDate?.format?.("YYYY-MM-DDTHH:mm:ss"),
                        date
                      );
                    }}
                    showDate={showEndDate}
                    setShowDate={setShowEndDate}
                    date={finishToShow}
                  />
                )}
              </View>
            </View>
            <TextComponent
              styles={{
                layout: {
                  position: "absolute",
                  top: 7,
                  right: 7,
                },
                text: {
                  fontWeight: "bold",
                  backgroundColor: "#ddd",
                  paddingHorizontal: 8,
                  borderRadius: 5,
                },
              }}
            >
              {timeBetweenText}
            </TextComponent>
          </>
        ) : (
          <TouchableOpacity
            onPress={() => {
              // add start now and end now + 1 hour with minutes 00
              const d = dayjs.utc().minute(0).second(0);
              properties?.onChange?.(
                d.format("YYYY-MM-DDTHH:mm:ss"),
                d.add(1, "hour").format("YYYY-MM-DDTHH:mm:ss")
              );
            }}
            style={{
              flexDirection: "row",
              gap: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                gap: 10,
                height: 40,
                alignContent: "center",
                alignItems: "center",
                paddingLeft: 10,
              }}
            >
              <IconComponent
                name="calendar"
                style={{
                  icon: {
                    fontSize: 24,
                    opacity: 0.5,
                    color: properties.invert ? "white" : "black",
                  },
                }}
              />

              <TextComponent
                styles={{
                  text: {
                    color: properties.invert ? "white" : "black",
                    fontWeight: "bold",
                  },
                }}
              >
                {t("select_date")}
              </TextComponent>
            </View>
          </TouchableOpacity>
        )}
      </View>
      {showCalendar && (
        <View
          style={{
            padding: 20,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#ddd",
            marginTop: 10,
          }}
        >
          <DateTimePicker
            firstDayOfWeek={1}
            mode="single"
            locale={i18n.language}
            selectedItemColor="rgb(101, 195, 102)"
            headerButtonColor="rgb(101, 195, 102)"
            date={startToShow}
            onChange={(params) => {
              const dateOfCalendar = dayjs.tz(params.date, currentTimezone);
              // get current hour and minute
              if (startDate && finishDate) {
                const startDateHour = startToShow.hour();
                const startDateMinute = startToShow.minute();
                const endHour = finishToShow.hour();
                const endMinute = finishToShow.minute();
                console.log(
                  "vaxx:",
                  dateOfCalendar.hour(startDateHour).minute(startDateMinute)
                );
                const dateStartx = dateOfCalendar
                  .hour(startDateHour)
                  .minute(startDateMinute)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss");
                const dateEndx = dateOfCalendar
                  .hour(endHour)
                  .minute(endMinute)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss");

                properties.onChange?.(dateStartx, dateEndx);
                setShowCalendar(false);
              }
            }}
          />
        </View>
      )}
    </>
  );
}

export function TimeSelector(properties: {
  setDate: (date: string) => void;
  showDate: boolean;
  setShowDate: (showDate: boolean) => void;
  date: dayjs.Dayjs;
}) {
  // get height 30 per 15 mins
  const currentHour = properties.date.hour();
  const currentMinute = properties.date.minute();
  const currentMinuteIndex = currentMinute / 15;
  const currentHourIndex = currentHour * 4;
  const currentTotalIndex = currentHourIndex + currentMinuteIndex;
  const scrollRef = React.createRef<ScrollView>();
  useEffect(() => {
    properties.showDate &&
      scrollRef.current?.scrollTo({
        y: 30 * currentTotalIndex - 50,
        animated: false,
      });
  }, [properties.showDate]);
  if (!properties.showDate) return null;

  const currentTimezone = dayjs.tz.guess();

  return (
    <ScrollView
      ref={scrollRef}
      style={{
        position: "absolute",
        top: "100%",
        height: 200,
        marginTop: 5,
        left: 0,
        width: 100,
        backgroundColor: "white",
        padding: 0,
        shadowColor: "#000",
        borderWidth: 1,
        borderRadius: 5,
        borderColor: "#ddd",

        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      }}
    >
      {Array.from({ length: 24 }).map((_, indexHour) => {
        const labelHour = `${indexHour}`;
        return (
          <View key={indexHour}>
            {Array.from({ length: 4 }).map((min, indexMinute) => {
              // am or pm
              const suffix = indexHour < 12 ? "AM" : "PM";
              const labelMinute = `${indexMinute * 15}`.padStart(2, "0");
              return (
                <TouchableOpacity
                  key={indexMinute}
                  onPress={() => {
                    // add hour in the same timezone
                    const date = properties.date
                      .hour(indexHour)
                      .minute(indexMinute * 15)
                      .tz(currentTimezone)
                      .utc()
                      .format("YYYY-MM-DDTHH:mm:ss");
                    //
                    properties.setDate(date);
                    properties.setShowDate(false);
                  }}
                  style={{
                    height: 30,
                    paddingLeft: 10,
                    alignContent: "center",
                    borderBottomWidth: 1,
                    borderBottomColor: "#ddd",
                    backgroundColor:
                      properties.date.hour() === indexHour &&
                      properties.date.minute() === indexMinute * 15
                        ? "#ddd"
                        : "white",
                  }}
                >
                  <TextComponent
                    styles={{
                      text: {
                        fontSize: 11,
                      },
                    }}
                  >
                    {labelHour}:{labelMinute} {suffix}
                  </TextComponent>
                </TouchableOpacity>
              );
            })}
          </View>
        );
      })}
    </ScrollView>
  );
}
