import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { CustomerRoutes } from "../customer.routes";
import axios from "axios";
import { Environment } from "@environment";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { permissionState } from "@states/permission.state";
import { useTranslation } from "react-i18next";

export function ListCustomerScreen() {
    const navigation = useNavigation<NavigationProp<CustomerRoutes, 'list'>>()
    const route = useRoute<RouteProp<CustomerRoutes, 'list'>>()
    const [user] = useRecoilState(userState)
    const [permission, setPermission] = useRecoilState(permissionState)
    const { t } = useTranslation()
    return <TableComponent<{
        id: string
        phone: string;
        name: string;
        surname: string;
        description: string;
        email: string;
        document: string;
        addressCountry: string;
        addressCity: string;
        addressStreet: string;
        addressZip: string;
        addressLatitude: number;
        addressLongitude: number;
        addressState: string;
        createdAt: string;
        _count: {
            Meets: number;
        }
    }>
        groupId={route.params.groupId}

        prefix="listCustomer"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                },
                ...permission?.administrator === true ? [{
                    label: 'mailchimp',
                    icon: 'envelope',
                    onPress: () => {
                        if (!user) return;
                        // download mailchimp from /customer/mailchimp?groupId=${route.params.groupId}
                        axios.get(`${Environment.api}/customer/mailchimp?groupId=${route.params.groupId}`, {
                            headers: {
                                Authorization: `Bearer ${user.accessToken}`
                            }
                        }
                        ).then((response) => {
                            // download csv response
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'file.csv'); //or any other extension
                            document.body.appendChild(link);
                            link.click();

                        })
                    }
                }] : []
            ]
        }}
        search={true}
        pagination={false}
        paginator={{
            limit: 40,
        }}
        path={`customer?groupId=${route.params.groupId}`}
        menu={
            [
                {
                    icon: 'signature',
                    label: 'consent',
                    onPress: (item) => {
                        navigation.navigate('newConsent', {
                            groupId: route.params.groupId,
                            customerId: item.id
                        })
                    }
                },
                {
                    icon: 'receipt',
                    label: 'receipt',
                    onPress: (item) => {
                        navigation.navigate('newReceipt', {
                            groupId: route.params.groupId,
                            customerId: item.id
                        })
                    }
                }
            ]
        }
        columns={[
            {
                always: true,
                icon: 'calendar-plus',
                type: 'icon',
                color: '#65c366',
                backgroundColor: 'rgba(52, 199, 89,0.1)',
                menu: {
                    icon: 'calendar-plus',
                    label: 'meet',
                },
                onPress: (item) => {
                    navigation.navigate('newMeet', {
                        groupId: route.params.groupId,
                        customerId: item.id
                    })
                }
            },
            {
                always: true,
                icon: 'pen',
                type: 'icon',
                menu: {
                    icon: 'pen',
                    label: 'edit',
                },
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        customerId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return `${item.name} ${item.surname}` },
                width: 200,
                type: 'text',
            },
            {
                title: 'phone', phone: false, renderLabel: (item) => { return item.phone },
                width: 150,
                fontSize: 13,
                fontWeight: '400',
                type: 'text',
            },


            {

                renderLabel: (item) => {
                    return `${item._count.Meets} ${t('customers.meets')}`
                },
                phone: false,
                width: 150,
                type: 'button',
                variant: 'primary',
                icon: 'calendar',
                menu: {
                    icon: 'calendar',
                    label: 'meets',
                },
                onPress: (item) => {
                    navigation.navigate('meets', {
                        screen: 'list',
                        params: {
                            groupId: route.params.groupId,
                            customerId: item.id
                        }
                    })
                }

            },

            {
                title: 'createdAt', phone: false, getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200,
            },

            {
                phone: false,
                icon: 'trash-alt',
                type: 'icon',
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',
                },
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        customerId: item.id
                    })
                }
            },

        ]}

    />

}
