import { Link, NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Text, View } from "react-native";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { GalleryRoutes } from "../gallery.router";

import axios from "axios";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { ErrorComponent } from "@components/error/error.component";
import { KnobComponent } from "@components/knob/knob.component";
import { LineComponent } from "@components/line/line.component";
import { ParagraphComponent } from "@components/paragraph/paragraph.component";
import { SpaceComponent } from "@components/space/space.component";
import { userState } from "@states/user.state";
import LoaderComponent from "@components/loader/loader.component";
import { useTranslation } from "react-i18next";
import { counterState } from "@states/counter.state";
import { Environment } from "@environment";
import { DeleteComponent } from "@components/delete/delete.component";
import { UserRoutes } from "../../user.routes";



export function DeleteGalleryScreen() {
    const navigation = useNavigation<NavigationProp<GalleryRoutes, 'delete'>>()
    const route = useRoute<RouteProp<GalleryRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteImage'}
        path={'image'}
        id={route.params.imageId}
        goBack={() => {
            navigation.navigate('list')
        }}
        onDeleted={() => {
            navigation.navigate('list')
        }}
    />
}

