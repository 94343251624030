import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { TemplateRoutes } from "../template.routes";

export function DeleteTemplateScreen() {
    const navigation = useNavigation<NavigationProp<TemplateRoutes, 'delete'>>()
    const route = useRoute<RouteProp<TemplateRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteTemplate'}
        path={'template'}
        id={route.params.templateId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

