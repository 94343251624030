import React from 'react';
import { TextInput, View } from 'react-native';

import { TimeInputProperties } from './time.properties';

export function TimeInputForm<T>(properties: TimeInputProperties<T>) {
    const handleChangeHour = (text: string) => {
        // Allow only numbers 
        const numericValue = text.replace(/[^0-9]/g, "");
        // convert to numeric value
        const numericValue2 = parseFloat(numericValue);
        properties.onChangeHour?.(numericValue2);
    };

    const handleChangeMinute = (text: string) => {
        // Allow only numbers 
        const numericValue = text.replace(/[^0-9]/g, "");
        // convert to numeric value
        const numericValue2 = parseFloat(numericValue);
        properties.onChangeMinute?.(numericValue2);
    };

    // convert minutes in 00
    const minutes = properties.valueMinute ? properties.valueMinute.toString().padStart(2, '0') : '00';
    // convert hours in 00
    const hours = properties.valueHour ? properties.valueHour.toString().padStart(2, '0') : '00';

    return <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap'

    }}>
        <TextInput
            style={{
                borderWidth: 1,
                borderColor: '#ddd',
                padding: 10,
                fontSize: 40,
                width: 80,
                borderRadius: 5,
                borderTopEndRadius: 0,
                borderBottomEndRadius: 0,
                borderRightWidth: 0,
                textAlign: 'center',
            }}
            value={hours ? `${hours}` : '00'}
            onChangeText={(value) => {
                // max value
                if (parseInt(value) > 23) {
                    return
                }
                handleChangeHour(value);
            }}
            onSubmitEditing={properties.onSubmit}

        />
        <TextInput
            style={{
                borderWidth: 1,
                width: 80,
                borderTopStartRadius: 0,
                borderBottomStartRadius: 0,
                textAlign: 'center',
                borderColor: '#ddd',
                padding: 10,
                fontSize: 40,
                borderRadius: 5,
            }}
            // if 0 => 00
            value={minutes ? `${minutes}` : '00'}
            onChangeText={(value) => {
                // max value
                if (parseInt(value) > 59) {
                    return
                }
                handleChangeMinute(value);
            }}
            onSubmitEditing={properties.onSubmit}

        />
    </View>
}