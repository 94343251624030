// Modules
import { SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import * as React from 'react'

// Properties
import { type SectionProperties } from './section.properties'

// Styles
import { SectionStyles } from './section.styles'
import { TitleComponent } from '../title/title.component'
import { KnobComponent } from '../knob/knob.component'
import { ButtonComponent } from '../button/button.component'
import { TextComponent } from '../text/text.component'
import { IconComponent } from '../icon/icon.component'

/**
 * Section Component
 * @description This component is used to render a section and save the information of the screen.
 * @param {SectionProperties} properties
 * @return {JSX.Element}
 */
export function SectionComponent(properties: SectionProperties): JSX.Element {
  const Parent = properties.withoutScroll ? View : ScrollView

  return (
    <>
      <View style={{
        flexDirection: 'row',
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {properties.button && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              top: 0,
              left: 10,
              height: 50,
              width: 50,
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.5,
            }}
            onPress={properties.button.onPress}
          >
            <TextComponent content={properties.button.label} />
          </TouchableOpacity>
        )}
        <Text style={{
          fontWeight: 'bold',
          fontSize: 15
        }}>{properties.title}</Text>
        {properties.onClose && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: 50,
              width: 50,
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.5,
            }}
            onPress={properties.onClose}
          >
            <IconComponent name="times" style={{
              icon: {
                fontSize: 20
              }
            }} />
          </TouchableOpacity>
        )}

      </View>
      <Parent style={SectionStyles.component}>


        <>


          <View
            style={[
              SectionStyles.container,
              properties.withoutSpacing ? { paddingHorizontal: 0 } : {},
              properties.style?.content,
            ]}
          >
            <View
              style={[
                SectionStyles.content,
                properties.withoutSpacing ? { paddingTop: 0 } : {},
              ]}
            >
              {properties.children}
            </View>
          </View>
        </>
      </Parent>
    </>
  )
}
