import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { MaterialRoutes } from "../material.routes";

export function ListMaterialScreen() {
    const navigation = useNavigation<NavigationProp<MaterialRoutes, 'list'>>()
    const route = useRoute<RouteProp<MaterialRoutes, 'list'>>()
    return <TableComponent<{
        id: string
        name: string
        type: string
        createdAt: string
    }>
        groupId={route.params.groupId}

        prefix="listMaterial"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`material?groupId=${route.params.groupId}`}
        columns={[
            {
                always: true,
                icon: 'pen',
                type: 'icon',
                menu: {
                    icon: 'pen',
                    label: 'edit'
                },
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        materialId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                width: 200,
                type: 'text',
            },
            {
                title: 'type',
                renderLabel: (item) => { return item.type },
                width: 200,
                fontWeight: 'normal',
                type: 'text',
            },
            {
                title: 'createdAt',
                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200
            },

            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete'
                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        materialId: item.id
                    })
                }
            },
        ]}

    />

}
