// Modules
import { useEffect, useState } from 'react'
import { Platform, Text, TextInput, View } from 'react-native'
import Toast from 'react-native-toast-message';
import * as Haptics from 'expo-haptics';


// Properties
import { type InputProperties } from './input.properties'

// Styles
import { InputStyles } from './input.styles'
import { IconComponent } from '../icon/icon.component'
import { LineComponent } from '../line/line.component'

/**
 * Input Component
 * @description Render a text field with label and error highlight if needed
 * @param {InputProperties} properties
 * @return {JSX.Element}
 */
import { BaseToast, ErrorToast } from 'react-native-toast-message';

/*
  1. Create the config
*/
const toastConfig = {
  /*
    Overwrite 'success' type,
    by modifying the existing `BaseToast` component
  */
  success: (props: any) => (
    <BaseToast
      {...props}
      style={{
        borderLeftColor: '#000', borderRightColor: '#000', borderRightWidth: 10, borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: 0, borderLeftWidth: 0, bottom: 80, maxWidth: 300, left: 'auto', right: 0, alignSelf: 'flex-end'
      }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 15,
        fontWeight: '500'
      }}
    />
  ),
  /*
    Overwrite 'error' type,
    by modifying the existing `ErrorToast` component
  */
  error: (props: any) => (
    <ErrorToast
      {...props}
      style={{
        borderLeftColor: 'red', borderRightColor: 'red', borderRightWidth: 10, borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: 0, borderLeftWidth: 0, bottom: 80, maxWidth: 300, left: 'auto', right: 0, alignSelf: 'flex-end'
      }}

      text1Style={{
        fontSize: 15, marginLeft: -10,
        paddingLeft: 0,
      }}
      text2Style={{
        fontSize: 14, marginLeft: -10,
      }}
    />
  ),
  /*
    Or create a completely new type - `tomatoToast`,
    building the layout from scratch.

    I can consume any custom `props` I want.
    They will be passed when calling the `show` method (see below)
  */

};


export function ErrorComponent(properties: InputProperties): JSX.Element {
  useEffect(() => {
    Toast.show({
      type: properties.type || 'error',
      text1: properties.title || 'Error',
      text2: properties.message,

    });
    // error Haptics
    Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error)

  }, [])
  if (!properties.message) return <></>

  return (
    <>
      <View style={[InputStyles.component,]}>


        <Toast position='bottom' visibilityTime={3000} config={toastConfig} />


      </View>

    </>
  )
}
