import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { BookingRoutes } from "../booking.routes";

export function DeleteBookingScreen() {
    const navigation = useNavigation<NavigationProp<BookingRoutes, 'delete'>>()
    const route = useRoute<RouteProp<BookingRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteBooking'}
        path={'meet'}
        id={route.params.bookingId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

