// Modules
import { StyleSheet } from 'react-native'

/**
 * Input styles
 */
export const InputErrorHighlightStyles = StyleSheet.create({
  error: {
    backgroundColor: '#dc1e3c',
    borderBottomEndRadius: 10,
    borderBottomStartRadius: 10,
    marginTop: -15,
    zIndex: -1,
  },
  errorText: {
    padding: 10,
    paddingTop: 23,
    paddingLeft: 15,
  },
})
