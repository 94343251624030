import { TableComponent } from "@components/table/table.component";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { ProductRoutes } from "../product.routes";
import { useCurrencyHook } from "../../../../hooks/currency.hook";

export function ListProductScreen() {
  const navigation = useNavigation<NavigationProp<ProductRoutes, "list">>();
  const route = useRoute<RouteProp<ProductRoutes, "list">>();
  const currency = useCurrencyHook(route.params.groupId);
  return (
    <TableComponent<{
      id: string;
      units: number;
      price: number;
      tax: number;
      name: string;
      description: string;
      createdAt: string;
    }>
      groupId={route.params.groupId}
      paginator={{
        limit: 40,
      }}
      prefix="listProduct"
      header={{
        actions: [
          {
            label: "new",
            icon: "plus",
            onPress: () => {
              navigation.navigate("new", {
                groupId: route.params.groupId,
              });
            },
          },
        ],
      }}
      search={true}
      pagination={false}
      path={`product?groupId=${route.params.groupId}`}
      columns={[
        {
          always: true,
          icon: "pen",
          type: "icon",
          menu: {
            icon: "pen",
            label: "edit",
          },
          onPress: (item) => {
            navigation.navigate("edit", {
              groupId: route.params.groupId,
              productId: item.id,
            });
          },
        },
        {
          always: true,
          title: "name",
          renderLabel: (item) => {
            return item.name;
          },
          width: 200,
          type: "text",
        },
        {
          title: "price",
          renderPrice: (item) => {
            return item.price;
          },
          renderCurrency: (item) => {
            return `${currency.currency}`;
          },
          width: 120,
          type: "price",
        },
        {
          title: "units",
          renderLabel: (item) => {
            return `${item.units}`;
          },
          width: 120,
          type: "number",
        },
        {
          title: "createdAt",
          getDate: (item) => {
            return item.createdAt;
          },
          type: "ago",
          width: 220,
        },

        {
          menu: {
            icon: "trash-alt",
            label: "delete",
          },
          icon: "trash-alt",
          type: "icon",
          onPress: (item) => {
            navigation.navigate("delete", {
              groupId: route.params.groupId,
              productId: item.id,
            });
          },
        },
      ]}
    />
  );
}
