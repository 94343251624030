import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ScheduleRoutes } from "../schedule.routes";
import { useTranslation } from "react-i18next";
import { TextComponent } from "@components/text/text.component";

export function ListScheduleScreen() {
    const navigation = useNavigation<NavigationProp<ScheduleRoutes, 'list'>>()
    const route = useRoute<RouteProp<ScheduleRoutes, 'list'>>()
    const { t } = useTranslation()
    return <TableComponent<{
        id: string
        startHour: number;
        startMinute: number;
        endHour: number;
        endMinute: number;
        day: number;
        createdAt: string
    }>
        groupId={route.params.groupId}

        prefix="listSchedule"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={false}
        pagination={false}
        path={`schedule?groupId=${route.params.groupId}`}
        columns={[
            {
                always: true,
                menu: {
                    icon: 'pen',
                    label: 'edit',

                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        scheduleId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'day',
                renderLabel: (item) => { return t(`day.${item.day}`) },
                width: 320,
                type: 'text',
                renderDetailComponent: (item) => {
                    const startHour00 = item.startHour < 10 ? `0${item.startHour}` : item.startHour
                    const startMinute00 = item.startMinute < 10 ? `0${item.startMinute}` : item.startMinute
                    const endHour00 = item.endHour < 10 ? `0${item.endHour}` : item.endHour
                    const endMinute00 = item.endMinute < 10 ? `0${item.endMinute}` : item.endMinute
                    return <>
                        <TextComponent styles={{
                            layout: {
                                paddingHorizontal: 11,
                            },
                            text: {
                                lineHeight: 20,
                                color: '#555',
                                fontSize: 12,
                            }
                        }}>{t('listSchedule.from')}{' '}
                            <TextComponent styles={{
                                text: {
                                    fontSize: 12, color: '#000',
                                    fontWeight: 'bold'
                                }
                            }}>{startHour00}:{startMinute00}</TextComponent>
                            {' '}{t('listSchedule.to')}{' '}
                            <TextComponent styles={{
                                text: {
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000'
                                }
                            }}>{endHour00}:{endMinute00}</TextComponent>
                        </TextComponent>
                    </>
                }
            },


            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',

                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        scheduleId: item.id
                    })
                }
            },
        ]}

    />

}
