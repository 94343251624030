import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { RoomRoutes } from "../room.routes";

export function NewRoomScreen() {
    const navigation = useNavigation<NavigationProp<RoomRoutes>>()
    const route = useRoute<RouteProp<RoomRoutes, 'new'>>()
    const level = route.params.level
    const callback = route.params.callback
    return <PostModule<{
        name: string
    }>
        level={level}
        prefix="newRoom"
        path="room"
        onSuccess={(data, xxxx) => {
            if ('items' in xxxx) {
                const item = xxxx?.items?.[0]
                const id = item?.id
                if (id) {
                    callback?.(item)
                }
            }

            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            }
        ]}
    />
}