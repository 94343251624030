import { IconComponent } from "@components/icon/icon.component"
import Moment from "react-moment"
import { Text, View } from "react-native"

export function TrialComponent(properties: {
    trial: {
        start: number,
        end: number
    }

}) {

    const now = new Date().getTime() / 1000
    if (properties.trial.end < now) {
        return null
    }

    return <View style={{
        marginTop: 10,
        alignSelf: 'flex-start',
        borderWidth: 3,
        paddingRight: 14,
        borderColor: 'rgba(255, 255, 255,0.1)',
        flexDirection: 'row',
        borderRadius: 10,
        alignContent: 'center',
        alignItems: 'center',
    }}>
        <IconComponent name="info-circle" style={{
            icon: {
                color: '#65c366',
                fontSize: 26,
                fontWeight: 'bold',
                margin: 10,
            }
        }} />
        <Text style={{
            color: '#65c366',
            fontSize: 16,
            fontWeight: '600',
            marginRight: 10,
        }}>Trial</Text>
        <Text style={{
            color: 'rgba(255, 255, 255,0.7)',
            fontSize: 14,
            fontWeight: '400',
        }}>finish <Text style={{
            color: '#65c366',
            fontSize: 14,
            fontWeight: 'bold',
        }}>
                <Moment fromNow unix>{properties.trial.end}</Moment>
            </Text></Text>
        <View style={{
            marginLeft: 20,
            flexDirection: 'row',
        }}>
            <Text style={{
                color: 'rgb(255, 255, 255)',
                fontSize: 14,
                fontWeight: '400',
            }}><Moment format="DD/MM/YYYY" unix>{properties.trial.start}</Moment></Text>
            <Text style={{
                color: 'rgba(255, 255, 255,0.4)',
                fontSize: 14,
                fontWeight: 'bold',
            }}> - </Text>
            <Text style={{
                color: 'rgb(255, 255, 255)',
                fontSize: 14,
                fontWeight: '400',
            }}><Moment format="DD/MM/YYYY" unix>{properties.trial.end}</Moment></Text>
        </View>
    </View>

}