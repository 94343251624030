import { NativeStackNavigationOptions } from "@react-navigation/native-stack"

export function modalOptionsHook() {
    const navigatorOptions: NativeStackNavigationOptions = {
        headerShown: false,
    }
    const modalOptions: {
        presentation: "transparentModal",
        contentStyle: {
            backgroundColor: string
        }

    } = {
        presentation: "transparentModal",
        contentStyle: {
            backgroundColor: 'rgba(0,0,0,0)'
        }

    }
    return { modalOptions, navigatorOptions }
}