import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ServiceRoutes } from "../service.routes";
import { useState } from "react";

export function NewServiceScreen() {
    const navigation = useNavigation<NavigationProp<ServiceRoutes>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'new'>>()
    const [priceType, setType] = useState<'EXACT' | 'MINUTE'>('MINUTE')

    const inputPrice = priceType === 'EXACT' ?
        [{
            name: 'exactPrice',
            type: 'price',
            groupId: route.params.groupId
        }]
        :
        [{
            name: 'minimumPrice',
            type: 'price',
            groupId: route.params.groupId
        }, {
            name: 'minutePrice',
            type: 'priceMinute',
            groupId: route.params.groupId
        }]

    return <PostModule<{
        name: string,
        color: string;
        description: string;
        notification: boolean;
        notificationMessage: string;
        minimumPrice: number;
        exactPrice: number;
        minutePrice: number;
        bookingOnline: boolean;
        depositPercentage: number;
        type: 'EXACT' | 'MINUTE'
    }>
        prefix="newService"
        path="service"
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        data={{
            type: 'MINUTE',
            description: '',
            minutePrice: 0,
            exactPrice: 0,
            minimumPrice: 0,
            depositPercentage: 20,
            notification: false,
            notificationMessage: '',
            bookingOnline: false,
        }}
        inputs={[
            {
                name: 'type',
                type: 'select',
                options: ['EXACT', 'MINUTE'],
                onSelect: (value) => {
                    setType(value as any)

                }
            },

            ...inputPrice,



            {
                name: 'depositPercentage',
                type: 'number',
            },
            {
                name: 'name',
                type: 'text'
            },
            {
                name: 'color',
                type: 'color'
            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                name: 'notification',
                type: 'checkbox'
            },
            {
                name: 'notificationMessage',
                type: 'text',
                lines: 5
            },

            {
                name: 'bookingOnline',
                type: 'checkbox'
            },

        ]}
    />
}