import { useNavigation } from "@react-navigation/native";
import { Fragment, useEffect, useRef, useState } from "react";
import { FlatList, Modal, Platform, Text, TouchableOpacity, View } from "react-native";
import { ButtonComponent } from "../button/button.component";
import { InputHeader } from "../input/components/inputHeader/inputHeader.component";
import { LineComponent } from "../line/line.component";
import { SearchComponent } from "../search/search.component";
import { SectionComponent } from "../section/section.component";
import * as FileSystem from 'expo-file-system';
import LoaderComponent from "@components/loader/loader.component";
import { SpaceComponent } from "@components/space/space.component";
import { CenterComponent } from "@components/center/center.component";
import { useWeb } from "../../hook/web.hook";
import { useTranslation } from "react-i18next";

export function SelectComponent(properties: {
    value?: string,
    getLabel?: (id: any) => Promise<string | undefined>,
    search?: boolean,
    mode?: 'button' | 'selector'
    modalTitle?: string,
    onSelect: (value: any) => void,
    getItems: (page: number, search?: string) => Promise<{
        id: string
        name: string
    }[]>,
    title: string
}) {
    const { t } = useTranslation()
    const searchSystem = properties.search === false ? false : true
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState<string>('')
    const [items, setItems] = useState<{
        id: string,
        name: string
    }[]>([])
    const loadItems = () => {
        setLoading(true);
        properties.getItems(0, search).then((newItems) => {

            setItems(newItems)
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        loadItems()
    }, [search])

    const getLabel = async (id: string) => {
        if (properties.getLabel) {
            const label = await properties.getLabel(id)
            label && setLabel(label)
        }
    }

    useEffect(() => {
        if (!properties.value) return
        if (properties.getLabel) {

            getLabel(properties.value)
        }

        else {
            const currentLabel = items.find((item) => item.id === properties.value)?.name
            setLabel(currentLabel || '')
        }


    }, [properties.value, items])
    const isWeb = useWeb()
    return <View style={{ backgroundColor: '#fff' }}>
        {
            properties.mode && properties.mode === 'button' ? <ButtonComponent
                onPress={() => {
                    setOpen(true);
                }}
                label={properties.title}
                detail={{
                    label: label
                }}
            /> : <TouchableOpacity
                style={{
                    height: 65,
                    borderRadius: 0,

                    backgroundColor: '#fff',
                    borderColor: '#ddd',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}
                onPress={() => {
                    setOpen(true);
                }
                }>
                <InputHeader
                    title={properties.title}
                    focused={(label ? true : false)}
                />
                <Text numberOfLines={1} style={{
                    flex: 1,
                    borderRadius: 10,
                    fontSize: 20,
                    fontWeight: '500',
                    paddingLeft: 15,
                }}>{label}</Text>
            </TouchableOpacity>}

        {
            open && <Modal
                style={{
                    width: 500,
                }}
                transparent={isWeb}
                animationType={
                    Platform.OS === 'web' ? 'none' : 'slide'
                } presentationStyle="pageSheet"

            >
                <CenterComponent>
                    <SectionComponent title={properties.modalTitle || t('SELECT')} onClose={() => {
                        setOpen(false);
                    }}
                        style={{
                            content: {
                                backgroundColor: '#eee'
                            }
                        }}
                        withoutScroll
                    >
                        {
                            loading && <LoaderComponent />
                        }
                        {
                            searchSystem === true ? <Fragment>

                                <LineComponent />

                                <SearchComponent


                                    onChangeText={(text) => {
                                        setSearch(text)
                                    }} />
                            </Fragment> : <><LineComponent /><SpaceComponent /></>}
                        <LineComponent />
                        <FlatList
                            key={search}
                            initialNumToRender={100}
                            data={items}
                            keyExtractor={(item) => item.id}
                            onEndReached={(event) => {
                                if (event.distanceFromEnd > 0) {
                                    if (!loading) {
                                        loadItems()
                                    }
                                }

                            }
                            }
                            renderItem={({ item }) => {
                                return <Fragment key={item.id}>
                                    <ButtonComponent
                                        onPress={() => {
                                            properties.onSelect(item)
                                            setOpen(false)
                                        }}
                                        label={item.name} />
                                    <LineComponent />
                                </Fragment>
                            }}
                        />
                        <LineComponent />
                    </SectionComponent>
                </CenterComponent>
            </Modal>
        }
    </View>
}

export function ModalContent() {
    return <TouchableOpacity onPress={() => {
    }
    }>
        <Text>asd</Text>

    </TouchableOpacity>
}