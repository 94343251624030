import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListConsentScreen } from "./list/list.consent.screen";
import { EditConsentScreen } from "./edit/edit.consent.screen";
import { DeleteConsentScreen } from "./delete/delete.consent.screen";
import { NewConsentScreen } from "./new/new.consent.screen";
import { ConsentRoutes } from "./consent.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewMemberScreen } from "../member/new/new.member.screen";
import { NewCustomerScreen } from "../customer/new/new.customer.screen";
import { SignatureConsentScreen } from "./signature/signature.consent.screen";
import { useTranslation } from "react-i18next";

const stack = createNativeStackNavigator<ConsentRoutes>();

export function ConsentNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'consents'>>()
    const groupId = route.params.groupId
    const { t } = useTranslation()
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListConsentScreen}
            initialParams={{ groupId }}

        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewConsentScreen}
        />
        <stack.Screen
            name="signature"
            initialParams={{ groupId }}
            options={modalOptions}
            component={SignatureConsentScreen}
        />
        <stack.Screen
            name="newCustomer"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewCustomerScreen}
        />

        <stack.Screen
            name="newMember"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewMemberScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditConsentScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteConsentScreen}
        />
    </stack.Navigator>
}
