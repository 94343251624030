// Modules
import { View } from 'react-native'

// Components
import { TextComponent } from '../../../text/text.component'

// Properties
import { type InputHeaderProperties } from './inputHeader.properties'

// Styles
import { InputHeaderStyles } from './inputHeader.styles'

/**
 * Input header component
 * @description Render the input title and description
 * @param {InputHeaderProperties} properties
 * @return {JSX.Element | null}
 */
export function InputHeader(properties: InputHeaderProperties): JSX.Element | null {
  if (!properties.title && !properties.description) {
    return null
  }

  return (
    <View style={[InputHeaderStyles.header,
    properties.focused ? InputHeaderStyles.headerFocused : InputHeaderStyles.headerUnfocused,
    ]}>
      {properties.title && <TextComponent styles={{
        layout: {
          minHeight: 20,
          marginTop: 15,
        },
        text: {
          ...InputHeaderStyles.title,
          ...properties.focused ? InputHeaderStyles.headerFocusedText : InputHeaderStyles.headerUnfocusedText
        }
      }}
      >{properties.title}</TextComponent>}

      {properties.description && (
        <TextComponent
          variant='h4'
          styles={{ text: InputHeaderStyles.description }}
        >
          {properties.description}
        </TextComponent>
      )}
    </View>
  )
}
