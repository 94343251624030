import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { TemplateRoutes } from "../template.routes";

export function ListTemplateScreen() {
    const navigation = useNavigation<NavigationProp<TemplateRoutes, 'list'>>()
    const route = useRoute<RouteProp<TemplateRoutes, 'list'>>()
    return <TableComponent<{
        id: string
        name: string
        createdAt: string
    }>
        prefix="listTemplate"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`template?groupId=${route.params.groupId}`}
        columns={[
            {
                always: true,
                icon: 'pen',
                type: 'icon',
                menu: {
                    icon: 'pen',
                    label: 'edit'
                },
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        templateId: item.id
                    })
                }
            },
            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                width: 400,
                type: 'text',
            },

            {
                label: 'createConsent',
                width: 150,
                type: 'button',
                variant: 'primary',
                icon: 'plus',
                menu: {
                    icon: 'plus',
                    label: 'createConsent'
                },
                onPress: (item) => {
                    navigation.navigate('consent', {
                        groupId: route.params.groupId,
                        templateId: item.id
                    })
                }

            },
            {
                icon: 'trash-alt',
                type: 'icon',
                menu: {
                    icon: 'trash-alt',
                    label: 'delete'
                },
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        templateId: item.id
                    })
                }
            },
        ]}

    />

}
