import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { LogBox, Platform, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { RecoilRoot } from 'recoil';

import LoaderComponent from '@components/loader/loader.component';
import { MainScreens } from './src/screens/main.screens';
import { TranslationsService } from './src/translations/translations.service';
import { fontLoaderUtility } from './src/utilities/font/FontLoaderUtility';
import { toastConfig } from './toast.configuration';
LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs();//Ignore all log notifications
const Stack = createNativeStackNavigator();
if (Platform.OS === 'web') {
  // @ts-ignore
  window._frameTimestamp = null
}
export default function App() {
  // This state helps determine if the font has loaded successfully.
  const [isFontLoaded, setIsFontLoaded] = useState(false)
  const [firstScreen, setFirstScreen] = useState<string>()
  // This useEffect initializes the market with the test data and loads the source.
  useEffect(() => {
    fontLoaderUtility().then(() => setIsFontLoaded(true))
  }, [])

  if (!isFontLoaded) {
    return null
  }



  return (<Suspense fallback={<View style={{
    flex: 1,
  }}><LoaderComponent /></View>}>

    <View style={[{
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
    },]}>
      <I18nextProvider i18n={TranslationsService}>

        <View style={{
          zIndex: 1000,

        }}>
          <Toast config={toastConfig} topOffset={0} />
        </View>
        <RecoilRoot>
          <MainScreens />

        </RecoilRoot>

      </I18nextProvider>

    </View>
  </Suspense>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
