import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ScheduleRoutes } from "../schedule.routes";

export function EditScheduleScreen() {
    const navigation = useNavigation<NavigationProp<ScheduleRoutes>>()
    const route = useRoute<RouteProp<ScheduleRoutes, 'edit'>>()

    return <PutModule<{
        startHour: number;
        startMinute: number;
        endHour: number;
        endMinute: number;
        day: number;
    }>
        prefix="updateSchedule"
        path="schedule"
        id={route.params.scheduleId}
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'day',
                type: 'select',
                options: [
                    1, 2, 3, 4, 5, 6, 7
                ]
            },
            {
                nameHour: 'startHour',
                nameMinute: 'startMinute',
                type: 'time'
            },
            {
                nameHour: 'endHour',
                nameMinute: 'endMinute',
                type: 'time'
            }
        ]}
    />
}
