// Modules
import React, { useEffect, useState } from 'react'
import { WebView } from 'react-native-webview'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'

// Components
import { injectedExecuteNativeFunction } from './injectedJavaScript/executeNativeFunction'
import { injectedErrorHandler } from './injectedJavaScript/errorHandler'
import { injectedSignaturePad } from './injectedJavaScript/signaturePad'
import { injectedApplication } from './injectedJavaScript/application'

// Styles
import { SignatureStyles } from './signature.styles'

// Template
import { SignatureTemplate } from './signature.template'
import { KnobComponent } from '@components/knob/knob.component'
import { IconComponent } from '@components/icon/icon.component'

/**
 *  Signature Component
 * @function SignatureComponent
 * @description This component is used to render a signature pad.
 * @param {SignatureProperties} properties
 * @return {JSX.Element}
 */
export function SignatureComponent(properties: {
  defaultValue?: string
  onChange: (signature: string) => void
  eraser?: boolean
}) {
  // html generated with JS injection
  const [html, setHtml] = useState<string>()
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  // make the html injection in the first render
  useEffect(() => {
    // compile js to be injected
    const injectedJavaScript =
      injectedExecuteNativeFunction +
      injectedErrorHandler +
      injectedSignaturePad +
      injectedApplication('#000', '#fff', properties.defaultValue || '')

    // embed the js generated in the html
    setHtml(SignatureTemplate(injectedJavaScript))
  }, [properties.defaultValue])
  return (
    <View
      renderToHardwareTextureAndroid={true}
      style={[SignatureStyles.component, {
        backgroundColor: '#fff',
        borderRadius: 5,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {
          width: 0,
          height: 2,
        },
      }]}>
      {
        properties.eraser && <TouchableOpacity


          style={{
            position: 'absolute',
            left: 10,
            height: 40,
            width: 40,
            borderRadius: 20,
            bottom: 10,
            backgroundColor: '#663D3D',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            properties.onChange('')
          }} >
          <IconComponent name="eraser" />
        </TouchableOpacity>
      }
      {html && (
        Platform.OS === 'web' ? <iframe
          ref={iframeRef}
          style={{
            width: '100%',
            height: '100%',
            border: 0,

            backgroundColor: '#fff'
          }}
          id='ReactNativeWebView'
          name='ReactNativeWebView'
          onLoad={() => {
            setTimeout(() => {
              const ifmr = iframeRef?.current?.contentWindow
              if (ifmr) {
                ifmr.postMessage = (event) => {
                  var signature = JSON.parse(event);

                  properties.onChange(signature.base64DataUrl)
                }
              }
            }, 300);
          }}
          srcDoc={html}
        /> :
          <WebView
            nestedScrollEnabled={true}

            onMessage={(event) => {

              var signature = JSON.parse(event.nativeEvent.data);
              properties.onChange(signature.base64DataUrl)

            }}
            bounces={false}
            useWebKit={true}
            androidLayerType={'hardware'}
            containerStyle={{
              maxWidth: 320,
              width: '100%',
              height: 177,
              backgroundColor: 'red'
            }}
            style={[SignatureStyles.webView, {
              maxWidth: 320,
              width: '100%',
              height: 177,
            }]}
            source={{
              html,
            }}
          />
      )}
    </View>
  )
}
