import { RouteProp, useIsFocused, useRoute } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity } from "react-native";
import { View } from "react-native";
import { StudioRoutes } from "../../studio.routes";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { useState } from "react";
import { Environment } from "@environment";
import axios from "axios";
import { useWidth } from "../../../../hook/isWidth";
const video = require("../../../../../assets/artist.mp4");

export function HeaderToPayComponent(properties: {
  mode: "month" | "year";
  getUrlStripe: () => void;
  hideLink?: boolean;
  setMode: (mode: "month" | "year") => void;
}) {
  const width = useWidth();

  const { t } = useTranslation();
  return (
    <View
      style={{
        height: width.tablet ? 350 : undefined,
        paddingBottom: width.tablet ? 0 : 20,
        overflow: "hidden",
        zIndex: 1,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          backgroundColor: "black",
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          opacity: 0.7,
        }}
      />
      <Text
        style={{
          color: "white",
          fontSize: width.tablet ? 35 : 22,
          fontWeight: "600",
          zIndex: 1,
          paddingLeft: 50,
        }}
      >
        {t(`subscription.title`)}
      </Text>
      <Text
        style={{
          color: "white",
          fontSize: width.tablet ? 18 : 14,
          maxWidth: 350,
          fontWeight: "200",
          zIndex: 1,
          paddingLeft: 50,
          paddingBottom: 30,
        }}
      >
        {t(`subscription.subtitle`)}
      </Text>

      <>
        <View
          style={{
            flexDirection: "row",
            marginLeft: 50,
            gap: 10,
            marginBottom: 10,
          }}
        >
          <TouchableOpacity
            style={{
              padding: 8,

              paddingVertical: 5,
              alignSelf: "flex-start",
              backgroundColor:
                properties.mode === "month"
                  ? "#65c366"
                  : "rgba(255, 255, 255,0.3)",
              zIndex: 1,
              borderRadius: 30,
            }}
            onPress={() => {
              properties.setMode("month");
            }}
          >
            <Text
              style={{
                color: properties.mode === "month" ? "white" : "#fff",
                fontSize: 12,
                textTransform: "uppercase",
                fontWeight: properties.mode === "month" ? "bold" : "500",
              }}
            >
              {t(`subscription.month`)}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              padding: 8,

              paddingVertical: 5,
              alignSelf: "flex-start",
              backgroundColor:
                properties.mode === "year"
                  ? "#65c366"
                  : "rgba(255, 255, 255,0.3)",
              zIndex: 1,
              borderRadius: 30,
            }}
            onPress={() => {
              properties.setMode("year");
            }}
          >
            <Text
              style={{
                color: properties.mode === "year" ? "white" : "#fff",
                fontSize: 12,
                textTransform: "uppercase",

                fontWeight: properties.mode === "year" ? "bold" : "500",
              }}
            >
              {t(`subscription.year`)}
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            flexDirection: "row",
            paddingLeft: 50,
          }}
        >
          <View>
            <View
              style={{
                height: 2,
                backgroundColor: "red",
                zIndex: 1,
                position: "absolute",
                transform: "rotate(5deg)",
                top: "50%",
                left: -10,
                opacity: 0.4,
                right: -10,
              }}
            />
            <Text
              style={{
                color: "red",
                fontSize: 40,
                fontWeight: "200",
                zIndex: 1,
              }}
            >
              {t(`subscription.priceOld_${properties.mode}`)}
            </Text>
          </View>
          <Text
            style={{
              paddingLeft: 20,
              color: "white",
              fontSize: 40,
              fontWeight: "600",
              zIndex: 1,
            }}
          >
            {t(`subscription.priceBase_${properties.mode}`)}{" "}
            {t(`subscription.currency`)}
          </Text>
        </View>
      </>
      <Text
        style={{
          color: "white",
          fontSize: 14,
          fontWeight: "600",
          zIndex: 1,
          paddingLeft: 50,
          opacity: 0.7,
        }}
      >
        {t(`subscription.per_${properties.mode}`)}
      </Text>
      {properties.hideLink ? (
        <></>
      ) : (
        <View style={{}}>
          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
              gap: 1,
              backgroundColor: "rgba(0,0,0,0.6)",
              marginRight: "auto",
              marginLeft: 50,
              overflow: "hidden",
              borderRadius: 30,
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: "#65c366",
                padding: 15,
                borderRadius: 30,
                paddingVertical: 10,
                alignSelf: "flex-start",

                zIndex: 1,
              }}
              onPress={() => {
                properties.getUrlStripe();
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 18,
                  fontWeight: "400",
                }}
              >
                {t(`subscription.button.start`)}
                <Text
                  style={{
                    marginLeft: 5,
                    color: "white",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {t(`subscription.button.finish`)}
                </Text>
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      <video
        style={{
          zIndex: -1,
          height: 400,
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          objectFit: "cover",
        }}
        src={video}
        autoPlay
        loop
        muted
      />
    </View>
  );
}
