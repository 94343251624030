// Modules
import { View, Text } from 'react-native'

// Styles
import { CountStyles } from './count.styles'

// Properties
import { type CountProperties } from './count.properties'

/**
 * Count Component
 * @description Render a badge counter inside input field
 * @param {CountProperties} properties
 * @return {JSX.Element | null}
 */
export function CountComponent(properties: CountProperties): JSX.Element | null {
  // Parse badgeCount property from string to float
  const badgeCountParsed = Number.parseFloat(
    properties.badgeCount ? properties.badgeCount : '',
  )

  /**
   * If the badgeCountParsed variable is not a number, this variable will store true.
   * Otherwise will be false because is a valid number.
   */
  const isNaN = Number.isNaN(badgeCountParsed)

  // Render nothing if the badgeCount property is not a number (NaN).
  if (isNaN) {
    return null
  }

  return (
    <View style={CountStyles.container}>
      <Text style={CountStyles.text}>{properties.badgeCount}</Text>
    </View>
  )
}
