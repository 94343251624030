// Modules
import { TouchableOpacity, View } from 'react-native'

// Components
import { RawIconComponent } from './rawIconComponent'

// Properties
import { type IconProperties } from './icon.properties'

/**
 * Icon Component
 * @description Component to render an icon could be clickable or not. You can see list of icons here: https://icons8.com/line-awesome
 * @function IconComponent
 * @param {IconProperties} properties
 * @return {JSX.Element}
 */
export function IconComponent(properties: IconProperties): JSX.Element {
  const iconRender = (
    <RawIconComponent
      name={properties.name}
      style={properties.style}
    />
  )

  // Handle a clickable icon if its needed
  if (properties.onPress != null) {
    return (
      <TouchableOpacity
        style={properties.style?.box}
        onPress={properties.onPress}
        testID={properties.testID}
      >
        {iconRender}
      </TouchableOpacity>
    )
  }

  return (
    <View
      style={properties.style?.box}
      testID={properties.testID}
    >
      {iconRender}
    </View>
  )
}
