// Modules
import { View, Text as TextNative } from 'react-native'

// Properties
import { type TextProperties } from './text.properties'

// Styles
import { FontTextStyles, LayoutTextStyles, TextStyles } from './text.styles'

/**
 * Text Component
 * @description Component to render text with different variants
 * @param {TextProperties} properties
 * @return {JSX.Element}
 */
export function TextComponent(properties: TextProperties): JSX.Element {
  const styles = [
    TextStyles.component,
    properties.variant && LayoutTextStyles[properties.variant],
    properties.styles?.layout,
  ]

  return (
    <View style={[styles]}>
      <TextNative
        testID={properties.testID ?? 'text'}
        style={[
          TextStyles.text,
          properties.variant && FontTextStyles[properties.variant],
          properties.bold && TextStyles.bold,
          properties.inverted && TextStyles.inverted,
          properties.styles?.text,
          properties.lineHeight ? { lineHeight: properties.lineHeight } : {},
          { textAlign: properties.textAlign },
          properties.color !== undefined && { color: properties.color },
        ]}
        onPress={properties.onPress}
        numberOfLines={properties.numberOfLines}

      >
        {properties.children ?? properties.content?.toString?.()}
      </TextNative>

      {properties.variant === 'h1' && <View style={TextStyles.bar} />}
    </View>
  )
}
