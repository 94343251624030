import React, { useEffect, useState } from 'react';
import { Text, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { ChooseInputProperties } from './choose.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import axios from 'axios';
import { Environment } from '@environment';
import { useIsFocused } from '@react-navigation/native';
import LoaderComponent from '@components/loader/loader.component';
import { IconComponent } from '@components/icon/icon.component';
import { membersSelectedState } from '@states/memberSelected.state';
import { roomSelectedState } from '@states/roomSelected.state';
import { EmptyComponent } from '@components/empty/empty.component';

export function ChooseInputForm<T>(properties: ChooseInputProperties<T>) {
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState)
    const [search, setSearch] = useState('')
    const [rows, setRows] = useState<any[]>([])
    const isFocus = useIsFocused()
    const [noResults, setNoResults] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [labelSelected, setLabelSelected] = useState<string>()
    const [showFinder, setShowFinder] = useState(false)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)

    const getRows = async (search?: string) => {
        if (!user) return
        setLoading(true)
        let queryString = `groupId=${properties.groupId}${search ? `&search=${search}` : ''}`
        if (properties.data && properties.params) {
            properties.params.forEach(param => {
                if (properties?.data?.[param]) queryString += `&${param}=${properties.data[param]}`
            })
        }
        setRows([])

        axios.get(`${Environment.api}/${properties.path}?limit=10&page=${page}&${queryString}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const items = response.data.items
            const total = response.data.total
            setTotal(total)
            setRows(items)
            setLoading(false)
            if (items.length === 0) setNoResults(true)
            else setNoResults(false)
        })
    }

    useEffect(() => {
        showFinder && getRows(search)
    }, [isFocus, showFinder, properties.data, page])

    useEffect(() => {
        setPage(0)
        const timeout = setTimeout(() => {
            getRows(search)
        }
            , 500)
        return () => {
            clearTimeout(timeout)
        }
    }
        , [search])




    const getLabel = (id: string) => {
        if (!user) return
        setLoadingLabel(true)
        axios.get(`${Environment.api}/${properties.path}?groupId=${properties.groupId}&${properties.path}Id=${id}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const item = response.data?.items?.[0]
            if (item) {
                if (properties.onSelectBackground) {
                    properties.onSelectBackground(item)
                }
                setLabelSelected(properties.renderLabel ? properties.renderLabel(item) : item.name)
            }
            else undefined
        }).finally(() => {
            setLoadingLabel(false)
        })
    }

    useEffect(() => {
        if (properties.value) {
            getLabel(properties.value)
        }
    }, [properties.value])


    function onNewFull(data: any) {

        if ('id' in data) {

            properties.onChange?.(data.id)
            setShowFinder(false)
        }
        else {
            // is uuid?
            if (data.length === 36) {

                properties.onChange?.(data)
                setShowFinder(false)
            }
        }
    }

    return <View style={{
        borderWidth: 1,
        borderColor: properties.invert ? 'rgba(255,255,255,0.1)' : '#ddd',
        padding: 0,
        borderRadius: 5,
    }}>
        {
            loadingLabel && <LoaderComponent />
        }
        {
            labelSelected ? <TouchableOpacity onPress={() => {
                setShowFinder(!showFinder)
            }} style={{
                padding: 10,

            }}>
                <TextComponent styles={{
                    text: {
                        color: properties.invert ? 'rgba(255,255,255,0.7)' : '#000',
                    }
                }}>{labelSelected}</TextComponent>
                <IconComponent name="angle-down" style={{
                    box: {
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    },
                    icon: {
                        color: properties.invert ? 'rgba(255,255,255,0.8)' : '#000',
                        fontSize: 20,
                    }
                }} />
            </TouchableOpacity> : <TouchableOpacity onPress={() => {
                setShowFinder(!showFinder)
            }} style={{
                padding: 10,
                paddingVertical: 7,
            }}>
                <TextComponent styles={{
                    text: {
                        color: properties.invert ? 'rgba(255,255,255,0.8)' : '#000',
                    }
                }}>{
                        t('choose.select')
                    }</TextComponent>
                <IconComponent name="angle-down" style={{
                    box: {
                        position: 'absolute',
                        right: 20,
                        top: 0,
                        bottom: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    icon: {
                        color: properties.invert ? '#666' : '#000',

                        fontSize: 16,
                    }
                }} />
            </TouchableOpacity>
        }
        {
            properties.onNew && <TouchableOpacity onPress={() => {
                properties.onNew?.(onNewFull)
            }} style={{
                backgroundColor: '#ddd',
                padding: 5,
                paddingHorizontal: 10,
                borderRadius: 5,
                position: 'absolute',
                top: -37,
                right: 0,
            }}>
                <TextComponent styles={{
                    text: {
                        fontSize: 11,
                        lineHeight: 16,
                        fontWeight: 'bold',
                    }
                }}>{t('choose.new')}</TextComponent>
            </TouchableOpacity>
        }
        {showFinder && <>

            <View>
                <IconComponent name='search' style={{
                    box: {
                        position: 'absolute',
                        top: 4,
                        left: 7,
                        height: 30,
                        width: 30,
                        borderRadius: 35,
                        justifyContent: 'center',
                        alignItems: 'center',

                    },
                    icon: {
                        fontSize: 20,
                        color: '#333',

                    }
                }}
                />

                <TextInputNative
                    style={{
                        borderTopColor: '#ddd',
                        borderTopWidth: 1,
                        borderWidth: 0,
                        borderColor: '#ddd',
                        padding: 10,
                        paddingLeft: 50,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderRadius: 0,
                    }}
                    placeholder={t(`search`)}
                    value={search}
                    onSubmitEditing={() => {
                        getRows(search)
                    }}
                    onChangeText={(value) => {
                        setSearch(value);
                    }}
                />
            </View>
            {
                noResults && <EmptyComponent />
            }
            {
                loading && <LoaderComponent />
            }
            {
                rows.map((row, index) => {
                    return <TouchableOpacity key={index} onPress={() => {
                        if (properties.onSelect) {
                            properties.onSelect?.(row)
                        }
                        else {
                            if (properties.onSelectBackground) {
                                properties.onSelectBackground(row)
                            }
                            if (properties.transformAll && properties.chooseOnChangeAll) {
                                const allValue = properties.transformAll(row)
                                properties.chooseOnChangeAll(allValue)
                            }


                            else properties.onChange?.(row.id)

                        }
                        setShowFinder(false)
                    }} style={{
                        padding: 10,
                        paddingVertical: 6,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        backgroundColor: '#f5f5f5',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        marginBottom: 5,
                    }}>
                        <TextComponent styles={{
                            text: {
                                fontWeight: 'bold'
                            }
                        }}>{properties.renderLabel ? properties.renderLabel(row) : row.name}</TextComponent>
                        {
                            properties.renderDetail && <TextComponent styles={{
                                text: {
                                    fontSize: 12,
                                    fontWeight: '200',
                                    color: '#666'
                                }
                            }}>{properties.renderDetail(row)}</TextComponent>
                        }
                    </TouchableOpacity>
                })
            }
        </>}

        {
            showFinder && !loading && rows.length > 0 && <View style={{
                padding: 10,
                flexDirection: 'row',
            }}>
                {
                    page > 0 && <TouchableOpacity onPress={() => {
                        setPage(page - 1)
                    }
                    } style={{
                        padding: 10,
                        backgroundColor: '#000',
                        borderRadius: 5,
                        marginBottom: 5,
                        height: 30,
                        width: 30,
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <IconComponent name="angle-left" style={{
                            box: {
                                marginRight: 5,
                            },
                            icon: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }} />
                    </TouchableOpacity>
                }
                {
                    rows.length > 0 && total / 10 > page + 1 && <TouchableOpacity onPress={() => {
                        setPage(page + 1)
                    }
                    } style={{
                        padding: 10,

                        marginLeft: 'auto',
                        backgroundColor: '#000',
                        borderRadius: 5,
                        marginBottom: 5,
                        height: 30,
                        width: 30,
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <IconComponent name="angle-right" style={{
                            box: {
                                marginRight: 5,
                            },
                            icon: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }} />
                    </TouchableOpacity>
                }

            </View>
        }
    </View >
}