import { DockComponent } from "@components/dock/dock.component";
import { IconComponent } from "@components/icon/icon.component";
import { IconType } from "@components/icon/icon.types";
import { Environment } from "@environment";
import {
  DrawerContentComponentProps,
  DrawerNavigationProp,
} from "@react-navigation/drawer";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { membersState } from "@states/members.state";
import { userState } from "@states/user.state";
import { useEffect, useState } from "react";
import {
  Image,
  Linking,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useRecoilState } from "recoil";
import { useWeb } from "../hook/web.hook";
import { MainRoutes } from "./main.routes";
import { MainSelector } from "./main.selector";
import { RoomRoutes } from "./studio/room/room.routes";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ChatComponent } from "@components/chat/chat.component";
import { HelpMain } from "./main.help";
import { InvitationsScreen } from "./invitations/invitations.screen";
import { counterState } from "@states/counter.state";
import { usePermissionsHook } from "../hooks/usePermissions.hook";
import { TextComponent } from "@components/text/text.component";
import { set } from "react-native-reanimated";
type ProfileScreenNavigationProp = CompositeNavigationProp<
  DrawerNavigationProp<MainRoutes, "studio">,
  NativeStackNavigationProp<RoomRoutes>
>;
export function MainMenu(props: DrawerContentComponentProps) {
  const [menuType, setMenuType] = useState<"studio" | "profile">("studio");
  const navigation = useNavigation<ProfileScreenNavigationProp>();
  const isWeb = useWeb();
  const [reloadGroups, setReloadGroups] = useState<string>();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [membersSelected, setMembersSelected] = useRecoilState(membersState);
  const path = props.state.routeNames[props.state.index];
  const [filterByPath, setFilterByPath] = useState(true);
  const route = useRoute<RouteProp<MainRoutes, "studio">>();
  const haveGroupId = route.params?.groupId;
  const [showInvitations, setShowInvitations] = useState(false);
  const { permissions } = usePermissionsHook(haveGroupId);
  const [haveSubscription, setHaveSubscription] = useState<boolean>(true);
  const [reloadDock, setReloadDock] = useState<string>();
  const [members, setMembers] = useState<
    {
      id: string;
      title: string;
      color: string;
    }[]
  >([]);
  const [groups, setGroups] = useState<
    {
      id: string;
      name: string;
      username: string;
    }[]
  >([]);
  const [counter, setCounter] = useRecoilState(counterState);

  const [counters, setCounters] = useState<{
    Customers: number;
    Materials: number;
    Products: number;
    Receipts: number;
    Services: number;
    Templates: number;
    Consents: number;
    Meets: number;
    Bookings: number;
    Members: number;
    Schedules: number;
    Rooms: number;
  }>({
    Customers: 0,
    Materials: 0,
    Products: 0,
    Receipts: 0,
    Services: 0,
    Templates: 0,
    Consents: 0,
    Meets: 0,
    Bookings: 0,
    Members: 0,
    Schedules: 0,
    Rooms: 0,
  });
  useEffect(() => {
    if (!user) return;
    axios
      .get(Environment.api + "/group?component=MainMenu", {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      .then((response) => {
        const groups = response.data.items;
        setGroups(groups);
      })
      .catch((error) => {
        alert("We are undergoing maintenance, please check in later.");
      });
  }, [user]);
  useEffect(() => {
    if (!user) return;
    if (!route?.params?.groupId) return;
    fetch(
      `${Environment.api}/group/counter?groupId=${route.params.groupId}&component=MainMenu`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data) return;
        if (data?.items?.[0]?.subscriptionId) {
          setHaveSubscription(true);
        } else setHaveSubscription(false);
        if (data?.items?.[0]?._count) {
          setCounters(data.items[0]._count);
        }
      });
  }, [route?.params?.groupId, counter]);

  const getMembers = async () => {
    if (!user) return;
    if (!route?.params?.groupId) return;

    // route contain calendar
    const response = await fetch(
      `${Environment.api}/member?groupId=${route.params.groupId}&component=menu`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    const data = await response.json();
    const members = data.items.map((member: any) => {
      return {
        id: member.id,
        title: `${member.User.name} ${member.User.surname}`,
        color: member.color,
      };
    });
    setMembers(members);
  };

  useEffect(() => {
    getMembers();
  }, [menuType]);

  useEffect(() => {
    // check path user
    if (path === "images" || path === "profile" || path === "password" || path === "settings") {
      setMenuType("profile");
    }
  }, [path]);

  const currentGroup =
    route.params?.groupId && groups
      ? groups.find((group) => group.id === route.params?.groupId)
      : undefined;

  return (
    <>
      {showInvitations && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: "100%",
            width: 400,
            bottom: 0,
            backgroundColor: "#eee",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 23.84,
            elevation: 5,
            flex: 1,
          }}
        >
          <InvitationsScreen
            reloadGroups={() => {
              setReloadDock(new Date().getTime().toString());
              setReloadGroups(new Date().getTime().toString());
            }}
            setShowInvitations={() => {
              setShowInvitations(!showInvitations);
            }}
          />
        </View>
      )}
      {showHelp && (
        <ScrollView
          style={{
            position: "absolute",
            top: 0,
            left: "100%",
            width: 400,
            bottom: 0,
            backgroundColor: "#eee",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 23.84,
            elevation: 5,
          }}
        >
          <IconComponent
            name="times"
            onPress={() => {
              setShowHelp(!showHelp);
            }}
            style={{
              box: {
                position: "absolute",
                top: 28,
                right: 20,
                zIndex: 100,
                opacity: 0.5,
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 10,
              },
              icon: {
                fontSize: 20,
                color: "#000",
                textAlign: "right",
              },
            }}
          />
          <Text
            style={{
              padding: 20,
              paddingBottom: 0,
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {t("menu.help")}
          </Text>
          <TextInput
            style={{
              padding: 10,
              margin: 20,
              marginTop: 10,
              marginBottom: 0,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.1)",
            }}
            value={search}
            onChangeText={(text) => {
              setSearch(text);
            }}
            placeholder="Search"
          />
          {path && filterByPath && (
            <TouchableOpacity
              onPress={() => {
                setFilterByPath(false);
              }}
              style={{
                padding: 20,
                borderBottomColor: "rgba(0,0,0,0.1)",
                borderBottomWidth: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {t("menu.all")}
              </Text>
            </TouchableOpacity>
          )}
          {HelpMain.map((help) => {
            if (path !== help.section && filterByPath) return null;
            return (
              <View
                key={help.prefix}
                style={{
                  padding: 20,
                  borderBottomColor: "rgba(0,0,0,0.1)",
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {t(`${help.section}.${help.prefix}.title`)}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "100",
                    opacity: 0.5,
                  }}
                >
                  {t(`${help.section}.${help.prefix}.subtitle`)}
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    marginTop: 10,
                    fontWeight: "normal",
                  }}
                >
                  {t(`${help.section}.${help.prefix}.body`)}
                </Text>
                <Text
                  style={{
                    fontSize: 11,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    padding: 5,
                    borderRadius: 5,
                    paddingHorizontal: 8,
                    alignSelf: "flex-start",
                    marginTop: 10,
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {help.section}
                </Text>
              </View>
            );
          })}
        </ScrollView>
      )}
      <View
        style={{
          borderRightWidth: 1,

          flex: 1,
          overflow: "hidden",
          borderRightColor: "#ddd",
          backgroundColor: "#fff",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: 80,
            zIndex: 100,
            paddingTop: 10,
            backgroundColor: "#101928",
          }}
        >
          <DockComponent
            groups={groups}
            key={reloadDock}
            setMenuType={setMenuType}
            setShowInvitations={() => {
              setShowInvitations(!showInvitations);
            }}
          />
        </View>
        <View style={{ flex: 1, padding: 0, backgroundColor: "#fff" }}>
          <IconComponent
            name="question-circle"
            onPress={() => {
              setShowHelp(!showHelp);
            }}
            style={{
              box: {
                display: "none",
                position: "absolute",
                top: 13,
                right: 15,
                zIndex: 1222200,
                opacity: 0.5,
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 10,
              },
              icon: {
                fontSize: 20,
                color: "#000",
                textAlign: "right",
              },
            }}
          />

          <ScrollView stickyHeaderIndices={[0]} stickyHeaderHiddenOnScroll>
            <View
              style={{
                height: 60,
              }}
            >
              <View
                style={{
                  paddingTop: 20,
                  marginTop: Platform.OS === "web" ? 0 : 50,
                  paddingBottom: 20,
                  paddingLeft: 5,
                  zIndex: 3100,
                  shadowColor: "#000",
                  borderBottomColor: "#ddd",
                  borderBottomWidth: 1,
                  position: "relative",
                  backgroundColor: "#fff",
                }}
              >
                <Image
                  source={require("../../assets/logo.png")}
                  style={{
                    height: 20,
                    width: 180,
                    backgroundColor: "transparent",
                  }}
                  resizeMode="contain"
                />
              </View>
            </View>
            <View
              style={{
                zIndex: -10,
                paddingBottom: 20,
              }}
            >
              {menuType === "studio" ? (
                <>
                  <MainSelector key={reloadGroups} />
                  {haveGroupId && (
                    <>
                      <Separator label={t("menu.main")} />
                      {permissions.administrator && (
                        <>
                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.dashboard")}
                            icon="tachometer-alt"
                            path="dashboard"
                            currentPath={path}
                          />
                        </>
                      )}

                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.calendar")}
                        icon="calendar"
                        path="calendar"
                        onPress={() => {
                          navigation.navigate("studio", {
                            groupId: route.params.groupId,
                            screen: "calendar",
                          });
                        }}
                        currentPath={path}
                      />
                      {(permissions.meeterAll || permissions.availabilityView) &&
                        path === "calendar" &&
                        members.length > 0 && (
                          <View
                            style={{
                              marginVertical: 5,
                            }}
                          >
                            {members.map((member) => {
                              const isSelected =
                                membersSelected.includes(member.id) ||
                                membersSelected.length === 0;

                              return (
                                <TouchableOpacity
                                  key={member.id}
                                  onPress={() => {
                                    if (membersSelected.includes(member.id)) {
                                      setMembersSelected(
                                        membersSelected.filter(
                                          (id) => id !== member.id
                                        )
                                      );
                                      return;
                                    }
                                    setMembersSelected([
                                      ...membersSelected,
                                      member.id,
                                    ]);
                                  }}
                                  style={{
                                    padding: 4,
                                    paddingLeft: 25,
                                    paddingBottom: 4,
                                    flexDirection: "row",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: 22,
                                      height: 22,
                                      borderRadius: 5,
                                      borderWidth: 2,
                                      backgroundColor: isSelected
                                        ? member.color
                                        : "transparent",
                                      borderColor: member.color,
                                      marginRight: 10,
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    <IconComponent
                                      style={{
                                        icon: {
                                          fontSize: 12,
                                          color: "#fff",
                                          lineHeight: 10,
                                          textAlign: "center",
                                        },
                                      }}
                                      name={isSelected ? "check" : "times"}
                                    />
                                  </View>
                                  <Text
                                    style={{
                                      fontSize: 12,
                                      opacity: 0.5,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {member.title}
                                  </Text>
                                </TouchableOpacity>
                              );
                            })}
                          </View>
                        )}
                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.meets")}
                        icon="stopwatch"
                        path="meets"
                        currentPath={path}
                        count={counters.Meets}
                        onPress={() => {
                          setFilterByPath(true);
                          navigation.navigate("studio", {
                            groupId: route.params.groupId,
                            screen: "meets",
                            params: {
                              screen: "list",
                            },
                          });
                        }}
                      />

                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.bookings")}
                        icon="comments-dollar"
                        path="bookings"
                        currentPath={path}
                        count={counters.Bookings}
                      />
                      {currentGroup && (
                        <MenuButton
                          isWeb={isWeb}
                          finish
                          label={t("menu.bookingOnline")}
                          icon="share"
                          onPress={() => {
                            Linking.openURL(
                              `https://new.tattoo/${currentGroup?.username}`
                            );
                          }}
                          currentPath={path}
                        />
                      )}
                      <Separator label={t("menu.directory")} />

                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.customers")}
                        icon="user-tag"
                        path="customers"
                        currentPath={path}
                        count={counters.Customers}
                        onPress={() => {
                          navigation.navigate("studio", {
                            groupId: route.params.groupId,
                            screen: "customers",
                            params: {
                              screen: "list",
                            },
                          });
                        }}
                      />
                      <Separator label={t("menu.documents")} />

                      <MenuButton
                        isWeb={isWeb}
                        label={t("menu.consents")}
                        icon="file-signature"
                        path="consents"
                        currentPath={path}
                        count={counters.Consents}
                        onPress={() => {
                          navigation.navigate("studio", {
                            groupId: route.params.groupId,
                            screen: "consents",
                            params: {
                              screen: "list",
                            },
                          });
                        }}
                      />
                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.templates")}
                        icon="file-alt"
                        path="templates"
                        currentPath={path}
                        count={counters.Templates}
                      />
                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.materials")}
                        icon="spray-can"
                        path="materials"
                        currentPath={path}
                        count={counters.Materials}
                      />
                      <Separator label={t("menu.shop")} />

                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.receipts")}
                        icon="receipt"
                        path="receipts"
                        currentPath={path}
                        count={counters.Receipts}
                        onPress={() => {
                          setFilterByPath(true);
                          navigation.navigate("studio", {
                            groupId: route.params.groupId,
                            screen: "receipts",
                            params: {
                              screen: "list",
                            },
                          });
                        }}
                      />
                      <MenuButton
                        isWeb={isWeb}
                        finish
                        label={t("menu.products")}
                        icon="tshirt"
                        path="products"
                        currentPath={path}
                        count={counters.Products}
                      />
                      {permissions.administrator && (
                        <>
                          <Separator label={t("menu.configuration")} />
                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.subscription")}
                            icon="credit-card"
                            path="subscription"
                            currentPath={path}
                            detail={
                              haveSubscription
                                ? undefined
                                : t("menu.subscriptionDetailNo")
                            }
                          />
                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.members")}
                            icon="users"
                            path="members"
                            currentPath={path}
                            count={counters.Members}
                          />

                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.services")}
                            icon="box"
                            path="services"
                            onPress={() => {
                              navigation.navigate("studio", {
                                groupId: route.params.groupId,
                                screen: "services",
                                params: {
                                  screen: "list",
                                },
                              });
                            }}
                            currentPath={path}
                            count={counters.Services}
                          />

                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.schedules")}
                            icon="clock"
                            path="schedules"
                            currentPath={path}
                            count={counters.Schedules}
                          />
                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.rooms")}
                            icon="person-booth"
                            path="rooms"
                            onPress={() => {
                              navigation.navigate("studio", {
                                groupId: route.params.groupId,
                                screen: "rooms",
                              });
                            }}
                            currentPath={path}
                            count={counters.Rooms}
                          />

                          <MenuButton
                            isWeb={isWeb}
                            finish
                            label={t("menu.settings")}
                            icon="cog"
                            path="configuration"
                            onPress={() => {
                              navigation.navigate("studio", {
                                groupId: route.params.groupId,
                                screen: "configuration",
                              });
                            }}
                            currentPath={path}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Separator label={t("menu.account")} />

                  <MenuButton
                    isWeb={isWeb}
                    label={t("menu.details")}
                    icon="user"
                    path="profile"
                    currentPath={path}
                  />

                  <MenuButton
                    isWeb={isWeb}
                    label={t("menu.images")}
                    icon="images"
                    path="images"
                    onPress={() => {
                      navigation.navigate("user", {
                        screen: "images",
                      });
                    }}
                    currentPath={path}
                  />
                  <MenuButton
                    isWeb={isWeb}
                    label={t("menu.settings")}
                    onPress={() => {
                      navigation.navigate("user", {
                        screen: "settings",
                      });
                    }}
                    icon="cog"
                    path="settings"
                    currentPath={path}
                  />
                  <MenuButton
                    isWeb={isWeb}
                    label={t("menu.password")}
                    onPress={() => {
                      navigation.navigate("user", {
                        screen: "password",
                      });
                    }}
                    icon="key"
                    path="password"
                    currentPath={path}
                  />
                  <Separator label={t("menu.links")} />

                  {
                    <MenuButton
                      isWeb={isWeb}
                      finish
                      label={t("menu.bookingOnline")}
                      icon="share"
                      onPress={() => {
                        Linking.openURL(`https://new.tattoo/${user?.username}`);
                      }}
                      currentPath={path}
                    />
                  }

                  <MenuButton
                    isWeb={isWeb}
                    label={t("menu.yourProfile")}
                    icon="location-arrow"
                    onPress={() => {
                      Linking.openURL(
                        `https://en.inkonsky.com/${user?.username}`
                      );
                    }}
                    currentPath={path}
                  />
                  {
                    <MenuButton
                      isWeb={isWeb}
                      finish
                      label={t("title.groups.new")}
                      icon="store"
                      onPress={() => {
                        navigation.navigate("new");
                      }}
                      currentPath={path}
                    />
                  }
                </>
              )}
            </View>
          </ScrollView>
          {route?.params?.groupId && (
            <ChatComponent groupId={route?.params?.groupId} />
          )}
          <View
            style={{
              marginTop: "auto",
              backgroundColor: "#fff",
              padding: 20,
            }}
          >
            <Text
              style={{
                fontWeight: "500",
              }}
            >
              {user?.name} {user?.surname}
            </Text>
            <Text
              style={{
                fontSize: 11,
                opacity: 0.5,
              }}
            >
              {user?.email}
            </Text>
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 20,
                top: "50%",
                marginTop: -15,
                height: 30,
                width: 30,
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: 10,
              }}
              onPress={() => {
                if (Platform.OS === "web") {
                  // add to localstorage sessionDestroy true
                  localStorage.setItem("sessionDestroy", "true");
                }
                setUser(undefined);
                navigation.navigate("login");
              }}
            >
              <IconComponent
                style={{
                  icon: {
                    lineHeight: 30,
                    width: 30,
                    height: 30,
                    textAlign: "center",
                    fontSize: 20,
                    color: "#444",
                  },
                }}
                name="times"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
}
function Separator(properties: { label: string }) {
  return (
    <View
      style={{
        padding: 10,
        paddingLeft: 25,
        paddingBottom: 4,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          opacity: 0.5,
        }}
      >
        {properties.label}
      </Text>
    </View>
  );
}
function MenuButton(properties: {
  label: string;
  icon: IconType;
  path?: string;
  currentPath: string;
  finish?: boolean;
  count?: number;
  isWeb?: boolean;
  detail?: string;
  onPress?: () => void;
}) {
  const navigation = useNavigation();

  const convertCountK = (count: number) => {
    if (count < 1000) return count;
    return (count / 1000).toFixed(1) + "k";
  };

  // randomn number 4 - 10
  return (
    <TouchableOpacity
      onPress={() => {
        if (properties.onPress) {
          properties.onPress();
          return;
        } else if (properties.path) navigation.navigate(properties.path);
      }}
      style={{
        borderLeftWidth: 0,
        borderLeftColor: "#65c366",
        backgroundColor:
          properties.currentPath === properties.path
            ? "#65c366"
            : "transparent",
        padding: 22,
        paddingLeft: properties.isWeb ? 22 : 14,
        paddingVertical: 0,
        height: 34,
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      {/* {
            properties.finish && <View style={{
                width: 8,
                height: 8,
                borderRadius: 5,
                backgroundColor: 'blue',
                position: 'absolute',
                left: 8,
                top: 14,
                bottom: 0,
            }}></View>
        } */}

      <IconComponent
        style={{
          icon: {
            opacity: properties.currentPath === properties.path ? 1 : 0.5,
            fontSize: 20,
            color: properties.currentPath === properties.path ? "#fff" : "#000",
          },
        }}
        name={properties.icon}
      />
      <Text
        numberOfLines={1}
        style={{
          fontSize: properties.isWeb ? 15 : 13,
          fontWeight: "500",
          color: properties.currentPath === properties.path ? "#fff" : "#000",
        }}
      >
        {properties.label}
      </Text>
      {"count" in properties && (
        <Text
          style={{
            height: 25,
            minWidth: 25,
            textAlign: "center",
            lineHeight: 25,
            borderRadius: 20,
            paddingHorizontal: 5,
            fontSize: 10,

            marginLeft: "auto",
            textShadowColor: "rgba(0,0,0,0.1)",
            textShadowOffset: {
              width: 0,
              height: 1,
            },
            textShadowRadius: 1,
            fontWeight: "700",
            backgroundColor:
              properties.currentPath === properties.path
                ? "rgba(255,255,255,0.3)"
                : "rgba(0,0,0,0.06)",
            color: properties.currentPath === properties.path ? "#fff" : "#444",
          }}
        >
          {(properties.count && convertCountK(properties.count)) || 0}
        </Text>
      )}
      {properties.detail && (
        <Text
          style={{
            marginLeft: "auto",
            fontSize: 11,
            opacity: 0.5,
          }}
        >
          {properties.detail}
        </Text>
      )}
    </TouchableOpacity>
  );
}
