import { Environment } from "@environment"
import { userState } from "@states/user.state"
import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { ModalComponent } from "../../@components/modal/modal.component"
import LoaderComponent from "@components/loader/loader.component"
import { ErrorComponent } from "@components/error/error.component"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { FormComponent } from "@components/form/form.component"
import { InputComponent } from "@components/input/input.component"
import { SubmitComponent } from "@components/submit/submit.component"
import { InputFormProperties } from "@modules/form/form.properties"
import { FormModule } from "@modules/form/form.module"
import { ScrollView, TouchableOpacity, View } from "react-native"
import Toast from "react-native-toast-message"
import { TextComponent } from "@components/text/text.component"
import { IconType } from "@components/icon/icon.types"
import { IconComponent } from "@components/icon/icon.component"
import { getFristWordCamelCase } from "./post.module"
import { counterState } from "@states/counter.state"

export function PutModule<T>(properties: {
    prefix: string
    groupId?: string
    path: string
    id: string
    idName?: string
    blocked?: boolean
    dontRenderInLoading?: boolean
    width?: number
    level?: number
    onCancel: () => void
    noModal?: boolean
    onLoadData?: (data: any) => void
    onSuccess: () => void
    data?: Partial<T>
    contentComponent?: (data: any) => React.ReactNode
    inputs: InputFormProperties<T>[]
    actions?: {
        label: string
        icon: IconType
        onPress: (data: any) => void
    }[]
    extraSubmit?: {
        submitFirst?: boolean
        label: string,
        onPress: () => void
    }
    links?: {
        label: string
        count: (data: any) => number
        onLeft: (data: any) => void
        onRight: (data: any) => void
        iconLeft: IconType
        iconRight: IconType
    }[]
    headerComponent?: (data: any) => React.ReactNode
    footerComponent?: (data: any) => React.ReactNode
    headerInsideComponent?: (data: any) => React.ReactNode

}) {
    const [data, setData] = useState<Partial<T>>({})
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [user, setUser] = useRecoilState(userState)
    const [title, setTitle] = useState<string>('')
    const [counter, setCounter] = useRecoilState(counterState)

    const handlerSubmit = (settings?: {
        callback?: () => void
    }) => {
        if (loading) return;
        if (!user) return;
        setLoading(true)
        setTimeout(() => {
            let pram = properties.idName || `${properties.path}Id`
            // explode /



            axios.put(`${Environment.api}/${properties.path}?${pram}=${properties.id}`, {
                ...data,
                groupId: properties.groupId
            }, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {
                Toast.show({
                    type: 'success',
                    text1: t(`${properties.prefix}.putSuccessTitle`),

                })
                setCounter(new Date().getTime())
                setLoading(false)
                properties.onSuccess()
                settings?.callback && settings.callback()
            }).catch((error) => {
                setLoading(false)
                const message = error.response.data.message
                setError(message)
            })
        }, 400);
    }
    useEffect(() => {
        setData({
            ...data,
            ...properties.data

        })
    }, [properties.data])

    const getItem = () => {
        if (!user) return;
        setLoading(true)
        const key = `${properties.path}Id`
        setTimeout(() => {
            let pram = properties.idName || key

            const path = `${Environment.api}/${properties.path}?${pram}=${properties.id}${pram !== 'groupId' ? `&groupId=${properties.groupId}` : ''}`
            axios.get(path, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {

                setLoading(false)
                const item = response.data?.items?.[0]
                if ('name' in item && 'surname' in item) setTitle(`${item.name} ${item.surname}`)

                else if ('name' in item) setTitle(item.name)
                properties.onLoadData && properties.onLoadData(item)
                setData({
                    ...item,
                    ...properties.data
                })
            }).catch((error) => {
                setLoading(false)
                const message = error.response.data.message
                setError(message)
            })
        }, 400);
    }

    useEffect(() => {
        getItem()
    }
        , [])


    const errorField = getFristWordCamelCase(properties.inputs, error || '')

    const RenderContent = () => {
        return <>
            <View style={{
                flex: 1,
            }}>
                {(loading) ? <LoaderComponent backgroundColor={'#fff'} /> : null}

                {
                    properties.headerComponent && properties.headerComponent(data)
                }

                {
                    properties.links && properties.links?.length > 0 && <View style={{
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        marginBottom: 20,
                        backgroundColor: 'red',
                        marginTop: -30,
                    }}>
                        {properties.links?.map((action, index) => {
                            return <View style={{
                                flexDirection: 'row',
                                gap: 0,
                                padding: 5,
                                borderBottomColor: 'rgba(0,0,0,0.1)',
                                borderBottomWidth: 1,
                            }} key={index}>
                                <TouchableOpacity style={{
                                    padding: 8,
                                    paddingVertical: 1,
                                    borderRadius: 1000,
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    gap: 7,

                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingLeft: 12,
                                    paddingRight: 8

                                }} onPress={() => {
                                    action.onLeft(data)
                                }} >
                                    <TextComponent styles={{
                                        text: {
                                            fontWeight: 'bold',
                                            color: '#000', fontSize: 11,
                                        }
                                    }}>
                                        {action.count(data)}
                                    </TextComponent>
                                    <IconComponent name={action.iconLeft} style={{
                                        icon: {
                                            fontSize: 17,
                                            color: '#000'
                                        }
                                    }}></IconComponent>
                                    <TextComponent styles={{
                                        text: {
                                            fontWeight: 'bold',
                                            color: '#000', fontSize: 11,
                                        }
                                    }}>
                                        {t(`${properties.prefix}.action.${action.label}`)}
                                    </TextComponent>
                                </TouchableOpacity>
                                <TouchableOpacity style={{
                                    padding: 8,
                                    marginLeft: 'auto',
                                    paddingVertical: 1,
                                    borderRadius: 5,
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    gap: 7,
                                    backgroundColor: '#65c366',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingLeft: 12,
                                    paddingRight: 8

                                }} onPress={() => {
                                    action.onRight(data)
                                }} >
                                    <IconComponent name={action.iconRight} style={{
                                        icon: {
                                            fontSize: 17,
                                            color: '#fff'
                                        }
                                    }}></IconComponent>
                                    <TextComponent styles={{
                                        text: {
                                            fontWeight: 'bold',
                                            color: '#fff', fontSize: 11,
                                        }
                                    }}>
                                        {t(`${properties.prefix}.action.${action.label}`)}
                                    </TextComponent>
                                </TouchableOpacity>
                            </View>
                        })}
                    </View>
                }

                {<FormModule<T>
                    key={'form'}
                    contentComponent={() => {
                        return properties.contentComponent && properties.contentComponent(data)
                    }}
                    prefix={properties.prefix}
                    error={(error) ? {
                        inputName: errorField,
                        message: (error)
                    } : undefined}
                    data={data}
                    headerInsideComponent={properties.headerInsideComponent}
                    onChange={(newData: any) => {

                        const _Data: any = data;
                        Object.keys(newData).forEach((key) => {
                            _Data[key] = newData[key] as any
                        })
                        setData({ ..._Data })
                    }}
                    extraSubmit={properties.extraSubmit}
                    onSubmit={(properties?: {
                        callback?: () => void
                    }) => {

                        handlerSubmit({
                            callback: () => {
                                properties?.callback && properties.callback()

                            }
                        })

                    }}
                    path={properties.path}
                    footerComponent={() => {
                        return properties.footerComponent && properties.footerComponent(data)
                    }}
                    onCancel={properties.onCancel} onSuccess={properties.onSuccess} inputs={properties.inputs} />}

            </View>
        </>
    }

    if (properties.noModal) return RenderContent()

    return <ModalComponent
        loading={loading}
        title={title || t(`${properties.prefix}.title`)}
        maxWidth={properties.width || 500}
        level={properties.level}
        contentFlex={properties.dontRenderInLoading ? 1 : undefined}
        description={t(`${properties.prefix}.description`)}
        headerBottomComponent={properties.actions ? () => {
            return <View style={{
                opacity: loading ? 0 : 1,
            }}>
                <Actions prefix={properties.prefix} data={data} actions={properties.actions} />
            </View>
        } : undefined}
        onClose={properties.onCancel}>
        {
            properties.dontRenderInLoading && loading ? <LoaderComponent /> : null
        }
        {
            properties.dontRenderInLoading && loading ? null : properties.blocked ? null : RenderContent()}
    </ModalComponent>
}

export function Actions(properties: {
    prefix: string
    data?: any
    actions?: {
        label: string
        icon: IconType
        onPress: (data: any) => void
    }[]
}) {
    const { t } = useTranslation()
    return <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        marginLeft: 40,
        marginTop: 14,
        zIndex: 10020,
        marginBottom: -15
    }}>
        {properties.actions?.map((action, index) => {
            return <TouchableOpacity style={{
                padding: 8,
                paddingVertical: 1,
                borderRadius: 1000,
                alignItems: 'center',
                alignContent: 'center',
                gap: 7,

                backgroundColor: '#eee',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 12,
                paddingRight: 14

            }} key={index} onPress={() => {
                action.onPress(properties.data)

            }} >
                <IconComponent name={action.icon} style={{
                    icon: {
                        fontSize: 17,
                        color: '#444'
                    }
                }}></IconComponent>

                <TextComponent styles={{
                    text: {
                        lineHeight: 25,
                        fontWeight: 'bold',
                        color: '#000', fontSize: 11,
                    }
                }}>
                    {t(`${properties.prefix}.action.${action.label}`)}
                </TextComponent>

            </TouchableOpacity>
        })}
    </View>
}