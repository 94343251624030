import { ButtonComponent } from "@components/button/button.component";
import { ErrorComponent } from "@components/error/error.component";
import { FilmComponent } from "@components/film/film.component";
import LoaderComponent from "@components/loader/loader.component";
import { MapComponent } from "@components/map/map.component";
import { SubmitComponent } from "@components/submit/submit.component";
import { FieldsetFormModule } from "@modules/form/form.module";
import { AccordionInputForm } from "@modules/form/inputs/accordion/accordion.input";
import { CheckboxInputForm } from "@modules/form/inputs/checkbox/checkbox.input";
import { TextInputForm } from "@modules/form/inputs/text/text.input";
import { LanguageModule } from "@modules/selectors/language/language.module";
import { UserAvatarModule } from "@modules/selectors/userAvatar/userAvatar.module";
import { NavigationProp, useIsFocused, useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { UserRoutes } from "../user.routes";
const videoBlue = require('../../../../assets/blue.mp4')

export function ProfileScreen() {
    const navigation = useNavigation<NavigationProp<UserRoutes, 'profile'>>()
    const { t, i18n } = useTranslation()
    const [user, setUser] = useRecoilState(userState)
    // profile data
    const [error, setError] = useState<string>()
    const [name, setName] = useState<string>()
    const [surname, setSurname] = useState<string>()
    const [username, setUsername] = useState<string>()
    const [document, setDocument] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [addressLatitude, setAddressLatitude] = useState<number>()
    const [addressLongitude, setAddressLongitude] = useState<number>()
    const [addressStreet, setAddressStreet] = useState<string>()
    const [addressCity, setAddressCity] = useState<string>()
    const [addressCountry, setAddressCountry] = useState<string>()
    const [addressState, setAddressState] = useState<string>()
    const [addressZip, setAddressZip] = useState<string>()
    const [language, setLanguage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const [showInMap, setShowInMap] = useState<boolean>(true)
    const [cache, setCache] = useState<string>()
    const focus = useIsFocused()
    useEffect(() => {
        if (!user) return;
        if (!focus) return
        setLoading(true)
        axios.get(`${Environment.api}/me?component=profile`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            setLoading(false)
            const user = response.data?.items?.[0]
            if (user) {
                setName(user.name)
                setSurname(user.surname)
                setShowInMap(user.showInMap)
                setUsername(user.username)
                setDocument(user.document)
                setEmail(user.email)
                setPhone(user.phone)
                setAddressLatitude(user.addressLatitude)
                setAddressLongitude(user.addressLongitude)
                setAddressStreet(user.addressStreet)
                setAddressCity(user.addressCity)
                setAddressCountry(user.addressCountry)
                setLanguage(user.language)
                setDescription(user.description)
                setAddressState(user.addressState)
                setAddressZip(user.addressZip)
                setLoading(false)
            }
        }).catch((error) => {
            console.log(error)
            setLoading(false)
            setError(error.response.data.message)
            setCache(new Date().getTime().toString())
        })

    }, [user, focus])





    return <FilmComponent video={videoBlue} noHeader noClose>
        {loading && <LoaderComponent />}
        <UserAvatarModule />
        {
            error && <ErrorComponent
                title={t('ACCOUNTPROFILE_ERROR')}
                message={error} key={cache} />
        }

        <KeyboardAwareScrollView style={{ backgroundColor: '#fff', padding: 30 }}>

            <View style={{
                flexDirection: 'row',
                gap: 15,
            }}>
                <View style={{
                    flex: 1,
                }}>
                    <FieldsetFormModule prefix="account" name="name" noDescription>
                        <TextInputForm
                            value={name}
                            onChange={setName}
                            type="text"
                            name={'name'}
                            prefix="account"
                        />
                    </FieldsetFormModule>
                </View>
                <View style={{
                    flex: 1
                }}>
                    <FieldsetFormModule prefix="account" name="surname" noDescription>
                        <TextInputForm
                            value={surname}
                            onChange={setSurname}
                            type="text"
                            name={'surname'}
                            prefix="account"
                        />
                    </FieldsetFormModule>
                </View>
            </View>

            <FieldsetFormModule prefix="account" name="username">
                <TextInputForm
                    value={username}
                    onChange={setUsername}
                    type="text"
                    name={'username'}
                    prefix="account"
                />
            </FieldsetFormModule>

            <FieldsetFormModule prefix="account" name="document">
                <TextInputForm
                    value={document}
                    onChange={setDocument}
                    type="text"
                    name={'document'}
                    prefix="account"
                />
            </FieldsetFormModule>

            <FieldsetFormModule prefix="account" name="description">
                <TextInputForm
                    value={description}
                    onChange={(x) => {
                        console.log({ x })
                        setDescription(x)
                    }}
                    type="text"
                    name={'description'}
                    prefix="account"
                    lines={5}
                />
            </FieldsetFormModule>
            <FieldsetFormModule prefix="account" name="language">

                <LanguageModule languageId={language} onSelect={(language) => {

                    setLanguage(language.id)

                }} />

            </FieldsetFormModule>



            <AccordionInputForm
                type="accordion"
                prefix={'account'}
                name="contact"
                inputs={[]}
            >
                <>
                    <FieldsetFormModule prefix="account" name="email">
                        <TextInputForm
                            value={email}
                            onChange={setEmail}
                            type="text"
                            name={'email'}
                            icon="envelope"
                            prefix="account"
                        />
                    </FieldsetFormModule>
                    <FieldsetFormModule prefix="account" name="phone">
                        <TextInputForm
                            value={phone}
                            onChange={setPhone}
                            type="text"
                            icon="phone"
                            name={'phone'}
                            prefix="account"
                        />
                    </FieldsetFormModule>
                    <FieldsetFormModule paddingLeft prefix="account" name="showInMap" >
                        <CheckboxInputForm
                            value={showInMap}
                            onChange={setShowInMap}
                            type="checkbox"
                            name={'showInMap'}
                            prefix="account"
                        />
                    </FieldsetFormModule>
                </>

            </AccordionInputForm>
            <View style={{
                height: 15
            }} />

            <AccordionInputForm
                type="accordion"
                prefix={'account'}
                name="address"
                inputs={[]}
            >

                <MapComponent
                    addressCity={addressCity}
                    addressCountry={addressCountry}
                    addressLatitude={addressLatitude}
                    addressLongitude={addressLongitude}
                    addressState={addressState}
                    addressStreet={addressStreet}
                    addressZip={addressZip}

                    onChange={(address) => {
                        setAddressLatitude(address.addressLatitude)
                        setAddressLongitude(address.addressLongitude)
                        setAddressStreet(address.addressStreet)
                        setAddressCity(address.addressCity)
                        setAddressCountry(address.addressCountry)
                        setAddressState(address.addressState)
                        setAddressZip(address.addressZip)

                    }} />
            </AccordionInputForm>
            <View style={{
                padding: 20,
            }}>
                <SubmitComponent
                    title={t('account.save')}
                    onPress={() => {
                        setLoading(true)
                        axios.put(`${Environment.api}/me`, {
                            name: name,
                            surname: surname,
                            username: username,
                            document: document,
                            email: email,
                            phone: phone,
                            language: language,
                            addressLatitude: addressLatitude,
                            addressLongitude: addressLongitude,
                            addressStreet: addressStreet,
                            description: description,
                            addressCity: addressCity,
                            addressCountry: addressCountry,
                            addressState: addressState,
                            addressZip: addressZip,
                            showInMap: showInMap
                        }, {
                            headers: {
                                Authorization: `Bearer ${user.accessToken}`
                            }
                        }).then((response) => {
                            setLoading(false)
                            const userSelected = response.data?.items?.[0]
                            if (userSelected) {
                                i18n.changeLanguage(userSelected.language)
                                setUser({
                                    id: userSelected.id,
                                    accessToken: user.accessToken,
                                    name: userSelected.name,
                                    surname: userSelected.surname,
                                    email: userSelected.email,
                                    username: userSelected.username,
                                    language: userSelected.language,
                                    phone: userSelected.phone,

                                })



                                navigation.goBack()
                            }
                        })
                            .catch((error) => {
                                setLoading(false)
                                console.log(error)
                                setError(error.response.data.message)
                                setCache(new Date().getTime().toString())

                            })
                    }}
                />
            </View>
            <View>
                {
                    Platform.OS === 'web' && <ButtonComponent
                        icon={{
                            name: 'trash-alt'
                        }}
                        label={t('profile.delete')}

                        onPress={() => {
                            navigation.navigate('delete-account')
                        }}
                    />
                }
            </View>
        </KeyboardAwareScrollView>

    </FilmComponent>
}