import { Switch, TouchableOpacity, View } from "react-native";
import { TextComponent } from "../text/text.component";

export function SwitchComponent(properties: {
    value: boolean,
    onChange: (value: boolean) => void
    label: string
}) {
    return <View style={{
        padding: 10,
        flexDirection: 'row',
        gap: 10,
        paddingLeft: 20,
        backgroundColor: 'white',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'
    }}>
        <TouchableOpacity onPress={() => {
            properties.onChange(!properties.value)
        }}>
            <TextComponent styles={{
                text: {
                    fontWeight: "500"
                }
            }} content={properties.label} />
        </TouchableOpacity>
        <Switch value={properties.value} onValueChange={properties.onChange} />
    </View>
}