import React, { useEffect, useState } from 'react';
import { Text, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { ProductsInputProperties } from './products.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import axios from 'axios';
import { Environment } from '@environment';
import { useIsFocused } from '@react-navigation/native';
import LoaderComponent from '@components/loader/loader.component';
import { IconComponent } from '@components/icon/icon.component';
import { NumberInputForm } from '../number/number.input';
import { priceUtility } from '../../../../utilities/price/priceUtility';
import { useCurrencyHook } from '../../../../hooks/currency.hook';
import { EmptyComponent } from '@components/empty/empty.component';
import { ScrollView } from 'react-native-gesture-handler';
import { TextInputForm } from '../text/text.input';
import { PriceInputForm } from '../price/price.input';
import { FieldsetFormModule } from '@modules/form/form.module';
import { SubmitComponent } from '@components/submit/submit.component';
import { useTaxHook } from '../../../../hooks/tax.hook';

export function ProductsInputForm<T>(properties: ProductsInputProperties<T>) {
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState)
    const [search, setSearch] = useState('')
    const [rows, setRows] = useState<any[]>([])
    const isFocus = useIsFocused()
    const [loading, setLoading] = useState(false)
    const { currency } = useCurrencyHook(properties.groupId)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [showFinder, setShowFinder] = useState(false)
    const [showAdd, setShowAdd] = useState(false)

    const [customName, setCustomName] = useState('')
    const [customPrice, setCustomPrice] = useState(0)
    const [customTax, setCustomTax] = useState(0)
    const [customUnits, setCustomUnits] = useState(0)
    const [errorCustomName, setErrorCustomName] = useState(false)
    const [errorCustomUnits, setErrorCustomUnits] = useState(false)
    const [typeElement, setTypeElement] = useState<'product' | 'deposit' | 'budget' | 'custom'>('product')
    const tax = useTaxHook(properties.groupId)
    const getRows = async (search?: string) => {
        if (!user) return
        setLoading(true)
        if (typeElement === 'custom') return

        let path = `${Environment.api}/product?groupId=${properties.groupId}${search ? `&search=${search}` : ''}`
        if (typeElement === 'deposit') {
            path = `${Environment.api}/meet?groupId=${properties.groupId}${properties.customerId ? `&customerId=${properties.customerId}&` : ''}&deposit=true&${search ? `&search=${search}` : ''}`
        }
        if (typeElement === 'budget') {
            path = `${Environment.api}/meet?groupId=${properties.groupId}${properties.customerId ? `&customerId=${properties.customerId}&` : ''}&budget=true&${search ? `&search=${search}` : ''}`
        }

        axios.get(path, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const items = response.data.items
            // if deposit, filter by id
            if (typeElement === 'deposit') {
                const notRepeat = items.filter((item: any) => {
                    return !properties.value?.find((value) => {
                        return value.meetId === item.id && value.meetType === 'deposit'
                    })
                })
                setRows(notRepeat)
            }

            else if (typeElement === 'budget') {
                const notRepeat = items.filter((item: any) => {
                    return !properties.value?.find((value) => {
                        return value.meetId === item.id && value.meetType === 'budget'
                    })
                })
                setRows(notRepeat)
            }
            else setRows(items)


            setLoading(false)
        })
    }

    useEffect(() => {
        getRows()
    }, [isFocus])

    useEffect(() => {
        const timeout = setTimeout(() => {
            getRows(search)
        }
            , 500)
        return () => {
            clearTimeout(timeout)
        }
    }
        , [search,])

    useEffect(() => {
        setRows([])
        getRows()
    }, [typeElement, properties.customerId])




    const total = (properties.value?.reduce((total, item) => {
        return total + item.units * item.price
    }
        , 0) || 0) + (properties.base || 0)

    const calculateTaxTotal = () => {
        const producsToSum: {
            units: number;
            productId?: string;
            price: number;
            tax: number;
            name: string;
        }[] = properties.value || []



        const tax = producsToSum.map((product) => {
            const price = product.price * product.units
            const tax = (price / 100) * (product.tax / 100)
            return tax
        }) || []

        const totalTax = tax.reduce((total, tax) => {
            return total + tax
        }, 0)

        return priceUtility({
            price: totalTax
        })
    }

    const ButtonCom = (settings: {
        text: string
        status?: boolean
        onPress: () => void
    }) => {
        return <TouchableOpacity onPress={settings.onPress} style={{
            padding: 10,
            paddingLeft: 15,
            borderTopStartRadius: 5,
            borderTopEndRadius: 5,
            alignContent: 'center',
            alignItems: 'center',
            paddingRight: 16,
            flexDirection: 'row',
            backgroundColor: settings.status ? '#fff' : 'rgba(0,0,0,0)',
        }}>

            <Text style={{
                color: '#000', fontSize: 14,
                flex: 1,
                fontWeight: 'bold',
            }}>
                {t(settings.text)}
            </Text>

        </TouchableOpacity>
    }

    return <View style={{
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 0,
        overflow: 'hidden',
        borderRadius: 5,
        minHeight: 400
    }}>
        {
            properties.value?.length === 0 && <EmptyComponent prefix={'noProducts'} />
        }
        {
            loadingLabel && <LoaderComponent />
        }

        {properties.value && properties.value?.length > 0 && <View style={{
            paddingHorizontal: 10,
            borderBottomWidth: 1,
            borderColor: '#ddd',
            flexDirection: 'row',
        }}>
            <View style={{
                paddingVertical: 5,
                width: 150, borderRightColor: '#ddd',
                borderRightWidth: 1,
                paddingLeft: 5,
            }}>
                <TextComponent>{t('products.name')}</TextComponent>
            </View>
            <View style={{
                paddingVertical: 5,
                paddingLeft: 15,
                width: 130, borderRightColor: '#ddd',
                borderRightWidth: 1,
            }}>
                <TextComponent>{t('products.units')}</TextComponent>
            </View>
            <View style={{
                paddingVertical: 5,
                paddingLeft: 15,
                width: 170,
            }}>
                <TextComponent>{t('products.price')}</TextComponent>
            </View>
        </View>}
        {
            properties.value && properties.value.length > 0 && <View style={{

                marginBottom: 20,
                flexDirection: 'column',
            }}>
                {
                    properties.value.map((item, index) => {
                        return <View key={index} style={{
                            flexDirection: 'row',
                            marginBottom: 5,
                            borderBottomWidth: 1,
                            borderColor: '#ddd',
                            padding: 5,
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                paddingLeft: 10,
                                width: 155,

                            }}>
                                <TextComponent styles={{
                                    text: {
                                        fontWeight: 'bold'
                                    }
                                }}>{item.name}</TextComponent>
                            </View>
                            <View style={{
                                width: 130,
                            }}>
                                <NumberInputForm
                                    value={item.units}
                                    name={'price'}
                                    type='number'
                                    onChange={(newUnits) => {
                                        if (newUnits < 0 || newUnits === 0) {
                                            // remove
                                            const newValue = properties.value?.filter((value, indexx) => {
                                                return index !== indexx
                                            })
                                            properties.onChange?.(newValue || [])
                                            return
                                        }
                                        const newValue = properties.value?.map((value, indexx) => {
                                            if (index === indexx) {
                                                return {
                                                    ...value,
                                                    units: newUnits
                                                }
                                            }
                                            return value
                                        })
                                        properties.onChange?.(newValue || [])
                                    }
                                    } />
                            </View>
                            <View style={{
                                width: 140,
                                paddingLeft: 10,
                                flexDirection: 'row',
                                flex: 1,
                                alignItems: 'center',
                            }}>
                                <TextComponent>{

                                    priceUtility({
                                        price: item.price,
                                    })
                                } {currency}</TextComponent>
                                <IconComponent style={{
                                    box: {
                                        height: 30,
                                        marginLeft: 'auto',
                                        width: 30,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 45,

                                    },
                                    icon: {
                                        fontSize: 22,
                                        color: 'rgb(199, 52, 71)',
                                    }
                                }} name="trash-alt"
                                    onPress={() => {
                                        const newValue = properties.value?.filter((value, indexx) => {
                                            return index !== indexx
                                        })
                                        properties.onChange?.(newValue || [])
                                    }
                                    }
                                />
                            </View>

                        </View>
                    })
                }
            </View>
        }
        {!showFinder && <TouchableOpacity onPress={() => {
            setShowFinder(true)
            setTypeElement('product')
        }}
            style={{
                backgroundColor: '#65c366',
                borderRadius: 45,
                padding: 4,
                paddingHorizontal: 15,
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
            }}
        >
            <TextComponent styles={{
                text: {
                    fontSize: 15,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#fff'
                }
            }}>{t('products.addItem')}</TextComponent>
        </TouchableOpacity>}
        {
            showFinder && <View style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                zIndex: 999,
            }} />
        }



        {showFinder && <View style={{

            overflow: 'hidden',
            backgroundColor: '#fff',

            maxHeight: 550,
            marginHorizontal: 20,
            height: 550,
            borderRadius: 5,
            zIndex: 2999,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 23.84,

        }}>
            {
                <View style={{
                    backgroundColor: '#ddd',

                    borderRadius: 5,
                    borderBottomEndRadius: 0,
                    borderBottomStartRadius: 0,
                    zIndex: 1000,
                    flexDirection: 'row',

                }}>

                    <ButtonCom
                        status={typeElement === 'product'}

                        text="products.addProduct" onPress={() => { setTypeElement('product') }} />
                    <ButtonCom
                        status={typeElement === 'deposit'}
                        text="products.addDeposit" onPress={() => { setTypeElement('deposit') }} />
                    <ButtonCom
                        status={typeElement === 'budget'}
                        text="products.addBudget" onPress={() => { setTypeElement('budget') }} />
                    <ButtonCom
                        status={typeElement === 'custom'}
                        text="products.addCustom" onPress={() => { setTypeElement('custom') }} />
                </View>
            }

            <IconComponent name='times' style={{
                box: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: 40,
                    width: 40,
                    zIndex: 1000,
                    borderRadius: 35,
                    justifyContent: 'center',
                    alignItems: 'center',

                },
                icon: {
                    fontSize: 20,
                    color: '#333',

                }
            }}
                onPress={() => {
                    setShowFinder(false)
                }}
            />
            {
                typeElement !== 'custom' && <View style={{
                    margin: 10, borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#ddd',

                }}>
                    <IconComponent name='search' style={{
                        box: {
                            position: 'absolute',
                            top: 4,
                            left: 7,
                            height: 30,
                            width: 30,
                            borderRadius: 35,
                            justifyContent: 'center',
                            alignItems: 'center',

                        },
                        icon: {
                            fontSize: 20,
                            color: '#333',

                        }
                    }}
                    />
                    <TextInputNative
                        style={{
                            borderWidth: 0,
                            borderColor: '#ddd',
                            padding: 10,
                            paddingLeft: 50,
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            borderRadius: 0,
                        }}
                        placeholder={t(`products.search.${typeElement}`)}
                        value={search}
                        onSubmitEditing={() => {
                            getRows(search)
                        }}
                        onChangeText={(value) => {
                            setSearch(value);
                        }}
                    />
                </View>
            }
            {
                typeElement !== 'custom' && loading && <LoaderComponent />
            }
            <ScrollView style={{
                flex: 1
            }}>
                {
                    typeElement === 'custom' && <View style={{
                        padding: 20,
                        paddingVertical: 6,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        backgroundColor: '#fff',
                        marginBottom: 5,
                        paddingTop: 30
                    }}>
                        <FieldsetFormModule prefix='products.custom' name='name' noDescription error={errorCustomName ? {
                            message: ('name.custom.errorName'),
                            inputName: 'name'
                        } : undefined}>
                            <TextInputForm
                                type='text'
                                name={'name'}
                                value={customName}
                                onChange={(value) => {
                                    setCustomName(value)
                                }}
                            />
                        </FieldsetFormModule>
                        <FieldsetFormModule prefix='products.custom' name='price' noDescription>

                            <PriceInputForm
                                groupId={properties.groupId}
                                type='price'
                                name={'units'}
                                value={customPrice}
                                onChange={(value) => {
                                    setCustomPrice(value)
                                }}
                            />
                        </FieldsetFormModule>
                        <FieldsetFormModule prefix='products.custom' name='tax' noDescription>

                            <PriceInputForm
                                type='price'
                                currency='%'

                                name={'units'}
                                value={customTax}
                                onChange={(value) => {
                                    setCustomTax(value)
                                }}
                            />
                        </FieldsetFormModule>
                        <FieldsetFormModule prefix='products.custom' name='units' noDescription error={errorCustomUnits ? {
                            message: ('units.custom.errorUnits'),
                            inputName: 'units'
                        } : undefined}>

                            <NumberInputForm
                                type='number'
                                name={'units'}
                                value={customUnits}
                                onChange={(value) => {
                                    setCustomUnits(value)
                                }}
                            />
                        </FieldsetFormModule>
                        <SubmitComponent title={t('products.custom.submit')} onPress={() => {
                            if (!customName || customName === '') {
                                setErrorCustomName(true)
                                return
                            }
                            else setErrorCustomName(false)
                            if (customUnits <= 0) {
                                setErrorCustomUnits(true)
                                return
                            } else setErrorCustomUnits(false)
                            properties.onChange?.([
                                ...properties.value || [],
                                {
                                    name: customName,
                                    units: customUnits,
                                    price: customPrice,
                                    tax: customTax,
                                }
                            ])
                            setCustomName('')
                            setCustomPrice(0)
                            setCustomTax(0)
                            setCustomUnits(0)
                            setShowFinder(false)
                        }} />
                    </View>
                }
                {
                    typeElement !== 'custom' && rows.map((row, index) => {
                        return <TouchableOpacity key={index} onPress={() => {
                            setShowFinder(false)
                            setShowAdd(false)

                            properties.onChange?.([
                                ...properties.value || [],
                                {
                                    productId: typeElement === 'product' ? row.id : undefined,
                                    name: row.name,
                                    meetId: typeElement === 'deposit' || typeElement === 'budget' ? row.id : undefined,
                                    meetType: typeElement === 'deposit' ? 'deposit' : typeElement === 'budget' ? 'budget' : undefined,
                                    units: 1,
                                    price: typeElement === 'product' ? row.price : typeElement === 'deposit' ? row.deposit : row.budget,
                                    tax: row.tax || tax.tax || 0,
                                }
                            ])


                            setShowFinder(false)
                        }} style={{
                            padding: 10,
                            paddingVertical: 6,
                            marginHorizontal: 5,
                            paddingLeft: 15,
                            borderRadius: 5,
                            backgroundColor: '#f5f5f5',
                            flexDirection: 'row',
                            marginBottom: 5,
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                borderRightColor: '#ddd',
                                borderRightWidth: 1,
                                width: 100,
                                paddingRight: 10,
                                justifyContent: 'flex-end'
                            }}>
                                <TextComponent styles={{
                                    layout: {

                                        paddingHorizontal: 10,
                                        paddingRight: 1,
                                    },
                                    text: {
                                        color: '#000',
                                        fontWeight: 'bold'
                                    }
                                }}>{priceUtility({
                                    price: typeElement === 'product' ? row.price : typeElement === 'deposit' ? row.deposit : row.budget
                                })} </TextComponent>
                                <TextComponent styles={{
                                    text: {
                                        opacity: 0.5,
                                        fontWeight: '200'
                                    }
                                }}>
                                    {currency}
                                </TextComponent>

                            </View>
                            <TextComponent styles={{
                                text: {
                                    marginLeft: 10,
                                    fontWeight: 'bold'
                                }
                            }}>{row.name}</TextComponent>

                            {
                                'Customer' in row && <TextComponent styles={{
                                    layout: {
                                        marginLeft: 10,
                                        backgroundColor: 'rgba(0, 0, 0,0.05)',
                                        borderRadius: 35,
                                        paddingHorizontal: 10,

                                    },
                                    text: {
                                        fontSize: 11,
                                        color: '#000',
                                        fontWeight: '500'
                                    }
                                }}>{row.Customer.name} {row.Customer.surname}</TextComponent>
                            }
                        </TouchableOpacity>
                    })
                }
            </ScrollView>
        </View>}
        <RenderTotal total={total} tax={calculateTaxTotal()} currency={currency} />
    </View >
}



const RenderTotal = (properties: {
    total: number
    tax: string
    currency?: string
}) => {
    const { t } = useTranslation()
    return <View style={{
        marginTop: 'auto'
    }}>
        <View style={{
            backgroundColor: '#eee',
            margin: 20,
            borderRadius: 5,
            padding: 10,
            paddingHorizontal: 20,
        }}>

            <View style={{
                borderBottomWidth: 2,
                borderColor: '#444',
                flexDirection: 'row',
            }}>
                <View style={{

                    flex: 1,
                    paddingVertical: 5,
                    paddingLeft: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 17,
                            textAlign: 'left',
                            fontWeight: '600',
                            textTransform: 'uppercase'
                        }
                    }}>
                        {t('products.totalPrice')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190, paddingVertical: 5,
                    paddingRight: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 25,
                            fontWeight: 'bold',
                            textAlign: 'right'
                        }
                    }}>{properties.total && priceUtility({
                        price: properties.total

                    }) || '0,00'}
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 25,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>
                            {properties.currency}
                        </TextComponent>
                    </TextComponent>
                </View>

            </View>
            <View style={{

                flexDirection: 'row',
            }}>
                <View style={{
                    flex: 1,
                    paddingVertical: 5,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 14,
                            opacity: 0.7,
                            fontWeight: '400',
                            paddingLeft: 10, textTransform: 'uppercase'

                        }
                    }}>
                        {t('products.totalTax')}
                    </TextComponent>
                </View>
                <View style={{

                    width: 190,
                    paddingRight: 10,
                    paddingVertical: 5,
                }}>
                    <TextComponent styles={{
                        text: {
                            fontSize: 20,
                            fontWeight: 'bold', textAlign: 'right'
                        }
                    }}>{properties.tax || '0,00'}
                        <TextComponent styles={{
                            text: {
                                marginLeft: 5,
                                fontSize: 20,
                                opacity: 0.4,
                                fontWeight: 'normal',
                            }
                        }}>{properties.currency}</TextComponent></TextComponent>
                </View>

            </View>
        </View>
    </View>

}