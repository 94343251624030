import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { CalendarRoutes } from "./calendar.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { CalendarScreen } from "./calendar/calendar.screen";
import { NewCustomerScreen } from "../customer/new/new.customer.screen";
import { NewRoomScreen } from "../room/new/new.room.screen";
import { NewMemberScreen } from "../member/new/new.member.screen";
import { EditMeetScreen } from "../meet/edit/edit.meet.screen";
import { DeleteMeetScreen } from "../meet/delete/delete.meet.screen";
import { EditCustomerScreen } from "../customer/edit/edit.customer.screen";
import { NewConsentScreen } from "../consent/new/new.consent.screen";
import { NewReceiptScreen } from "../receipt/new/new.receipt.screen";

const stack = createNativeStackNavigator<CalendarRoutes>();

export function CalendarNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'meets'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="all"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            initialParams={{ groupId }}
            name="all"
            options={modalOptions}
            component={CalendarScreen}
        />

        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            name="newCustomer"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewCustomerScreen}
        />
        <stack.Screen
            name="newConsent"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewConsentScreen}
        />
        <stack.Screen
            name="newReceipt"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewReceiptScreen}
        />
        <stack.Screen
            name="editCustomer"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={EditCustomerScreen}
        />
        <stack.Screen
            name="newRoom"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewRoomScreen}
        />
        <stack.Screen
            name="newMember"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewMemberScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteMeetScreen}
        />

    </stack.Navigator>
}
