import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { ServiceRoutes } from "../service.routes";
import { Text, TouchableOpacity, View } from "react-native";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { Environment } from "@environment";
import { IconComponent } from "@components/icon/icon.component";
import axios from "axios";
import { priceUtility } from "../../../../utilities/price/priceUtility";
import LoaderComponent from "@components/loader/loader.component";
import { set } from "react-native-reanimated";
import { useTranslation } from "react-i18next";

export function ListServiceScreen() {
    const navigation = useNavigation<NavigationProp<ServiceRoutes, 'list'>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'list'>>()
    const [reload, setReload] = useState<string>('')
    const [user,] = useRecoilState(userState)
    const [members, setMembers] = useState<{
        id: string
        name: string
        color: string
    }[]>([])
    const isFocus = useIsFocused()
    useEffect(() => {
        isFocus && setReload(Math.random().toString())
    }, [isFocus])

    useEffect(() => {
        if (!route.params.groupId) return
        if (!user) return
        fetch(`${Environment.api}/member?groupId=${route.params.groupId}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            setMembers(data.items)
        })
    }, [route.params.groupId, user])
    return <TableComponent<{
        id: string
        name: string
        color: string
        createdAt: string
    }>

        groupId={route.params.groupId}

        prefix="listService"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`service?groupId=${route.params.groupId}`}
        renderFooterItem={
            (item) => {
                if (!user) return <></>
                return <MemberServices
                    key={reload}
                    members={members}
                    serviceName={item.name}
                    userToken={user.accessToken}
                    serviceId={item.id}
                    groupId={route.params.groupId}
                />
            }
        }
        columns={[
            {
                always: true,
                menu: {
                    icon: 'pen',
                    label: 'edit',

                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        serviceId: item.id
                    })
                }
            },

            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                width: 200,
                type: 'text',
            },
            {
                width: 40,
                type: 'color',
                renderColor: (item) => { return item.color },
            },
            {
                title: 'createdAt',
                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200,
            },
            {
                label: 'createMeet',
                width: 150,
                type: 'button',
                variant: 'primary',
                icon: 'plus',
                menu: {
                    icon: 'calendar-plus',
                    label: 'createMeet',

                },
                onPress: (item) => {
                    navigation.navigate('newMeet', {
                        groupId: route.params.groupId,
                        serviceId: item.id
                    })
                }

            },
            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',

                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        serviceId: item.id
                    })
                }
            },
        ]}

    />

}


export function MemberServices(properties: {
    serviceId: string
    userToken: string
    groupId: string
    serviceName: string
    members: {
        id: string
        name: string
        color: string
    }[]
}) {
    const [serviceMembers, setServiceMembers] = useState<{
        id: string,
        createdAt: string,
        depositPercentage: number,
        exactPrice: number,
        minutePrice: number,
        customPrice: number,
        minimumPrice: number,
        memberId: string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`${Environment.api}/service/member?serviceId=${properties.serviceId}&groupId=${properties.groupId}`, {
            headers: {
                Authorization: `Bearer ${properties.userToken}`
            }
        }).then((response) => {
            setLoading(false)
            setServiceMembers(response.data.items)
        })
    }, [properties.serviceId, properties.userToken])


    return <View style={{
        marginTop: -5,
        zIndex: -1,
        overflow: 'hidden',
        marginBottom: 15,
        borderBottomEndRadius: 5,
        borderBottomStartRadius: 5,
        paddingTop: 15,
        backgroundColor: 'rgba(0,0,0,0.1)',
        paddingBottom: 15
    }
    } >

        {
            properties.members.map((member) => {
                const service = serviceMembers.find((service) => service.memberId === member.id)
                return <MemberService key={member.id} member={member} service={service} {...properties}
                    removeService={(id) => {
                        setServiceMembers((serviceMembers) => {
                            return serviceMembers.filter((service) => service.id !== id)
                        })

                    }}
                    serviceMembers={serviceMembers}
                    loading={loading}
                />
            })
        }
    </View >
}


const MemberService = (properties: {
    member: {
        id: string
        name: string
        color: string
    }
    loading: boolean
    removeService: (serviceId: string) => void
    serviceMembers: any
    serviceId: string
    service?: {
        id: string,
        createdAt: string,
        depositPercentage: number,
        exactPrice: number,
        minutePrice: number,
        customPrice: number,
        minimumPrice: number,
        memberId: string,
        type: string
    }
    userToken: string
    groupId: string
    serviceName: string
}) => {
    const navigation = useNavigation<NavigationProp<ServiceRoutes, 'list'>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'list'>>()
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    return <View style={{
        flexDirection: 'row'
    }}>
        <View style={{
            height: 53,
            width: 30,
            borderBottomStartRadius: 10,
            borderLeftWidth: 2,
            borderBottomWidth: 2,
            borderColor: '#fff',
            marginLeft: 18,
            marginTop: -33,
        }} />
        <View style={{
            height: 10,
            width: 4,
            borderTopStartRadius: 10,
            borderBottomStartRadius: 10,
            backgroundColor: '#666',
            position: 'absolute',
            top: 14,
            left: 44,
        }} />
        <View key={properties.member.id} style={{
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            gap: 10,
            padding: 3,
            paddingLeft: 12,
            paddingRight: 10,
            marginBottom: 4,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            overflow: 'hidden',
            alignSelf: 'flex-start',
            shadowOpacity: 0.22,
            shadowRadius: 2.22,
            borderRadius: 5,
            height: 40,
            backgroundColor: 'rgba(255,255,255,0.6)',
        }}>
            {
                (properties.loading || loading) && <LoaderComponent backgroundColor="#fff" />
            }
            <IconComponent style={{
                box: {
                    width: 25,
                    backgroundColor: !properties.service ? 'rgba(212,32,62,1)' : '#65c366',
                    height: 25,
                    alignContent: 'center',
                    alignItems: 'center',
                    borderRadius: 25,
                },
                icon: {
                    fontSize: 13,
                    color: '#fff',
                    width: 25,
                    height: 25,

                    fontWeight: 'bold',
                    lineHeight: 25,
                    textAlign: 'center',
                }
            }} name={

                !properties.service ? 'times' : 'check'
            } />
            <Text
                numberOfLines={1}
                style={{
                    fontSize: 12,
                    width: 150,

                    marginLeft: 0,
                    fontWeight: 'bold',
                }}>
                {properties.member.name}
            </Text>
            {
                properties.service ? <>
                    <View style={{
                        borderRightColor: '#ccc',
                        borderRightWidth: 1,
                        paddingRight: 10,
                    }}>
                        <Text style={{
                            fontSize: 12,
                            marginLeft: 0,
                            fontWeight: 'bold',
                        }}>

                            {priceUtility({
                                price: properties.service.minimumPrice
                            })}

                        </Text>
                        <Text style={{
                            fontSize: 10,
                            marginLeft: 0,
                            opacity: 0.5,
                            fontWeight: '400',
                        }}>
                            {
                                t('services.member.minimum')
                            }
                        </Text>
                    </View>
                    {
                        properties.service.type === 'EXACT' ? <View style={{
                            borderRightColor: '#ccc',
                            borderRightWidth: 1,
                            paddingRight: 10,
                        }}>
                            <Text style={{
                                fontSize: 12,
                                marginLeft: 0,
                                fontWeight: 'bold',
                            }}>

                                {priceUtility({
                                    price: properties.service.exactPrice
                                })}

                            </Text>
                            <Text style={{
                                fontSize: 10,
                                marginLeft: 0,
                                opacity: 0.5,
                                fontWeight: '400',
                            }}>
                                {
                                    t('services.member.exact')
                                }
                            </Text>
                        </View> : <View style={{
                            borderRightColor: '#ccc',
                            borderRightWidth: 1,
                            paddingRight: 10,
                        }}>
                            <Text style={{
                                fontSize: 12,
                                marginLeft: 0,
                                fontWeight: 'bold',
                            }}>

                                {priceUtility({
                                    price: properties.service.minutePrice
                                })}

                            </Text>
                            <Text style={{
                                fontSize: 10,
                                marginLeft: 0,
                                opacity: 0.5,
                                fontWeight: '400',
                            }}>{
                                    t('services.member.hour')
                                }</Text>
                        </View>
                    }
                    <View style={{

                    }}>
                        <Text style={{
                            fontSize: 12,
                            marginLeft: 0,
                            fontWeight: 'bold',
                        }}>

                            {properties.service.depositPercentage}%

                        </Text>
                        <Text style={{
                            fontSize: 10,
                            marginLeft: 0,
                            opacity: 0.5,
                            fontWeight: '400',
                        }}>

                            {
                                t('services.member.deposit')
                            }
                        </Text>
                    </View>
                    <TouchableOpacity
                        style={{
                            marginLeft: 10
                        }}
                        onPress={() => {
                            properties.service && navigation.navigate('editMemberService', {
                                groupId: properties.groupId,
                                serviceId: properties.serviceId,
                                serviceMemberId: properties.service.id,
                                memberName: properties.member.name,
                                serviceName: properties.serviceName,
                                memberId: properties.member.id
                            })
                        }}>
                        <Text style={{
                            fontSize: 10,
                            backgroundColor: 'rgba(0,0,0,0.05)',
                            paddingVertical: 5,
                            paddingHorizontal: 10,
                            borderRadius: 5,
                            fontWeight: '500',
                        }}>
                            {
                                t('services.member.edit')
                            }
                        </Text>
                    </TouchableOpacity>
                    <IconComponent name="trash-alt" style={{
                        box: {
                            width: 25,
                            height: 25,
                            alignContent: 'center',
                            alignItems: 'center',
                            borderRadius: 25,
                        },
                        icon: {
                            fontSize: 20,
                            color: 'rgba(212,32,62,1)',
                            width: 25,
                            height: 25,
                            lineHeight: 25,
                            textAlign: 'center',
                        }
                    }} onPress={() => {
                        setLoading(true)
                        setTimeout(() => {
                            axios.delete(`${Environment.api}/service/member?serviceMemberId=${properties.service?.id}&groupId=${properties.groupId}`, {
                                headers: {
                                    Authorization: `Bearer ${properties.userToken}`
                                }
                            }).then(() => {
                                setLoading(false)

                                properties.removeService(properties.service.id)
                            })
                        }, 300);
                    }} />
                </> : <TouchableOpacity onPress={() => {
                    navigation.navigate('newMemberService', {
                        groupId: properties.groupId,
                        serviceId: properties.serviceId,
                        memberName: properties.member.name,
                        serviceName: properties.serviceName,
                        memberId: properties.member.id
                    })
                }}>
                    <Text style={{
                        fontSize: 10,
                        backgroundColor: 'rgba(0,0,0,0.05)',
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 5,
                        fontWeight: '500',
                    }}>
                        {t('services.member.add')}
                    </Text>
                </TouchableOpacity>
            }
        </View>
    </View>
}