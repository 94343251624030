import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { BookingRoutes } from "../booking.routes";

export function NewBookingScreen() {
  const navigation = useNavigation<NavigationProp<BookingRoutes>>();
  const route = useRoute<RouteProp<BookingRoutes, "new">>();

  return (
    <PostModule<{
      name: string;
      customerId: string;
      roomId: string;
      startedAt?: string;
      finishedAt?: string;
      description: string;
      type: string;
      memberId: string;
      deposit?: number;
      commission?: number;
      budget?: number;
      size?: string;
      body?: string;
      images: string[];
      serviceId: string;
    }>
      prefix="newBooking"
      path="meet"
      onSuccess={() => {
        navigation.goBack();
      }}
      groupId={route.params.groupId}
      onCancel={() => {
        navigation.goBack();
      }}
      data={{
        type: "booking",
        images: [],
        description: "",
        serviceId: route.params.serviceId,
        customerId: route.params.customerId,
        roomId: route.params.roomId,
      }}
      inputs={[
        {
          name: "serviceId",
          type: "service",
          path: "service",
          groupId: route.params.groupId,
          serviceName: "serviceId",
          depositName: "deposit",
          budgetName: "budget",
          commissionName: "commission",
          startedAtName: "startedAt",
          finishedAtName: "finishedAt",
          memberName: "memberId",
        },
        {
          name: "name",
          type: "text",
        },
        {
          name: "description",
          type: "text",
          lines: 5,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newRoom", {
              level: 1 + (route.params.level || 0),
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          name: "roomId",
          type: "choose",
          path: "room",
          groupId: route.params.groupId,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newCustomer", {
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          renderLabel: (item) => {
            return `${item?.name} ${item?.surname}`;
          },
          renderDetail: (item) => {
            return `${item?.email || ""}`;
          },
          name: "customerId",
          type: "choose",
          path: "customer",
          groupId: route.params.groupId,
        },

        {
          name: "size",
          type: "select",
          options: ["mini", "small", "medium", "big"],
        },
        {
          name: "body",
          type: "text",
        },
        {
          name: "images",
          type: "images",
        },
      ]}
    />
  );
}
