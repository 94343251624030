import { IconComponent } from "@components/icon/icon.component";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { BoxTitle, SelectorComponent } from "../box.component";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export function TwoStep(properties: {
    setStep: (step: number) => void
    setServices: (services: string[]) => void
    services: string[]
}) {
    const { t } = useTranslation()
    const servicesIcons = [
        {
            label: t('services.tattoo'),
            value: 'tattoo',
            icon: 'anchor'
        },
        {
            label: t('services.piercing'),
            value: 'piercing',
            icon: 'anchor'
        },
        {
            label: t('services.tattoo_removal'),
            value: 'tattoo_removal',
            icon: 'eraser'
        },
        {
            label: t('services.microblading'),
            value: 'microblading',
            icon: 'anchor'
        },
        {
            label: t('services.micropigmentation'),
            value: 'micropigmentation',
            icon: 'anchor'
        },
        {
            label: t('services.other'),
            value: 'other',
            icon: 'anchor'
        }
    ]

    return <View style={{
        width: '100%',
        maxWidth: 700,
        marginHorizontal: 'auto',
        padding: 20,
    }}>
        <BoxTitle title={t('onboarding.two.title')}

            subtitle={t('onboarding.two.subtitle')} />

        <SelectorComponent
            translationPrefix="services"
            selected={properties.services}
            setSelected={properties.setServices}
            options={servicesIcons}
            multiple={true}
        />
        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: '#333',
                    padding: 20,
                    paddingHorizontal: 40,
                    borderRadius: 10,
                    alignSelf: 'center',
                    margin: 10,
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
                onPress={() => {
                    properties.setStep(3)
                }}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>{
                        t('onboarding.two.next')

                    }</Text>
                <IconComponent name="arrow-right" style={{
                    icon: {
                        color: '#fff',
                        fontSize: 30,
                        textAlign: 'center',
                    }
                }} />
            </TouchableOpacity>
        </View>
    </View>

}