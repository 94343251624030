import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ScheduleRoutes } from "../schedule.routes";

export function NewScheduleScreen() {
    const navigation = useNavigation<NavigationProp<ScheduleRoutes>>()
    const route = useRoute<RouteProp<ScheduleRoutes, 'new'>>()
    return <PostModule<{
        startHour: number;
        startMinute: number;
        endHour: number;
        endMinute: number;
        day: number;
    }>
        prefix="newSchedule"
        path="schedule"
        onSuccess={() => {
            navigation.goBack()
        }}
        data={{
            day: 1,
            startHour: 10,
            startMinute: 0,
            endHour: 20,
            endMinute: 0,
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                optionPrefix: false,
                name: 'day',
                type: 'select',
                options: [
                    1, 2, 3, 4, 5, 6, 7
                ]
            },
            {
                nameHour: 'startHour',
                nameMinute: 'startMinute',
                type: 'time'
            },
            {
                nameHour: 'endHour',
                nameMinute: 'endMinute',
                type: 'time'
            }
        ]}
    />
}