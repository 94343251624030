import React, { useState } from 'react';
import { Image, Platform, View } from 'react-native';

import { PickerImage } from "@components/picker/picker.component";
import { Environment } from '@environment';
import { userState } from '@states/user.state';
import axios from 'axios';
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { StorageService } from '../../../../services/storage.service';
import { ImageInputProperties } from './image.properties';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ImageZoom } from '@likashefqet/react-native-image-zoom';

export function ImageInputForm<T>(properties: ImageInputProperties<T>) {
    const [image, setImage] = useState<string>()
    const [title, setTitle] = useState('')
    const [user, setUser] = useRecoilState(userState)
    const [description, setDescription] = useState('')
    const [error, setError] = useState<string>()
    const [cache, setCache] = useState<string>()
    const [designId, setDesignId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const handlerSubmit = () => {
        if (!user) return;
        setLoading(true)
        axios.post(`${Environment.api}/image`, {
            title: title,
            description: description,
            path: image,
            design: designId

        }, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            setLoading(false)
        }).catch((error) => {
            const message = error.response.data.message
            setError(message)
            setLoading(false)
            setCache(new Date().getTime().toString())
        })
    }


    const pickImage = async (uri: string) => {
        if (!user) return;

        if (uri) {
            // compress width 500
            const compressedImage = await manipulateAsync(
                uri,
                [{ resize: { width: 500 } }],
                {
                    base64: true,
                    compress: 1, format: SaveFormat.JPEG
                }
            );
            StorageService(user?.accessToken, 'image', compressedImage).then((cleanPath: string) => {
                setImage(`${cleanPath}?cache=${new Date().getTime()}`)
                properties.onChange?.(cleanPath)
            })

        }

    };
    return <View>
        {
            properties.value ? <>
                {Platform.OS === 'web' ? <img
                    src={
                        properties.value
                    }

                    style={{
                        overflow: 'hidden',
                        width: '100%',
                        borderWidth: 10
                    }}
                /> :
                    <SafeAreaView style={{
                        height: 500,
                        overflow: 'hidden',
                        backgroundColor: '#555'
                    }}>


                        <ImageZoom
                            uri={properties.value}
                            minScale={0.5}
                            minPanPointers={1}

                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            resizeMode="cover"
                        />
                    </SafeAreaView>}
            </>
                :
                <PickerImage

                    onChange={(uri) => {
                        pickImage(uri)
                    }} />}
    </View>
}