import { PutModule } from "@modules/post/put.module";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { MeetRoutes } from "../meet.routes";
import { Linking, Text, View } from "react-native";
import { IconComponent } from "@components/icon/icon.component";
import { useState } from "react";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";
import { useTranslation } from "react-i18next";

export function EditMeetScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<MeetRoutes>>();
  const route = useRoute<RouteProp<MeetRoutes, "edit">>();
  const [commissionFirstTime, setCommissionFirstTime] =
    useState<boolean>(false);
  const [commission, setCommission] = useState<number>();
  const { permissions } = usePermissionsHook(route.params.groupId);
  return (
    <PutModule<{
      name: string;
      customerId: string;
      roomId: string;
      startedAt?: string;
      finishedAt?: string;
      description: string;
      type: string;
      memberId: string;
      commission?: number;
      deposit?: number;
      budget?: number;
      size?: string;
      body?: string;
      images: string[];
      serviceId: string;
    }>
      prefix="updateMeet"
      path="meet"
      id={route.params.meetId}
      onSuccess={() => {
        navigation.goBack();
      }}
      onGet={(data) => {}}
      data={
        commission !== undefined
          ? {
              commission: commission,
            }
          : undefined
      }
      groupId={route.params.groupId}
      onCancel={() => {
        navigation.goBack();
      }}
      width={600}
      extraSubmit={{
        label: t("updateMeet.toDelete"),
        onPress: () => {
          navigation.navigate("delete", {
            meetId: route.params.meetId,
            groupId: route.params.groupId,
            callback: () => {
              navigation.goBack();
              setTimeout(() => {
                navigation.goBack();
              }, 300);
            },
          });
        },
      }}
      actions={[
        {
          icon: "eye",
          label: "customer",
          onPress: (data: any) => {
            navigation.navigate("editCustomer", {
              customerId: data.customerId,
              groupId: route.params.groupId,
              level: 2,
            });
          },
        },
        {
          label: "consent",
          icon: "plus",
          onPress: (data: any) => {
            console.log({ datarote: data });
            navigation.navigate("newConsent", {
              customerId: data.customerId,
              memberId: data.memberId,
              groupId: route.params.groupId,
            });
          },
        },
        {
          icon: "plus",
          label: "receipt",
          onPress: (data: any) => {
            navigation.navigate("newReceipt", {
              meetId: route.params.meetId,
              customerId: data.customerId,
              memberId: data.memberId,
              groupId: route.params.groupId,
            });
          },
        },
      ]}
      inputs={[
        {
          name: "serviceId",
          type: "service",
          path: "service",
          groupId: route.params.groupId,
          serviceName: "serviceId",
          depositName: "deposit",
          budgetName: "budget",
          commissionName: "commission",
          startedAtName: "startedAt",
          finishedAtName: "finishedAt",
          memberName: "memberId",
        },

        {
          name: "name",
          type: "text",
        },
        {
          name: "description",
          type: "text",
          lines: 5,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newRoom", {
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          name: "roomId",
          type: "choose",
          path: "room",
          groupId: route.params.groupId,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newCustomer", {
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          name: "customerId",
          type: "choose",
          path: "customer",
          groupId: route.params.groupId,

          renderLabel: (item) => {
            return `${item.name} ${item.surname}`;
          },
          renderDetail: (item) => {
            return item.email || undefined;
          },
        },

        {
          name: "size",
          type: "select",
          options: ["mini", "small", "medium", "big"],
        },
        {
          name: "body",
          type: "text",
        },
        {
          name: "images",
          type: "images",
        },
      ]}
    />
  );
}
