import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { RoomRoutes } from "../room.routes";

export function DeleteRoomScreen() {
    const navigation = useNavigation<NavigationProp<RoomRoutes, 'delete'>>()
    const route = useRoute<RouteProp<RoomRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteRoom'}
        path={'room'}
        id={route.params.roomId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

