// modules
import { StyleSheet } from 'react-native'

/**
 * Signature Styles
 * @constant {StyleSheet} SignatureStyles
 */
export const SignatureStyles = StyleSheet.create({
  component: {
    backgroundColor: '#fff',
    width: 320,
    height: 177,
    aspectRatio: 1.8,
    alignSelf: 'center',
    marginVertical: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    borderRadius: 10,
    elevation: 5,

  },
  webView: {
    width: 320,
    height: 177,
    backgroundColor: '#fff',
    flex: 1,
  },
})
