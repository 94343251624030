import { TextComponent } from "@components/text/text.component";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { Platform, View, useWindowDimensions } from "react-native";
import { ModalComponent } from "../../@components/modal/modal.component";
import { useWidth } from "../../hook/isWidth";
export function FilmComponent(properties: {
  prefix?: string;
  noHeader?: boolean;
  maxWidth?: number;
  children: any;
  noClose?: boolean;
  video: any;
}) {
  const navigation = useNavigation();

  const { t } = useTranslation();

  const width = useWidth();
  return (
    <>
      <View
        style={{
          flex: 1,
          backgroundColor: "#111",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            marginLeft: !width.laptop ? 0 : 40,
            width: "100%",
            maxWidth: properties.maxWidth || 600,
          }}
        >
          <ModalComponent
            maxWidth={width.laptop ? 600 : undefined}
            overlay={false}
            onClose={
              properties.noClose
                ? undefined
                : () => {
                    navigation.goBack();
                  }
            }
            scrollable
            noHeader={properties.noHeader}
            title={t(`${properties.prefix}.title`)}
            description={t(`${properties.prefix}.description`)}
          >
            {properties.children}
          </ModalComponent>
        </View>
        {width.laptop && Platform.OS === "web" && (
          <>
            <video
              autoPlay
              muted
              loop
              style={{
                opacity: 0.5,
                flex: 1,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: "100%",
                width: "100%",
                zIndex: -1,
                objectFit: "cover",
              }}
            >
              <source src={properties.video} type="video/mp4" />
            </video>
          </>
        )}
        <View
          style={{
            flex: 1,
          }}
        ></View>
      </View>
    </>
  );
}
