import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { CustomerRoutes } from "../customer.routes";

export function NewCustomerScreen() {
    const navigation = useNavigation<NavigationProp<CustomerRoutes>>()
    const route = useRoute<RouteProp<CustomerRoutes, 'new'>>()
    const level = route.params.level
    const callback = route.params.callback
    return <PostModule<{
        phone: string;
        name: string;
        surname: string;
        description: string;
        email: string;
        document: string;
        addressCountry: string;
        addressCity: string;
        addressStreet: string;
        addressZip: string;
        addressLatitude: number;
        addressLongitude: number;
        addressState: string;
        birthdate: string;
    }>
        level={level}
        prefix="newCustomer"
        path="customer"
        onSuccess={(data, xxxx) => {
            if ('items' in xxxx) {
                const item = xxxx?.items?.[0]
                const id = item?.id
                if (id) {
                    callback?.(item)
                }
            }
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        data={{
            description: '',
        }}
        inputs={[
            {
                type: 'pair',
                inputs: [
                    {
                        noDescription: true,
                        name: 'name',
                        type: 'text'
                    },
                    {
                        noDescription: true,
                        name: 'surname',
                        type: 'text'
                    },
                ]
            },
            {
                icon: 'phone',
                name: 'phone',
                type: 'text'
            },
            {
                name: 'birthdate',
                type: 'birthday'
            },
            {
                name: 'email',
                type: 'text',
                icon: 'envelope'
            },
            {
                name: 'document',
                type: 'text',
                icon: 'id-card'
            },
            {
                name: 'description',
                type: 'text',
            },
            {
                name: 'addressCountry',
                type: 'text',
                icon: 'globe',
                noDescription: true
            },
            {
                name: 'addressCity',
                type: 'text',
                icon: 'city',
                noDescription: true
            },
            {
                name: 'addressStreet',
                type: 'text',
                noDescription: true
            },
            {
                name: 'addressZip',
                type: 'text',
                noDescription: true
            },

            {
                name: 'addressState',
                type: 'text',
                noDescription: true
            },
        ]}
    />
}