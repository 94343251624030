// Modules
import { View } from 'react-native'

// Components
import { TextComponent } from '../../../text/text.component'

// Properties
import { type InputErrorHighlightProperties } from './inputErrorHighlight.properties'

// Styles
import { InputErrorHighlightStyles } from './inputErrorHighlight.styles'

/**
 * Input error highlight component
 * @description Render input error if its needed
 * @param {InputErrorHighlightProperties} properties
 * @return {JSX.Element | null}
 */
export function InputErrorHighlight(
  properties: InputErrorHighlightProperties,
): JSX.Element | null {
  if (properties.error === undefined) {
    return null
  }

  return (
    <View style={InputErrorHighlightStyles.error}>
      <TextComponent
        variant='errorInverted'
        styles={{
          layout: InputErrorHighlightStyles.errorText,
        }}
        content={properties.error}
      />
    </View>
  )
}
