import React, { useEffect, useState } from 'react';
import { Button, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';
import dayjs from 'dayjs';
import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { BirthdayInputProperties } from './birthday.properties';
import { TextComponent } from '@components/text/text.component';
import { ScrollView } from 'react-native-gesture-handler';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';

export function BirthdayInputForm<T>(properties: BirthdayInputProperties<T>) {
    const startDate = properties.value ? dayjs(properties.value) : undefined;
    const [showStartDate, setShowStartDate] = useState(false);
    const [showEndDate, setShowEndDate] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const { t, i18n } = useTranslation()


    return <>
        <View
            key={properties.value}
            style={{
                borderWidth: 1,
                borderRadius: 5,
                borderColor: '#ddd',
                zIndex: 1000,
            }}
        >
            <View style={{
                flexDirection: 'row',
                gap: 10,
                zIndex: 1000,
            }}>
                <TouchableOpacity onPress={() => setShowCalendar(!showCalendar)}
                    style={{
                        flexDirection: 'row',
                        gap: 10,
                    }}
                >
                    <View style={{
                        flexDirection: 'row',
                        gap: 10,
                        height: 40,
                        alignContent: 'center',
                        alignItems: 'center',
                        paddingLeft: 10
                    }}>
                        <IconComponent name="calendar" style={{
                            icon: {
                                fontSize: 24,
                                opacity: 0.5,
                            }
                        }} />

                        <TextComponent styles={{
                            text: {
                                fontWeight: 'bold',
                            }
                        }}>{startDate?.format?.('dddd, YYYY-MM-DD')}</TextComponent>
                    </View>
                </TouchableOpacity>

            </View>
        </View >
        {
            showCalendar && <View style={{
                padding: 20,
                borderWidth: 1,
                borderRadius: 5,
                borderColor: '#ddd',
                marginTop: 10,
            }}>
                <DateTimePicker

                    mode="single"
                    date={startDate}
                    locale={i18n.language}
                    onChange={(params) => {
                        // set start date, and end date + 1 hour with minutes 00
                        const dateOfCalendar = dayjs(params.date);
                        // get current hour and minute
                        properties.onChange?.(dateOfCalendar.format('YYYY-MM-DD'))
                        setShowCalendar(false)


                    }}
                />

            </View>

        }
    </>
}