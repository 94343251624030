import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, View } from "react-native";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { StudioRoutes } from "../../studio.routes";
import { ConfigurationRoutes } from "../configuration.routes";
import { MapComponent } from "@components/map/map.component";
import React, { useEffect, useState } from "react";
import { SubmitComponent } from "@components/submit/submit.component";
import axios from "axios";
import { Environment } from "@environment";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { TimezoneModule } from "@modules/selectors/timezone/timezone.module";

export function AddressConfigurationScreen() {
    const { t } = useTranslation()
    const [user] = useRecoilState(userState)
    const navigation = useNavigation<NavigationProp<ConfigurationRoutes, 'address'>>()
    const route = useRoute<RouteProp<ConfigurationRoutes, 'address'>>()
    const [addressLatitude, setAddressLatitude] = useState<number>()
    const [timezone, setTimezone] = useState<string>()
    const [addressLongitude, setAddressLongitude] = useState<number>()
    const [addressStreet, setAddressStreet] = useState<string>()
    const [addressCity, setAddressCity] = useState<string>()
    const [addressCountry, setAddressCountry] = useState<string>()
    const [addressState, setAddressState] = useState<string>()
    const [addressZip, setAddressZip] = useState<string>()
    const [groupId, setGroupId] = useState<string>()
    useEffect(() => {
        if (user) {
            if (route.params.groupId) {
                axios.get(`${Environment.api}/group?groupId=${route.params.groupId}&component=address`, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                }).then((response) => {
                    const data = response.data.items?.[0]
                    setGroupId(data.id)
                    setAddressLatitude(data.addressLatitude)
                    setAddressLongitude(data.addressLongitude)
                    setAddressStreet(data.addressStreet)
                    setAddressCity(data.addressCity)
                    setAddressCountry(data.addressCountry)
                    setAddressState(data.addressState)
                    setAddressZip(data.addressZip)
                    setTimezone(data.timezone)
                })
            }
        }
    }, [user, route.params.groupId])

    return (
        <ModalComponent
            title={t('configuration.map')}
            description={t('configuration.mapDescription')}
            position="left"
            level={1}
            onClose={() => {
                navigation.goBack()
            }}
            maxWidth={600}
        >
            {
                groupId && <ScrollView style={{
                    flex: 1,
                }}>
                    <TimezoneModule timezone={timezone} onSelect={(item) => {
                        setTimezone(item.id)
                    }} />
                    <MapComponent
                        addressCity={addressCity}
                        addressCountry={addressCountry}
                        addressLatitude={addressLatitude}
                        addressLongitude={addressLongitude}
                        addressState={addressState}
                        addressStreet={addressStreet}
                        addressZip={addressZip}

                        onChange={(address) => {
                            setAddressLatitude(address.addressLatitude)
                            setAddressLongitude(address.addressLongitude)
                            setAddressStreet(address.addressStreet)
                            setAddressCity(address.addressCity)
                            setAddressCountry(address.addressCountry)
                            setAddressState(address.addressState)
                            setAddressZip(address.addressZip)

                        }} />
                    <View style={{
                        padding: 20,
                        backgroundColor: '#eee'
                    }}>
                        <SubmitComponent
                            title={t('configurationAddress.save')}
                            onPress={() => {
                                if (user) {
                                    axios.put(`${Environment.api}/group/address?groupId=${route.params.groupId}`, {
                                        timezone,
                                        addressCity,
                                        addressCountry,
                                        addressLatitude,
                                        addressLongitude,
                                        addressState,
                                        addressStreet,
                                        addressZip
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${user.accessToken}`
                                        }
                                    }).then(() => {
                                        navigation.goBack()
                                    })
                                }
                            }}
                        />
                    </View>
                </ScrollView>
            }
        </ModalComponent>

    )
}