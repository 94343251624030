import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { ServiceRoutes } from "../service.routes";
import { useState } from "react";

export function NewMemberServiceScreen() {
    const navigation = useNavigation<NavigationProp<ServiceRoutes>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'newMemberService'>>()
    const [priceType, setType] = useState<'EXACT' | 'MINUTE'>('EXACT')

    const inputPrice = priceType === 'EXACT' ?
        [{
            name: 'exactPrice',
            type: 'price',
            groupId: route.params.groupId
        }]
        :
        [{
            name: 'minimumPrice',
            type: 'price',
            groupId: route.params.groupId
        }, {
            name: 'minutePrice',
            type: 'priceMinute',
            groupId: route.params.groupId
        }]

    return <PostModule<{
        minimumPrice: number;
        exactPrice: number;
        minutePrice: number;
        depositPercentage: number;
        type: 'EXACT' | 'MINUTE',
        memberId: string,
        serviceId: string
    }>
        key={priceType}
        title={route.params.serviceName}
        description={route.params.memberName}
        path="service/member"
        onSuccess={() => {
            navigation.goBack()
        }}
        prefix="newMemberService"
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        width={350}
        data={{
            minutePrice: 0,
            exactPrice: 0,
            minimumPrice: 0,
            depositPercentage: 20,
            type: priceType,
            memberId: route.params.memberId,
            serviceId: route.params.serviceId
        }}
        inputs={[

            {
                name: 'type',
                type: 'select',
                options: ['EXACT', 'MINUTE'],
                onSelect: (value) => {
                    setType(value as any)
                }
            },

            ...inputPrice,

            {
                name: 'depositPercentage',
                type: 'number',
            },

        ]}
    />
}