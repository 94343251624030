import { Platform } from "react-native";
import { persistentAtom } from './persistence/react';
import { persistentAtom as persistentAtomNative } from './persistence/react-native';
import { atom } from "recoil";


export type CacheType = number


export const counterState = atom<CacheType | null>({
    key: "cacheType",
    default: null,

});


