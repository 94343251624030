import axios from "axios";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import { Environment } from "../../../environment";
import { MainRoutes } from "../../screens/main.routes";
import { userState } from "../../states/user.state";
import { BoxComponent } from "./box.component";
import { FiveStep } from "./steps/five.step";
import { FourStep } from "./steps/four.step";
import { OneStep } from "./steps/one.step";
import { ThreeStep } from "./steps/three.step";
import { TwoStep } from "./steps/two.step";

export function OnboardingScene(properties: {
    navigation: any
}) {
    const navigation = useNavigation<NavigationProp<MainRoutes>>()
    const [services, setServices] = useState<string[]>([])
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [language, setLanguage] = useState<string>('en')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [legal, setLegal] = useState<boolean>(false)
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useRecoilState(userState)
    const [error, setError] = useState<string>()
    const [cache, setCache] = useState<string>()
    return <BoxComponent
        key={`${step}-${error}-${cache}`}
        loading={loading}
        goBack={step !== 1 ? () => {
            if (step === 1) {
                properties.navigation.navigate('INKONSKY')
                if (Platform.OS === 'web') {
                    // change url without reload
                    window.history.pushState({}, '', '/')
                }
            }
            else setStep(step - 1)
        } : undefined}>

        {step === 1 && <OneStep
            setLanguage={setLanguage}
            setStep={setStep} />}
        {step === 2 && <TwoStep setStep={setStep} setServices={setServices} services={services} />}
        {step === 3 && <ThreeStep setStep={setStep} />}
        {
            step === 4 && <FourStep
                error={error}
                setStep={setStep} setName={setName} setSurname={setSurname} setEmail={setEmail} name={name} surname={surname} email={email}
                submitPartial={() => {

                    setLoading(true)
                    axios.post(`${Environment.api}/user/partial`, {
                        email: email,
                        name: name,
                        surname: surname,
                    }).then((response) => {
                        setTimeout(() => {
                            setError(undefined)
                            setLoading(false)

                            setStep(5)
                        }, 1000);

                    }).catch((error) => {
                        setTimeout(() => {
                            setLoading(false)
                            const message = error.response.data.message
                            if (message === 'email is already used') {
                                properties.navigation.navigate('Login')
                                if (Platform.OS === 'web') {
                                    window.history.pushState({}, '', '/')
                                }
                            }
                            else {
                                setError(message)
                                setCache(new Date().getTime().toString())
                            }
                        }, 1000);
                    })

                }}


            />
        }
        {
            step === 5 && <FiveStep
                name={name}
                error={error}
                submitRegister={() => {
                    setError(undefined)
                    setLoading(true)
                    axios.post(`${Environment.api}/user`, {
                        isOnboarding: true,
                        email: email,
                        password: password,
                        name: name,
                        surname: surname,
                        username: username,
                        language: language,
                        legal: legal
                    }).then((response) => {
                        setLoading(false)
                        setError(undefined)
                        const user = response.data.user
                        if (user) {
                            setUser(user)
                            navigation.navigate('new')


                        }
                        else {

                        }
                    }).catch((error) => {
                        setLoading(false)
                        const message = error.response.data.message
                        setError(message)
                        setCache(new Date().getTime().toString())
                    })

                }}

                setStep={setStep} setLanguage={setLanguage} username={username} setUsername={setUsername} password={password} setPassword={setPassword} legal={legal} setLegal={setLegal} language={language} />
        }

    </BoxComponent >
}