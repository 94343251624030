// Modules
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Translations
import en from './en.new.translations.json'
import es from './es.new.translations.json'
import de from './de.new.translations.json'
import it from './it.new.translations.json'
import fr from './fr.new.translations.json'
import pt from './pt.new.translations.json'

/**
 * @description Overrides i18n options and prevent return null when we did a translation using
 */
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

// Setup and import different langs files
const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  pt: {
    translation: pt,
  },

}

/**
 * @description Init i18n configuration
 */
void i18n.use(initReactI18next).init({
  resources, // Use different langs options
  returnNull: false, // Prevent return null when we did a translation
  lng: 'en', // Default language
  fallbackLng: 'en', // If the default language does not works, we use this
  compatibilityJSON: 'v3', // JSON compatibility version
  interpolation: {
    escapeValue: false, // Disable escape values because React do that by default
  },
})

export { i18n as TranslationsService }
