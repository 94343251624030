import { Platform, View, useWindowDimensions } from "react-native";

export function CenterComponent(properties: {
    position?: 'center' | 'right',
    children: any,
    height?: number
}) {
    const { width } = useWindowDimensions()
    if (Platform.OS === 'web' && width > 800)
        return <>
            <View style={{
                position: 'absolute',
                left: 0, right: 0, top: 0, bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)'
            }}></View>
            <View style={{
                borderWidth: 1,
                borderColor: '#ddd',
                maxWidth: 400,
                width: 400,
                maxHeight: properties.height,
                flex: 1,
                borderRadius: 5,
                backgroundColor: '#fff',
                overflow: 'hidden',
                marginHorizontal: properties.position === 'right' ? 0 : 'auto',
                marginVertical: properties.position === 'right' ? 0 : properties.height ? 'auto' : 50,
                position: properties.position === 'right' ? 'absolute' : 'relative',
                top: properties.position === 'right' ? 20 : undefined, right: properties.position === 'right' ? 20 : undefined, bottom: properties.position === 'right' ? 20 : undefined
            }}>
                {properties.children}
            </ View>
        </>
    else
        return properties.children
}