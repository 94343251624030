import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { TemplateRoutes } from "../template.routes";
import { useTranslation } from "react-i18next";

export function NewTemplateScreen() {
    const navigation = useNavigation<NavigationProp<TemplateRoutes>>()
    const route = useRoute<RouteProp<TemplateRoutes, 'new'>>()
    const { t } = useTranslation()
    return <PostModule<{
        name: string,
        content: string,
        showMaterials: boolean,
        showImages: boolean,
        showSignatures: boolean,
        materialsText: string,
        imagesText: string,
        signaturesText: string,
        showLogotype: boolean,
        showSeal: boolean,
        showBarcode: boolean,
        showFooter: boolean,
        showHeader: boolean,
        customCSS: boolean,
        customCSSText: string,
    }>
        width={900}
        prefix="newTemplate"
        path="template"
        data={{
            customCSS: false,
            customCSSText: '',
            showHeader: false,
            showFooter: false,
            showBarcode: false,
            showSeal: false,
            showLogotype: false,
            showMaterials: true,
            showImages: true,
            showSignatures: true,
            materialsText: '',
            imagesText: '',
            signaturesText: '',
        }}
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            },
            {
                onNewLabel: t('newTemplate.content'),
                onNew: (callback) => {
                    navigation.navigate('variables', {
                        groupId: route.params.groupId,
                    })
                },
                name: 'content',
                type: 'editor'
            },
            {
                name: 'showMaterials',
                type: 'checkbox'
            },
            {
                name: 'showImages',
                type: 'checkbox'
            },
            {
                name: 'showSignatures',
                type: 'checkbox'
            },
            {
                name: 'materialsText',
                type: 'editor'
            },
            {
                name: 'imagesText',
                type: 'editor'
            },
            {
                name: 'signaturesText',
                type: 'editor'
            },
            // {
            //     name: 'showLogotype',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'showSeal',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'showBarcode',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'showFooter',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'showHeader',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'customCSS',
            //     type: 'checkbox'
            // },
            // {
            //     name: 'customCSSText',
            //     type: 'text'
            // },
        ]}
    />
}