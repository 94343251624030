import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { menuState } from "@states/menu.state";
import { userState } from "@states/user.state";
import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { ConsentRoutes } from "../consent.routes";
import { NewConsentForm } from "./new.consent.form";


export function NewConsentScreen() {
    const [memberSignature, setMemberSignature] = useState<string>()
    const navigation = useNavigation<NavigationProp<ConsentRoutes>>()
    const route = useRoute<RouteProp<ConsentRoutes, 'new'>>()
    const [showSignatures, setShowSignatures] = useState<boolean>(false)
    const [menu, setMenu] = useRecoilState(menuState)
    const ref = useRef(null);

    const [customerSignature, setCustomerSignature] = useState<string>()

    const [user, setUser] = useRecoilState(userState)


    const [consent, setConsent] = useState<{
        consent: string,
        customCSS: boolean,
        customCSSText: string,
        templateId: string,
        imagesText: string,
        materialsText: string,
        name: string,
        showBarcode: boolean,
        showFooter: boolean,
        showHeader: boolean,
        showImages: boolean,
        showLogotype: boolean,
        showMaterials: boolean,
        showSeal: boolean,
        showSignatures: boolean,
        signaturesText: string

    }>()





    return <NewConsentForm />

}