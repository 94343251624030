import { IconComponent } from "@components/icon/icon.component"
import { IconType } from "@components/icon/icon.types"
import { PayNowComponent } from "@components/payNow/payNow.component"
import { useDrawerStatus } from "@react-navigation/drawer"
import { DrawerActions, useNavigation } from "@react-navigation/native"
import { StatusBar, Text, TouchableOpacity } from "react-native"
import { View } from "react-native"
import { useWeb } from "../../hook/web.hook"
import { useMemo } from "react"
export type actionHeaderType = {
    label: string,
    icon: IconType,
    onPress: () => void
}
export function HeaderComponent(properties: {
    title: string,
    paddingBottom?: number
    description: string
    actions?: actionHeaderType[]
    groupId?: string
}) {
    const isWeb = useWeb()
    const navigation = useNavigation()
    const drawer = useDrawerStatus()
    // status bar height
    const statusBarHeight = StatusBar.currentHeight || 0
    const payNow = useMemo(() => {
        if (!properties.groupId) return null
        return <PayNowComponent groupId={properties.groupId} />
    }, [properties.groupId])
    return <>
        {isWeb === false && <IconComponent name="bars" style={{
            box: {
                height: 50,
                width: 50,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 25,
                position: 'absolute',
                top: 20 + statusBarHeight,
                zIndex: 100,
                left: 0,
            },
            icon: {
                color: 'black',
                fontSize: 20
            }
        }}
            onPress={() => {

                if (drawer === 'open') {
                    navigation.dispatch(DrawerActions.closeDrawer())
                } else {
                    navigation.dispatch(DrawerActions.openDrawer())
                }
            }}
        />}
        <View style={{
            paddingVertical: 20,
            paddingBottom: 'paddingBottom' in properties ? properties.paddingBottom : 30,

        }}>


            <View style={{
                flex: 1,
                paddingLeft: isWeb ? 0 : 60,
            }}>
                <Text style={{
                    fontSize: 22,
                    fontWeight: 'bold'
                }}>{properties.title}</Text>
                <Text style={{
                    fontSize: 14,
                    opacity: 0.4,
                    fontWeight: '300'
                }}>{properties.description}</Text>
            </View>
            {properties.actions && <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10
            }}>
                {properties.actions?.map((action, index) => {
                    return <TouchableOpacity key={index} style={{
                        padding: 7,
                        paddingHorizontal: 12,
                        backgroundColor: '#fff',
                        borderWidth: 1,
                        borderColor: '#ccc',
                        borderRadius: 5,

                        flexDirection: 'row',
                    }}
                        onPress={action.onPress}
                    >
                        <IconComponent
                            style={{
                                icon: {
                                    fontWeight: 'bold',
                                    color: '#65c366',
                                    fontSize: 15
                                }
                            }}
                            name={action.icon} />
                        <Text style={{
                            fontWeight: 'bold',
                            marginLeft: 8,
                            color: '#333'
                        }}>{action.label?.toUpperCase?.()}</Text>
                    </TouchableOpacity>
                })}
            </View>}

        </View>
        {payNow}
    </>
}