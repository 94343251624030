import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListRoomScreen } from "./list/list.room.screen";
import { EditRoomScreen } from "./edit/edit.room.screen";
import { DeleteRoomScreen } from "./delete/delete.room.screen";
import { NewRoomScreen } from "./new/new.room.screen";
import { RoomRoutes } from "./room.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";

const stack = createNativeStackNavigator<RoomRoutes>();

export function RoomNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'rooms'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListRoomScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewRoomScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="meet"
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditRoomScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteRoomScreen}
        />
    </stack.Navigator>
}
