import { Environment } from "@environment"
import { useIsFocused } from "@react-navigation/native"
import { userState } from "@states/user.state"
import axios from "axios"
import { useEffect, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"

export function usePermissionsHook(groupId: string) {
    const focus = useIsFocused()
    const [user] = useRecoilState(userState)
    const [permissions, setPermissions] = useState<{
        id: string | undefined,
        meeterAll: boolean
        administrator: boolean,
        consenterAll: boolean,
        accounterAll: boolean,
        availabilityView: boolean,
    }>({
        id: undefined,
        meeterAll: false,
        administrator: false,
        consenterAll: false,
        accounterAll: false,
        availabilityView: false,
    })
    const getPermissions = () => {
        if (!user) return
        if (!groupId) return
        axios.get(`${Environment.api}/group/permission?groupId=${groupId}&component=permissions`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        })
            .then((response) => {
                const group = response.data?.items?.[0]
                if (group) {
                    setPermissions(group)
                }
            })
    }
    useEffect(() => {
        getPermissions()
    }, [groupId, user, focus])

    return {
        permissions,
    }
}