import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { BookingRoutes } from "../booking.routes";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

export function ConfirmBookingScreen() {
    const navigation = useNavigation<NavigationProp<BookingRoutes>>()
    const route = useRoute<RouteProp<BookingRoutes, 'edit'>>()
    const { t } = useTranslation()
    return <PutModule<{
        name: string
        customerId: string;
        roomId: string;
        startedAt?: string;
        finishedAt?: string;
        description: string;
        type: string
        memberId: string;
        deposit?: number;
        budget?: number;
        size?: string;
        body?: string;
        images: string[]
        serviceId: string;
    }>
        width={400}
        level={1}
        prefix="confirmBooking"
        path="meet"
        id={route.params.bookingId}
        onSuccess={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}

        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        data={{
            type: 'meet'
        }}
        contentComponent={() => {
            return <View>
                <Text>{t('confirmBooking.text')}</Text>
            </View>
        }}
        inputs={[

        ]}
    />
}
