import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ConsentRoutes } from "../consent.routes";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";

export function ListConsentScreen() {
    const navigation = useNavigation<NavigationProp<ConsentRoutes, 'list'>>()
    const route = useRoute<RouteProp<ConsentRoutes, 'list'>>()
    const permissions = usePermissionsHook(route?.params?.groupId)

    return <TableComponent<{
        id: string
        status: boolean,
        createdAt: string
        Customer: {
            name: string
            surname: string
        },
        Member: {
            name: string
        },

    }>
        prefix="listConsent"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId,
                            customerId: route.params.customerId
                        })
                    }
                }
            ]
        }}
        groupId={route.params.groupId}
        groupBy="createdAt"
        search={true}
        pagination={false}
        path={`consent?groupId=${route.params.groupId}${route.params.customerId ? `&customerId=${route.params.customerId}` : ''}`}
        filters={
            route.params.customerId ? [
                {
                    value: route.params.customerId,
                    name: 'customerId',
                    onPress: () => {
                        navigation.navigate('list', {
                            groupId: route.params.groupId
                        })
                    }
                }
            ] : []
        }
        paginator={{
            limit: 40,

        }}
        membering={permissions?.permissions?.consenterAll ? true : false}
        columns={[
            {
                always: true,
                phone: false,
                menu: {
                    icon: 'eye',
                    label: 'download',
                },
                icon: 'eye',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('signature', {
                        groupId: route.params.groupId,
                        consentId: item.id
                    })
                }
            },
            {
                always: true,
                phone: false,
                menu: {
                    icon: 'pen',
                    label: 'edit',
                },
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        consentId: item.id
                    })
                }
            },
            {
                always: true,
                renderIcon: (item) => {
                    return item.status ? 'check' : 'signature'
                },
                type: 'icon',
            },
            {
                title: 'customer',
                always: true, always: true, renderLabel: (item) => { return `${item.Customer.name} ${item.Customer.surname}` },
                width: 200,
                type: 'text',
                subtitle: {
                    phone: true,
                    render: (item) => { return `${item.Member.name}` },
                }
            },
            {
                phone: false,

                title: 'member',
                renderLabel: (item) => { return `${item?.Member?.name}` },
                renderColor: (item) => { return item?.Member?.color },
                width: 200,
                type: 'member',
            },
            {
                phone: false,

                title: 'createdAt',
                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 220,
            },


            {
                phone: false,
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',
                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        consentId: item.id
                    })
                }
            },
        ]}

    />

}
