import { IconComponent } from "@components/icon/icon.component"
import { userState } from "@states/user.state"
import { useTranslation } from "react-i18next"
import { Pressable, Text, View } from "react-native"
import { useRecoilState } from "recoil"

export function ChatComponent(properties: {
    groupId?: string
}) {
    const [user] = useRecoilState(userState)
    const { t } = useTranslation()


    if (!user) return <></>

    return <>
        <Pressable style={{
            backgroundColor: '#65c366',
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 20,
            alignItems: 'center',
            gap: 10,
        }} onPress={() => {
            // @ts-ignore
            window.ChatraID = 'SEMuNF3GvcmTwndZw';
            // @ts-ignore
            window.ChatraSetup = {
                startAutomatically: false,
                startHidden: true
            };
            const path = 'https://call.chatra.io/chatra.js'

            // create script element
            const script = document.createElement('script');
            script.src = path;
            script.async = true;
            // @ts-ignore
            script.setAttribute('data-chatra', window.ChatraID);

            // load
            document.body.appendChild(script);

            // event listener
            script.onload = () => {


                let config = {
                    ID: 'SEMuNF3GvcmTwndZw',
                    // open auto
                    open: false,
                    colors: {
                        buttonText: '#f0f0f0', /* chat button text color */
                        buttonBg: '#565656'    /* chat button background color */
                    },
                    startHidden: true,


                }
                // @ts-ignore
                window.ChatraIntegration = {
                    /* main properties */
                    name: user?.name + ' ' + user?.surname,
                    email: user?.email,
                    phone: user?.phone,
                    notes: `Group: ${properties.groupId}`,
                };

                // update data integration
                // @ts-ignore
                Chatra('updateIntegrationData', {
                    name: user?.name + ' ' + user?.surname,
                    email: user?.email,
                    phone: user?.phone,
                    notes: `Group: ${properties.groupId}}`,
                });
                // @ts-ignore
                Chatra('show')
                // @ts-ignore
                Chatra('expandWidget', [focus])

            }

        }}>
            <IconComponent name="comments" style={{
                icon: {
                    fontSize: 20, color: '#fff'
                }
            }} />
            <Text style={{
                color: '#fff',
                fontSize: 14,
                fontWeight: 'bold',
            }}>
                {t('chat.with.us')}
            </Text>
            <View style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: 5,
                padding: 7,
                marginLeft: 'auto',
                display: 'flex',
                marginRight: 0,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={{
                    color: '#fff',
                    fontSize: 12,
                    fontWeight: 'bold',
                }}>CHAT</Text>
            </View>
        </Pressable >
    </>
}