import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { MeetRoutes } from "../meet.routes";

export function DeleteMeetScreen() {
    const navigation = useNavigation<NavigationProp<MeetRoutes, 'delete'>>()
    const route = useRoute<RouteProp<MeetRoutes, 'delete'>>()

    return <DeleteComponent
        prefix={'deleteMeet'}
        path={'meet'}
        id={route.params.meetId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.goBack()
        }}
        onDeleted={() => {
            if (route.params.callback) {
                route.params.callback()
            }
            else navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

