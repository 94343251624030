import { NavigationProp } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { userState } from "@states/user.state";
import { useRecoilState } from "recoil";
import { useWeb } from "../../hook/web.hook";
import { LoginScene } from "./login/login.scene";
import { WelcomeRoutes } from "./welcome.routes";
import { OnboardingScene } from "../onboarding/onboarding.scene";

export type MainStackNavigation = NavigationProp<WelcomeRoutes>;

const Drawer = createNativeStackNavigator<WelcomeRoutes>();


export function WelcomeNavigator() {
    const [user, setUser] = useRecoilState(userState)

    const isWeb = useWeb()
    return (
        <Drawer.Navigator


            screenOptions={{
                headerShown: isWeb ? false : true,

            }} >
            <Drawer.Screen name="login" component={LoginScene} />
            <Drawer.Screen name="register" component={OnboardingScene} />
        </Drawer.Navigator >
    );
}


