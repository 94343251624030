import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { Chart, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ScrollView, Text, View } from "react-native";
import { StudioRoutes } from '../studio.routes';
import { Environment } from '@environment';
import { useRecoilState } from 'recoil';
import { userState } from '@states/user.state';
import axios from 'axios';
import { IconType } from '@components/icon/icon.types';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { HeaderComponent } from '@components/header/header.component';
import LoaderComponent from '@components/loader/loader.component';
import 'chartjs-adapter-moment';
import { priceUtility } from '../../../utilities/price/priceUtility';
import { useWidth } from '../../../hook/isWidth';
import dayjs from 'dayjs'

Chart.register(...registerables);

type PackType = {
    label: string
    data: number[]
    backgroundColor: string

}
export function DashboardScreen() {
    const { t } = useTranslation()
    const [user, setUser] = useRecoilState(userState)
    const route = useRoute<RouteProp<StudioRoutes, 'dashboard'>>()
    const groupId = route?.params.groupId
    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())
    const focus = useIsFocused()
    const width = useWidth()
    const [loading, setLoading] = useState(true)
    const [cache, setCache] = useState<string>()
    const [data, setData] = useState<{
        charts: {
            meets: PackType[],
            budgets: PackType[],
            customers: PackType[],
        }
        dates: {
            start: string,
            end: string
        },
        totals: {
            consents: number,
            customers: number,
            meets: number,
            receipts: number,
        },
        commissions: {
            labels: string[],
            datasets: [{
                label: 'Comission',
                data: number[],
            }],
        }
    }>()


    const [counters, setCounters] = useState<{
        Customers: number
        Materials: number
        Products: number
        Receipts: number
        Services: number,
        Templates: number,
        Consents: number,
        Meets: number,
        Bookings: number,
        Members: number,
        Schedules: number,
        Rooms: number

    }>({
        Customers: 0,
        Materials: 0,
        Products: 0,
        Receipts: 0,
        Services: 0,
        Templates: 0,
        Consents: 0,
        Meets: 0,
        Bookings: 0,
        Members: 0,
        Schedules: 0,
        Rooms: 0

    })

    useEffect(() => {
        if (!user) return
        if (!route?.params?.groupId) return
        fetch(`${Environment.api}/group/counter?groupId=${route.params.groupId}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            return response.json()
        }).then((data) => {
            setCache(new Date().toISOString())
            if (data?.items?.[0]?._count) setCounters(data.items[0]._count)
        })
    }, [route?.params?.groupId, focus])
    useEffect(() => {
        setLoading(true)
        setData(undefined)
        axios.get(`${Environment.api}/dashboard?groupId=${groupId}&month=${month}&year=${year}`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        }).then((response) => {

            const meets: any = response.data
            // dividir por 100 cada importe de budget
            meets.charts.budgets = meets.charts.budgets.map((budget: any) => {
                return {
                    ...budget,
                    data: budget.data.map((value: number) => value / 100)
                }
            })
            setCache(new Date().toISOString())


            setData(meets)

        }).finally(() => {
            setLoading(false)
        })
    }, [focus, year, month])

    const RenderChart = (settings: {
        prefix: string,
        icon: IconType,
        height?: number,
        children: any
    }) => {
        return <View style={{
            overflow: 'hidden',
            backgroundColor: '#eee',
            margin: 20,
            marginBottom: 0,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.15,
            shadowRadius: 53.84,
            elevation: 5,
            borderRadius: 5,
        }}>

            <View style={{
                flexDirection: 'row',
                borderBottomColor: '#ddd',
                borderBottomWidth: 1,
                alignContent: 'center',
                alignItems: 'center',
            }}>
                <IconComponent name={settings.icon} style={{
                    icon: {
                        fontSize: 30,
                        height: 60,
                        width: 60,
                        alignContent: 'center',
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        opacity: 0.5

                    }
                }} />
                <View>
                    <Text style={{
                        fontSize: 15,
                        fontWeight: 'bold'
                    }}>{t(`dashboard.${settings.prefix}.title`)}</Text>
                    <Text style={{
                        fontSize: 13,
                        opacity: 0.5
                    }}>{t(`dashboard.${settings.prefix}.subtitle`)}</Text>
                </View>

            </View>
            <View style={{
                height: settings.height || 300,
                backgroundColor: '#fff',
                paddingBottom: 20,
                paddingTop: 10,
                paddingLeft: 20,
                paddingRight: 20
            }}>
                {settings.children}
            </View>
            <View style={{
                padding: 6,

                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                backgroundColor: '#444',
                borderBottomEndRadius: 5,
                borderBottomStartRadius: 5,
            }}>
                <IconComponent name='info-circle' style={{
                    icon: {
                        fontSize: 20,
                        height: 30,
                        width: 30,
                        alignContent: 'center',
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        opacity: 0.5,
                        color: '#fff'

                    }
                }} />
                <Text style={{
                    color: '#fff',
                    fontSize: 12,

                }}>{t(`dashboard.${settings.prefix}.description`)}</Text>
            </View>
        </View>
    }
    const renderCounter = (properties: {
        label: string,
        count: number,
        total: number
        icon: IconType
    }) => {
        return <View

            style={{
                backgroundColor: '#fff',
                borderRadius: 5,
                maxWidth: width.laptop ? 230 : undefined,
                flex: 1,
                minWidth: 190,
                overflow: 'hidden',
            }}>

            <View style={{
                padding: 10,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}>

                <Text style={{
                    fontSize: 23,
                    fontWeight: 'bold',
                    marginRight: 10,
                    marginLeft: 10
                }}>{properties.count}</Text>
                <Text>{t(`dashboard.${properties.label}.count`)}</Text>
                <IconComponent name={properties.icon} style={{
                    box: {
                        position: 'absolute',
                        right: 10,
                        top: '50%',
                        marginTop: -20,
                        height: 40,
                        width: 40,
                    },
                    icon: {
                        height: 40,
                        width: 40,
                        fontSize: 30,

                        alignContent: 'center',
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        opacity: 0.5

                    }
                }} />
            </View>
            <View style={{
                padding: 6,

                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                backgroundColor: '#444',
                borderBottomEndRadius: 5,
                borderBottomStartRadius: 5,
                flexWrap: 'nowrap'
            }}>
                <IconComponent name='info-circle' style={{
                    icon: {
                        fontSize: 20,
                        height: 30,
                        width: 30,
                        alignContent: 'center',
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        opacity: 0.5,
                        color: '#fff'

                    }
                }} />
                <Text style={{
                    fontWeight: 'bold',
                    color: '#fff',
                    marginRight: 4,
                }}>
                    {properties.total}
                </Text>
                <Text
                    numberOfLines={1}
                    style={{
                        color: '#fff',
                        fontSize: 12,

                    }}> {t(`dashboard.${properties.label}.total`)}</Text>
            </View>
        </View>
    }

    const labelDays = (start: string, end: string) => {
        // create labels for days of month based on start and end date with timezone and dayjs
        const startDate = dayjs(start)
        const endDate = dayjs(end)
        const monthDays = endDate.diff(startDate, 'day') + 1


        const days = []
        for (let i = 0; i < monthDays; i++) {
            const date = startDate.add(i, 'day').format('YYYY-MM-DD')


            days.push(date)
        }
        return days
    }
    const plugin = {

        id: "increase-legend-spacing",
        beforeInit(chart: any) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
                // Call original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)();
                // Change the height as suggested in another answers
                this.height += 10;
            }
        }
    };

    console.log({
        labels: labelDays(data?.dates.start || '', data?.dates.end || ''),
        meets: data?.charts.meets || []
    })

    return <View style={{
        flex: 1,
    }}
        key={`${month}-${year}-${cache}`}
    >
        {
            loading && <LoaderComponent />
        }
        <ScrollView style={{
            backgroundColor: '#ddd',
            paddingBottom: 20,
        }}

        >

            <View>
                <View style={{
                    paddingLeft: 20,
                }}>
                    <HeaderComponent
                        paddingBottom={20}
                        title={t(`month.${month}`) + ' ' + year}
                        description={t(`dashboard.description`)}
                    />
                </View>
                <View style={{
                    position: 'absolute',
                    right: 20,
                    zIndex: 100,
                    top: '50%',
                    marginTop: -20,
                    flexDirection: 'row',
                    gap: 20
                }}>
                    <IconComponent name='arrow-left' style={{
                        box: {

                            backgroundColor: '#fff',

                            borderRadius: 20,

                            height: 40,
                            width: 40,
                        },
                        icon: {
                            height: 40,
                            width: 40,
                            fontSize: 20,

                            alignContent: 'center',
                            textAlign: 'center',
                            textAlignVertical: 'center',
                            opacity: 0.8

                        }
                    }}
                        onPress={() => {
                            setLoading(true)
                            const newMonth = month - 1
                            if (newMonth < 0) {
                                setMonth(11)
                                setYear(year - 1)
                            } else {
                                setMonth(newMonth)
                            }
                        }}
                    />

                    <IconComponent name='arrow-right' style={{
                        box: {

                            backgroundColor: '#fff',

                            borderRadius: 20,

                            height: 40,
                            width: 40,
                        },
                        icon: {
                            height: 40,
                            width: 40,
                            fontSize: 20,

                            alignContent: 'center',
                            textAlign: 'center',
                            textAlignVertical: 'center',
                            opacity: 0.8

                        }
                    }}
                        onPress={() => {
                            setLoading(true)
                            const newMonth = month + 1
                            if (newMonth > 11) {
                                setMonth(0)
                                setYear(year + 1)
                            } else {
                                setMonth(newMonth)
                            }
                        }}
                    />

                </View>
            </View>
            <View
                key={cache}
                style={{
                    flexDirection: 'row',
                    flex: 1,
                    padding: 20,
                    paddingTop: 0,
                    paddingBottom: 0,
                    gap: 20,
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    flexWrap: 'wrap',
                }}>

                {
                    renderCounter({
                        label: 'meet',
                        count: data?.totals.meets || 0,
                        total: counters.Meets,
                        icon: 'calendar-day'
                    })
                }
                {
                    renderCounter({
                        label: 'customer',
                        count: data?.totals.customers || 0,
                        total: counters.Customers,
                        icon: 'users'
                    })
                }
                {
                    renderCounter({
                        label: 'consent',
                        count: data?.totals.consents || 0,
                        total: counters.Consents,
                        icon: 'file-signature'
                    })
                }
                {/* {
                renderCounter({
                    label: 'notification',
                    count: 22,
                    total: 44,
                    icon: 'bell'
                })
            } */}

            </View>
            <RenderChart
                prefix='appointments'
                icon='calendar-day'
            >
                <Bar
                    style={{
                        opacity: loading ? 0 : 1
                    }}
                    data={{

                        labels: labelDays(data?.dates.start || '', data?.dates.end || ''),
                        datasets: data?.charts.meets || []
                    }}
                    plugins={[plugin]}
                    width={100}
                    height={50}
                    options={{

                        maintainAspectRatio: false,
                        plugins: {

                            legend: {
                                position: "top",
                                align: "start",
                                labels: {
                                    usePointStyle: true,
                                    boxWidth: 9,
                                    boxHeight: 9
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context: any) {
                                        var label = context.dataset.label || '';

                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed.y !== null) {

                                            label += context.parsed.y;
                                        }
                                        return label;
                                    }
                                }
                            }
                        },

                        scales: {

                            x: {
                                stacked: true,
                                type: 'time',
                                ticks: {
                                    maxRotation: 0,
                                    minRotation: 0,
                                    autoSkip: false,
                                    font: {
                                        size: width.tablet ? 12 : 8
                                    }
                                },
                                time: {
                                    tooltipFormat: 'DD/MM/YYYY',

                                    unit: 'day',
                                    displayFormats: {
                                        day: 'DD'
                                    }
                                },

                            },
                            y: {
                                stacked: true,
                                beginAtZero: true,
                                ticks: {
                                    precision: 0
                                },
                            }
                        }
                    }}

                />
            </RenderChart>
            <RenderChart
                prefix='budget'
                icon='calendar-day'
            >
                <Bar
                    style={{
                        opacity: loading ? 0 : 1
                    }}
                    data={{

                        labels: labelDays(data?.dates.start || '', data?.dates.end || ''),
                        datasets: data?.charts.budgets || []
                    }}
                    width={100}
                    height={50}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {

                            legend: {
                                position: "top",
                                align: "start",
                                labels: {
                                    usePointStyle: true,
                                    boxWidth: 9,
                                    boxHeight: 9
                                }
                            },

                            tooltip: {
                                callbacks: {
                                    label: function (context: any) {
                                        var label = context.dataset.label || '';

                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed.y !== null) {
                                            const total = context.parsed.y
                                            // with 00.00 price
                                            const price = total.toFixed(2)
                                            label += price;
                                        }
                                        return label;
                                    }
                                }
                            }


                        },
                        scales: {
                            x: {
                                stacked: true,
                                type: 'time',
                                ticks: {
                                    maxRotation: 0,
                                    minRotation: 0,
                                    autoSkip: false,
                                    font: {
                                        size: width.tablet ? 12 : 8
                                    }
                                },
                                time: {
                                    tooltipFormat: 'DD/MM/YYYY',
                                    unit: 'day',
                                    displayFormats: {
                                        day: 'DD'
                                    }
                                }
                            },
                            y: {
                                stacked: true,
                                beginAtZero: true,
                                ticks: {
                                    precision: 0
                                },
                            }
                        }
                    }}

                />
            </RenderChart>

            <RenderChart
                prefix='customer'
                icon='users'
            >
                <Bar
                    style={{
                        opacity: loading ? 0 : 1
                    }}
                    data={{

                        labels: labelDays(data?.dates.start || '', data?.dates.end || ''),
                        datasets: data?.charts.customers || []
                    }}
                    width={100}
                    height={50}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {

                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            x: {
                                stacked: true,
                                type: 'time',
                                ticks: {
                                    maxRotation: 0,
                                    minRotation: 0,
                                    autoSkip: false,
                                    font: {
                                        size: width.tablet ? 12 : 8
                                    }
                                },
                                time: {
                                    tooltipFormat: 'DD/MM/YYYY',

                                    unit: 'day',
                                    minUnit: 'day',
                                    displayFormats: {
                                        day: 'DD'
                                    }
                                }
                            },
                            y: {
                                stacked: true,
                                beginAtZero: true,
                                ticks: {
                                    precision: 0
                                },
                            }
                        }
                    }}
                />
            </RenderChart>
            <RenderChart
                prefix='commissions'
                icon='coins'
                height={data?.commissions ? data.commissions.labels.length * 30 + 60 : 0}
            >
                {data?.commissions ? <Bar
                    style={{
                        opacity: loading ? 0 : 1
                    }}
                    data={data?.commissions}

                    options={{
                        indexAxis: 'y',
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {

                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context: any) {
                                        var label = context.dataset.label || '';


                                        const total = context.raw
                                        // with 00.00 price
                                        const price = total.toFixed(2)
                                        label += ': ' + price;

                                        return label;
                                    }
                                }
                            }




                        },
                        scales: {
                            // format scale in price
                            x: {
                                type: 'linear',
                                position: 'bottom',
                                ticks: {
                                    callback: function (value, index, values) {
                                        // Formatea los valores del eje x con dos decimales
                                        const nu = Number(value)
                                        return nu.toFixed(2);
                                    }
                                }
                            },
                            y: {
                                stacked: true,
                                beginAtZero: true,
                                ticks: {
                                    precision: 0
                                },
                            }
                        }
                    }}
                /> : null}

            </RenderChart>
        </ScrollView>
    </View>
}