// Modules
import { StyleSheet } from 'react-native'

/**
 * Button Styles
 * @constant {StyleSheet} ButtonStyles
 */
export const ButtonStyles = StyleSheet.create({
  component: {
    paddingVertical: 0,
    paddingTop: 3,
    paddingBottom: 3,
    height: 50,
    paddingRight: 0,
    backgroundColor: '#fff',
    borderTopColor: '#0000002a',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textBox: {
    marginRight: 'auto',
    flex: 1, overflow: 'hidden',
    flexWrap: 'nowrap',

  },
  textBoxWithLabelLeft: {
    overflow: 'hidden',
    paddingRight: 10,
    flexWrap: 'nowrap',
  },
  text: {
    fontSize: 15,
    fontFamily: 'OpenSans-SemiBold',
    marginRight: 'auto',
    overflow: 'hidden',
    flexWrap: 'nowrap',

  },
  iconSuccess: {
    opacity: 0.3,
    height: 30,
    width: 30,
    marginLeft: 12,
    marginRight: 11,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    alignItems: 'center',
    borderRadius: 15,
  },
  iconSuccessShape: {
    color: '#aaa',
    fontSize: 23,
  },
  angle: {
    height: 40,
    width: 50,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  angleShape: {
    color: '#aaa',
    fontSize: 20,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 0,
    gap: 5,
    borderRadius: 30,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  detailNumber: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'OpenSans-Bold',
    color: '#3d6dff',
  },
  detailLabel: {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: 'OpenSans-Bold',
    color: '#0000008a',
    textTransform: 'uppercase',
  },
})
