import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ConsentRoutes } from "../consent.routes";

export function EditConsentScreen() {
    const navigation = useNavigation<NavigationProp<ConsentRoutes>>()
    const route = useRoute<RouteProp<ConsentRoutes, 'edit'>>()

    return <PutModule<{
        content: string,
        templateId: string,
        memberId: string,
        customerId: string,
        signatureCustomer: string,
        signatureMember: string,
        images: string[],
        groupId: string,
        materials: string[],
        materialsText: string,
        imagesText: string,
        signaturesText: string,
        showMaterials: boolean,
        showImages: boolean,
        showSignatures: boolean,
        showLogotype: boolean,
        showSeal: boolean,
        showBarcode: boolean,
        showFooter: boolean,
        showHeader: boolean,
        customCSS: boolean,
        customCSSText: string,
    }>
        prefix="updateConsent"
        path="consent"
        id={route.params.consentId}
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        dontRenderInLoading={true}
        inputs={[
            {

                name: 'customerId',
                type: 'choose',
                path: 'customer',
                groupId: route.params.groupId,
                renderLabel: (item) => {
                    return `${item.name} ${item.surname}`
                },
                renderDetail: (item) => {
                    return item.email || undefined
                }
            },


            {
                renderLabel: (item) => { return `${item?.User?.name} ${item?.User?.surname}` },
                name: 'memberId',
                type: 'choose',
                path: 'member',
                groupId: route.params.groupId,

            },

            {
                name: 'content',
                type: 'editor',
                disable: true,
            },

            {
                name: 'signatureMember',
                type: 'signature'

            },
            {
                name: 'images',
                type: 'images'
            },
            {
                name: 'materials',
                type: 'materials',
                groupId: route.params.groupId
            }
        ]}
    />
}
