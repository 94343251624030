import { RouteProp, useIsFocused, useRoute } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { Text, TouchableOpacity } from "react-native"
import { View } from "react-native"
import { StudioRoutes } from "../../studio.routes"
import { useRecoilState } from "recoil"
import { userState } from "@states/user.state"
import { useState } from "react"
import { Environment } from "@environment"
import axios from "axios"
import { SubscriptionType } from "../subscription.types"
const video = require('../../../../../assets/artist.mp4')

export function HeaderActiveComponent(properties: {
    subscription: SubscriptionType
}) {

    const { t } = useTranslation()
    return <View style={{
        height: 150,
        overflow: 'hidden',
        zIndex: 1,
        alignContent: 'center',
        justifyContent: 'center',
    }}>
        <View style={{

            backgroundColor: 'black',
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0.7
        }} />
        <Text style={{

            color: 'white',
            fontSize: 35,
            fontWeight: '600',
            zIndex: 1,
            paddingLeft: 50,
        }}>
            {t(`subscription.active.title`)}
        </Text>
        <Text style={{

            color: 'white',
            fontSize: 18,
            maxWidth: 350,
            fontWeight: '200',
            zIndex: 1,
            paddingLeft: 50,
        }}>
            {
                t(`subscription.active.subtitle`)}
        </Text>




        <video
            style={{
                zIndex: -1,
                height: 200,
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0,
                objectFit: 'cover'
            }}
            src={video} autoPlay loop muted />
    </View>
}