import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ReceiptRoutes } from "../receipt.routes";
import { useTranslation } from "react-i18next";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";
import { useCurrencyHook } from "../../../../hooks/currency.hook";

export function ListReceiptScreen() {
    const navigation = useNavigation<NavigationProp<ReceiptRoutes, 'list'>>()
    const route = useRoute<RouteProp<ReceiptRoutes, 'list'>>()
    const permissions = usePermissionsHook(route?.params?.groupId)
    const currency = useCurrencyHook(route.params.groupId)
    const { t } = useTranslation()
    return <TableComponent<{
        id: string
        name: string
        createdAt: string
        status: boolean
        method: string
        Customer: {
            name: string
            surname: string
        },
        Purchases: {

            units: number,
            price: number,
            tax: true,

        }[],
        Member: {

            name: string
            color: string

        },

    }>
        groupBy="createdAt"

        groupId={route.params.groupId}
        paginator={{
            limit: 40,
        }}
        membering={permissions?.permissions?.accounterAll ? true : false}
        prefix="listReceipt"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`receipt?groupId=${route.params.groupId}${route.params.customerId ? `&customerId=${route.params.customerId}` : ''}`}
        filters={route.params.customerId ? [
            {
                name: 'customerId',
                value: route.params.customerId,
                onPress: () => {
                    navigation.navigate('list', {
                        groupId: route.params.groupId
                    })
                }
            }

        ] : undefined}
        columns={[
            {
                always: true,
                icon: 'pen',
                type: 'icon',
                menu: {
                    icon: 'pen',
                    label: 'edit'
                },
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        receiptId: item.id
                    })
                }
            },
            {
                always: true,
                renderIcon: (item) => {
                    return item.status ? 'check' : 'times'
                },
                type: 'icon',
            },
            {
                always: true,
                title: 'total',
                renderPrice: (item) => {
                    return item.Purchases.reduce((acc, purchase) => {
                        return acc + purchase.price * purchase.units
                    }, 0)
                },
                renderCurrency: (item) => {
                    return `${currency.currency}`
                },
                type: 'price',
                width: 120
            },
            {
                always: true,
                title: 'customer',
                renderLabel: (item) => { return `${item.Customer.name} ${item.Customer.surname}` },
                width: 200,
                type: 'text',

            },
            {
                always: true,
                title: 'method',
                renderLabel: (item) => { return t(`method.${item.method}`) },
                width: 100,
                type: 'text',

            },
            {
                title: 'member',
                renderLabel: (item) => { return `${item?.Member?.name}` },
                renderColor: (item) => { return item?.Member?.color },
                width: 200,
                type: 'member',
            },


            {
                title: 'createdAt',

                getDate: (item) => { return item.createdAt },
                type: 'ago',
                width: 200
            },
            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',

                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        receiptId: item.id
                    })
                }
            },
        ]}

    />

}
