import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { MaterialRoutes } from "../material.routes";

export function NewMaterialScreen() {
    const navigation = useNavigation<NavigationProp<MaterialRoutes>>()
    const route = useRoute<RouteProp<MaterialRoutes, 'new'>>()

    return <PostModule<{
        name: string
        description?: string;
        expiration?: string;
        type?: string;
        denomination?: string;
        reference?: string;
        pack?: string;
        reused?: string;
        health?: string;
    }>
        prefix="newMaterial"
        path="material"
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            },
            {
                name: 'type',
                type: 'text',

            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                type: 'accordion',
                name: 'details',
                inputs: [

                    {
                        name: 'expiration',
                        type: 'text'
                    },

                    {
                        name: 'denomination',
                        type: 'text'
                    },
                    {
                        name: 'reference',
                        type: 'text'
                    },
                    {
                        name: 'pack',
                        type: 'text'
                    },
                    {
                        name: 'reused',
                        type: 'text'
                    },
                    {
                        name: 'health',
                        type: 'text'
                    }
                ]
            }
        ]}
    />
}