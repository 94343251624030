import { CenterComponent } from "@components/center/center.component";
import LoaderComponent from "@components/loader/loader.component";
import { LogoComponent } from "@components/logo/logo.component";
import { SubmitComponent } from "@components/submit/submit.component";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, SafeAreaView, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { ButtonComponent } from "../../../components/button/button.component";
import { ErrorComponent } from "../../../components/error/error.component";
import { InputComponent } from "../../../components/input/input.component";
import { LineComponent } from "../../../components/line/line.component";
import { SpaceComponent } from "../../../components/space/space.component";
import { useWeb } from "../../../hook/web.hook";
import { userState } from "../../../states/user.state";
import { MainRoutes } from "../../main.routes";

export function LoginScene(properties: { navigation: any }) {
  const [user, setUser] = useRecoilState(userState);
  const navigation = useNavigation<NavigationProp<MainRoutes>>();
  const [cache, setCache] = useState<string>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const handlerSubmit = () => {
    setLoading(true);

    axios
      .post(`${Environment.api}/session`, {
        email: email,
        password: password,
      })
      .then((response) => {
        setLoading(false);
        const user = response.data.user;
        setUser(user);
        // get first group
        axios
          .get(`${Environment.api}/group`, {
            headers: {
              Authorization: `Bearer ${response.data.user?.accessToken}`,
            },
          })
          .then((response) => {
            const group = response.data?.items?.[0];

            if (group) {
              navigation.reset({
                index: 0,
                routes: [{ name: "studio", params: { groupId: group.id } }],
              });
            } else {
              properties.navigation.navigate("user");
            }
          });
      })
      .catch((error) => {
        setLoading(false);
        const message = error.response.data.message;
        setError(message);
        setCache(new Date().getTime().toString());
      });
  };
  const { t } = useTranslation();

  const isWeb = useWeb();
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Platform.OS === "web" ? "transparent" : "#fff",
      }}
    >
      <CenterComponent height={700}>
        {error && (
          <ErrorComponent
            message={t(error)}
            title={t("login.title")}
            key={cache}
          />
        )}

        {loading && <LoaderComponent />}
        <KeyboardAwareScrollView
          extraHeight={100}
          extraScrollHeight={100}
          style={{
            padding: 30,
          }}
        >
          {<LogoComponent />}
          {isWeb && (
            <>
              <ButtonComponent
                angle={false}
                icon={{
                  name: "arrow-left",
                }}
                label={t("login.back")}
                onPress={() => {
                  properties.navigation.navigate("register");
                }}
              />
            </>
          )}
          <View
            style={{
              height: 20,
            }}
          ></View>
          <InputComponent
            autoCapitalize="none"
            title={t("login.username")}
            value={email}
            onChange={setEmail}
          />
          <InputComponent
            autoCapitalize="none"
            returnKeyType="go"
            onSubmitEditing={handlerSubmit}
            title={t("login.password")}
            value={password}
            onChange={setPassword}
            secureTextEntry
          />
          <SpaceComponent />
          <SubmitComponent title={t("login.submit")} onPress={handlerSubmit} />
          <SpaceComponent />
          <LineComponent />

          <ButtonComponent
            icon={{
              name: "question-circle",
            }}
            label={t("login.recovery")}
            onPress={() => {
              properties.navigation.navigate("recovery");
            }}
          />
          <LineComponent />
          <ButtonComponent
            icon={{
              name: "user-plus",
            }}
            label={t("login.register")}
            onPress={() => {
              properties.navigation.navigate("register");
            }}
          />
          <LineComponent />
        </KeyboardAwareScrollView>
      </CenterComponent>
    </SafeAreaView>
  );
}
