import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";

import { PutModule } from "@modules/post/put.module";
import { GalleryRoutes } from "../gallery.router";
import { useTranslation } from "react-i18next";



export function EditGalleryScreen() {
    const navigation = useNavigation<NavigationProp<GalleryRoutes>>()

    const route = useRoute<RouteProp<GalleryRoutes, 'edit'>>()
    const { t } = useTranslation()

    return <PutModule<{
        title: string
        description: string
        design: string
        path: string
    }>
        prefix="editImage"
        path="image"
        id={route.params.imageId}
        onSuccess={(data) => {
            navigation.navigate('list')
        }}
        extraSubmit={{
            label: t('image.delete'),
            onPress: () => {
                navigation.navigate('delete', {
                    imageId: route.params.imageId
                })
            }
        }

        }
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'title',
                type: 'text'
            },
            {
                name: 'path',
                type: 'image'
            },
            {
                name: 'description',
                type: 'text',
                lines: 5
            },
            {
                name: 'design',
                type: 'select',
                options: [
                    'blackandgrey',
                    'realism',
                    'neotraditional',
                    'ornamental',
                    'lettering',
                    'anime',
                    'oldschool',
                    'geometric',
                    'dotwork',
                    'newschool',
                    'fineline',
                    'freehand',
                    'cover',
                    'line',
                    'minimalism',
                    'blackwork',
                    'watercolor',
                    'tribal',
                    'trashpolka',
                    'chicano',
                    'piercing',
                    'tattooremoval',
                    'neooriental',
                    'points',
                    'cartoon',
                    'brush',
                    'expressionism',
                    'cubism',
                    'comic',
                    'celtic',
                    'abstract',
                    'horror',
                    'graffiti',
                    'microblading',
                    'magicalgirl',
                    'pixel',
                    'biomechanical',
                    'kawaii'
                ]
            }
        ]}
    />
}