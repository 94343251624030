import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { DeleteComponent } from "@components/delete/delete.component";
import { MemberRoutes } from "../member.routes";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";

export function DeleteMemberScreen() {
    const navigation = useNavigation<NavigationProp<MemberRoutes, 'delete'>>()
    const route = useRoute<RouteProp<MemberRoutes, 'delete'>>()
    const [user] = useRecoilState(userState)
    return <DeleteComponent
        prefix={'deleteMember'}
        path={'member'}
        id={route.params.memberId}
        groupId={route.params.groupId}
        goBack={() => {
            navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
        onDeleted={() => {
            if (route.params.isMe) {
                navigation.navigate('user')
            }
            else navigation.navigate('list', {
                groupId: route.params.groupId
            })
        }}
    />
}

