interface PriceUtilityParams {
  price: number
}

/*
 * @param {number} price
 * @returns {string}
 * @description
 * This function returns a price that was previously multiplied by 100, to return the price divide by 100 and change the . by a ,
 * */
export function priceUtility(params: PriceUtilityParams) {
  return (params.price / 100).toFixed(2).replace('.', ',')
}

/*
 * @param {number} price
 * @returns {string}
 * @description
 * This function returns a price that was previously multiplied by 100, to return the price divide by 100 and with the decimal point
 * */
export function priceJavaUtility(params: PriceUtilityParams) {
  return (params.price / 100).toFixed(2)
}

export function priceUtilityRevert(params: PriceUtilityParams) {
  return params.price * 100
}
