import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListReceiptScreen } from "./list/list.receipt.screen";
import { EditReceiptScreen } from "./edit/edit.receipt.screen";
import { DeleteReceiptScreen } from "./delete/delete.receipt.screen";
import { NewReceiptScreen } from "./new/new.receipt.screen";
import { ReceiptRoutes } from "./receipt.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewCustomerScreen } from "../customer/new/new.customer.screen";
import { NewMemberScreen } from "../member/new/new.member.screen";

const stack = createNativeStackNavigator<ReceiptRoutes>();

export function ReceiptNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'receipts'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListReceiptScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewReceiptScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditReceiptScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteReceiptScreen}
        />
        <stack.Screen
            name="customer"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewCustomerScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="member"
            options={modalOptions}
            component={NewMemberScreen}
        />
    </stack.Navigator>
}
