import { atom } from "recoil";


export interface MenuType {
    show: boolean;
}

export const menuState = atom<MenuType | null>({
    key: "menux",
    default: {
        show: true
    },

});


