import { Image, View } from "react-native";
import { useWeb } from "../../hook/web.hook";

export function LogoComponent() {
    const isWeb = useWeb()

    return <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: isWeb ? 20 : 50,
        marginBottom: isWeb ? 20 : 0,

    }}>
        <Image source={require('../../../assets/logo.png')} style={{
            height: 60,
            width: 150,
            marginHorizontal: 'auto',
        }}
            resizeMode="contain"
        />
    </View>
}