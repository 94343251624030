import { PutModule } from "@modules/post/put.module";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { BookingRoutes } from "../booking.routes";
import { useTranslation } from "react-i18next";

export function EditBookingScreen() {
  const navigation = useNavigation<NavigationProp<BookingRoutes>>();
  const route = useRoute<RouteProp<BookingRoutes, "edit">>();
  const { t } = useTranslation();
  return (
    <PutModule<{
      name: string;
      customerId: string;
      roomId: string;
      startedAt?: string;
      finishedAt?: string;
      description: string;
      type: string;
      memberId: string;
      deposit?: number;
      budget?: number;
      size?: string;
      body?: string;
      images: string[];
      serviceId: string;
      commission?: number;
    }>
      prefix="updateBooking"
      path="meet"
      id={route.params.bookingId}
      onSuccess={() => {
        navigation.goBack();
      }}
      groupId={route.params.groupId}
      onCancel={() => {
        navigation.goBack();
      }}
      extraSubmit={{
        submitFirst: true,
        label: t("updateBooking.toMeet"),
        onPress: () => {
          navigation.navigate("confirm", {
            bookingId: route.params.bookingId,
            groupId: route.params.groupId,
          });
        },
      }}
      actions={[
        {
          icon: "eye",
          label: "customer",
          onPress: (data: any) => {
            navigation.navigate("editCustomer", {
              customerId: data.customerId,
              groupId: route.params.groupId,
              level: 2,
            });
          },
        },

      ]}
      inputs={[
        {
          name: "serviceId",
          type: "service",
          path: "service",
          groupId: route.params.groupId,
          serviceName: "serviceId",
          depositName: "deposit",
          budgetName: "budget",
          commissionName: "commission",
          startedAtName: "startedAt",
          finishedAtName: "finishedAt",
          memberName: "memberId",
        },
        {
          name: "name",
          type: "text",
        },
        {
          name: "description",
          type: "text",
          lines: 5,
        },
        {
          onNew: (callback) => {
            navigation.navigate("newRoom", {
              level: 1 + (route.params.level || 0),
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          name: "roomId",
          type: "choose",
          path: "room",
          groupId: route.params.groupId,
        },

        {
          onNew: (callback) => {
            navigation.navigate("newCustomer", {
              groupId: route.params.groupId,
              callback: callback,
            });
          },
          renderLabel: (item) => {
            return `${item?.name} ${item?.surname}`;
          },
          renderDetail: (item) => {
            return `${item?.email}`;
          },
          name: "customerId",
          type: "choose",
          path: "customer",
          groupId: route.params.groupId,
        },

        {
          name: "size",
          type: "select",
          options: ["mini", "small", "medium", "big"],
        },
        {
          name: "body",
          type: "text",
        },
        {
          name: "images",
          type: "images",
        },
      ]}
    />
  );
}
