import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListServiceScreen } from "./list/list.service.screen";
import { EditServiceScreen } from "./edit/edit.service.screen";
import { DeleteServiceScreen } from "./delete/delete.service.screen";
import { NewServiceScreen } from "./new/new.service.screen";
import { ServiceRoutes } from "./service.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewMemberServiceScreen } from "./newMember/new.service.screen";
import { EditServiceMemberScreen } from "./editMember/new.service.screen";

const stack = createNativeStackNavigator<ServiceRoutes>();

export function ServiceNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'services'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListServiceScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewServiceScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditServiceScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteServiceScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="newMeet"
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="newMemberService"
            options={modalOptions}
            component={NewMemberServiceScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="editMemberService"
            options={modalOptions}
            component={EditServiceMemberScreen}
        />
    </stack.Navigator>
}
