import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListCustomerScreen } from "./list/list.customer.screen";
import { EditCustomerScreen } from "./edit/edit.customer.screen";
import { DeleteCustomerScreen } from "./delete/delete.customer.screen";
import { NewCustomerScreen } from "./new/new.customer.screen";
import { CustomerRoutes } from "./customer.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewReceiptScreen } from "../receipt/new/new.receipt.screen";
import { NewConsentScreen } from "../consent/new/new.consent.screen";
import { NewRoomScreen } from "../room/new/new.room.screen";

const stack = createNativeStackNavigator<CustomerRoutes>();

export function CustomerNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'customers'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListCustomerScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewCustomerScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="newMeet"
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="newReceipt"
            options={modalOptions}
            component={NewReceiptScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="newConsent"
            options={modalOptions}
            component={NewConsentScreen}
        />
        <stack.Screen
            name="newRoom"
            initialParams={{ groupId, level: 1 }}
            options={modalOptions}
            component={NewRoomScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditCustomerScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteCustomerScreen}
        />


    </stack.Navigator>
}
