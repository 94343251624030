import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { PostModule } from "@modules/post/post.module";
import { MemberRoutes } from "../member.routes";
import { useState } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { useTranslation } from "react-i18next";
import { IconComponent } from "@components/icon/icon.component";
import { TextComponent } from "@components/text/text.component";

export function NewMemberScreen() {
    const navigation = useNavigation<NavigationProp<MemberRoutes>>()
    const route = useRoute<RouteProp<MemberRoutes, 'new'>>()
    const level = route.params.level
    const [start, setStart] = useState<boolean>(false)
    const callback = route.params.callback
    const [email, setEmail] = useState<string>('')
    const { t } = useTranslation()
    if (!start) {
        return <ModalComponent
            title={t(`newMemberSelect.title`)}
            maxWidth={500}
            level={route.params.level}
            description={t(`newMemberSelect.description`)}
            onClose={() => {
                navigation.goBack()
            }}>
            <View style={{
                gap: 20,
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#fff',
                padding: 20,
                paddingBottom: 0,
            }}>
                <TouchableOpacity
                    style={{
                        padding: 20,
                        borderRadius: 10,
                        backgroundColor: '#eee',
                        marginBottom: 20,
                        borderWidth: 4,
                        borderColor: '#ddd',
                        flex: 1,
                    }}
                    onPress={() => {
                        setStart(true)
                    }}>
                    <IconComponent name="angle-right" style={{
                        box: {
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            width: 32,
                            height: 32,
                            borderRadius: 45,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#333',
                        },
                        icon: {
                            fontSize: 20,
                            color: '#fff'
                        }
                    }} />
                    <IconComponent name="search" style={{
                        icon: {
                            fontSize: 50,
                            color: '#333'
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: '600',
                            fontSize: 18
                        }
                    }}>{t('newMemberSelect.existing')}</TextComponent>
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,
                            opacity: 0.5
                        }
                    }}>{t('newMemberSelect.existing.description')}</TextComponent>
                </TouchableOpacity>
                <TouchableOpacity style={{
                    padding: 20,
                    flex: 1,
                    borderRadius: 10,
                    backgroundColor: '#eee',
                    marginBottom: 20,
                    borderWidth: 4,
                    borderColor: '#ddd'
                }}
                    onPress={() => {
                        setStart(true)
                        navigation.navigate('newUser', {
                            level: route.params?.level ? route.params?.level + 1 : 1,
                            groupId: route.params.groupId,
                            callback: (data) => setEmail(data.email)
                        })
                    }}>
                    <IconComponent name="user-plus" style={{
                        icon: {
                            fontSize: 50,
                            color: '#333'
                        }
                    }} />
                    <IconComponent name="angle-right" style={{
                        box: {
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            width: 32,
                            height: 32,
                            borderRadius: 45,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#333',
                        },
                        icon: {
                            fontSize: 20,
                            color: '#fff'
                        }
                    }} />
                    <TextComponent styles={{
                        text: {
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: '600',
                            fontSize: 18
                        }
                    }}>{t('newMemberSelect.new')}</TextComponent>
                    <TextComponent styles={{
                        text: {
                            fontSize: 12,
                            opacity: 0.5
                        }
                    }}>{t('newMemberSelect.new.description')}</TextComponent>
                </TouchableOpacity>
            </View>
        </ModalComponent >
    }
    return <PostModule<{
        email: string;
        isPublic: boolean;
        color: string;
        consenter: boolean;
        consenterAll: boolean;
        availabilityView: boolean;
        accounter: boolean;
        accounterAll: boolean;
        commission: number;
        administrator: boolean;
        meeterAll: boolean;
    }>
        level={level}
        key={email}
        prefix="newMember"
        path="member"
        onSuccess={(data) => {
            callback?.(data)
            navigation.goBack()
        }}
        data={{
            email,
            isPublic: false,
            consenter: false,
            consenterAll: false,
            accounter: false,
            accounterAll: false,
            commission: 0,
            administrator: false,
            meeterAll: false,
            availabilityView: false,

        }}
        groupId={route.params.groupId}
        onCancel={() => {
            if (start) {
                setStart(false)
            }
            else navigation.goBack()
        }}
        inputs={[
            {
                name: 'email',
                type: 'text',
                onNew: (callback) => {
                    navigation.navigate('newUser', {
                        level: route.params?.level ? route.params?.level + 1 : 1,
                        groupId: route.params.groupId,
                        callback: callback
                    })
                }
            },
            {
                name: 'isPublic',
                type: 'checkbox'
            },
            {
                name: 'color',
                type: 'color'
            },
            {
                name: 'administrator',
                type: 'checkbox'
            },
            {
                name: 'consenter',
                type: 'checkbox'
            },
            {
                name: 'consenterAll',
                type: 'checkbox'
            },
            {
                name: 'accounter',
                type: 'checkbox'
            },
            {
                name: 'accounterAll',
                type: 'checkbox'
            },

            {
                name: 'meeterAll',
                type: 'checkbox'
            },
            {
                name: 'availabilityView',
                type: 'checkbox'
            },
            {
                name: 'commission',
                type: 'number'
            },

        ]}
    />
}