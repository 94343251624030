export const HelpMain: {
    prefix: string,
    section: 'calendar' | 'receipts' | 'meets' | 'appointments' | 'customers' | 'consents' | 'templates' | 'materials' | 'rooms' | 'members' | 'settings' | 'profile' | 'password' | 'delete-account' | 'images' | 'schedules' | 'subscription' | 'services',
}[] = [
        {
            section: 'meets',
            prefix: 'budget'
        },
        {
            section: 'templates',
            prefix: 'variables'
        },
        {
            section: 'receipts',
            prefix: 'products'
        },
        {
            section: 'meets',
            prefix: 'budget'
        },
        {
            section: 'meets',
            prefix: 'budget'
        },
        {
            section: 'meets',
            prefix: 'budget'
        }
    ]