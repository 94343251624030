// Modules
import { View, TouchableOpacity, ImageBackground, Platform } from 'react-native'
import * as Haptics from 'expo-haptics';

// Components
import { TextComponent } from '../text/text.component'
import { IconComponent } from '../icon/icon.component'

// Constants
import { ButtonIconPosition } from './button.constants'

// Properties
import { type ButtonProperties } from './button.properties'

// Styles
import { ButtonStyles } from './button.styles'

/**
 * Button Component
 * @description Define a button with icon, label and detail
 * @param {ButtonProperties} properties
 * @returns {JSX.Element}
 */
export function ButtonComponent(properties: ButtonProperties): JSX.Element {
  return (
    <TouchableOpacity
      testID={properties.testID}
      onPress={() => {
        Platform.OS !== 'web' && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
        properties.onPress?.()
      }}
      style={[ButtonStyles.component, properties.style]}
    >
      {properties.icon ? (
        <IconComponent
          name={properties.icon.name}
          style={{
            box: {
              ...ButtonStyles.iconSuccess,
              backgroundColor: properties.icon.background,
              ...properties.icon.color ? {
                opacity: 1,
              } : null
            },
            icon: {
              ...ButtonStyles.iconSuccessShape,
              color: properties.icon.color,
              ...properties.icon.color ? {
                opacity: 1,
              } : null
            },
          }}
        />
      ) : <View style={{ width: 15 }} />}

      <TextComponent
        numberOfLines={1}
        styles={{ layout: properties.labelLeft ? ButtonStyles.textBoxWithLabelLeft : ButtonStyles.textBox, text: ButtonStyles.text }}
        content={properties.label}
      />
      {
        properties.labelLeft && <TextComponent
          numberOfLines={1}
          styles={{
            layout: {
              backgroundColor: 'rgba(0, 132, 255,0.1)',
              borderRadius: 20,
              paddingHorizontal: 12,
              marginRight: 'auto'
            }, text: {
              color: 'rgb(0, 132, 255)',
            }
          }}
          content={properties.labelLeft}
        />
      }
      {
        properties.details && <View style={{ gap: 5, marginRight: -10, flexDirection: 'row' }}>
          {
            properties.details.map((detail, index) => (
              <View
                key={index}
                style={[
                  ButtonStyles.detail,
                  detail.background ? {
                    backgroundColor: detail.background,
                  } : null
                ]}>
                {detail.value ?? detail.value === 0 ? (
                  <TextComponent
                    styles={{ text: ButtonStyles.detailNumber }}
                    content={`${detail.value}`}
                  />
                ) : null}

                {detail.label && (
                  <TextComponent
                    styles={{
                      text: [
                        ButtonStyles.detailLabel,
                        detail.color ? {
                          color: detail.color,
                        } : null
                      ]
                    }}
                    content={detail.label}
                  />
                )}
              </View>))}

        </View>
      }
      {properties.detail && (
        <View style={ButtonStyles.detail}>
          {properties.detail.value ?? properties.detail.value === 0 ? (
            <TextComponent
              styles={{ text: ButtonStyles.detailNumber }}
              content={`${properties.detail.value}`}
            />
          ) : null}

          {properties.detail.label && (
            <TextComponent
              styles={{ text: ButtonStyles.detailLabel }}
              content={properties.detail.label}
            />
          )}
        </View>
      )}

      {properties.images?.map((image, index) => (
        <ImageBackground
          key={index}
          source={{
            uri: `data:image/png;base64,${image}`,
          }}
          style={{
            width: 60,
            height: 60,
          }}
        />
      ))}

      {
        properties.angle !== false ? <IconComponent
          name={ButtonIconPosition}
          style={{
            box: ButtonStyles.angle,
            icon: ButtonStyles.angleShape,
          }}
        /> : <View style={{ width: 10 }} />
      }
    </TouchableOpacity>
  )
}
