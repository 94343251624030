import { HeaderComponent } from "@components/header/header.component";
import { IconComponent } from "@components/icon/icon.component";
import LoaderComponent from "@components/loader/loader.component";
import { Environment } from "@environment";
import { NavigationProp, useIsFocused, useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { GalleryRoutes } from "../gallery.router";

export function ListGalleryScreen() {
    const navigation = useNavigation<NavigationProp<GalleryRoutes>>()

    const isFocus = useIsFocused()
    const [user, setUser] = useRecoilState(userState)
    const [images, setImages] = useState<{
        id: string
        name: string
        path: string,
        design: string

    }[]>([])
    const [width, setWidth] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    useEffect(() => {
        if (user) {
            setLoading(true)
            axios.get(`${Environment.api}/image`, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((response) => {
                const meets = response.data.items
                setImages(meets)
                setLoading(false)
                setPage(0)
            }
            )
                .catch((error) => {
                    console.log(error.response.data)
                })
        }

    }, [user, isFocus])
    const { t } = useTranslation()

    const widthBig = width / 5
    const widthBigReal = widthBig > 200 ? 200 : widthBig

    const imagesTo40 = images.length < 40 ? new Array(40).fill(0).map((item, index) => 1) : []


    return <View style={{ flex: 1, backgroundColor: '#f6f7fa' }} onLayout={(e) => {
        const { width } = e.nativeEvent.layout
        setWidth(width)
    }}>

        <>
            {
                loading && <LoaderComponent />
            }


            <ScrollView style={{

                flex: 1
            }}
                scrollEnabled={images.length > 0}
                onLayout={(event) => {

                    const { width } = event.nativeEvent.layout;
                    setWidth(width)
                }
                }
                contentContainerStyle={{
                    padding: 20
                }}
            >
                <HeaderComponent title={t('gallery.list.title')} description={t('gallery.list.description')}
                    actions={[
                        {
                            label: 'new',
                            icon: 'plus',
                            onPress: () => {
                                navigation.navigate('new')
                            }
                        }
                    ]
                    }
                />
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 5,
                    justifyContent: 'flex-start',
                    padding: 10,
                }}
                >
                    {
                        images.length === 0 && <>
                            {
                                new Array(80).fill(0).map((item, index) => {
                                    if (index === 0) return <TouchableOpacity key={index}
                                        onPress={() => {
                                            navigation.navigate("new")
                                        }}
                                        style={{
                                            minWidth: widthBigReal,
                                            flex: 1,
                                            aspectRatio: 1,
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#ddd',
                                            borderRadius: 10
                                        }}
                                    >
                                        <IconComponent name="plus" style={{
                                            icon: {
                                                fontSize: 40,
                                                opacity: 0.5
                                            }
                                        }} />
                                    </TouchableOpacity>
                                    else return <View key={index}
                                        style={{
                                            minWidth: widthBigReal,
                                            flex: 1,
                                            aspectRatio: 1,
                                            backgroundColor: '#ddd',
                                            borderRadius: 10
                                        }}></View>
                                })
                            }

                        </>
                    }
                    {
                        images.map((image, index) => {
                            return <TouchableOpacity key={index}
                                onPress={() => {
                                    navigation.navigate("edit", {
                                        imageId: image.id
                                    })
                                }}
                                style={{
                                    overflow: 'hidden',
                                    minWidth: widthBigReal,
                                    flex: 1,
                                    borderRadius: 10,
                                    aspectRatio: 1,
                                }}
                            >
                                <Image key={index}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        flex: 1,
                                        aspectRatio: 1,
                                    }}
                                    source={{ uri: `${image.path}` }}
                                />
                            </TouchableOpacity>
                        })
                    }

                    {
                        <>
                            {
                                imagesTo40.map((item, index) => {
                                    return <View key={index}
                                        style={{
                                            minWidth: widthBigReal,
                                            flex: 1,
                                            aspectRatio: 1,
                                            backgroundColor: '#ddd',
                                            borderRadius: 10
                                        }}></View>
                                })
                            }

                        </>
                    }
                </View>
            </ScrollView>
        </>
    </View>
}
