import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListProductScreen } from "./list/list.product.screen";
import { EditProductScreen } from "./edit/edit.product.screen";
import { DeleteProductScreen } from "./delete/delete.product.screen";
import { NewProductScreen } from "./new/new.product.screen";
import { ProductRoutes } from "./product.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";

const stack = createNativeStackNavigator<ProductRoutes>();

export function ProductNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'products'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListProductScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewProductScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditProductScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteProductScreen}
        />
    </stack.Navigator>
}
