import React from 'react';
import { TextInput as TextInputNative, View } from 'react-native';

import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useCurrencyHook } from '../../../../hooks/currency.hook';
import { priceUtility } from '../../../../utilities/price/priceUtility';
import { PriceInputProperties } from './price.properties';

export function PriceInputForm<T>(properties: PriceInputProperties<T>) {

    const currency = useCurrencyHook(properties.groupId)
    const handleChange = (text: string) => {
        // Allow only numbers 
        const numericValue = text.replace(/[^0-9]/g, "");
        // convert to numeric value
        const numericValue2 = parseFloat(numericValue);
        properties.onChange?.(numericValue2);
    };

    const textCurrency = properties.currency || currency.currency
    return <View style={{
        position: 'relative',
    }}>
        <TextInputNative
            style={{
                borderWidth: 1,
                borderColor: '#ddd',
                padding: 10,
                borderRadius: 5,
                fontWeight: 'bold',
                paddingLeft: (textCurrency) ? textCurrency.length * 8 + 20 : 10
            }}
            value={`${priceUtility({
                price: properties.value || 0,
            })}`}
            onChangeText={(value) => {
                handleChange(value);
            }}
            onSubmitEditing={properties.onSubmit}

        />
        <View
            pointerEvents='none'
            style={{
                position: 'absolute',
                top: 12,
                left: 13,
                opacity: 0.5
            }}>
            <TextComponent

                styles={{
                    text: {
                        fontSize: 12,
                    },
                    layout: {

                    }
                }}>
                {properties.currency || currency.currency}
            </TextComponent>
        </View>
        <IconComponent name="plus" style={{
            box: {
                position: 'absolute',
                top: 4,
                right: 4,
                width: 32,
                height: 32,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#333',
                borderRadius: 55,
            },
            icon: {
                fontSize: 20,
                color: '#fff',
            }
        }} onPress={() => {
            properties.onChange?.((properties.value || 0) + 100);
        }} />
        <IconComponent name="minus" style={{
            box: {
                position: 'absolute',
                borderRadius: 55,
                right: 40,
                top: 4,
                width: 32,
                height: 32,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ddd',
            },
            icon: {
                fontSize: 20,
                color: '#333',
            }
        }} onPress={() => {
            // is empty, 0 or null or undefined or less than 0
            if (!properties.value || properties.value <= 0) {
                return;
            }
            properties.onChange?.((properties.value || 0) - 100);
        }} />
    </View>
}