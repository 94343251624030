// Modules
import { useEffect, useState } from 'react'
import { Platform, Text, TextInput, View } from 'react-native'

// Components
import { CountComponent } from './components/count/count.component'
import { InputHeader } from './components/inputHeader/inputHeader.component'
import { InputErrorHighlight } from './components/inputErrorHighlight/inputErrorHighlight.component'

// Properties
import { type InputProperties } from './input.properties'

// Styles
import { InputStyles } from './input.styles'
import { IconComponent } from '@components/icon/icon.component'

/**
 * Input Component
 * @description Render a text field with label and error highlight if needed
 * @param {InputProperties} properties
 * @return {JSX.Element}
 */
export function InputComponent(properties: InputProperties): JSX.Element {
  // Shows input field error
  const [secureStatus, setSecureStatus] = useState<boolean>(properties.secureTextEntry || false)
  const [error, setError] = useState<string>()
  const [focus, setFocus] = useState<boolean>(false)
  // Displays a custom error using the component's error property
  useEffect(() => {
    setError(properties.error)
  }, [properties.error])

  return (
    <View style={[InputStyles.component, properties.styles,
    properties.numberOfLines ? { minHeight: 200, maxHeight: 200, height: 200 } : {},
    ]}>
      <InputHeader
        title={properties.title}
        description={properties.description}
        focused={!!properties.placeholder || focus || (properties.value && properties.value?.length > 0 ? true : false)}

      />

      <View
        style={[
          InputStyles.field,
          error ? InputStyles.fieldError : InputStyles.fieldValid,
          properties.fieldStyles,
          InputStyles.positionRelative,

          properties.numberOfLines ? { minHeight: 200, maxHeight: 200, height: 200 } : {},

        ]}
      >
        <TextInput
          testID={properties.testID ?? 'input-field'}
          placeholder={properties.placeholder}
          autoCorrect={false}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          style={[InputStyles.fieldInput,
          properties.numberOfLines ? {
            fontSize: 16,
            paddingTop: 25,
            minHeight: 200, maxHeight: 200, height: 200
          } : {},
          ]}
          autoCapitalize={properties.autoCapitalize}
          multiline={properties.numberOfLines && properties.numberOfLines > 0 ? true : false}
          defaultValue={properties.defaultValue}
          value={properties.value ?? ''}
          keyboardType={properties.keyboardType}
          onChangeText={properties.onChange}
          onKeyPress={properties.onKeyPress}
          editable={properties.editable}
          spellCheck={false}
          autoComplete='off'
          maxLength={properties.maxLength}
          importantForAutofill='no'
          numberOfLines={properties.numberOfLines}
          secureTextEntry={secureStatus}
          placeholderTextColor={'#aaa'}
          onSubmitEditing={properties.onSubmitEditing}
          returnKeyType={properties.returnKeyType}
        />

        <CountComponent badgeCount={properties.badgeCount} />
        {
          properties.secureTextEntry && <IconComponent
            style={{
              box: {
                position: 'absolute',
                right: 0,
                backgroundColor: 'transparent',
                width: 50, opacity: 0.3
              },
              icon: {
                fontSize: Platform.OS === 'web' ? 25 : 20,
                backgroundColor: 'transparent',
              }
            }}
            name={secureStatus ? 'eye-slash' : 'eye'}
            onPress={() => setSecureStatus(!secureStatus)}
          />
        }
      </View>


      {!!error && <InputErrorHighlight error={error} />}
    </View>
  )
}
