import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { CustomerRoutes } from "../customer.routes";
import { StudioRoutes } from "../../studio.routes";
import { Linking, Platform, TouchableOpacity, View } from "react-native";
import { IconComponent } from "@components/icon/icon.component";
import { IconType } from "@components/icon/icon.types";
import { TextComponent } from "@components/text/text.component";
import { useTranslation } from "react-i18next";
import * as Clipboard from 'expo-clipboard';
import Toast from 'react-native-toast-message';

export function EditCustomerScreen() {
    const navigation = useNavigation<NavigationProp<CustomerRoutes>>()
    const navigationStudio = useNavigation<NavigationProp<StudioRoutes>>()
    const route = useRoute<RouteProp<CustomerRoutes, 'edit'>>()
    const { t } = useTranslation()
    const CardComponent = (properties: {
        icon: IconType,
        prefix: string,
        title?: string,
        color: string,
        counter?: number,
        linkText?: boolean,
        more?: () => void
        active?: boolean
        link?: () => void
    }) => {
        return <View style={{
            zIndex: 1000,
            marginTop: 0,
            flex: 1,
            overflow: 'hidden',
            minWidth: 140,
            backgroundColor: '#fff', borderRadius: 10,
            flexDirection: 'column',
            opacity: 'active' in properties ? properties.active ? 1 : 0.3 : 1,
        }}
            pointerEvents={'active' in properties ? properties.active ? 'auto' : 'none' : 'auto'}
        >


            <View style={{

                position: 'absolute',
                top: 0,
                left: 0, borderRadius: 10,
                right: 0,
                bottom: 0,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 11,
                },
                shadowOpacity: 0.10,
                shadowRadius: 5.84,

            }}></View>
            <IconComponent name={properties.icon} style={{
                box: {
                    marginRight: 10,
                    padding: 10,
                    paddingBottom: 0,
                },
                icon: {
                    color: 'rgba(52, 199, 89,1)',
                    fontSize: 36
                }
            }} />
            {
                properties.title ? <IconComponent name={'copy'} style={{
                    box: {
                        opacity: properties.active ? 1 : 0,
                        flexDirection: 'row',
                        position: 'absolute',
                        top: 13,
                        right: 10, width: 30,
                        paddingVertical: 10,
                        alignItems: 'center',
                        borderRadius: 50,
                        backgroundColor: '#333',
                        justifyContent: 'center',
                        height: 30,
                    },
                    icon: {
                        color: '#fff',
                        fontSize: 16
                    }
                }}

                    onPress={() => {
                        properties.title && Clipboard.setString(properties.title)
                        Toast.show({
                            type: 'success',
                            text1: t(`customer.${properties.prefix}.copy`),
                            text2: properties.title,
                            visibilityTime: 500,
                        });

                    }}
                /> :

                    <TouchableOpacity
                        onPress={() => {
                            properties.more && properties.more()
                        }}
                        style={{
                            opacity: 'active' in properties ? properties.active ? 1 : 0 : 1,

                            flexDirection: 'row',
                            position: 'absolute',
                            top: 13,
                            right: 10,
                            paddingVertical: 10,
                            alignItems: 'center',
                            borderRadius: 50,
                            backgroundColor: '#333',
                            justifyContent: 'center',
                            height: 30,
                        }}>


                        {'counter' in properties && <TextComponent styles={{
                            layout: {
                                marginLeft: 12,
                                height: 30, marginRight: 4,
                                borderRadius: 50,
                                justifyContent: 'center',
                                alignItems: 'center',

                                zIndex: 1000
                            },
                            text: {
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 13,
                            }
                        }}>
                            {properties.counter}
                        </TextComponent>}
                        <IconComponent name="angle-right" style={{
                            box: {

                                marginRight: 10,
                            },
                            icon: {
                                color: '#aaa',
                                fontSize: 15
                            }
                        }} />
                    </TouchableOpacity>}
            <TextComponent
                numberOfLines={1}
                styles={{
                    text: {
                        marginTop: -5,
                        marginBottom: 5,
                        fontWeight: 'bold',
                        fontSize: 12, marginLeft: 15,
                    }
                }}>
                {t(`customer.${properties.prefix}.first`)}
            </TextComponent>
            {
                properties.link && <TouchableOpacity style={{
                    padding: 15,
                    borderTopColor: 'rgba(0,0,0,0.1)',
                    borderTopWidth: 1,
                    height: 40,

                    backgroundColor: '#333',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 10,
                }} onPress={() => {
                    properties.link && properties.link()
                }}>
                    {properties.linkText ? <TextComponent styles={{
                        text: {
                            fontWeight: 'bold',
                            color: 'rgba(52, 199, 89,1)',
                            fontSize: 12,
                            letterSpacing: 1,
                            lineHeight: 15,
                            textTransform: 'uppercase'
                        }
                    }}>
                        {t(`customer.${properties.prefix}.link`)}
                    </TextComponent> : <IconComponent name="plus" style={{
                        icon: {
                            color: 'rgba(52, 199, 89,1)',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }
                    }} />}


                </TouchableOpacity>
            }
        </View>
    }


    return <PutModule<{
        phone: string;
        name: string;
        surname: string;
        description: string;
        email: string;
        document: string;
        addressCountry: string;
        addressCity: string;
        addressStreet: string;
        addressZip: string;
        addressLatitude: number;
        addressLongitude: number;
        addressState: string;
        birthdate: string;
    }>
        data={{
            addressLatitude: 0,
            addressLongitude: 0,
        }}
        headerInsideComponent={(data) => {
            return <View style={{
                zIndex: 1000,
                flexWrap: 'wrap',
                marginBottom: 20,
                backgroundColor: '#111',
                marginLeft: -20,
                marginRight: -20,
                paddingTop: 20,
                marginTop: -20,
                paddingHorizontal: 20,
                borderBottomColor: '#ddd',
                borderBottomWidth: 1,
                paddingBottom: 20,
                flexDirection: 'row', gap: 10,
            }}>
                <CardComponent link={() => { Linking.openURL(`tel:${data?.phone}`) }}
                    active={data.phone ? true : false}
                    title={data.phone}
                    linkText={true} color="rgba(2,0,255,1)" icon={'phone'} prefix={'phone'} />
                <CardComponent
                    active={data.email ? true : false}

                    title={data.email}
                    link={() => { Linking.openURL(`mailto:${data?.email}`) }} linkText={true} color="rgba(255,0,255,1)" icon={'envelope'} prefix={'email'} />
                <CardComponent
                    active={data.phone ? true : false}

                    title={data.phone}
                    link={() => {
                        if (Platform.OS === 'web') {
                            Linking.openURL(`https://web.whatsapp.com/send?phone=${data?.phone}`)

                        }
                        else Linking.openURL(`https://wa.me/${data?.phone}`)

                    }} linkText={true} color="rgba(111,110,111,1)" icon={'whatsapp'} prefix={'whatsapp'} />
                <CardComponent

                    more={() => {
                        navigationStudio.navigate('meets', {
                            screen: 'list',
                            params: {
                                customerId: route.params.customerId,
                                groupId: route.params.groupId
                            }
                        })

                    }}
                    link={() => {
                        navigation.navigate('newMeet', {
                            level: 1,
                            customerId: route.params.customerId,
                            groupId: route.params.groupId,
                            callback: () => {

                                navigationStudio.navigate('meets', {
                                    screen: 'list',
                                    params: {
                                        customerId: route.params.customerId,
                                        groupId: route.params.groupId
                                    }
                                })
                            }
                        })

                    }} color="rgba(255,20,22,1)" icon={'calendar'} prefix={'meets'} counter={data?._count?.Meets || 0} />
                <CardComponent
                    more={() => {
                        navigationStudio.navigate('receipts', {
                            screen: 'list',
                            params: {
                                customerId: route.params.customerId,
                                groupId: route.params.groupId
                            }
                        })
                    }}

                    link={() => {
                        navigation.navigate('newReceipt', {
                            level: 1,
                            customerId: route.params.customerId,
                            groupId: route.params.groupId,
                            callback: () => {
                                navigationStudio.navigate('receipts', {
                                    screen: 'list',
                                    params: {
                                        customerId: route.params.customerId,
                                        groupId: route.params.groupId
                                    }
                                })
                            }
                        })
                    }} color="rgba(33,310,33,1)" icon={'coins'} prefix={'receipts'} counter={data?._count?.Receipts || 0} />
                <CardComponent more={() => {
                    navigationStudio.navigate('consents', {
                        screen: 'list',
                        params: {
                            customerId: route.params.customerId,
                            groupId: route.params.groupId
                        }
                    })

                }} link={() => {
                    navigation.navigate('newConsent', {
                        level: 1,
                        customerId: route.params.customerId,
                        groupId: route.params.groupId, callback: () => {
                            navigationStudio.navigate('consents', {
                                screen: 'list',
                                params: {
                                    customerId: route.params.customerId,
                                    groupId: route.params.groupId
                                }
                            })
                        }
                    })
                }} color="rgba(221,0,1,1)" icon={'signature'} prefix={'consents'} counter={data?._count?.Consents || 0} />


            </View>

        }}

        prefix="updateCustomer"
        path="customer"
        id={route.params.customerId}
        level={route.params.level}
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                type: 'pair',
                inputs: [
                    {
                        name: 'name',
                        type: 'text',
                        noDescription: true
                    },
                    {
                        name: 'surname',
                        type: 'text',
                        noDescription: true
                    },
                ]
            },
            {
                name: 'birthdate',
                type: 'birthday'
            },
            {
                icon: 'phone',
                name: 'phone',
                type: 'text'
            },
            {
                name: 'email',
                type: 'text',
                icon: 'envelope'
            },
            {
                name: 'document',
                type: 'text',
                icon: 'id-card'
            },
            {
                name: 'description',
                type: 'text',
            },
            {
                name: 'addressCountry',
                type: 'text',
                icon: 'globe',
                noDescription: true
            },
            {
                name: 'addressCity',
                type: 'text',
                icon: 'city',
                noDescription: true
            },
            {
                name: 'addressStreet',
                type: 'text',
                noDescription: true
            },
            {
                name: 'addressZip',
                type: 'text',
                noDescription: true
            },

            {
                name: 'addressState',
                type: 'text',
                noDescription: true
            },
        ]}
    />
}
