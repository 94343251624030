import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListGalleryScreen } from "./list/list.gallery.screen";
import { DeleteGalleryScreen } from "./delete/delete.gallery.screen";
import { NewGalleryScreen } from "./new/new.gallery.screen";
import { EditGalleryScreen } from "./edit/edit.gallery.screen";

const stack = createNativeStackNavigator<GalleryRoutes>();
export type GalleryRoutes = {
    list: undefined
    new: undefined
    edit: {
        imageId: string
    }
    delete: {
        imageId: string
    }
}
export function GalleryRouter() {
    return <stack.Navigator
        initialRouteName="list" screenOptions={{
            headerShown: false
        }}>
        <stack.Screen name="list" options={{
            title: 'Customers',
        }} component={ListGalleryScreen} />
        <stack.Screen name="new" options={{
            presentation: "transparentModal",

        }} component={NewGalleryScreen} />
        <stack.Screen name="edit" options={{
            presentation: "transparentModal",
            animation: "flip"

        }} component={EditGalleryScreen} />
        <stack.Screen name="delete" options={{
            presentation: "transparentModal",

        }} component={DeleteGalleryScreen} />
    </stack.Navigator>
}
