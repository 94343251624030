import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListTemplateScreen } from "./list/list.template.screen";
import { EditTemplateScreen } from "./edit/edit.template.screen";
import { DeleteTemplateScreen } from "./delete/delete.template.screen";
import { NewTemplateScreen } from "./new/new.template.screen";
import { TemplateRoutes } from "./template.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { VariablesTemplateScreen } from "./variables/variables.template.screen";
import { NewConsentScreen } from "../consent/new/new.consent.screen";

const stack = createNativeStackNavigator<TemplateRoutes>();

export function TemplateNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'templates'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListTemplateScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewTemplateScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="consent"
            options={modalOptions}
            component={NewConsentScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditTemplateScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteTemplateScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="variables"
            options={modalOptions}
            component={VariablesTemplateScreen}
        />

    </stack.Navigator>
}
