import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ServiceRoutes } from "../service.routes";
import { useState } from "react";

export function EditServiceMemberScreen() {
    const navigation = useNavigation<NavigationProp<ServiceRoutes>>()
    const route = useRoute<RouteProp<ServiceRoutes, 'editMemberService'>>()
    const [priceType, setType] = useState<'EXACT' | 'MINUTE'>('EXACT')

    const inputPrice = priceType === 'EXACT' ?
        [{
            name: 'exactPrice',
            type: 'price',
            groupId: route.params.groupId
        }]
        :
        [{
            name: 'minimumPrice',
            type: 'price',
            groupId: route.params.groupId
        },
        {
            name: 'minutePrice',
            type: 'priceMinute',
            groupId: route.params.groupId
        }]
    return <PutModule<{
        serviceMemberId: string,
        minimumPrice: number;
        exactPrice: number;
        depositPercentage: number;
        serviceId: string;
        minutePrice: number;
        type: 'EXACT' | 'MINUTE'
    }>
        idName="serviceMemberId"
        prefix="updateServiceMember"
        path="service/member"
        id={route.params.serviceMemberId}
        onSuccess={() => {
            navigation.goBack()
        }}
        onLoadData={
            (data) => {
                setType(data.type)
            }
        }
        width={350}
        data={{
            serviceMemberId: route.params.serviceMemberId,
            serviceId: route.params.serviceId,

        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'type',
                type: 'select',
                options: ['EXACT', 'MINUTE'],
                onSelect: (value) => {
                    setType(value as any)
                }
            },


            ...inputPrice,
            {
                name: 'depositPercentage',
                type: 'number',
            },

        ]}
    />
}
