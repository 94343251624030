import { TableComponent } from "@components/table/table.component";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { BookingRoutes } from "../booking.routes";
import { useCurrencyHook } from "../../../../hooks/currency.hook";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";

export function ListBookingScreen(properties: {
    navigation: NavigationProp<BookingRoutes, 'list'>
    route: {
        params: BookingRoutes['list']
    }
}) {
    const navigation = useNavigation<NavigationProp<BookingRoutes, 'list'>>()
    const route = useRoute<RouteProp<BookingRoutes, 'list'>>()
    const currency = useCurrencyHook(route.params.groupId)
    const permissions = usePermissionsHook(properties.route?.params?.groupId)

    return <TableComponent<{
        id: string
        name: string
        createdAt: string
        budget: number
        deposit: number
        Customer: {
            name: string
            surname: string
        },
        Member: {
            color: string,
            name: string,

        },
        startedAt: string
        finishedAt: string
    }>
        groupId={route.params.groupId}
        groupBy="createdAt"
        membering={permissions?.permissions?.meeterAll ? true : false}
        prefix="listBooking"
        header={{
            actions: [
                {
                    label: 'new',
                    icon: 'plus',
                    onPress: () => {
                        navigation.navigate('new', {
                            groupId: route.params.groupId

                        })
                    }
                }
            ]
        }}
        search={true}
        pagination={false}
        path={`meet?groupId=${route.params.groupId}&type=booking`}
        columns={[
            {
                menu: {
                    icon: 'pen',
                    label: 'update',
                },
                always: true,
                icon: 'pen',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('edit', {
                        groupId: route.params.groupId,
                        bookingId: item.id
                    })
                }
            },

            {
                always: true,
                title: 'name',
                renderLabel: (item) => { return item.name },
                width: 200,
                type: 'text',
            },

            {
                phone: false,

                title: 'createdAt',
                getDate: (item) => {
                    return item.createdAt
                },
                type: 'ago',
                width: 220,
            },

            {
                title: 'Customer',
                renderLabel: (item) => { return `${item.Customer.name} ${item.Customer.surname}` },
                width: 140,
                type: 'text',

                menu: {
                    label: 'customer',
                    icon: 'user-tag',
                    onPress: (data: any) => {

                        properties.navigation.navigate('editCustomer', {
                            customerId: data.customerId,
                            groupId: properties.route?.params.groupId
                        })
                    }
                },
            },
            {
                title: 'Member',
                renderLabel: (item) => { return `${item.Member.name}` },
                renderColor: (item) => { return item?.Member?.color },
                width: 200,
                type: 'member',
            },


            {
                title: 'budget',
                renderPrice: (item) => { return item.budget },
                renderCurrency: () => { return `${currency.currency}` },
                width: 120,
                type: 'price',
            },
            {
                title: 'deposit',
                renderPrice: (item) => { return item.deposit },
                renderCurrency: () => { return `${currency.currency}` },
                width: 120,
                type: 'price',
            },

            {
                menu: {
                    icon: 'trash-alt',
                    label: 'delete',
                    onPress: (item) => {
                        navigation.navigate('delete', {
                            groupId: route.params.groupId,
                            bookingId: item.id
                        })
                    }
                },
                icon: 'trash-alt',
                type: 'icon',
                onPress: (item) => {
                    navigation.navigate('delete', {
                        groupId: route.params.groupId,
                        bookingId: item.id
                    })
                }
            },
        ]}

    />

}
