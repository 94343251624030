import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListMemberScreen } from "./list/list.member.screen";
import { EditMemberScreen } from "./edit/edit.member.screen";
import { DeleteMemberScreen } from "./delete/delete.member.screen";
import { NewMemberScreen } from "./new/new.member.screen";
import { MemberRoutes } from "./member.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";
import { NewUserScreen } from "./newUser/newUser.member.screen";

const stack = createNativeStackNavigator<MemberRoutes>();

export function MemberNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'members'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListMemberScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewMemberScreen}
        />
        <stack.Screen
            name="newUser"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewUserScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="meet"
            options={modalOptions}
            component={NewMeetScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditMemberScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteMemberScreen}
        />
    </stack.Navigator>
}
