import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { RoomRoutes } from "../room.routes";

export function EditRoomScreen() {
    const navigation = useNavigation<NavigationProp<RoomRoutes>>()
    const route = useRoute<RouteProp<RoomRoutes, 'edit'>>()

    return <PutModule<{
        name: string
    }>
        prefix="updateRoom"
        path="room"
        id={route.params.roomId}
        onSuccess={() => {
            navigation.goBack()
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={[
            {
                name: 'name',
                type: 'text'
            }
        ]}
    />
}
