/**
 * Signature Template
 * @function SignatureTemplate
 * @description HTML to render the canvas signature
 * @param {string} script - JS to embed in the HTML
 * @returns {string} HTML
 */
export function SignatureTemplate(script: string) {
  return `
<html>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
      *
        {
          margin:0;
          padding:0;
        }

      canvas
        {
          position:absolute;transform:translateZ(0);
          /* In case the React Transformation is not performant, we'll fall back to this one
          margin-left: 0; margin-top: 0;
          transform-origin:left top;
          -ms-transform-origin:left top;
          -webkit-transform-origin:left top;
          transform:rotate(-90deg) translate(-100%, 0px);
          -ms-transform:rotate(-90deg)  translate(-100%, 0px);
          -webkit-transform:rotate(-90deg)  translate(-100%, 0px);*/
        }
  </style>
  <body>
    <canvas></canvas>
    <script>${script}</script>
  </body>
</html>`
}
