import { IconComponent } from "@components/icon/icon.component";
import { IconType } from "@components/icon/icon.types";
import { SpaceComponent } from "@components/space/space.component";
import { NavigationProp, useNavigation, useRoute } from "@react-navigation/native";
import { permissionState } from "@states/permission.state";
import { Image, Linking, Platform, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useRecoilState } from "recoil";

import { Tooltip } from 'react-tooltip'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Environment } from "@environment";
import { userState } from "@states/user.state";
import { TextComponent } from "@components/text/text.component";
import { MainRoutes } from "../../screens/main.routes";




export function DockComponent(properties: {
    setMenuType: (type: 'studio' | 'profile') => void
    setShowInvitations: () => void
    groups: {
        id: string
    }[]
}) {
    const navigation = useNavigation<NavigationProp<MainRoutes>>()

    const { t } = useTranslation()
    const [permission, setPermission] = useRecoilState(permissionState)
    const route = useRoute()
    const [user, setUser] = useRecoilState(userState)
    const groupId = route.params?.groupId
    const [invitations, setInvitations] = useState<{
        id: string
        Group: {
            name: string
        }
    }[]>([])
    const getInvitations = () => {
        if (!user) return;
        axios.get(`${Environment.api}/invitation`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const invitations = response.data.items
            setInvitations(invitations)
        }).catch((error) => {
            console.log({ error })
        })
    }
    useEffect(() => {

        groupId && getInvitations()
    }, [user, groupId])
    return <>
        <DockItem

            title={t('dock.profile')}
            onPress={() => {
                navigation.navigate('user')
            }}
            icon="user"
        />
        {!groupId && <DockItem

            title={t('dock.groups')}
            onPress={() => {
                if (properties.groups && properties.groups?.[0]?.id) {
                    navigation.navigate('studio', {
                        groupId: properties.groups[0].id,
                        screen: 'calendar'
                    })
                }
                else {
                    navigation.navigate('new')
                }
            }}
            icon="store"
        />}
        <DockItem

            title={t('dock.invitations')}
            onPress={() => {
                properties.setShowInvitations()
            }}
            icon="inbox"
            counter={invitations.length}
        />
        {groupId ? <>


            <DockItem

                title={t('dock.newMeet')}

                icon="calendar-plus"
                scene="meets"
                subscene="new"
            />
            <DockItem

                title={t('dock.newConsent')}

                icon="signature"
                scene="consents"
                subscene="new"
            />
            <DockItem
                title={t('dock.newCustomer')}

                icon="user-plus"
                scene="customers"
                subscene="new"
            />




            {permission?.accounter && <DockItem
                title={
                    t('dock.newReceipt')
                }

                icon="receipt"
                scene="receipts"
                subscene="new"

            />}
        </> : <>
            <DockItem
                title={t('dock.newImage')}

                icon="image"
                scene="images"
                subscene="new"
            />
        </>}

        <View style={{ flex: 1 }} />
        {


            <DockItem
                title="Ink on Sky"

                icon="tint" onPress={() => {
                    Linking.openURL('https://inkonsky.com')
                }} />}

        <View style={{ height: 20 }}></View>
    </>
}

export function DockItem(properties: {
    icon: IconType,
    scene?: string,
    subscene?: string,
    title?: string,
    onPress?: () => void
    counter?: number
}) {
    const navigation = useNavigation<any>()
    return <><div
        className="dock-item"
        id={properties.icon}
        style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 20,
            height: 50,
            marginTop: 10,
            width: 50,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#eee',
            display: 'flex',
            backgroundColor: 'rgba(0,0,0,0.05)',
        }

        }
        onClick={() => {
            if (properties.onPress) {
                properties.onPress()
            }
            if (properties.scene && properties.subscene) {
                navigation.navigate(properties.scene, {
                    screen: 'list'
                })
                setTimeout(() => {
                    navigation.navigate(properties.scene, {
                        screen: properties.subscene
                    })
                }, 100)
            }
            else properties.scene && navigation.navigate(properties.scene)
        }}>
        <IconComponent name={properties.icon} style={{
            icon: {
                fontSize: 30,
                color: '#fff'
            }
        }} />

        {
            properties.counter ? <View style={{
                position: 'absolute',
                width: 20,
                height: 20,
                borderRadius: 10,
                backgroundColor: '#fff',
                right: -4,
                top: -4,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <TextComponent styles={{
                    text: {
                        fontSize: 12,
                        color: '#000',
                        fontWeight: 'bold'
                    }
                }}>{properties.counter}</TextComponent>
            </View> : null
        }
    </div >
        <Tooltip
            // Don't forget the `#`!
            anchorSelect={`#${properties.icon}`}
            content={properties.title}
            style={{
                backgroundColor: '#65c366'
            }}
            opacity={1}
            place='right'
        />
    </  >
}
