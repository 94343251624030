import { StyleSheet } from 'react-native'

export const TextStyles = StyleSheet.create({
  component: {},
  inverted: {
    color: '#fff',
  },
  text: {
    fontSize: 15,
  },
  bold: {
    fontWeight: 'bold',
  },
  bar: {
    marginTop: 15,
    height: 7,
    width: 60,
    backgroundColor: '#ce2b36',
  },
})

export const LayoutTextStyles = StyleSheet.create({
  error: {},
  errorInverted: {},
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  detail: {},
  title: {},
  subtitle: {},
  em: {},
  label: {},
  selectInputPlaceholder: {},
  p: {},
})

export const FontTextStyles = StyleSheet.create({
  error: {
    color: 'red',
  },
  errorInverted: {
    color: '#fff',
  },
  h1: {
    fontSize: 33,
    lineHeight: 40,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 30,
    lineHeight: 32,
  },
  h3: {
    fontSize: 17,
    fontWeight: 'bold',
    fontFamily: 'OpenSans-Bold',
    lineHeight: 18,
  },
  h4: {
    fontSize: 14,
  },
  h5: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  p: {
    fontSize: 16,
    lineHeight: 24,
  },
  em: {
    fontSize: 14,
    lineHeight: 25,
  },
  title: {
    fontSize: 24,
    lineHeight: 26,
    fontWeight: '500',
    fontFamily: 'OpenSans-Bold',
  },
  subtitle: {
    fontSize: 15,
    lineHeight: 17,
    fontWeight: '200',
    fontFamily: 'OpenSans-Light',
  },
  detail: {
    fontSize: 14,
    fontWeight: '500',
  },
  label: {
    fontWeight: 'bold',
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
  },
  selectInputPlaceholder: {
    fontSize: 20,
  },
})
