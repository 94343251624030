import { Environment } from "@environment"
import { RouteProp, useIsFocused, useNavigation, useRoute } from "@react-navigation/native"
import { userState } from "@states/user.state"
import axios from "axios"
import { useEffect, useState } from "react"
import { Text, View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import { useRecoilState } from "recoil"
import { MainRoutes } from "./main.routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { MainStackNavigation } from "./main.screens"
import { IconComponent } from "@components/icon/icon.component"
import { reloadGroupState } from "@states/reloadGroup.state"
import { membersState } from "@states/members.state"
import { useTranslation } from "react-i18next"
import { TextComponent } from "@components/text/text.component"

export function MainSelector() {
    const [groups, setGroups] = useState<{
        id: string,
        name: string,
        username: string,
    }[]>([])
    const { t } = useTranslation()
    const [user, setUser] = useRecoilState(userState)
    const navigation = useNavigation<MainStackNavigation>();
    const [open, setOpen] = useState<boolean>(false)
    const isFocused = useIsFocused()
    const [membersSelected, setMembersSelected] = useRecoilState(membersState)
    const getMembers = async (groupid: string) => {
        if (!user) return
        if (!groupid) return
        const response = await fetch(`${Environment.api}/member?groupId=${groupid}&component=selector`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        })
        const data = await response.json()
        const ids = data.items.map((item: any) => item.id)
        setMembersSelected(ids)
    }
    const [groupReload, setGroupReload] = useRecoilState(reloadGroupState)
    const route = useRoute<RouteProp<MainRoutes, 'studio'>>()
    const groupId = route.params?.groupId
    useEffect(() => {

        if (!user) return;
        if (!isFocused) return;
        axios.get(Environment.api + '/group?component=Selector', {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const groups = response.data.items
            setGroups(groups)
        })
            .catch((error) => {
                alert('We are undergoing maintenance, please check in later.')
            }
            )
    }, [user, isFocused, groupReload])

    const group = groups.find((group) => group.id === groupId)


    return <View style={{ zIndex: 100 }}>
        <TouchableOpacity
            style={{
                height: 45,
                marginBottom: 0,
                padding: 9,
                paddingLeft: 25,
                alignContent: 'center',
                alignItems: 'center',
                paddingRight: 16,
                backgroundColor: '#eee',
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderColor: 'rgba(0,0,0,0.1)',
            }}
            onPress={() => {
                setOpen(!open)
            }}>
            <Text style={{
                color: '#000',
                flex: 1,
                fontWeight: 'bold',
            }}>
                {group?.name || 'Select Group'}
            </Text>
            <IconComponent style={{
                box: {
                    width: 25,
                    height: 25,
                    backgroundColor: '#000',
                    borderRadius: 15,
                },
                icon: {
                    fontSize: 12,
                    color: '#fff',
                    lineHeight: 25,
                    textAlign: 'center',
                }
            }} name="angle-down" />
        </TouchableOpacity>
        {
            open && <View style={{
                backgroundColor: '#ddd',
                padding: 10,
            }}>
                {
                    groups.map((group, index) => {
                        return <TouchableOpacity onPress={async () => {
                            // edit navigation parent

                            // first add members:
                            await getMembers(group.id)

                            navigation.reset({
                                index: 0,
                                routes: [{ name: 'studio', params: { groupId: group.id } }],
                            });
                        }}
                            style={{
                                padding: 5,
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <View style={{
                                width: 25,
                                height: 25,
                                borderRadius: 25,
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: groupId === group.id ? '#65c366' : '#ccc',
                            }}>
                                <IconComponent style={{
                                    box: {
                                        width: 25,
                                        height: 25,
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 25,
                                    },
                                    icon: {
                                        fontSize: 13,
                                        color: groupId === group.id ? '#fff' : '#ccc',
                                        width: 25,
                                        height: 25,

                                        fontWeight: 'bold',
                                        lineHeight: 25,
                                        textAlign: 'center',
                                    }
                                }} name={
                                    'check'

                                } />
                            </View>

                            <TextComponent styles={{
                                text: {
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                }
                            }}>
                                {group.name}
                            </TextComponent>
                        </TouchableOpacity>
                    })
                }
                <TouchableOpacity onPress={() => {
                    navigation.navigate('new')
                }} style={{
                    margin: 20,
                    gap: 10,
                    marginLeft: 'auto',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 3,
                    paddingLeft: 12,
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingRight: 16,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    borderRadius: 5,
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.1)',
                    marginHorizontal: 10,
                }}>
                    <TextComponent styles={{
                        text: {
                            color: '#000',
                            flex: 1,
                            fontSize: 12,
                            fontWeight: 'bold',
                        }
                    }}>
                        {t('selector.group')}
                    </TextComponent>
                    <IconComponent style={{
                        box: {
                            height: 14,
                        },
                        icon: {
                            opacity: 1,
                            fontSize: 15,
                            color: '#000'
                        }
                    }} name="plus" />
                </TouchableOpacity>
            </View>
        }

    </View>
}