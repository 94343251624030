import { IconComponent } from "@components/icon/icon.component";
import { TextComponent } from "@components/text/text.component";
import { useIsFocused } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { StorageService } from "../../../services/storage.service";

export function StudioAvatarModule(properties: {
    groupId: string
}) {
    const isFocus = useIsFocused()

    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const [image, setImage] = useState<string>();
    const [name, setName] = useState<string>();
    const [username, setUsername] = useState<string>();
    const [user, setUser] = useRecoilState(userState)
    const [haveSelfie, setHaveSelfie] = useState<boolean>(false)
    useEffect(() => {
        if (!user) return;
        if (!properties.groupId) return;
        axios.get(`${Environment.api}/group?groupId=${properties.groupId}&component=avatarStudio`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const itm = response.data?.items?.[0]
            itm && setHaveSelfie(itm.selfie)
            setName(itm.name)
            setUsername(itm.username)
        }).catch((error) => {
            console.log(error.response.data)
        })
    }, [isFocus, user, properties.groupId])

    const pickImage = async () => {
        if (!user) return;
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });
        if (result?.assets?.[0].uri) {
            // compress width 500
            const compressedImage = await manipulateAsync(
                result.assets[0].uri,
                [{ resize: { width: 500 } }],
                {
                    base64: true,
                    compress: 1, format: SaveFormat.JPEG
                }
            );
            setLoading(true)
            if (!properties.groupId) return
            if (!user) return
            StorageService(user?.accessToken, 'groupSelfie', compressedImage, properties.groupId).then((cleanPath: string) => {
                setImage(`${cleanPath}?cache=${new Date().getTime()}`)
                return axios.put(`${Environment.api}/selfie`, {
                    selfie: true,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                }).then((response) => {
                    if (!properties.groupId) return

                    axios.put(`${Environment.api}/group/selfie?groupId=${properties.groupId}&component=StorageService`, {
                        selfie: true,
                    }, {
                        headers: {
                            Authorization: `Bearer ${user.accessToken}`
                        }
                    }).then((response) => {
                        setLoading(false)
                    }).catch((error) => {
                        console.log(error.response.data)
                    })

                })
            })

        }

    };


    if (!properties.groupId || !name) return (<View></View>)
    return <View style={{
        height: 100,
        backgroundColor: '#eee'
    }}>

        <TouchableOpacity style={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            borderRadius: 30,
            right: 0,
            height: 60,
            width: 60,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#ccc'
        }}
            onPress={() => {
                pickImage()
            }}
        >
            {
                loading && <ActivityIndicator style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    right: 0,
                    zIndex: 100,
                    width: 60, borderRadius: 30,
                    height: 60,
                }} size="small" color="#000" />
            }
            {
                image ? <Image source={{ uri: image }} style={{
                    height: 60, borderRadius: 30,
                    width: 60,

                }} /> : haveSelfie ? <Image source={{ uri: `${Environment.storage}/${properties.groupId}/upload/public/selfie/image.jpg?cache=${new Date().getTime()}` }} style={{
                    height: 60,
                    width: 60,
                    borderRadius: 30,
                }} /> : null

            }
            {
                !loading && !image && !haveSelfie && <IconComponent name="plus" style={{
                    box: {
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }} />
            }
        </TouchableOpacity>
        <View style={{
            left: 100,
            position: 'absolute',
            top: 0,
            bottom: 0,
            justifyContent: 'center'
        }}>
            <TextComponent styles={{
                text: {
                    fontWeight: 'bold',
                    fontSize: 17
                }
            }} content={`${name}`} />

            <TextComponent styles={{
                text: {
                    fontWeight: '400',
                    fontSize: 13,
                    marginTop: -4,
                    lineHeight: 14,
                    opacity: 0.5
                }
            }} content={`@${username}`} />

        </View>
    </View>


}