import { SafeAreaView, ScrollView, Text, View } from "react-native";
import { InputComponent } from "../../components/input/input.component";
import { LineComponent } from "../../components/line/line.component";
import { KnobComponent } from "../../components/knob/knob.component";
import { SpaceComponent } from "../../components/space/space.component";
import { ButtonComponent } from "../../components/button/button.component";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CodeField } from "@components/codex/CodeField";
import { Cursor } from "@components/codex/Cursor";
import { useBlurOnFulfill } from "@components/codex/useBlurOnFulfill";
import { useClearByFocusCell } from "@components/codex/useClearByFocusCell";
import axios from "axios";
import { Environment } from "@environment";
import { ErrorComponent } from "@components/error/error.component";
import { CenterComponent } from "@components/center/center.component";
import LoaderComponent from "@components/loader/loader.component";
import { LogoComponent } from "@components/logo/logo.component";
import { useWeb } from "../../hook/web.hook";

export function RecoveryPasswordScene(properties: {
  navigation: any;
  route: any;
}) {
  const { t, i18n } = useTranslation();
  const [codeValue, setCodeValue] = useState<string>("");
  const navigation = useNavigation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [cache, setCache] = useState<string>();
  const [repeat, setRepeat] = useState<string>("");
  useEffect(() => {}, []);
  const CELL_COUNT = 5;
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: codeValue,
    setValue: setCodeValue,
  });
  const isWeb = useWeb();
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <CenterComponent height={700}>
        {isWeb && <LogoComponent />}
        {isWeb && (
          <>
            <LineComponent />
            <ButtonComponent
              angle={false}
              icon={{
                name: "arrow-left",
              }}
              label={t("RECOVERYPASSWORD_BACK")}
              onPress={() => {
                properties.navigation.navigate("login");
              }}
            />
          </>
        )}
        {error && (
          <ErrorComponent message={t(error)} title="Error" key={cache} />
        )}
        <CodeField
          ref={ref}
          // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
          value={codeValue}
          onChangeText={setCodeValue}
          cellCount={CELL_COUNT}
          rootStyle={{
            padding: 40,
            alignContent: "center",
            justifyContent: "center",
            gap: 10,
          }}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          renderCell={({ index, symbol, isFocused }) => (
            <Text
              key={index}
              style={[
                {
                  width: 50,
                  borderRadius: 5,
                  lineHeight: 60,
                  height: 60,
                  overflow: "hidden",
                  textAlign: "center",
                  fontSize: 30,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 1,
                  fontWeight: "bold",
                  borderColor: "#ddd",
                  backgroundColor: "#fff",
                },
                isFocused && { backgroundColor: "#ddd" },
              ]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          )}
        />
        <View
          style={{
            paddingHorizontal: 20,
          }}
        >
          <InputComponent
            secureTextEntry
            title={t("RECOVERYPASSWORD_NEW_PASSWORD")}
            autoCapitalize="none"
            value={password}
            onChange={(text) => {
              setPassword(text);
            }}
          />

          <SpaceComponent />
          <InputComponent
            secureTextEntry
            title={t("RECOVERYPASSWORD_REPEAT_PASSWORD")}
            autoCapitalize="none"
            value={repeat}
            onChange={(text) => {
              setRepeat(text);
            }}
          />
        </View>

        <KnobComponent
          icon={"arrow-right"}
          onPress={() => {
            if (password !== repeat) {
              setError(t("RECOVERYPASSWORD_PASSWORD_NOT_MATCH"));
              setCache(new Date().getTime().toString());
              return;
            }
            const codeValueNumber = parseInt(codeValue);
            const email_or_username = properties.route.params.email_or_username;
            axios
              .post(`${Environment.api}/recovery/change`, {
                recoveryCode: codeValueNumber,
                email_or_username: email_or_username,
                password,
              })
              .then(() => {
                properties.navigation.pop(2);
              })
              .catch((error) => {
                setError(error.response.data.message);
                setCache(new Date().getTime().toString());
              });
          }}
        />
      </CenterComponent>
    </SafeAreaView>
  );
}
