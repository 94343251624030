import React, { useEffect, useMemo } from 'react';


//asd
const LoaderComponent = (properties: {
    backgroundColor?: string;
}) => {


    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: properties.backgroundColor || 'rgb(242, 242, 242)',
            zIndex: 50,
            bottom: 0,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        }}>
            <span className="loading">
                <span className="loading-dots"></span>
                <span className="loading-dots"></span>
                <span className="loading-dots"></span>
            </span>
        </div>
    );
};

export default LoaderComponent;
