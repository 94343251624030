import React, { useState } from 'react';
import { TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { TextInputProperties } from './text.properties';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';

export function TextInputForm<T>(properties: TextInputProperties<T>) {
    return <View>
        {
            properties.onNew && <TouchableOpacity onPress={() => {
                properties.onNew?.((data: any) => {
                    if ('id' in data) {
                        properties.onChange?.(data.id)
                    }

                    if ('email' in data) {
                        properties.onChange?.(data.email)
                    }
                })
            }} style={{
                backgroundColor: '#ddd',
                padding: 5,
                paddingHorizontal: 10,
                borderRadius: 5,
                position: 'absolute',
                top: -37,
                right: 0,
            }}>
                <TextComponent styles={{
                    text: {
                        fontSize: 11,
                        lineHeight: 16,
                        fontWeight: 'bold',
                    }
                }}>New</TextComponent>
            </TouchableOpacity>
        }
        {
            properties.icon && <IconComponent
                style={{
                    box: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 40,
                        height: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    icon: {
                        fontSize: 20,
                        color: '#333',

                    }
                }}
                name={properties.icon} />
        }
        <TextInputNative
            editable={properties.editable}
            style={{
                borderWidth: 1,
                borderColor: '#ddd',
                padding: 10,
                borderRadius: 5,
                paddingLeft: properties.icon ? 40 : 10,
            }}
            secureTextEntry={properties.secure}
            numberOfLines={properties.lines}
            multiline={properties.lines ? true : false}
            value={properties.value}
            onSubmitEditing={properties.onSubmit}
            onChange={(event) => {
                console.log({
                    text: event.nativeEvent.text
                })
                properties.onChange?.(event.nativeEvent.text)

            }}
        />
        {
            properties.editable === false && <IconComponent name='lock' style={{
                box: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                icon: {
                    fontSize: 20,
                    color: '#333',
                }
            }} />
        }
    </View>
}