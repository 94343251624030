import { IconComponent } from "@components/icon/icon.component";
import { SwitchComponent } from "@components/switch/switch.component";
import { LanguageModule } from "@modules/selectors/language/language.module";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import { InputComponent } from "../../../components/input/input.component";
import { BoxTitle, ErrorRender } from "../box.component";

export function FiveStep(properties: {
    error?: string
    name: string
    setStep: (step: number) => void
    setLanguage: (language: string) => void
    username: string
    setUsername: (username: string) => void
    password: string
    setPassword: (password: string) => void
    legal: boolean
    setLegal: (legal: boolean) => void
    language: string
    submitRegister: () => void
}) {
    const { t, i18n } = useTranslation()

    return <View style={{
        width: '100%',
        maxWidth: 700, padding: 20,
        marginHorizontal: 'auto',
        overflow: 'visible'
    }}>

        <BoxTitle title={`${properties.name}, ${t('onboarding.five.title')}`}
            subtitle={t('onboarding.five.subtitle')} />
        {
            properties.error && <ErrorRender message={properties.error} />

        }
        <View style={{
            padding: 10,
        }}>
            <Text style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: 20,
                fontSize: 20,
                paddingVertical: 10,
            }}>
                {t('onboarding.five.username')}
            </Text>
            <View style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#fff',
                borderRadius: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                overflow: 'hidden',
                shadowOpacity: 0.1,
                shadowRadius: 26.00,
            }}>
                <InputComponent autoCapitalize="none" value={properties.username} onChange={properties.setUsername} />
            </View>
            <Text style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: 20,
                fontSize: 20,
                paddingVertical: 10,
            }}>
                {t('onboarding.five.password')}
            </Text>
            <View style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#fff',
                borderRadius: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                overflow: 'hidden',
                shadowOpacity: 0.1,
                shadowRadius: 26.00,
            }}>
                <InputComponent value={properties.password} onChange={properties.setPassword}

                    secureTextEntry={true} />
            </View>
            <Text style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: 20,
                fontSize: 20,
                paddingVertical: 10,
            }}>
                {t('onboarding.five.language')}
            </Text>
            <View style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#fff',
                borderRadius: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                overflow: 'hidden',
                shadowOpacity: 0.1,
                shadowRadius: 26.00,
            }}>
                <LanguageModule languageId={properties.language}

                    onSelect={(language) => {
                        i18n.changeLanguage(language.id)
                        properties.setLanguage(language.id)
                    }} />
            </View>
            <Text style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: 20,
                fontSize: 20,
                paddingVertical: 10,
            }}>
                {t('onboarding.five.legal')}
            </Text>
            <View style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#fff',
                borderRadius: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 6,
                },
                overflow: 'hidden',
                shadowOpacity: 0.1,
                shadowRadius: 26.00,
                alignContent: 'center',
                justifyContent: 'center',
                paddingRight: 20
            }}>
                <SwitchComponent
                    value={properties.legal}
                    onChange={(value) => {
                        properties.setLegal(value)
                    }}
                    label={t('onboarding.five.legal.agree')} />
            </View>
        </View>
        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 30
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: '#333',
                    padding: 20,
                    paddingHorizontal: 40,
                    borderRadius: 10,
                    alignSelf: 'center',
                    margin: 10,
                    marginTop: 0,
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
                onPress={() => {
                    properties.submitRegister()
                }}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20,
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                    {t('onboarding.five.next')}
                </Text>
                <IconComponent name="arrow-right" style={{
                    icon: {
                        color: '#fff',
                        fontSize: 30,
                        textAlign: 'center',
                    }
                }} />
            </TouchableOpacity>
        </View>
    </View>

}