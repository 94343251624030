import { Image, Text, View } from "react-native";
import { CardSubscriptionType, PaypalSubscriptionType } from "../subscription.types";
import { IconComponent } from "@components/icon/icon.component";
const visa = require('./visa.png')
const mastercard = require('./mastercard.png')
const paypal = require('./paypal.png')
const card = require('./card.png')
export function PaypalPaymentComponent(properties: {
    paypal: PaypalSubscriptionType
}) {
    return <View style={{
        backgroundColor: '#111',
        flexDirection: 'row',
        gap: 40
    }}>
        <View style={{
            // credit card dimensions
            width: 300,
            borderColor: '#444',
            backgroundColor: '#222',
            height: 200,
            padding: 20,
            borderWidth: 0,
            borderRadius: 13,
            overflow: 'hidden',
        }}>
            <Image source={card} style={{
                opacity: 0.4,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            />
            <View style={{
                flexDirection: 'row',
                gap: 10,
                position: 'absolute',
                top: 70,
                left: 0,
                right: 0,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

            </View>

            <Text style={{
                color: 'white',
                position: 'absolute',
                bottom: 27,
                left: 27,
                fontSize: 15,
                fontWeight: 'bold',
                textShadowColor: 'rgba(0,0,0,0.5)',
                textShadowOffset: {
                    width: 0,
                    height: 2
                },
                textShadowRadius: 0,
            }}>{properties?.paypal.payer_email}</Text>

            <Image source={paypal} style={{
                width: 50,
                height: 30,
                position: 'absolute',
                bottom: 20,
                right: 20,
            }}
                resizeMode="contain"
            />
        </View>
    </View>
}