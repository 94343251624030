import { Environment } from "@environment"
import { useIsFocused } from "@react-navigation/native"
import { userState } from "@states/user.state"
import axios from "axios"
import { useEffect, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"

export function useCurrencyHook(groupId: string) {
    const [currency, setCurrency] = useState<string>()
    const focus = useIsFocused()
    const [user] = useRecoilState(userState)
    const getCurrency = () => {
        if (!user) return
        axios.get(`${Environment.api}/group?groupId=${groupId}&component=currency`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        })
            .then((response) => {
                const group = response.data?.items?.[0]
                if (group) {
                    setCurrency(group.currency)
                }
            })
    }
    useEffect(() => {
        getCurrency()
    }, [groupId, user, focus])

    return {
        currency,
    }
}