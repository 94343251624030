// Modules
import { StyleSheet } from 'react-native'

export const SectionStyles = StyleSheet.create({
  component: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    justifyContent: 'center',
  },
})
