export const LanguageConstants = [

    {
        name: 'English',
        id: 'en',
    },
    {
        name: 'Spanish',
        id: 'es',
    },
    {
        name: 'Portuguese',
        id: 'pt',
    },
    {
        name: 'Italian',
        id: 'it',
    },
    {
        name: 'German',
        id: 'de',
    },
    {
        name: 'French',
        id: 'fr',
    },


]
