import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ListMaterialScreen } from "./list/list.material.screen";
import { EditMaterialScreen } from "./edit/edit.material.screen";
import { DeleteMaterialScreen } from "./delete/delete.material.screen";
import { NewMaterialScreen } from "./new/new.material.screen";
import { MaterialRoutes } from "./material.routes";
import { NewMeetScreen } from "../meet/new/new.meet.screen";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StudioRoutes } from "../studio.routes";
import { modalOptionsHook } from "../../../hook/modalOptions.hook";

const stack = createNativeStackNavigator<MaterialRoutes>();

export function MaterialNavigator() {
    const { modalOptions, navigatorOptions } = modalOptionsHook()
    const route = useRoute<RouteProp<StudioRoutes, 'materials'>>()
    const groupId = route.params.groupId
    return <stack.Navigator
        initialRouteName="list"
        screenOptions={navigatorOptions}
    >
        <stack.Screen
            name="list"
            component={ListMaterialScreen}
            initialParams={{ groupId }}
        />
        <stack.Screen
            name="new"
            initialParams={{ groupId }}
            options={modalOptions}
            component={NewMaterialScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="edit"
            options={modalOptions}
            component={EditMaterialScreen}
        />
        <stack.Screen
            initialParams={{ groupId }}
            name="delete"
            options={modalOptions}
            component={DeleteMaterialScreen}
        />
    </stack.Navigator>
}
