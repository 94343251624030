// Modules
import { StyleSheet } from 'react-native'

/**
 * Line Styles
 * @constant {StyleSheet} LineStyles
 */
export const LineStyles = StyleSheet.create({
  component: {
    backgroundColor: '#ddd',
    maxHeight: 1,
    minHeight: 1,
    height: 1,
  },
})
