import { Platform, View } from "react-native";
import WebView from "react-native-webview";

import { createDrawerNavigator } from '@react-navigation/drawer';
import { RouteProp, useRoute } from "@react-navigation/native";
import { useWeb } from "../../hook/web.hook";
import { MainMenu } from "../main.menu";
import { MainRoutes } from '../main.routes';
import { useTranslation } from 'react-i18next';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import { useRecoilValue } from 'recoil';
import { menuState } from '@states/menu.state';

export function ProfessionaScreen() {
    return <View style={{ flex: 1, }}>
        {
            Platform.OS === 'web' ? <iframe
                frameBorder={0}
                src="https://es.inkonsky.com/professional" style={{
                    border: 0,
                    width: '100%', height: '100%'
                }}></iframe> :

                <WebView
                    style={{ flex: 1 }}
                    source={{ uri: 'https://es.inkonsky.com/professional' }} />}
    </View>
}

const Drawer = createDrawerNavigator<any>();
enableExperimentalWebImplementation(true)

export function ProfessionalNavigator() {
    const isWeb = useWeb()
    const { t } = useTranslation()
    const menu = useRecoilValue(menuState)
    return (
        <Drawer.Navigator
            drawerContent={MainMenu}
            screenOptions={{
                gestureHandlerProps: {
                    userSelect: 'auto',
                },
                drawerType: isWeb && menu?.show !== false ? 'permanent' : 'slide',
                headerShown: isWeb ? false : true,
                headerTitleStyle: {
                    fontWeight: 'bold',
                    paddingLeft: 0,
                },
                headerTitleContainerStyle: {
                    marginLeft: 4
                },
                drawerStyle: {
                    width: 350,
                    borderRightWidth: 0,
                },
            }
            } >

            <Drawer.Screen
                name="view"
                component={ProfessionaScreen}
            />

        </Drawer.Navigator >
    );
}


