import { ErrorComponent } from "@components/error/error.component";
import { SpaceComponent } from "@components/space/space.component";
import { SubmitComponent } from "@components/submit/submit.component";
import { TextComponent } from "@components/text/text.component";
import { useNavigation } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View, useWindowDimensions } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { ModalComponent } from "../../../@components/modal/modal.component";
import { InputComponent } from "../../../components/input/input.component";
import { FilmComponent } from "@components/film/film.component";
const videoBlue = require('../../../../assets/blue.mp4')
export function PasswordScreen() {
    const navigation = useNavigation();
    const [currentPassword, setCurrentPassword] = useState<string>()
    const [newPassword, setNewPassword] = useState<string>()
    const [repeatNewPassword, setRepeatNewPassword] = useState<string>()
    const [user, setUser] = useRecoilState(userState)
    const [error, setError] = useState<string>()
    const [cache, setCache] = useState<string>('')
    const { t } = useTranslation()
    const saveNewPassword = () => {
        if (!user) return;
        axios.put(`${Environment.api}/password`, {
            currentPassword: currentPassword,
            newPassword: newPassword,
            repeatNewPassword: repeatNewPassword
        }, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {

            navigation.goBack()

        }).catch((error) => {
            const message = error.response.data.message
            setError(message)
            setCache(Date.now().toString())
        })

    }
    const width = useWindowDimensions().width

    return <>
        <FilmComponent video={videoBlue} prefix="password" maxWidth={500} >

            {
                error && <ErrorComponent message={error} title="Error" key={cache} />
            }
            <KeyboardAwareScrollView extraHeight={100} extraScrollHeight={100} style={{
                padding: 20,
            }}>

                <InputComponent secureTextEntry
                    title={t('password.current')}
                    value={currentPassword} onChange={setCurrentPassword} />
                <InputComponent secureTextEntry
                    title={t('password.new')}
                    value={newPassword} onChange={setNewPassword} />
                <InputComponent secureTextEntry
                    title={t('password.repeat')}
                    value={repeatNewPassword} onChange={setRepeatNewPassword} />
                <SpaceComponent />
                <SubmitComponent title={t('password.submit')}
                    onPress={saveNewPassword} />
            </KeyboardAwareScrollView>

        </FilmComponent>

    </>
}