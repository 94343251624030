import { IconComponent } from "@components/icon/icon.component";
import { Environment } from "@environment";
import {
  NavigationProp,
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { StudioRoutes } from "../studio.routes";
import { QualitiesComponent } from "./components/qualities.component";
import { HeaderToPayComponent } from "./components/headerToPay.component";
import { HeaderActiveComponent } from "./components/headerActive.component";
import { CardPaymentComponent } from "./components/cardPayment.component";
import { SubscriptionType } from "./subscription.types";
import { InformationPaymentComponent } from "./components/informationPayment.component";
import LoaderComponent from "@components/loader/loader.component";
import { useWidth } from "../../../hook/isWidth";
export function SubscriptionScreen() {
  const { t } = useTranslation();
  const [haveSubscription, setHaveSubscription] = useState<boolean>();
  const [subscription, setSubscription] = useState<SubscriptionType>();
  const route = useRoute<RouteProp<StudioRoutes, "subscription">>();
  const focus = useIsFocused();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useRecoilState(userState);
  const [mode, setMode] = useState<"month" | "year">("month");
  const getUrlStripe = (params: { mode: string }) => {
    if (!user) return;
    if (!route.params.groupId) return;
    return axios
      .get(
        `${Environment.api}/subscription?groupId=${route.params.groupId}&mode=${params.mode}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then((response) => {
        const url = response.data.items?.[0]?.url;
        window.open(url, "_self");
      });
  };
  const countCustomersAction = () => {
    setLoading(true);
    if (!user?.accessToken) return;
    if (!route.params.groupId) return;
    axios
      .get(
        `${Environment.api}/subscription/status?groupId=${route.params.groupId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.items.length === 0) {
          setHaveSubscription(false);
          return;
        }
        const group = response.data.items?.[0];
        if (group) {
          setSubscription(group);
        }
      });
  };
  useEffect(() => {
    countCustomersAction();
  }, [focus, route.params.groupId, user?.accessToken]);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#f6f7fa",
      }}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <ScrollView
          style={{
            backgroundColor: "#333",
          }}
        >
          {subscription ? (
            <>
              {subscription.status === "active" ||
              subscription.status === "trialing" ? (
                <HeaderActiveComponent subscription={subscription} />
              ) : (
                <HeaderToPayComponent
                  getUrlStripe={() => {
                    getUrlStripe({ mode });
                  }}
                  hideLink={true}
                  setMode={setMode}
                  mode={mode}
                />
              )}
              <InformationPaymentComponent
                subscription={subscription}
                getUrlStripe={() => {
                  getUrlStripe({ mode });
                }}
              />
            </>
          ) : (
            <>
              <HeaderToPayComponent
                setMode={setMode}
                mode={mode}
                getUrlStripe={() => {
                  getUrlStripe({ mode });
                }}
              />
            </>
          )}

          <QualitiesComponent />
        </ScrollView>
      )}
    </View>
  );
}
