import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

// custom hook for detect platform web and width window more than 768px with useWindo....
export const useWidth = () => {
    const width = useWindowDimensions().width;

    const tablet = width > 768
    const phone = width <= 768
    const small = width <= 576
    const laptop = width > 1000
    const computer = width > 1200

    return {
        tablet,
        phone,
        small,
        computer,
        width,
        laptop
    };
}