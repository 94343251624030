import { Image, Text, View } from "react-native";
import { CardSubscriptionType, SubscriptionType } from "../subscription.types";
import { IconComponent } from "@components/icon/icon.component";
const visa = require('./visa.png')
const mastercard = require('./mastercard.png')
const card = require('./card.png')
export function CardPaymentComponent(properties: {

    card: CardSubscriptionType
}) {
    return <View style={{
        backgroundColor: '#111',
        flexDirection: 'row',
        gap: 40
    }}>
        <View style={{
            // credit card dimensions
            width: 300,
            borderColor: '#444',
            backgroundColor: '#222',
            height: 200,
            padding: 20,
            borderWidth: 0,
            borderRadius: 13,
            overflow: 'hidden',
        }}>
            <Image source={card} style={{
                opacity: 0.4,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            />
            <View style={{
                flexDirection: 'row',
                gap: 10,
                position: 'absolute',
                top: 70,
                left: 0,
                right: 0,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {
                    new Array(3).fill(0).map((item, index) => {
                        return <View style={{
                            marginRight: 10,
                            flexDirection: 'row',
                            gap: 5,
                        }}>
                            {
                                new Array(4).fill(0).map((item, index) => {
                                    return <View style={{
                                        height: 7,
                                        backgroundColor: '#fff',
                                        width: 7,
                                        borderRadius: 10,
                                        shadowColor: 'rgba(0,0,0,0.5)',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2
                                        },
                                        shadowRadius: 0,
                                        shadowOpacity: 1,
                                    }}></View>
                                })
                            }
                        </View>
                    })
                }

                <Text style={{
                    color: 'white',
                    fontSize: 20,
                    textShadowColor: 'rgba(0,0,0,0.5)',
                    textShadowOffset: {
                        width: 0,
                        height: 2
                    },
                    textShadowRadius: 0,


                }}>{properties?.card?.last4}</Text>
            </View>
            <Text style={{
                color: 'white',
                fontSize: 16,
                position: 'absolute',
                bottom: 55,
                textShadowColor: 'rgba(0,0,0,0.5)',
                textShadowOffset: {
                    width: 0,
                    height: 2
                },
                textShadowRadius: 0,
                left: 27,
            }}>{properties?.card?.exp_month}/{properties?.card?.exp_year}</Text>
            <Text style={{
                color: 'white',
                position: 'absolute',
                bottom: 27,
                left: 27,
                fontSize: 15,
                fontWeight: 'bold',
                textShadowColor: 'rgba(0,0,0,0.5)',
                textShadowOffset: {
                    width: 0,
                    height: 2
                },
                textShadowRadius: 0,
            }}>{properties?.card?.name}</Text>
            <Text style={{
                color: 'white',
                position: 'absolute',
                top: 25,
                left: 27,
                fontSize: 10,
                textShadowColor: 'rgba(0,0,0,0.2)',
                textShadowOffset: {
                    width: 0,
                    height: 2
                },
                textShadowRadius: 0,
                textTransform: 'uppercase',
                opacity: 0.5,
            }}>
                {properties?.card?.brand}
            </Text>
            <Image source={properties?.card?.brand === 'visa' ? visa : mastercard} style={{
                width: 50,
                height: 30,
                position: 'absolute',
                bottom: 20,
                right: 20,
            }}
                resizeMode="contain"
            />
        </View>
    </View>
}