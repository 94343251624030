import React, { useEffect, useState } from 'react';
import { Text, TextInput as TextInputNative, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { PriceMinuteInputProperties } from './priceMinute.properties';
import { PriceInputForm } from '../price/price.input';
import { useTranslation } from 'react-i18next';

export function PriceMinuteInputForm<T>(properties: PriceMinuteInputProperties<T>) {

    const { t } = useTranslation()


    return <View style={{
        flexDirection: 'row',
        gap: 10,
    }}>
        <View style={{
            flex: 1
        }}>

            <PriceInputForm
                type='price'
                value={(properties.value || 0)}
                name={properties.name}
                groupId={properties.groupId}
                onChange={(value) => {
                    // only two decimals
                    properties.onChange?.(value);
                }}
            />
        </View>


    </View>
}