import { IconComponent } from "@components/icon/icon.component";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import axios from "axios";
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native";
import { useRecoilState } from "recoil";
import { Environment } from "../../../../environment";
import { TextComponent } from "../../../components/text/text.component";
import { StorageService } from "../../../services/storage.service";
import { userState } from "../../../states/user.state";

export function UserAvatarModule() {
    const { t } = useTranslation()
    const [user, setUser] = useRecoilState(userState)
    const [image, setImage] = useState<string>();
    const [haveSelfie, setHaveSelfie] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const focus = useIsFocused()
    const pickImage = async () => {
        if (!user) return;
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });
        if (result?.assets?.[0].uri) {
            // compress width 500
            const compressedImage = await manipulateAsync(
                result.assets[0].uri,
                [{ resize: { width: 500 } }],
                {
                    base64: true,
                    compress: 1, format: SaveFormat.JPEG
                }
            );
            setLoading(true)
            StorageService(user?.accessToken, 'selfie', compressedImage).then((cleanPath: string) => {
                setImage(`${cleanPath}?cache=${new Date().getTime()}`)
                axios.put(`${Environment.api}/selfie`, {
                    selfie: true,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                }).then((response) => {
                    setLoading(false)
                })
            })

        }

    };
    const navigation = useNavigation();
    useEffect(() => {
        if (!user) return;
        if (!focus) return
        axios.get(`${Environment.api}/me?component=avatar`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response) => {
            const item = response.data?.items?.[0]
            item && setHaveSelfie(item.selfie)
        })
    }, [user, focus])

    if (!user) return <View></View>
    return <View style={{
        height: 100,
        backgroundColor: '#eee',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
    }}>
        <TouchableOpacity style={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            borderRadius: 30,
            right: 0,
            height: 60,
            width: 60,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#ccc'
        }}
            onPress={() => {
                pickImage()
            }}
        >
            {
                loading && <ActivityIndicator style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    right: 0,
                    zIndex: 100,
                    width: 60, borderRadius: 30,
                    height: 60,
                }} size="small" color="#000" />
            }
            {
                image ? <Image source={{ uri: image }} style={{
                    height: 60,
                    width: 60,
                    borderRadius: 30,
                }} /> : haveSelfie ? <Image source={{ uri: `${Environment.storage}/${user.id}/upload/public/selfie/image.jpg?cache=${new Date().getMinutes()}` }} style={{
                    height: 60,
                    width: 60,
                    borderRadius: 30,
                }} /> : null

            }
            {
                !loading && !image && !haveSelfie && <IconComponent name="plus" style={{
                    box: {
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }} />
            }
        </TouchableOpacity>
        <View style={{
            left: 100,
            position: 'absolute',
            top: 0,
            bottom: 0,
            justifyContent: 'center'
        }}>
            <TextComponent styles={{
                text: {
                    fontWeight: 'bold',
                    fontSize: 17
                }
            }} content={`${user.name} ${user.surname}`} />

            <TextComponent styles={{
                text: {
                    fontWeight: '400',
                    fontSize: 13,
                    lineHeight: 14,
                    opacity: 0.5
                }
            }} content={`@${user.username}`} />

        </View>
    </View>
}