import { PutModule } from "@modules/post/put.module";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { MemberRoutes } from "../member.routes";
import { useRecoilState } from "recoil";
import { userState } from "@states/user.state";
import { useEffect, useState } from "react";
import axios from "axios";
import { Environment } from "@environment";

export function EditMemberScreen() {
    const navigation = useNavigation<NavigationProp<MemberRoutes>>()
    const route = useRoute<RouteProp<MemberRoutes, 'edit'>>()
    const [email, setEmail] = useState<string>('')
    const [user, setUser] = useRecoilState(userState)

    useEffect(() => {
        if (route.params.memberId) {
            if (user) {

                axios.get(`${Environment.api}/member?memberId=${route.params.memberId}&groupId=${route.params.groupId}`, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`
                    }
                }).then(response => {
                    setEmail(response?.data?.items?.[0]?.User?.email || '')
                })
            }
        }
    }, [route.params.memberId])



    return <PutModule<{
        email: string
        memberId: string;
        consenter: boolean;
        accounter: boolean;
        administrator: boolean;
        commission: number;
        consenterAll: boolean;
        accounterAll: boolean;
        availabilityView: boolean;
        color: string;
        meet: boolean;
        meeterAll: boolean;
        isPublic: boolean;
    }>
        blocked={!email}
        prefix="updateMember"
        path="member"
        id={route.params.memberId}
        key={email}
        onSuccess={() => {
            navigation.goBack()
        }}
        data={{
            email: email
        }}
        groupId={route.params.groupId}
        onCancel={() => {
            navigation.goBack()
        }}
        inputs={email ? [
            {
                name: 'email',
                type: 'text',
                editable: false
            },
            {
                name: 'isPublic',
                type: 'checkbox'
            },
            {
                name: 'color',
                type: 'color'
            },
            {
                name: 'administrator',
                type: 'checkbox'
            },
            {
                name: 'consenter',
                type: 'checkbox'
            },
            {
                name: 'consenterAll',
                type: 'checkbox'
            },

            {
                name: 'accounter',
                type: 'checkbox'
            },
            {
                name: 'accounterAll',
                type: 'checkbox'
            },

            {
                name: 'meeterAll',
                type: 'checkbox'
            },
            {
                name: 'availabilityView',
                type: 'checkbox'
            },
            {
                name: 'commission',
                type: 'number',
            },
        ] : []}
    />
}
