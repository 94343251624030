import React, { useState } from 'react';
import { ScrollView, TextInput as TextInputNative, TouchableOpacity, View } from 'react-native';

import ColorPicker, { Panel1, Swatches, Preview, OpacitySlider, HueSlider } from 'reanimated-color-picker';
import { SelectInputProperties } from './select.properties';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@components/icon/icon.component';

export function SelectInputForm<T>(properties: SelectInputProperties<T>) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);


    return <View style={{
        borderWidth: 1,
        borderColor: '#ddd',

        borderRadius: 5,
    }}>
        <TouchableOpacity onPress={() => setOpen(!open)} style={{
            flexDirection: 'row',
            gap: 5,
            padding: 10,
            paddingRight: 5,
            paddingVertical: 5,
            alignContent: 'center',
            alignItems: 'center',
        }}>
            {
                properties.value !== undefined ? <TextComponent styles={{
                    text: {
                        fontSize: 13,
                        fontWeight: 'bold',
                    }
                }}>
                    {t(`${properties.optionPrefix ? `${properties.prefix}.` : ''}${properties.name as string}.${properties.value}`)}
                </TextComponent>
                    :
                    <TextComponent styles={{
                        text: {
                            fontSize: 13,
                        }
                    }}>
                        {t(`${properties.optionPrefix ? `${properties.prefix}.` : ''}${properties.name as string}.placeholder`)}
                    </TextComponent>
            }
            <IconComponent name={
                open ? 'angle-up' : 'angle-down'
            } style={{
                box: {
                    marginLeft: 'auto',
                    height: 25,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 25,
                    borderRadius: 30,
                    backgroundColor: '#eee',
                },
                icon: {
                    color: '#111',
                    fontSize: 13,
                }
            }} />
        </TouchableOpacity>


        {
            open && <ScrollView

                style={{
                    maxHeight: 200,
                    borderTopColor: '#ddd',
                    borderTopWidth: 1,
                }}
                contentContainerStyle={{
                    gap: 5,
                    padding: 10
                }}
            >
                {
                    properties.options.map((option, index) => {
                        const v = typeof option === 'object' && option !== null && 'value' in option ? option.value : option;
                        const detail = typeof option === 'object' && option !== null && 'detail' in option ? option.detail : false;
                        const isSelected = properties.value === v;
                        return <TouchableOpacity key={index} onPress={() => {

                            properties.onSelect?.(v);

                            properties.onChange?.(v);
                            setOpen(false);
                        }}
                            style={{
                                padding: 3,
                                paddingHorizontal: 7,
                                borderRadius: 5,
                                backgroundColor: isSelected ? 'rgba(52, 199, 89,0.1)' : '#eee',
                                borderWidth: 2,
                                borderColor: isSelected ? '#65c366' : '#eee',
                                flexDirection: 'row',
                                gap: 4,
                            }}>
                            <View style={{
                                flexDirection: 'row',
                                gap: 5,
                                flex: 1,
                                alignContent: 'center',
                                alignItems: 'center',
                            }}>
                                <View style={{
                                    marginRight: 10,
                                    backgroundColor: isSelected ? '#65c366' : '#ccc',
                                    height: 21,
                                    width: 21,
                                    borderRadius: 3,
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {
                                        isSelected && <IconComponent name='check' style={{
                                            icon: {
                                                color: '#fff',
                                                fontSize: 13,
                                            }
                                        }} />
                                    }
                                </View>
                                <TextComponent styles={{
                                    text: {
                                        fontSize: 13,
                                    }
                                }}>{t(`${properties.optionPrefix ? `${properties.prefix}.` : ''}${properties.name as string}.${v}`)}</TextComponent>
                                {
                                    detail && <TextComponent
                                        styles={{
                                            text: {
                                                fontSize: 13,
                                            }
                                        }}

                                    >{t(`${detail}`)}</TextComponent>
                                }

                            </View>
                        </TouchableOpacity>
                    })
                }
            </ScrollView>
        }
    </View >
}