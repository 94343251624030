import { Environment } from "@environment";
import { ContainerFormModule, FieldsetFormModule } from "@modules/form/form.module";
import { ChooseInputForm } from "@modules/form/inputs/choose/choose.input";
import { ProductsInputForm } from "@modules/form/inputs/products/products.input";
import { SelectInputForm } from "@modules/form/inputs/select/select.input";
import { NavigationProp, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { userState } from "@states/user.state";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { ModalComponent } from "../../../../@components/modal/modal.component";
import { ReceiptRoutes } from "../receipt.routes";
import LoaderComponent from "@components/loader/loader.component";
import { PriceInputForm } from "@modules/form/inputs/price/price.input";
import { usePermissionsHook } from "../../../../hooks/usePermissions.hook";

// component with function that will be call from parent with ref

interface NewReceipt {
    customerId: string;
    memberId: string;
    meetId: string;
    products: {
        units: number;
        productId: string;
        price: number;
        tax: number;
        name: string;
    }[];
    mode: 'deposit' | 'budget';
    method: string;
}


export function NewReceiptScreen() {
    const navigation = useNavigation<NavigationProp<ReceiptRoutes>>()
    const route = useRoute<RouteProp<ReceiptRoutes, 'new'>>()
    const [user,] = useRecoilState(userState)
    // values
    const { permissions } = usePermissionsHook(route.params.groupId)

    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [customerId, setCustomerId] = useState<string | undefined>(route.params.customerId)
    const [memberId, setMemberId] = useState<string>()
    const [meetId, setMeetId] = useState<string>()
    const [totalDeposit, setTotalDeposit] = useState<number>(0)
    const [totalBudget, setTotalBudget] = useState<number>(0)
    const [commission, setCommission] = useState<number>()
    const [products, setProducts] = useState<{
        units: number;
        productId: string;
        price: number;
        tax: number;
        name: string;
    }[]>([])
    const [mode, setMode] = useState<'deposit' | 'budget'>()
    const [method, setMethod] = useState<string>('cash')
    const [status, setStatus] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const getMeetById = async (id: string) => {
        if (!user) return
        if (!route.params.groupId) return

        const response = await axios.get(`${Environment.api}/meet?meetId=${id}&groupId=${route.params.groupId}`, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        })
        const item = response.data?.items?.[0]
        if (item) {
            setTotalDeposit(item.deposit)
            setTotalBudget(item.budget)
            setMeetId(item.id)
            setMemberId(item.memberId)
        }
    }

    useEffect(() => {
        if (route.params.meetId) {
            getMeetById(route.params.meetId)
        }
    }, [route.params.meetId])

    if (!route.params.groupId) return null
    return <ModalComponent title={t('newReceipt.title')}
        description={t('newReceipt.description')}
        onClose={() => {
            navigation.goBack()
        }}>
        {
            loading && <LoaderComponent />
        }
        <ContainerFormModule prefix="newReceipt" onSubmit={() => {
            if (!user) return;
            setLoading(true)
            axios.post(`${Environment.api}/receipt`, {
                customerId,
                memberId,
                groupId: route.params.groupId,
                meetId,
                purchases: products,
                mode,
                status,
                commission,
                method
            }, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }

            }).then((response) => {
                setLoading(false)
                if (route.params.callback) {
                    route.params.callback(response.data)
                }
                else {
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'receipts' }]
                    })
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error)
                setError(error.response.data.message)
            })
        }}>
            {!route.params.customerId && <FieldsetFormModule prefix="newReceipt" name="customerId" error={error ? {
                inputName: 'customerId',
                message: error
            } : undefined}>
                <ChooseInputForm<NewReceipt>
                    value={customerId}
                    name="customerId"
                    type="choose"
                    path="customer"
                    renderLabel={(item) => {
                        return `${item.name} ${item.surname}`
                    }}
                    renderDetail={(item) => {
                        return item.email || undefined
                    }}
                    groupId={route.params.groupId}
                    onSelect={(item) => {
                        setCustomerId(item.id)
                    }}
                />
            </FieldsetFormModule>}
            <FieldsetFormModule prefix="newReceipt" name="products" error={error ? {
                inputName: 'products',
                message: error
            } : undefined}>
                <ProductsInputForm<NewReceipt>
                    value={products}
                    name="products"
                    type="products"
                    customerId={route.params.customerId || customerId}
                    onChange={(value) => {
                        setProducts(value)
                    }}
                    groupId={route.params.groupId}
                />
            </FieldsetFormModule>

            <FieldsetFormModule prefix="newReceipt" name="memberId" error={error ? {
                inputName: 'memberId',
                message: error
            } : undefined}>
                <ChooseInputForm<NewReceipt>
                    value={memberId}
                    name="memberId"
                    type="choose"
                    renderLabel={(item) => {
                        return item.name
                    }}
                    onSelectBackground={(item) => {
                        setCommission(item.commission)
                    }}
                    path="member"
                    groupId={route.params.groupId}
                    onSelect={(item) => {
                        setMemberId(item.id)
                    }}

                />
            </FieldsetFormModule>

            <FieldsetFormModule prefix="newReceipt" name="method" error={error ? {
                inputName: 'method',
                message: error
            } : undefined}>
                <SelectInputForm<NewReceipt>
                    value={method}
                    name="method"
                    type="select"
                    onChange={(value) => {
                        setMethod(value)

                    }}
                    options={['cash', 'transfer', 'credit-card', 'paypal', 'other',]}
                />
            </FieldsetFormModule>
            {
                permissions.administrator && <FieldsetFormModule prefix="newReceipt" name="commision" error={error ? {
                    inputName: 'commision',
                    message: error
                } : undefined}>
                    <PriceInputForm<NewReceipt>
                        value={commission}
                        name="commision"
                        type="price"

                        onChange={(item) => {
                            setCommission(item)
                        }}

                    />
                </FieldsetFormModule>

            }

        </ContainerFormModule>

    </ModalComponent>
}